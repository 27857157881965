<template>
  <div class="container job_hiring">
    <a-breadcrumb separator=">">
      <a-breadcrumb-item>
        <router-link to="/job">校园招聘</router-link>
      </a-breadcrumb-item>
      <a-breadcrumb-item>{{ data_list.name }}</a-breadcrumb-item>
    </a-breadcrumb>
    <div class="row">
      <div class="col-md-8">
        <div class="company">
          <div class="company_logo">
            <img v-if="data_list.is_logo" :src="`/web/image/hw.job.recruit.company/${company_id}/logo`"/>
            <img v-else src="/hw_web/static/src/img/company_logo.png" />
          </div>
          <div class="company_info">
            <h5>
              {{ data_list.name }}
              <span v-if="data_list.city_id">{{ data_list.city_id }}</span>
              <span v-if="data_list.city_id && data_list.county_id"> - </span>
              <span v-if="data_list.county_id">{{ data_list.county_id }}</span>
            </h5>
            <div>
              <span v-if="data_list.company_type">
                <img src="/hw_web/static/src/img/company_type.png" height="18" width="18">
                {{data_list.company_type}}
              </span>
              <span v-if="data_list.company_size">
                <img src="/hw_web/static/src/img/company_people.png" height="20" width="20">
                {{data_list.company_size}}
              </span>
              <span v-if="data_list.industry_str">
                <img src="/hw_web/static/src/img/company_operate.png" height="26" width="26">
                {{data_list.industry_str}}
              </span>
              <span v-for="category in data_list.company_business" :key="category.id">
                <img src="/hw_web/static/src/img/company_environment.png" height="18" width="18">
                {{ category.name }}
              </span>
            </div>
          </div>
        </div>
        <div class="position">
          <h4>在招职位</h4>
          <div v-for="job in data_list.job_ids" :key="job.id">
            <div>
              <h5>
                <router-link :to="`/job/${job.id}`">{{ job.name }}</router-link>
                <span v-for="skill in job.skill_ids" :key="skill.id">{{skill.name}}</span>
              </h5>
              <div class="wage">
                {{ (job.salary_minimum / 10000).toFixed(2) }} - {{ (job.salary_maximum / 10000).toFixed(2) }}万
              </div>
            </div>
            <div>
              <div>
                <span v-if="job.experience_id">{{ job.experience_id }}</span>
                <span v-if="job.education_id">{{ job.education_id }}</span>
              </div>
              <div class="date" v-if="job.publish_date">{{ job.publish_date }}</div>
            </div>
          </div>
        </div>
      </div>
      <div class="col-md-4">
        <div class="introduce">
          <h4>公司介绍</h4>
          <div v-if="data_list.description" v-html="data_list.description"></div>
        </div>
      </div>
    </div>
  </div>
</template>

<script setup>
import { logDebug, logError } from "@/utils/logger";
import { onMounted, ref, reactive } from "vue";
import { getResponseData, jsonRPC } from "@/utils/http_utils";
import { getRouterParam } from "@/utils/router_utils";
import { useRouter } from "vue-router";
import { message } from "ant-design-vue";
import { trackUV } from "@/utils/trackPage";

const router = useRouter();
const company_id = ref(getRouterParam(router, "id"));

const data_list = reactive({});
function fetchData() {
  jsonRPC({
    url: `/api/job/hiring`,
    params: {
      company_id: company_id.value,
    },
    success(res) {
      const data = getResponseData(res);
      logDebug(`获取公司信息成功`, data);
      Object.assign(data_list, data);
    },
    fail(error) {
      logError(`获取公司信息失败`, error);
      message.error(`获取公司信息失败，请稍后再试`);
    },
  });
}
onMounted(() => {
  fetchData();
  trackUV();
});
</script>

<style scoped lang="scss">
.job_hiring {
  margin-bottom: 30px;
  > .row {
    > .col-md-8 {
      display: flex;
      flex-direction: column;
      > .company {
        padding: 20px;
        background-color: #fff;
        margin-bottom: 20px;
        display: flex;
        > .company_logo {
          margin-right: 0.5rem;
          flex-shrink: 0;
          img{
            height: 40px;
            width: 40px;
            border-radius: 50%;
            object-fit: cover;
          }
        }
        > .company_info {
          flex-grow: 1;
          > h5 {
            font-size: 16px;
            font-weight: bold;
            display: flex;
            align-items: center;
            > span {
              display: inline-block;
              margin-left: 15px;
              font-size: 14px;
              color: #34669e;
            }
          }
          >div{
            display: flex;
            flex-wrap: wrap;
            row-gap: 8px; /* 上下间距 */
            column-gap: 15px; /* 左右间距 */
            > span {
              display: inline-block;
              color: #868686;
            }
          }
        }
      }
      > .position {
        flex: 1 0 auto;
        padding: 20px;
        background-color: #fff;
        > h4 {
          width: 145px;
          padding-bottom: 5px;
          border-bottom: 1px solid #0078ff;
          font-size: 18px;
          font-weight: bold;
        }
        > div {
          padding: 15px 0;
          border-bottom: 1px solid #bbb;
          > div {
            display: flex;
            justify-content: space-between;
            &:not(:last-child) {
              margin-bottom: 10px;
            }
            > h5 {
              margin: 0;
              > a {
                font-size: 18px;
                font-weight: bold;
                color: #101010;
                &:hover{
                  color: #ff8c00;
                }
              }
              
              > span {
                background-color: #d9ebff;
                padding: 3px 7px;
                border-radius: 4px;
                margin-bottom: -3px;
                position: relative;
                top: -2px;
                margin-left: 4px;
                font-size: 14px;
              }
            }
            .wage {
                font-size: 16px;
                color: #ff5200;
              }
            > div {
              > span {
                color: #989898;
                margin-right: 15px;
              }
            }
            > .date {
              color: #989898;
            }
          }
        }
      }
    }
    > .col-md-4 {
      > .introduce {
        height: 100%;
        padding: 20px;
        background-color: #fff;
        > h4 {
          width: 145px;
          padding-bottom: 5px;
          border-bottom: 1px solid #0078ff;
          font-size: 18px;
          font-weight: bold;
        }
      }
    }
  }
}
</style>
