import {defineStore} from 'pinia';
import {reactive, toRefs} from 'vue';
import {isEmpty} from "@/utils/common_utils";

const desktopStore = defineStore('desktop', () => {
        const state = reactive({
            type: 'browser', //桌面类型，可选值有： browser、electron
        });

        const setType = function (type) {
            if (isEmpty(type)) {
                type = 'browser'
            }
            type = `${type}`.trim().toLowerCase()
            state.type = type
        }

        return {
            setType,
            ...toRefs(state)
        }
    },
    {
        persist: true,
    }
);
export default desktopStore;
