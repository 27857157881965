<template>
  <div class="container cost">
    <h3>
      费用中心
      <!-- <a-badge :count="5">
        <img src="/hw_web_console/static/src/img/console_message_notification.png"/>
      </a-badge> -->
    </h3>
    <div class="current">
      <div class="row">
        <div class="col-md-6">
          <div>
            <h5>我的算力点</h5>
            <h1>{{parseFloat(quotas.total_points.toFixed(8))}} 算力点
              <a-tooltip title="刷新">
                <a-button type="primary" shape="circle" :loading="loading" @click="handleRefresh" :icon="h(RedoOutlined)" />
              </a-tooltip>
            </h1>
            <a-button type="primary" @click="onTopUp">充值</a-button>
          </div>
        </div>
        <div class="col-md-6">
          <div>
            <h5>当前应用算力消耗总额</h5>
            <h1> {{parseFloat(quotas.consume_points.toFixed(8))}} 算力点 / 小时</h1>
          </div>
        </div>
      </div>
    </div>
    <div class="order_center">
      <h5>
        订单中心
        <router-link to="/order">全部订单<DoubleRightOutlined style="color: #007bff;"/></router-link>
      </h5>
      <div class="menu">
        <a-menu v-model:selectedKeys="menu_current" mode="horizontal" :items="menu_items"/>
        <div class="search_bar">
          <input type="text" v-model="search" @keyup="handleKeyUp" placeholder="请输入订单关键词" />
          <SearchOutlined @click="onSearch"/>
        </div>
      </div>
      <a-table :key="tableKey" :columns="columns" :data-source="orderList" :pagination="pagination" 
        :expandFixed="false" :defaultExpandAllRows="true" :expandIcon="() => null">
        <template #bodyCell="{ column, record, text }">
          <template v-if="record.order_id">
            <template v-if="column.dataIndex === 'order_id'">
              订单号：{{text}}<br/>
              <div class="text-info" v-if="record.order_status == 'TRADING'">自创建订单起，24小时后自动关闭</div>
            </template>
            <template v-if="column.dataIndex === 'product_price_subtotal'">
              <span style="white-space: nowrap;">¥ {{ (text ?? 0).toFixed(2) }}</span>
            </template>
            <template v-if="column.dataIndex === 'actual_payment'">
              <span style="white-space: nowrap;">¥ {{text?.toFixed(2)}}</span>
              <!-- <a v-if="record.order_status == 'TRADING'" style="color: #ff8c00;width: min-content;" class="dropdown-item px-2"
                @click.prevent="continue_payment(record.id,record.order_id)">继续付款</a>
              <span v-else style="white-space: nowrap;">¥ {{text?.toFixed(2)}}</span> -->
            </template>
            <template v-if="column.dataIndex === 'order_status'">
              <span class="badge badge-pill" style="background:blue;color:white" v-if="record.order_status == 'TRADING'">待付款</span>
              <span class="badge badge-pill" style="background:#144af1;color:white" v-if="record.order_status == 'PROCESSING'">交易完成，处理中</span>
              <span class="badge badge-pill" style="background:#0bd734;color:white" v-if="record.order_status == 'FINISHED'">已完成</span>
              <span class="badge badge-pill" style="background:#ffa000;color:white" v-if="record.order_status == 'REFUNDING'">退款，处理中</span>
              <span class="badge badge-pill" style="background:#fc0707;color:white" v-if="record.order_status == 'PART_REFUNDED'">部分已退款</span>
              <span class="badge badge-pill" style="background:#fc0707;color:white" v-if="record.order_status == 'REFUNDED'">已退款</span>
              <span class="badge badge-pill" style="background:#a29b93;color:white" v-if="record.order_status == 'CLOSED'">已关闭</span>
            </template>
            <template v-if="column.dataIndex === 'operation'">
              <a-popconfirm title="确定删除该订单？" @confirm="()=>deleteProduct(record.id)">
                <a class="text-muted" style="white-space: nowrap;">删除订单</a>
              </a-popconfirm>
            </template>
          </template>
          <template v-else-if="!record.is_coupons">
            <template v-if="column.dataIndex === 'order_id'">
              <div class="product_info">
                <img v-if="record.image" :src="`/web/image/product.template/${record.product_id}/image_512`"/>
                <div>
                  <p>{{record.product_name}}</p>
                  <p>
                    <span v-for="(i,index) in record.product_tpl_variant_value_ids" :key="index"
                      class="badge badge-pill" style="background:blue;color:white">{{i}}
                    </span>
                  </p>
                </div>
              </div>
            </template>
            <template v-if="column.dataIndex === 'product_price_unit'">
              <span style="white-space: nowrap;">¥ {{text?.toFixed(2)}}
                <template v-if="record.product_categ_id[1]?.includes('实验环境资源')">/ 天</template>
              </span>
            </template>
            <template v-if="column.dataIndex === 'product_quantity'">
              <span style="white-space: nowrap;">x {{text}}</span>
              <template v-if="record.product_categ_id[1]?.includes('实验环境资源')">天</template>
            </template>
            <template v-if="column.dataIndex === 'product_price_subtotal'">
              <span style="white-space: nowrap;">¥ {{text?.toFixed(2)}}</span>
            </template>
            <template v-if="column.dataIndex === 'operation'">
              <a v-if="record.order_line_state == 'Paid' && record.product_price_subtotal > 0"
                @click.prevent="_requestOrderRefundBtn(record.payment_info_id,record.order_line_id,record.payment_info_id,record.product_price_subtotal)"
                style="color: #cf0000">申请退款</a>
            </template>
          </template>
        </template>
      </a-table>
    </div>
  </div>
</template>

<script setup>
import {DoubleRightOutlined,SearchOutlined,RedoOutlined} from '@ant-design/icons-vue';
import {logDebug, logError} from "@/utils/logger";
import {h, onMounted, reactive, ref, watch, markRaw} from "vue";
import {jsonOwlRPC, jsonRPC, getResponseData} from "@/utils/http_utils";
import {message, Modal} from "ant-design-vue";
import { useRouter } from 'vue-router';
const router = useRouter();

const loading = ref(false);
const handleRefresh = () => {
  loading.value = true; // 开始旋转
  fetchCountData()
  setTimeout(() => {
    loading.value = false; // 1.5秒后停止旋转
    message.success("刷新成功！")
  }, 1500);
};

const onTopUp = () => {
  router.push('/cost/topup');
};

const handlePageChange = (page) => {
  pagination.current = page;
  fetchData();
}
const pagination = reactive({
  // 分页配置
  pageSize: 4, // 每页显示的条数
  showSizeChanger: false, // 是否可以改变每页显示的条数
  pageSizeOptions: ['5', '10', '20'], // 可选的每页显示条数
  showQuickJumper: false, // 是否可以快速跳转到指定页
  showTotal: total => `共 ${total} 条`, // 显示总条数和当前数据范围
  hideOnSinglePage: true, // 只有一页时是否隐藏分页器 true为不显示 false为显示
  current: 1, // 当前页数
  total: 0, // 总条数
  onChange: markRaw(handlePageChange), // 页码改变时的回调函数
})

const menu_current = ref(['FINISHED']);
const menu_items = ref([
  {
    key: 'FINISHED',
    label: '已完成',
  },
  {
    key: 'REFUNDING',
    label: '处理中',
  },
  {
    key: 'CLOSED',
    label: '已关闭',
  },
])

const quotas = reactive({
  total_points: 0,
  consume_points: 0,
});

const orderList = ref([]);
const search = ref("");
const tableKey = ref(0)
const columns = [
  {
    title: '订单内容',
    dataIndex: 'order_id',
  },
  {
    title: '单价',
    dataIndex: 'product_price_unit',
  },
  {
    title: '数量',
    dataIndex: 'product_quantity',
  },
  {
    title: '小计',
    dataIndex: 'product_price_subtotal',
  },
  {
    title: '实付款',
    dataIndex: 'actual_payment',
  },
  {
    title: '订单状态',
    dataIndex: 'order_status',
  },
  {
    title: '操作',
    dataIndex: 'operation',
  },
];
function fetchData() {
  jsonOwlRPC({
    url: `/query/my/order/info`,
    params:{
      page_index: pagination.current,
      name: search.value,
      state: menu_current.value[0],
    },
    success(res) {
      orderList.value = res.records;
      pagination.total = res.record_count;
      tableKey.value += 1
    },
    fail(error) {
      logError(`查询失败, `, error);
    },
  })
}
function fetchCountData() {
  jsonRPC({
    url: `/vue/cost/info`,
    params:{},
    success(res) {
      const data = getResponseData(res)
      logDebug(`获取成功`, data)
      Object.assign(quotas, data);
    },
    fail(error) {
      logError(`查询失败, `, error);
    },
  })
}
onMounted(()=>{
  fetchCountData()
  fetchData()
});
watch([menu_current], fetchData);
const onSearch = () => {
  pagination.current = 1;
  fetchData();
};
const handleKeyUp = (event) => {
  if (event.key === 'Enter') {
    onSearch()
  }
}
const deleteProduct = (order_id)=>{
  jsonOwlRPC({
    url: `/shop/order/delete`,
    params:{
      order_id: order_id,
    },
    success(res) {
      logDebug(res);
      fetchData()
    },
    fail(error) {
      logError(`查询失败, `, error);
    },
  })
}
const _requestOrderRefundBtn = (order_id, line_id, payment_info_id, refund_amount)=>{
  Modal.confirm({
    title: '申请退款',
    content: '您正在为当前订单申请退款，请再次确认！',
    async onOk() {
      try {
        // 使用Promise控制Modal的关闭时机
        return new Promise((resolve, reject) => {
          jsonOwlRPC({
            url: `/payment/refund/application`,
            params: {
              sale_order: order_id,
              sale_order_line: line_id,
              payment_info_id: payment_info_id,
              refund_amount: refund_amount,
            },
            success(res) {
              logDebug(res);
              message.success('退款申请提交成功，我们将在第一时间进行处理，感谢您的耐心等待！');
              fetchData();
              resolve();  // 请求成功后，关闭 Modal
            },
            fail(error) {
              logError(`退款申请失败, `, error);
              message.error(`退款申请失败, ` + error);
              reject();  // 请求失败时，不关闭 Modal，并且显示错误消息
            },
          });
        });
      } catch (error) {
        logError('Oops errors!', error);
        message.error('操作失败，请稍后再试');
      }
    },
  });
}

import {trackUV} from "@/utils/trackPage";
// 在页面加载时触发 UV 埋点
onMounted(() => {
  // 调用 trackUV 来统计 UV 数据
  trackUV(router.currentRoute);
});

</script>

<style scoped lang="scss">
.cost {
  min-height: calc(100vh - 100px);
  display: flex;
  flex-direction: column;
  > h3 {
    display: flex;
    justify-content: space-between;
    align-items: center;
    font-size: 18px;
    font-weight: bold;
    margin: 1rem 0;
    img {
      cursor: pointer;
      height: 30px;
      width: 30px;
    }
  }
  .current {
    .row {
      margin-right: -10px;
      margin-left: -10px;
      .col-md-6 {
        padding-right: 10px;
        padding-left: 10px;
        margin-bottom: 20px;
        > div {
          height: 100%;
          background-color: #fff;
          padding: 15px;
          box-shadow: -1px 2px 4px 0px #a1a1a1;
          border: 1px solid #dbedff;
          border-radius: 8px;
          text-align: center;
          &.renewal_resources {
            .row {
              margin-right: -5px;
              margin-left: -5px;
              > div {
                padding-right: 2.5px;
                padding-left: 2.5px;
                margin-bottom: 8px;
                div {
                  background-color: #ebf5ff;
                  border-radius: 4px;
                  padding: 5px;
                  text-align: center;
                  h4 {
                    font-size: 20px;
                    text-align: center;
                    color: #2481e9;
                    margin-bottom: 0;
                  }
                }
              }
            }
          }
          h5 {
            font-size: 16px;
            margin-bottom: 25px;
            text-align: left;
            font-weight: bold;
            span {
              color: #2481e9;
            }
          }
          button {
            height: 40px;
            width: 100px;
            font-size: 16px;
          }
          h1 {
            display: flex;
            justify-content: center;
            align-items: center;
            gap: 10px;
            font-size: 28px;
            color: #2481e9;
            text-align: center;
            margin-bottom: 18px;
            button{
              height: initial; 
              width: initial; 
              font-size: 14px;
              background-color: #ccc;
              :where(.ant-btn >span){
                display: inline-flex;
              }
            }
          }
          > span {
            color: #0d9527;
            display: block;
            text-align: center;
          }
        }
      }
    }
  }
  .order_center {
    flex: 1 0 auto;
    background-color: #fff;
    padding: 15px;
    box-shadow: -1px 2px 4px 0px #a1a1a1;
    border: 1px solid #dbedff;
    border-radius: 8px;
    margin-bottom: 20px;
    h5 {
      display: flex;
      justify-content: space-between;
      font-size: 16px;
      font-weight: bold;
      a{
        display: flex;
        gap: 5px;
        color: #000;
        font-weight: initial;
        &:hover{
          color: #ff8c00;
        }
      }
    }
    .menu {
      display: flex;
      flex-wrap: wrap;
      justify-content: space-between;
      align-items: center;
      margin-bottom: 0.5rem;
      .search_bar {
        background-color: #F5F5F5;
        border-radius: 3px;
        padding: 0 0.5rem;
        line-height: 28px;
        input {
          border: none;
          background-color: transparent;
          &:focus-visible {
            outline: none;
          }
        }
        >span{
          font-size: 16px;
          color: #2481e9;
          margin-left: 5px;
        }
      }
    }
    .product_info{
      display: flex;
      align-items: center;
      img{
        height: 90px;
        width: 160px;
      }
      >div{
        display: flex;
        flex-direction: column;
        margin-left: 16px;
        p{
          font-weight: bold;
          margin: 0;
        }
        span{
          margin-top: 10px;
        }
      }
    }
  }
}
:where(.ant-menu-light){
  background-color: transparent;
  border-bottom: none;
}
</style>
