<template>
  <div class="class_detail">
    <a-breadcrumb separator=">">
      <a-breadcrumb-item>
        <router-link to="/console/class">培训班级</router-link>
      </a-breadcrumb-item>
      <a-breadcrumb-item>{{ data_list.name }}</a-breadcrumb-item>
    </a-breadcrumb>
    <div class="info">
      <a-flex justify="space-between" align="center">
        <h4>{{ data_list.name }}</h4>
        <a-flex gap="middle">
          <a-button type="primary" @click="copyQRCode">复制二维码</a-button>
          <a-button type="primary" v-if="data_list.is_create" @click="enterForm">编辑</a-button>
        </a-flex>
      </a-flex>
      <a-row :gutter="[16,16]">
        <a-col :span="24" :md="8">
          <a-form-item label="班级编号">{{data_list.hw_class_number}}</a-form-item>
          <a-form-item label="班级名称">{{data_list.name}}</a-form-item>
          <a-form-item label="负责人">
            <span v-for="(item, index) in data_list.teacher_ids" :key="item.id">
              {{ item.name }}<span v-if="index !== data_list.teacher_ids.length - 1">、</span>
            </span>
          </a-form-item>
          <a-form-item label="班级人数">{{data_list.people_number}}</a-form-item>
        </a-col>
        <a-col :span="24" :md="8">
          <a-form-item label="所属院校">{{data_list.school_id}}</a-form-item>
          <a-form-item label="所属院系">{{data_list.department_id}}</a-form-item>
        </a-col>
        <a-col :span="24" :md="8">
          <a-form-item label="班级二维码">
            <img id="qrCode" :src="`/web/image/hw.class/${class_id}/qr_code?t=${new Date().getTime()}`"/>
          </a-form-item>
        </a-col>
      </a-row>
      <a-tabs v-model:activeKey="activeKey">
        <a-tab-pane key="1" tab="班级课程" force-render>
          <a-table :columns="columns1" :dataSource="data_list.course_ids" :pagination="false">
            <template #bodyCell="{ column, record, text }">
              <template v-if="column.dataIndex === 'name'">
                <router-link :to="`/console/class/${class_id}/${record.id}`">{{ text }}</router-link>
              </template>
              <template v-if="column.dataIndex === 'teacher_ids'">
                <span v-for="(item, index) in record.teacher_ids" :key="item.id">
                  {{ item.name }}<span v-if="index !== record.teacher_ids.length - 1">、</span>
                </span>
              </template>
              <template v-if="column.dataIndex === 'exam_time'">
                {{record.exam_start_datetime}} ~ {{record.exam_end_datetime}}
              </template>
              <template v-if="column.dataIndex === 'operation'">
                <router-link :to="`/console/class/${class_id}/${record.id}`">继续学习</router-link>
              </template>
            </template>
          </a-table>
        </a-tab-pane>
        <a-tab-pane key="2" tab="直播实训">
          <a-table :columns="columns2" :dataSource="data_list.live_ids" :pagination="false">
            <template #bodyCell="{ column, record }">
              <template v-if="column.dataIndex == 'teacher_ids'">
                <span v-for="(item, index) in record.teacher_ids" :key="item.id">
                  {{ item.name }}<span v-if="index !== record.teacher_ids.length - 1">、</span>
                </span>
              </template>
              <template v-if="column.dataIndex == 'time'">
                {{record.start_time}} - {{record.end_time}}
              </template>
              <template v-if="column.dataIndex == 'operation'">
                <a :href="`/live/${record.training_id}`">进入直播</a>
              </template>
            </template>
          </a-table>
        </a-tab-pane>
        <template #rightExtra>
          <template v-if="activeKey=='4'">
            <!-- 学期
            <a-select v-model:value="studentForm.semester_id" :allowClear="true"
              show-search :options="data_list.semester_ids" :filter-option="filterOption" placeholder="请选择学期">
            </a-select> -->
          </template>
        </template>
      </a-tabs>
    </div>
  </div>
</template>

<script setup>
import {logDebug, logError} from "@/utils/logger";
import {onMounted, ref, reactive } from 'vue'
import {jsonRPC, getResponseData} from "@/utils/http_utils";
import {getRouterParam } from "@/utils/router_utils";
import { message } from "ant-design-vue";
import { useRouter } from "vue-router";
const router = useRouter();
const class_id = ref(getRouterParam(router, "class_id"));
const enterForm = ()=>{
  router.push(`/console/class/form/${class_id.value}?mode=readonly`)
}

const data_list = reactive({
  name: '',
  hw_class_number: '',
  people_number: '',
  teacher_id: '',
  school_id: '',
  department_id: '',
  is_create: false,
});
function fetchData() {
  jsonRPC({
    url: `/vue/console/class/detail`,
    params:{
      class_id: class_id.value,
    },
    success(res) {
      const data = getResponseData(res);
      logDebug(`获取成功`, data);
      Object.assign(data_list, data);
    },
    fail(error) {
      logError(`查询失败, `, error);
    },
  })
}
onMounted(()=>{
  fetchData()
});
const activeKey = ref('1');
const columns1 = [
  {
    title: '课程ID',
    dataIndex: 'id',
    align: 'center',
  },
  {
    title: '课程名称',
    dataIndex: 'name',
    align: 'center',
  },
  {
    title: '授课老师',
    dataIndex: 'teacher_ids',
    align: 'center',
  },
  {
    title: '开课学期',
    dataIndex: 'semester',
    align: 'center',
  },
  {
    title: '考核方式',
    dataIndex: 'exam_type',
    align: 'center',
  },
  {
    title: '考试时间',
    dataIndex: 'exam_time',
    align: 'center',
  },
  {
    title: '操作',
    dataIndex: 'operation',
    align: 'center',
  },
];
const columns2 = [
  {
    title: '实训ID',
    dataIndex: 'id',
    align: 'center',
  },
  {
    title: '直播名称',
    dataIndex: 'name',
    align: 'center',
  },
  {
    title: '实训讲师',
    dataIndex: 'teacher_ids',
    align: 'center',
  },
  {
    title: '所属课程',
    dataIndex: 'course',
    align: 'center',
  },
  {
    title: '直播时间',
    dataIndex: 'time',
    align: 'center',
  },
  {
    title: '操作',
    dataIndex: 'operation',
    align: 'center',
  },
];
// 复制二维码图片
const copyQRCode = async () => {
  try {
    const qrCodeElement = document.getElementById('qrCode');
    const response = await fetch(qrCodeElement.src);
    const blob = await response.blob();
    const imgBitmap = await createImageBitmap(blob);

    const canvas = document.createElement('canvas');
    canvas.width = imgBitmap.width;
    canvas.height = imgBitmap.height;
    const context = canvas.getContext('2d');
    context.drawImage(imgBitmap, 0, 0);

    const pngBlob = await new Promise(resolve => canvas.toBlob(resolve, 'image/png'));

    const clipboardItem = new ClipboardItem({ 'image/png': pngBlob });

    await navigator.clipboard.write([clipboardItem]);
    message.success('二维码已复制到剪贴板！');
  } catch (err) {
    logError('复制失败：', err);
    message.error('复制失败，请手动复制！');
  }
};
</script>

<style scoped lang="scss">
.class_detail{
  height: 100%;
  width: 100%;
  overflow: auto;
  padding: 16px;
  .info{
    background-color: #fff;
    padding: 25px 40px;
    h4{
      display: inline-block;
      font-size: 18px;
      padding-bottom: 5px;
      padding-right: 5vw;
      border-bottom: 2px solid #63B1FF;
    }
    img{
      width: 100%;
      max-width: 170px;
      max-height: 170px;
    }
  }
}
:deep(.ant-table-wrapper .ant-table-thead >tr>th){
  background-color: #F9FCFF;
}
:deep(.ant-form-item){
  margin-bottom: 2px;
}
</style>
