import Cookies from 'js-cookie'
import {isEmpty, isNotEmpty} from "@/utils/common_utils";
import axios from "axios";
import {logDebug, logError} from "@/utils/logger";
import {encodeBase64} from "@/utils/crypto_utils";
import {AppGlobalConfig} from "@/utils/config_utils";
import router from "@/router";
import {routerReplace} from "@/utils/router_utils";
import desktopStore from "@/stores/desktop";

const _global = {
    // baseURL: 'https://www.hi168.com',
    // 下面这个http://127.0.0.1:8080是本地开发服务器的地址，
    // npm serve会自动启动一个调试服务器，并根据vue.config.js里的devServer配置项进行代理，
    // 从而将http请求转发到真正的后端服务器上。对于hi168而言，就是本地启动的odoo服务器http://127.0.0.1:8069/。
    // baseURL: 'http://127.0.0.1:8080',
    // baseURL: 'https://abctest.hwua.com',
    baseURL: AppGlobalConfig.baseUrl
};


const SessionCookieName = 'session_id'

export function getSessionCookie() {
    return Cookies.get(SessionCookieName)
}

export function setSessionCookie(sessionCookie) {
    return Cookies.set(SessionCookieName, sessionCookie)
}

export function clearSessionCookie() {
    return Cookies.remove(SessionCookieName)
}

export function normalizeURL(url) {
    if (isEmpty(url)) {
        return '';
    }
    if (url.startsWith('https://') || url.startsWith('http://')) {
        return url;
    }
    if (_global.baseURL.endsWith('/')) {
        _global.baseURL = _global.baseURL.substring(0, _global.baseURL.length - 1);
    }
    if (url.startsWith('/')) {
        return _global.baseURL + url;
    }
    return `${_global.baseURL}/${url}`;
}

export const HttpCode = {
    OK: 2000,
    FAIL: 4000,
}


export function jsonRPC(args) {
    const {url, params, success, fail, sessionExpiredHandler} = args;
    const normUrl = normalizeURL(url);
    const method = 'POST'; // odoo的jsonRPC只支持POST方法
    const headers = {'content-type': 'application/json'};
    const data = {
        jsonrpc: '2.0',
        method: 'call',
        params: params,
        id: null,
    };

    const options = {
        method: method,
        url: normUrl,
        data: JSON.stringify(data),
        headers: headers,
    };

    return axios(options)
        .then(function (response) {
            if (isFailedResponse(response)) {
                logError(`isFailedResponse response, `, response);
                if (isSessionExpired(response)) {
                    // 如果是Session过期的错误，就调用sessionExpiredHandler进行回调处理
                    if (typeof sessionExpiredHandler === 'function') {
                        sessionExpiredHandler(response)
                    } else {
                        newSessionExpiredHandler()()
                    }
                } else {
                    if (typeof fail === 'function') {
                        fail(response.data.result.message)
                    }
                }
                return false
            } else {
                if (typeof success === 'function') {
                    success(response);
                }
                return true
            }
        })
        .catch(function (error) {
            logError(`jsonRPC exception, error[${JSON.stringify(error)}]`);
            if (typeof fail === 'function') {
                fail(error);
            }
            return false
        });
}

// 旧的owlRPC
export function jsonOwlRPC(args) {
    const {url, params, success, fail} = args;
    const normUrl = normalizeURL(url);
    const method = 'POST'; // odoo的jsonRPC只支持POST方法
    const headers = {'content-type': 'application/json'};
    const data = {
        jsonrpc: '2.0',
        method: 'call',
        params: params,
        id: null,
    };

    const options = {
        method: method,
        url: normUrl,
        data: JSON.stringify(data),
        headers: headers,
    };

    return axios(options)
        .then((response) => {
            if (response.data.error) {
                if (response.data.error.code == 100) {
                    router.push('/login')
                }
                fail(response.data.error)
                return false
            } else if (response.data.result?.error) {
                fail(response.data.result.error)
                return false
            } else if (response.data.result?.data) {
                success(response.data.result.data)
                return true
            } else if (response.data.result) {
                if (response.data.result.status) {
                    if (response.data.result.status != 200) {
                        fail(response.data.result)
                        return false
                    } else if (response.data.result.records) {
                        success(response.data.result.records)
                        return true
                    } else {
                        success(response.data.result)
                        return true
                    }
                } else if (response.data.result.code) {
                    if (response.data.result.code != 200) {
                        fail(response.data.result.message || response.data.result)
                        return false
                    } else {
                        success(response.data.result)
                        return true
                    }
                } else {
                    success(response.data.result)
                    return true
                }
            } else {
                success(response);
                return true
            }
        })
        .catch((error) => {
            logError(`jsonOwlRPC exception, error[${JSON.stringify(error)}]`);
            if (typeof fail === 'function') {
                fail(error);
            }
            return false
        });
}

export function isFailedResponse(response) {
    return response && response.data && (response.data.error || response.data.result.code !== HttpCode.OK);
}

export function isSucceedResponse(response) {
    return !isFailedResponse(response)
}

export function getSucceedMessage(response) {
    if (response && response.data && response.data.result && isNotEmpty(response.data.result.message)) {
        return response.data.result.message
    } else {
        return {}
    }
}

export function getResponseData(response) {
    if (response && response.data && response.data.result && isNotEmpty(response.data.result.data)) {
        return response.data.result.data
    } else {
        return {}
    }
}

export function getResponseCode(response) {
    if (response && response.data && response.data.result) {
        return response.data.result.code
    } else {
        return -1
    }
}

export function getFailedMessage(error) {
    if (isFailedResponse(error)) {
        if (error && error.data && isNotEmpty(error.data.error)) {
            return error.data.error
        } else if (error && error.data && error.data.result && isNotEmpty(error.data.result.message)) {
            return error.data.result.message
        } else {
            return error
        }
    } else {
        return error
    }
}

// 只有是Odoo判断出是PublicUser访问了需要"user"权限访问的API时，才返回true，其它情况统一返回false。
// 也就是说，如果请求处理过程中，出现诸如网络断开导致response是error的情况，也认为isSessionExpired返回false。
// 这样做的原因在于，本身请求处理中发生了异常后，我们的请求没有正确执行，所以是不能知晓session是否过期的。因此这个时候返回false。
// 至于response中的error错误，会由jsonRPC的fail回调进行处理，所以不会出现错误不被处理的情况。
// 对于Odoo而言，由于服务端不管用户有没有真正登录，都会在浏览器端设置一个名字为session_id的cookie，
// 所以Odoo服务器返回Session Expired代表着两种情况：
// 第一种情况是用户根本就没成功登录过，
// 第二种情况是用户曾经登录过，但是后来session过期了，或者服务器端将这个session设置失效了。
// 以上两种情况，下面的isSessionExpired函数都会返回true。
export function isSessionExpired(response) {
//  当未登录（也就是PublicUer访问需要"user"权限访问的API）时，response的返回值是"code": 100, "message": "Odoo Session Expired",
//    {
//     "data": {
//         "jsonrpc": "2.0",
//         "id": null,
//         "error": {
//             "code": 100,
//             "message": "Odoo Session Expired",
//             "data": {
//                 "name": "odoo.http.SessionExpiredException",
//                 "debug": "Traceback (most recent call last):\n  File \"c:\\zalain\\git\\abc\\abc_v3\\addons\\http_routing\\models\\ir_http.py\", line 449, in _dispatch\n    cls._authenticate(func)\n  File \"c:\\zalain\\git\\abc\\abc_v3\\hwua\\hw_core\\libs\\models.py\", line 307, in _authenticate\n    res = super(IrHttp, cls)._authenticate(endpoint=endpoint)\n  File \"C:\\zalain\\git\\abc\\abc_v3\\odoo\\addons\\base\\models\\ir_http.py\", line 126, in _authenticate\n    getattr(cls, \"_auth_method_%s\" % auth_method)()\n  File \"C:\\zalain\\git\\abc\\abc_v3\\odoo\\addons\\base\\models\\ir_http.py\", line 96, in _auth_method_user\n    raise http.SessionExpiredException(\"Session expired\")\nException\n\nThe above exception was the direct cause of the following exception:\n\nTraceback (most recent call last):\n  File \"C:\\zalain\\git\\abc\\abc_v3\\odoo\\http.py\", line 644, in _handle_exception\n    return super(JsonRequest, self)._handle_exception(exception)\n  File \"C:\\zalain\\git\\abc\\abc_v3\\odoo\\http.py\", line 302, in _handle_exception\n    raise exception.with_traceback(None) from new_cause\nodoo.http.SessionExpiredException: Session expired\n",
//                 "message": "Session expired",
//                 "arguments": [
//                     "Session expired"
//                 ],
//                 "context": {}
//             }
//         }
//     },
// }
    if (response && response.data && response.data.error) {
        const {code, message} = response.data.error
        return `${code}` === '100' && `${message}` === 'Odoo Session Expired';
    }
    return false;
}

// router是当前组件setup中创建的router对象，loginPath是登录页面的路径，默认值是'/login'
export function newSessionExpiredHandler(loginPath = '/web/login') {
    return function () {
        const $desktopStore = desktopStore();
        if ($desktopStore && `${$desktopStore.type}`.trim().toLowerCase() === 'electron') {
            routerReplace(router, '/login_desktop');
        } else {
            const redirect = encodeBase64(window.location.href)
            logDebug(`redirect[${redirect}]`)
            logDebug(`${loginPath}?redirect=${redirect}`)
            // window.location.href = `${loginPath}?redirect=${redirect}`;
            router.push('/login')
        }
    }
}
