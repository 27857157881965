import {Base64} from 'js-base64';

export function encodeBase64(str) {
    return Base64.encode(str);
}

export function decodeBase64(encodedStr) {
    return Base64.decode(encodedStr);
}

