<template>
  <div class="container job_exercise">
    <a-breadcrumb separator=">">
      <a-breadcrumb-item>
        <router-link to="/job">校园招聘</router-link>
      </a-breadcrumb-item>
      <a-breadcrumb-item>面试刷题</a-breadcrumb-item>
    </a-breadcrumb>
    <div class="row">
      <div class="col-md-8">
        <component :is="currentComponent" @call-parent-method="parentMethod" @call-parent-refresh="parentRefresh" :exercise_id=exercise_id></component>
      </div>
      <div class="col-md-4">
        <div class="myexercise" v-if="data_list.user">
          <h4>
            <img v-if="data_list.user.has_image" :src="`/web/image/res.users/${data_list.user.id}/image_512`"/>
            <img v-else src="/hw_web/static/src/img/avatar_live.png">
            {{data_list.user.name}}
          </h4>
          <p>
            刷题数：<span>{{ data_list.user.exercise_num }}</span>次
            <span class="mx-2"></span>
            刷题通过率：<span>{{ data_list.user.exercise_rate }}</span>%
          </p>
          <p>
            累计刷题时长：
            <span>{{ Math.floor(data_list.user.exercise_duration / 60) }}</span>小时
            <span>{{ data_list.user.exercise_duration % 60 }}</span>分钟
          </p>
        </div>
        <div class="ranking">
          <h4>刷题排行榜</h4>
          <div v-for="(leaderboard, index) in data_list.leaderboards" :key="index" :title="leaderboard.name">
            <div v-if="index < 3">
              <img src="/hw_web/static/src/img/medal.png" />
              {{ leaderboard.name }}
            </div>
            <div v-else>{{ leaderboard.name }}</div>
            <p>
              刷题数：<span>{{ leaderboard.exercise_count }}</span>次
              <span class="mx-2"></span>
              通过率：<span>{{ leaderboard.exercise_ratio }}</span>%
            </p>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script setup>
import { logDebug, logError } from "@/utils/logger";
import { onMounted, ref, reactive, computed } from "vue";
import { jsonOwlRPC } from "@/utils/http_utils";
import { getRouterParam, getRouterQuery } from "@/utils/router_utils";
import { useRouter } from "vue-router";
import { message } from "ant-design-vue";
import { trackUV } from "@/utils/trackPage";

const router = useRouter();
const company_id = ref(getRouterParam(router, "id"));
const paper_id = ref(getRouterQuery(router, "paper_id"));

// 示例数据
const data_list = reactive({
  user: null,
  leaderboards: []
});
function fetchData() {
  jsonOwlRPC({
    url: `/job/exercise/score/ranking`,
    params: {
      company_id: company_id.value,
    },
    success(res) {
      logDebug(`获取练习成功`, res);
      Object.assign(data_list, res);
    },
    fail(error) {
      logError(`获取练习失败`, error);
      message.error(`获取练习失败，请稍后再试`);
    },
  });
}
onMounted(() => {
  fetchData();
  trackUV();
});
// 组件切换
import exerciseDetail from "./JobExerciseDetail.vue";
import exerciseList from "./JobExerciseList.vue";
const selectedKey = ref("list");
const exercise_id = ref();
if (paper_id.value) {
  exercise_id.value = paper_id.value;
  selectedKey.value = 'detail';
}
const components = {
  detail: exerciseDetail,
  list: exerciseList,
};
const currentComponent = computed(() => {
  return components[selectedKey.value];
});
function parentMethod(e) {
  selectedKey.value = e.component;
  exercise_id.value = e.exercise_id;
}
function parentRefresh() {
  fetchData();
}
</script>

<style scoped lang="scss">
.job_exercise {
  margin-bottom: 30px;
  > .row {
    > .col-md-4 {
      > .myexercise {
        padding: 20px;
        background-color: #fff;
        margin-bottom: 20px;
        h4 {
          font-size: 16px;
          font-weight: bold;
          img{
            height: 40px;
            width: 40px;
            margin-right: 15px;
            border-radius: 50%;
          }
        }
        > div {
          display: flex;
          align-items: center;
          margin-bottom: 10px;
          > div {
            height: 40px;
            width: 40px;
            border-radius: 50%;
            overflow: hidden;
            flex-shrink: 0;
          }
          
        }
        > p {
          margin: 0;
          &:not(:last-child) {
            margin-bottom: 10px;
          }
          > span {
            color: #0078ff;
          }
        }
      }
      > .ranking {
        overflow: hidden;
        padding: 20px;
        background-color: #fff;
        h4{
          font-size: 18px;
        }
        > div {
          display: flex;
          justify-content: space-between;
          align-items: center;
          margin-bottom: 10px;
          line-height: 25px;
          img {
            height: 25px;
            width: 25px;
          }
          > div {
            flex-grow: 1;
            flex-shrink: 1;
            overflow: hidden;
            white-space: nowrap;
            text-overflow: ellipsis;
            // display: flex;
            // align-items: center;
            // >.avatar{
            //     height: 25px;
            //     width: 25px;
            //     border-radius: 50%;
            //     overflow: hidden;
            //     flex-shrink: 0;
            //     margin-right: 5px;
            // }
          }
          > p {
            flex-grow: 0;
            flex-shrink: 0;
            margin: 0;
            > span {
              color: #0078ff;
            }
          }
        }
      }
    }
  }
}
</style>
