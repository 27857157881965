<template>
  <div class="slider-wrapper">
    <div class="slider-container">
      <input
        type="range"
        :min="0"
        :max="markKeys.length - 1"
        :step="1"
        v-model="currentIndex"
        @input="updateValue"
        :style="sliderBackground"
        :disabled="isDisabled"
      />
    </div>
    <div class="ticks">
      <span
        v-for="(label, i) in markKeys"
        :key="i"
        class="tick"
        :style="{ left: (i / (markKeys.length - 1)) * 100 + '%' }"
      >
        {{ marks[label] }}
      </span>
    </div>
  </div>
</template>

<script setup>
import { ref, computed, watch } from "vue";

const props = defineProps({
  modelValue: {
    type: Number,
    required: true,
  },
  marks: {
    type: Object,
    default: () => ({}),
  },
  disabled: {
    type: Boolean,
    default: false,
  }
});

const emit = defineEmits(["update:modelValue"]);

// 从 marks 对象中获取键（刻度的值）
const markKeys = computed(() => Object.keys(props.marks).map(Number));

// 找到与传入的 modelValue 最接近的刻度值
const findClosestIndex = (value) => {
  let closestIndex = 0;
  let closestDiff = Infinity;
  markKeys.value.forEach((key, index) => {
    const diff = Math.abs(key - value);
    if (diff < closestDiff) {
      closestIndex = index;
      closestDiff = diff;
    }
  });
  return closestIndex;
};

// 通过当前的 modelValue 确定滑动条初始值
const currentIndex = ref(findClosestIndex(props.modelValue));

// 计算禁用状态
const isDisabled = computed(() => props.disabled);

// 更新值
const updateValue = () => {
  if (!isDisabled.value) {
    // 通过当前的 index 获取 marks 的实际值
    emit("update:modelValue", markKeys.value[currentIndex.value]);
  }
};

// 如果 modelValue 改变，重新计算 currentIndex
watch(
  () => props.modelValue,
  (newVal) => {
    currentIndex.value = findClosestIndex(newVal);
  },
  { immediate: true }
);

// 计算滑动条背景颜色，选中部分为蓝色
const sliderBackground = computed(() => {
  const percentage = (currentIndex.value / (markKeys.value.length - 1)) * 100;
  return {
    background: `linear-gradient(to right, #3b82f6 0%, #3b82f6 ${percentage}%, #e0e0e0 ${percentage}%, #e0e0e0 100%)`,
  };
});
</script>

<style lang="scss" scoped>
$slider-thumb-size: 16px;
$slider-color: #3b82f6;
$slider-background: #e0e0e0;
$slider-disabled-color: #dcdcdc;
$slider-disabled-thumb-color: #b0b0b0;

.slider-wrapper {
  width: 100%;
  height: 40px;
  display: flex;
  flex-direction: column;
  justify-content: center;

  .slider-container {
    display: flex;
    align-items: center;
    gap: 10px;

    input[type="range"] {
      width: 100%;
      -webkit-appearance: none;
      height: 6px;
      border-radius: 5px;
      outline: none;
      transition: background 0.15s ease-in-out;

      &::-webkit-slider-thumb {
        -webkit-appearance: none;
        width: $slider-thumb-size;
        height: $slider-thumb-size;
        border-radius: 50%;
        background-color: $slider-color;
        cursor: pointer;
        border: 2px solid white;

        // 禁用状态下的样式
        &:disabled {
          background-color: $slider-disabled-thumb-color;
          border-color: white;
          cursor: not-allowed;
        }
      }

      &::-moz-range-thumb {
        width: $slider-thumb-size;
        height: $slider-thumb-size;
        border-radius: 50%;
        background-color: $slider-color;
        cursor: pointer;
        border: 2px solid white;

        // 禁用状态下的样式
        &:disabled {
          background-color: $slider-disabled-thumb-color;
          border-color: white;
          cursor: not-allowed;
        }
      }

      // 禁用状态下的样式
      &:disabled {
        background-color: $slider-disabled-color;
        cursor: not-allowed;
      }
    }
  }

  .ticks {
    user-select: none;
    position: relative;
    display: flex;
    justify-content: space-between;
    margin: 5px;

    .tick {
      position: absolute;
      top: 0;
      text-align: center;
      font-size: 12px;
      transform: translateX(-50%);
      margin: 0;
      white-space: nowrap;
      color: #00000073;
    }
  }
}
</style>
