<template>
	<div class="snow-container" ref="snowContainer" @click="onClick()">
		<canvas id="snowCanvas" ref="snowCanvas"></canvas>
	</div>
</template>

<script setup>
import {
	ref,
	onMounted,
	onUnmounted,
	reactive
} from 'vue';
//https://www.jb51.net/html5/790625.html
const snowCanvas = ref(null);
const snowContainer = ref(null)
const data = reactive({
	ctx: {},
	timer: {},
	offset: 0, // 波段偏移位置
	//imgData : null,            // 画布图信息
	text: "没信号", // 绘制文字
	alpha: 0, // 透明度
	alphaNum: 0.005, // 透明度衰减值
	alphaMax: 0.35, // 透明度极值
})
onMounted(() => {
	data.ctx = snowCanvas.value.getContext('2d');
	data.timer = setInterval(() => {
		printSnow()
		printText()
		drawFrame()
	}, 40);

});
onUnmounted(() => {
	clearTimeout(data.timer)
})

const onClick = () => {
	data.ctx = snowCanvas.value.getContext('2d');
}

const printSnow = () => {
	let w = snowCanvas.value.clientWidth
	let h = snowCanvas.value.clientHeight//宽高和像素数不是一码事
	snowCanvas.value.width = w
	snowCanvas.value.height = h//设置canvas 像素
	data.ctx.fillStyle = 'white';
	data.ctx.fillRect(0, 0, w, h);
	data.ctx.fill();
	let imgData = data.ctx.getImageData(0, 0, w, h);
	for (let i = 0, data = imgData.data; i < data.length; i += 4) {
		let color = Math.floor(Math.random() * 255) + 50;
		data[i] = color;
		data[i + 1] = color;
		data[i + 2] = color;
	}
	data.ctx.putImageData(imgData, 0, 0);

	data.alpha += data.alphaNum;
	if (data.alpha >= data.alphaMax) {
		data.alpha = data.alphaMax;
		data.alphaNum *= -1;
	} else if (data.alpha < 0) {
		data.alpha = 0;
		data.alphaNum *= -1;
	}
	let fontSize = w * 0.16;
	data.ctx.save();
	data.ctx.fillStyle = `rgba(0,0,0,${data.alpha})`
	data.ctx.font = `bold ${fontSize}px fantasy, monospace`;
	data.ctx.textAlign = "center";
	data.ctx.textBaseline = 'middle';
	data.ctx.shadowColor = "black";
	data.ctx.shadowBlur = 10;
	data.ctx.fillText(data.text, w / 2, h / 2);
	data.ctx.restore();
	
	data.offset = 10;
	for (let y = -h / 5, v = h / 5; y < h; y += v) {
		data.ctx.fillStyle = `rgba(0,0,0,${(data.alphaMax - data.alpha) / 8 + 0.02})`;
		data.ctx.shadowColor = "black";
		data.ctx.shadowBlur = 20;
		data.ctx.fillRect(0, y + data.offset % v, w, v / 2);
	}
}

const printText = () => {
	
}

const drawFrame = () => {
	
}
</script>

<style lang="less" scoped>
#snowCanvas {
	width: 100%;
	height: 100%;
	position: absolute;
	top: 0;
	left: 0;
	right: 0;
	bottom: 0;
}

.snow-container {
	width: 100%;
	height: 100%;
	display: flex;
	align-items: center;
	justify-content: center;
	background-color: transparent;
}

.snow-container::after {
	content: "";
	display: block;
	position: absolute;
	top: 0;
	bottom: 0;
	left: 0;
	right: 0;

	background: radial-gradient(ellipse at center,
			rgba(0, 0, 0, 0) 0%,
			rgba(0, 0, 0, 0.6) 100%);
}
</style>
