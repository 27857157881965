<template>
  <div class="container blog_write">
    <a-breadcrumb separator=">">
      <a-breadcrumb-item>
        <router-link to="/blog">技术论坛</router-link>
      </a-breadcrumb-item>
      <a-breadcrumb-item>发帖</a-breadcrumb-item>
    </a-breadcrumb>
    <a-form ref="formRef" :model="data_list" :rules="rules" 
      :label-col="labelCol" :wrapper-col="wrapperCol" :disabled="editorMode === 'readonly'">
      <a-form-item label="标题" name="name">
        <a-input v-model:value="data_list.name" placeholder="请输入标题"/>
      </a-form-item>
      <a-form-item label="技术分区" name="category_id">
        <a-select v-model:value="data_list.category_id" style="width: 100%" :options="data_list.category_ids"
                  show-search :filter-option="filterOption" :allowClear="true" placeholder="请选择技术分区">
          <a-select-option value="">选择视频库里的视频</a-select-option>
        </a-select>
      </a-form-item>
      <a-form-item label="正文">
        <RichEditor height="500" :editMode="editorMode" v-model="data_list.content"/>
      </a-form-item>
      <a-flex justify="center" align="center" gap="20">
        <a-button type="primary" @click="onPublish">发布</a-button>
        <a-button @click="onCancel">取消</a-button>
      </a-flex>
    </a-form>
  </div>
</template>

<script setup>
import RichEditor from "@/components/RichEditor.vue";
import { logDebug, logError } from "@/utils/logger";
import { onMounted, ref, reactive } from "vue";
import { getResponseData, jsonRPC } from "@/utils/http_utils";
import { message } from "ant-design-vue";
import { trackUV } from "@/utils/trackPage";
import { useRouter } from "vue-router";
const router = useRouter();

const editorMode = ref('create')
const formRef = ref()
const rules = {
  name: [
    {
      required: true,
      message: '请输入标题',
    },
  ],
  category_id: [
    {
      required: true,
      message: '请选择技术分区',
      trigger: 'change',
    },
  ],
}
const labelCol = {style: {width: '100px',},};
const wrapperCol = {span: 20,};

const data_list = reactive({
  category_id: null,
  category_ids: [],
  name: null,
  subtitle: null,
  tag_ids: null,
  content: null,
});
const filterOption = (input, option) => {
  return option.label.toLowerCase().indexOf(input.toLowerCase()) >= 0;
};
const onPublish = ()=>{
  formRef.value.validate()
  .then(() => {
    jsonRPC({
      url: `/api/blog/write`,
      params: {
        category_id: data_list.category_id,
        name: data_list.name,
        // subtitle: data_list.subtitle,
        // tag_ids: data_list.tag_ids,
        content: data_list.content,
      },
      success() {
        onCancel()
        message.success(`创建成功`);
      },
      fail(error) {
        logError(`创建失败`, error);
        message.error(`创建失败，请稍后再试`);
      },
    });
    return
  })
  .catch(error => {
    logError('error', error);
    return
  });
}
const onCancel = ()=>{
  router.push("/blog")
}
function fetchData() {
  jsonRPC({
    url: `/api/blog/write`,
    params: {},
    success(res) {
      const data = getResponseData(res);
      logDebug(`获取发帖成功`, data);
      Object.assign(data_list, data);
    },
    fail(error) {
      logError(`获取发帖失败`, error);
      message.error(`获取发帖失败，请稍后再试`);
    },
  });
}
onMounted(() => {
  fetchData();
  trackUV();
});
</script>

<style scoped lang="scss">
.blog_write {
  margin-bottom: 30px;
  .ant-form{
    background-color: #fff;
    padding: 20px;
  }
}
</style>
