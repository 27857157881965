// stores/debugStore.js
import { defineStore } from 'pinia';
import { reactive, toRefs } from 'vue';

const useDebugStore = defineStore('debugStore', () => {
    // 定义响应式状态
    const state = reactive({
        debugStates: {},  // 存储多个envId的调试状态
    });

    // 设置调试状态
    const setDebugSettings = (envId, isDebug) => {
        // 设置指定envId的调试状态
        state.debugStates[envId] = isDebug;
    };

    // 获取调试状态
    const getDebugSettings = (envId) => {
        return state.debugStates[envId] || false; // 如果不存在则默认为false
    };

    // 返回设置函数和响应式的state
    return {
        setDebugSettings,
        getDebugSettings,
        ...toRefs(state),  // 转换state为可解构的响应式对象
    };
}, {
    persist: false,  // 不启用持久化（根据需求来决定）
});

export default useDebugStore;  // 默认导出
