<template>
  <div class="blog_detail">
    <a-breadcrumb separator=">">
      <a-breadcrumb-item>
        <router-link to="/blog">技术论坛</router-link>
      </a-breadcrumb-item>
      <a-breadcrumb-item>{{ data_list.name }}</a-breadcrumb-item>
    </a-breadcrumb>
    <a-row gutter="20">
      <a-col :span="19">
        <div class="info">
          <div class="blog_list">
            <div class="blog_list_top">
              <h5>
                <router-link :to="`/blog/${blog_id}`">{{data_list.name}}</router-link>
                <span v-for="i in data_list.tag_ids" :key="i.id">{{i.name}}</span>
              </h5>
              <span class="ml-3">{{ data_list.post_date }}</span>
            </div>
            <div class="blog_list_content">
              <div>
                <router-link :to="`/blog/homepage?user_id=${data_list.create_uid?.id}`" class="user">
                  <img :src="`/web/image/res.users/${data_list.create_uid?.id}/image_128`"/>
                  {{ data_list.create_uid?.name }}
                </router-link>
                <a-typography-text :style="{overflow: 'hidden',textOverflow: 'ellipsis',whiteSpace: 'normal',width: '100%',}" :ellipsis="false">
                  <div v-html="data_list.content"></div>
                </a-typography-text>
              </div>
              <img v-if="data_list.is_image" :src="`/web/image/hw.blog.post/${blog_id}/image_512`"/>
            </div>
            <div class="blog_list_bottom">
              <a-tooltip placement="bottom">
                <template #title>浏览量</template>
                <span>
                  <img src="/hw_blog/static/src/img/visited.png" />
                  <span>{{ data_list.visits }}</span>
                </span>
              </a-tooltip>
              <a-tooltip placement="bottom">
                <template #title>评论人数</template>
                <span>
                  <img v-if="data_list.is_comments" src="/hw_blog/static/src/img/info.png"/>
                  <img v-else src="/hw_blog/static/src/img/comment.png" />
                  <span>{{ data_list.comments }}</span>
                </span>
              </a-tooltip>
              <a-tooltip placement="bottom">
                <template #title>点赞</template>
                <span>
                  <img v-if="data_list.is_likes" @click="onBlogLike" src="/hw_blog/static/src/img/liked.png"/>
                  <img v-else @click="onBlogLike" src="/hw_blog/static/src/img/praise.png"/>
                  <span>{{ data_list.likes }}</span>
                </span>
              </a-tooltip>
            </div>
          </div>
          <a-comment>
            <template #avatar>
              <a-avatar v-if="data_list.user?.has_image" :src="`/web/image/res.users/${data_list.user?.id}/image_512`" :size="40" style="margin-top: 4px"/>
              <a-avatar v-else src="/hw_web/static/src/img/avatar_live.png" :size="40" style="margin-top: 4px"/>
            </template>
            <template #content>
              <a-flex justify="space-between" align="center" gap="12">
                <a-input v-model:value="commentValue" @keydown.enter="handleSubmit" auto-size placeholder="等待您的评论" style="height: 40px; border-color: #77b7ff"/>
                <a-button :loading="submitting" type="primary" @click="handleSubmit" style="height: 40px; background-color: #77b7ff">回帖</a-button>
              </a-flex>
            </template>
          </a-comment>
          <div class="comment_list px-4">
            <div class="item border-bottom pb-4 mb-4" v-for="row in data_list.comment_ids" :key="row.id">
              <div class="comment_content d-flex">
                <div class="avatar mr-2">
                  <img :src="`/web/image/res.users/${row.create_uid.id}/image_512`" class="mr-2"/>
                </div>
                <div class="content_info">
                  <div class="top mb-2">{{ row.create_uid.name }} :</div>
                  <div class="body">{{ row.comment }}</div>
                  <div class="bottom mt-2">
                    <span class="date mr-3">{{ row.create_date }}</span>
                    <a v-if="row.is_likes" class="praise active mr-3">
                      <LikeFilled @click="onLike(row.id)" />
                      <span class="ml-1">{{ row.likes }}</span>
                    </a>
                    <a v-else class="praise mr-3">
                      <LikeOutlined @click="onLike(row.id)" />
                      <span class="ml-1">{{ row.likes }}</span>
                    </a>
                    <a-popconfirm v-if="row.is_delete" title="确定要删除?" @confirm="commentDelete(row.id)">
                      <a>删除</a>
                    </a-popconfirm>
                    <template v-else>
                      <a @click="row.visible = !row.visible" class="mr-3">回复</a>
                      <div v-show="row.visible" class="comment_post">
                        <img :src="`/web/image/res.users/${data_list.user?.id}/image_512`" class="mr-2"/>
                        <a-input v-model:value="rec_commentValue" @keydown.enter="handleSubmit1(row.id)" auto-size
                          placeholder="等待您的评论" class="mr-3"/>
                        <a-button :loading="submitting" @click="handleSubmit1(row.id)">回帖</a-button>
                      </div>
                    </template>
                  </div>
                </div>
              </div>
              <div class="reply mt-3">
                <div class="comment_list px-4">
                  <div v-for="com in row.comment_ids" :key="com.id" class="item">
                    <div class="comment_content d-flex">
                      <div class="avatar mr-2">
                        <img :src="`/web/image/res.users/${com.create_uid?.id}/image_512`" class="mr-2"/>
                      </div>
                      <div class="content_info">
                        <div class="top mb-2">
                          {{ com.create_uid?.name }}
                          <span class="text-dark">回复</span>
                          {{ row.create_uid?.name }} :
                        </div>
                        <div class="body">{{ com.comment }}</div>
                        <div class="bottom mt-2">
                          <span class="date mr-3">{{ com.create_date }}</span>
                          <a v-if="com.is_likes" class="praise active mr-3">
                            <LikeFilled @click="onLike(com.id)" />
                            <span class="ml-1">{{ com.likes }}</span>
                          </a>
                          <a v-else class="praise mr-3">
                            <LikeOutlined @click="onLike(com.id)" />
                            <span class="ml-1">{{ com.likes }}</span>
                          </a>
                          <a-popconfirm v-if="com.is_delete" title="确定要删除?" @confirm="commentDelete(com.id)">
                            <a>删除</a>
                          </a-popconfirm>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </a-col>
      <a-col :span="5">
        <div class="blog_user">
          <div class="user_info">
            <img v-if="data_list.user?.has_image" :src="`/web/image/res.users/${data_list.user?.id}/image_512`"/>
            <img v-else src="/hw_web/static/src/img/avatar_live.png" />
            <h5>{{ data_list.user?.name }}</h5>
          </div>
          <div class="blog_info">
            <router-link :to="`/blog/homepage`">
              <span class="count">{{data_list.user_blog_num}}</span>
              <span class="title">帖子</span>
            </router-link>
            <router-link :to="`/blog/attention`">
              <span class="count">{{data_list.blog_id?.blog_attention_ids.length}}</span>
              <span class="title">关注</span>
            </router-link>
            <router-link :to="`/blog/fan`">
              <span class="count">{{data_list.blog_id?.blog_fan_ids.length}}</span>
              <span class="title">粉丝</span>
            </router-link>
          </div>
        </div>
        <div class="blog_message">
          <h5>互动消息</h5>
          <router-link to="/blog/message?key=reply">
            <img src="/hw_frontend/static/img/blog_@my_icon.png"/>
            @我的
            <a-badge :count="data_list.reply_comment_count" :number-style="{ backgroundColor: '#FF8110' }"/>
          </router-link>
          <router-link to="/blog/message?key=comment">
            <img src="/hw_frontend/static/img/blog_comment_icon.png"/>
            评论
            <a-badge :count="data_list.comment_count" :number-style="{ backgroundColor: '#FF8110' }"/>
          </router-link>
          <router-link to="/blog/message?key=like">
            <img src="/hw_frontend/static/img/blog_like_icon.png"/>
            点赞
            <a-badge :count="data_list.like_count" :number-style="{ backgroundColor: '#FF8110' }"/>
          </router-link>
        </div>
        <div class="blog_hot_post">
          <h5>
            热帖推荐
            <span @click="onOneChange"><RedoOutlined />换一批</span>
          </h5>
          <ul>
            <li v-for="(item, index) in data_list.hot_blog" :key="index">
              <router-link :to="`/blog/${item.id}`" class="line-clamp-1" :title="item.name">
                {{ item.name }}
              </router-link>
            </li>
          </ul>
        </div>
      </a-col>
    </a-row>
    <a-back-top />
  </div>
</template>

<script setup>
import { LikeOutlined, LikeFilled } from "@ant-design/icons-vue";
import { logDebug, logError } from "@/utils/logger";
import { onMounted, ref, reactive } from "vue";
import { getResponseData, jsonRPC } from "@/utils/http_utils";
import { getRouterParam } from "@/utils/router_utils";
import { useRouter } from "vue-router";
import { message } from "ant-design-vue";

const router = useRouter();
const blog_id = ref(getRouterParam(router, "id"));

const data_list = reactive({
  name: null,
  user: null,
  post_date: null,
  tag_ids: [],
  content: null,
  is_image: false,
  visits: null,
  is_comments: null,
  comments: null,
  is_likes: null,
  likes: null,
  hot_blog: [],
  hot_blog_page_count: 2,
  hot_blog_page_index: 1,
  comment_ids: [],
});
function fetchData() {
  jsonRPC({
    url: `/api/blog/comment`,
    params: {
      id: blog_id.value,
    },
    success(res) {
      const data = getResponseData(res);
      logDebug(`获取评论成功`, data);
      Object.assign(data_list, data);
    },
    fail(error) {
      logError(`获取评论失败`, error);
      message.error(`获取评论失败，请稍后再试`);
    },
  });
}
onMounted(() => {
  jsonRPC({
    url: `/api/blog/${blog_id.value}`,
    params: {},
    success(res) {
      const data = getResponseData(res);
      logDebug(`获取论坛详情成功`, data);
      Object.assign(data_list, data);
    },
    fail(error) {
      logError(`获取论坛详情失败`, error);
      message.error(`获取论坛详情失败，请稍后再试`);
    },
  });
  fetchData();
});
const onOneChange = () => {
  if (data_list.hot_blog_page_index >= data_list.hot_blog_page_count) {
    data_list.hot_blog_page_index = 1;
  } else {
    data_list.hot_blog_page_index += 1;
  }
  jsonRPC({
    url: `/api/blog/change`,
    params: {
      page_index: data_list.hot_blog_page_index,
    },
    success(res) {
      const data = getResponseData(res);
      logDebug(`获取热帖推荐成功`, data);
      Object.assign(data_list, data);
    },
    fail(error) {
      logError(`获取热帖推荐失败`, error);
      message.error(`获取热帖推荐失败，请稍后再试`);
    },
  });
};

import dayjs from "dayjs";
import relativeTime from "dayjs/plugin/relativeTime";
dayjs.extend(relativeTime);
const submitting = ref(false);
const commentValue = ref("");
const rec_commentValue = ref("");
const handleSubmit = () => {
  if (!commentValue.value.trim()) {
    message.info("评论内容不能为空");
    return;
  }
  submitting.value = true;
  jsonRPC({
    url: `/api/blog/comment/create`,
    params: {
      blog_id: blog_id.value,
      comment: commentValue.value,
    },
    success(res) {
      logDebug(res);
      message.success(`评论成功`);
      fetchData();
    },
    fail(error) {
      logError(`评论失败`, error);
      message.error(`评论失败，请稍后再试`);
    },
  }).then(() => {
    submitting.value = false;
    commentValue.value = "";
  });
};
const handleSubmit1 = (e) => {
  if (!rec_commentValue.value.trim()) {
    message.info("评论内容不能为空");
    return;
  }
  submitting.value = true;
  jsonRPC({
    url: `/api/blog/${blog_id.value}/comment/${e}`,
    params: {
      comment: rec_commentValue.value,
    },
    success(res) {
      logDebug(res);
      message.success(`评论成功`);
      fetchData();
    },
    fail(error) {
      logError(`评论失败`, error);
      message.error(`评论失败，请稍后再试`);
    },
  }).then(() => {
    submitting.value = false;
    rec_commentValue.value = "";
  });
};
const commentDelete = (e) => {
  jsonRPC({
    url: `/api/blog/comment/delete`,
    params: {
      comment_id: e,
    },
    success(res) {
      logDebug(res);
      message.success(`删除评论成功`);
      fetchData();
    },
    fail(error) {
      logError(`删除评论失败`, error);
      message.error(`删除评论失败，请稍后再试`);
    },
  });
};
const onLike = (e) => {
  jsonRPC({
    url: `/api/blog/comment/like/${e}`,
    params: {},
    success(res) {
      logDebug(res);
      fetchData();
    },
    fail(error) {
      logError(`操作失败`, error);
    },
  });
};
const onBlogLike = () => {
  jsonRPC({
    url: `/api/blog/like/${blog_id.value}`,
    params: {},
    success(res) {
      logDebug(res);
      fetchData();
    },
    fail(error) {
      logError(`操作失败`, error);
    },
  });
};
</script>

<style scoped lang="scss">
.blog_detail {
  margin-bottom: 30px;
  margin-left: 10px;
  margin-right: 10px;
  .info {
    padding: 15px;
    background-color: #fff;
    .blog_title {
      display: flex;
      margin-bottom: 15px;
      .tags {
        flex: 1 1 100%;
        display: flex;
        flex-wrap: wrap;
        .tag {
          color: #000;
          border-radius: 8px;
          padding: 0.3rem 1rem;
          cursor: pointer;
          &.active,
          &:hover {
            background-color: #d5e9ff;
          }
          &:not(:last-child) {
            margin-right: 10px;
          }
        }
      }
      .search {
        flex: 0 0 auto;
        display: flex;
        align-items: center;
        .write_blog {
          display: flex;
          align-items: center;
          gap: 0.3rem;
          background-color: #77b7ff;
          color: #fff;
          border-radius: 15px;
          padding: 0.25rem 0.75rem;
          line-height: 20px;
          margin-right: 1rem;
          img {
            width: 16px;
            height: 16px;
          }
        }
        .ant-btn-primary {
          background-color: #77b7ff;
        }
      }
    }
    .blog_list {
      padding: 15px;
      margin-bottom: 15px;
      .blog_list_top {
        display: flex;
        justify-content: space-between;
        align-items: center;
        color: #a1a1a1;
        margin-bottom: 10px;
        h5 {
          display: flex;
          flex-flow: wrap;
          gap: 5px;
          margin-bottom: 0;
          a {
            color: #000;
            font-size: 18px;
            font-weight: bold;
            &:hover {
              color: #ff8c00;
            }
          }
          span {
            background-color: #e4efff;
            color: #2580fd;
            padding: 3px 7px;
            border-radius: 4px;
            margin-bottom: -3px;
            position: relative;
            top: -2px;
            margin-left: 6px;
            font-size: 14px;
          }
        }
      }
      .blog_list_content {
        display: flex;
        gap: 10px;
        > div {
          flex-grow: 1;
          .user {
            display: flex;
            align-items: center;
            color: #a1a1a1;
            margin-bottom: 5px;
            &:hover {
              color: #ff8c00;
            }
            img {
              height: 25px;
              width: 25px;
              margin-right: 5px;
              border-radius: 50%;
              object-fit: cover;
            }
          }
        }
        > img {
          flex-shrink: 0;
          width: 100%;
          max-width: 200px;
          object-fit: cover;
        }
      }
      .blog_list_bottom {
        display: flex;
        justify-content: space-around;
        margin-top: 5px;
        img {
          width: 25px;
          height: 25px;
          margin-right: 5px;
        }
      }
    }
  }
  .blog_user {
    border-radius: 8px;
    background-color: #fff;
    padding: 25px;
    margin-bottom: 15px;
    .user_info {
      text-align: center;
      img {
        width: 50px;
        height: 50px;
        border-radius: 50%;
        margin-bottom: 1rem;
        object-fit: cover;
      }
      h5 {
        font-size: 16px;
      }
    }
    .blog_info {
      display: flex;
      justify-content: space-around;
      a {
        display: flex;
        flex-direction: column;
        align-items: center;
        .count {
          font-size: 28px;
          color: #429bff;
        }
        .title {
          color: #000;
        }
      }
    }
  }
  .blog_message {
    border-radius: 8px;
    background-color: #fff;
    padding: 15px;
    margin-bottom: 15px;
    h5 {
      font-weight: bold;
      font-size: 16px;
    }
    img {
      width: 25px;
      height: 25px;
    }
    a {
      display: flex;
      gap: 10px;
      padding: 10px 0;
      color: #101010;
      &:hover {
        color: #ff8c00;
      }
      span {
        flex-shrink: 0;
        margin-left: auto;
        .ant-badge-count {
          min-width: 24px;
          height: 24px;
          font-size: 14px;
          line-height: 24px;
          border-radius: 12px;
        }
      }
    }
  }
  .blog_hot_post {
    flex-grow: 1;
    border-radius: 8px;
    background-color: #fff;
    padding: 15px;
    h5 {
      font-size: 16px;
      font-weight: bold;
      display: flex;
      justify-content: space-between;
      align-items: center;
      span {
        font-size: 14px;
        color: #949494;
        cursor: pointer;
        display: flex;
        gap: 5px;
      }
    }
    ul {
      padding: 0;
      list-style: none;
      li {
        margin: 10px 0;
        a {
          color: #000;
          &:hover {
            color: #ff8c00;
          }
        }
      }
    }
  }
}
:deep(.ant-comment .ant-comment-avatar img) {
  width: 100%;
  height: 100%;
}
.comment_post {
  display: flex;
  margin-top: 1rem;
  img {
    width: 35px;
    height: 35px;
    border-radius: 50%;
    flex: 0 0 auto;
    object-fit: cover;
  }
  input {
    border: 1px solid #77b7ff;
    border-radius: 8px;
    flex: 1 1 100%;
    padding: 0.35rem 0.5rem;

    &:focus-visible {
      border-width: 2px;
    }
  }
  button {
    border-radius: 8px;
    background-color: #77b7ff;
    text-align: center;
    border: 1px solid #77b7ff;
    flex: 0 0 auto;
    color: #ffffff;
    padding: 0.3rem 0.75rem;
  }
}
.comment_list {
  .item {
    .comment_content {
      .avatar {
        flex-shrink: 0;
        img {
          width: 35px;
          height: 35px;
          border-radius: 50%;
          object-fit: cover;
        }
      }
      .content_info {
        flex: 1 1 100%;
        .top {
          color: #047AFF;
        }
        .body {
          color: #000;
        }
        .bottom {
          color: #9499a0;
          a {
            color: #9499a0;
            &:hover,
            &.active {
              color: #ff8c00;
            }
            i {
              font-weight: normal;
              font-size: inherit;
              bottom: auto;
              position: static;
            }
          }
        }
      }
    }
    .reply {
      padding-left: 2rem;
      .item {
        background-color: #EFF6FF;
        padding: 10px;
        border-radius: 8px;
      }
    }
  }
}
</style>
