<template>
  <div>
    <a-button id="function-student-question" type="primary"
              @click="showStudentQuestionModal" :style="props.styleSetting.buttonStyle">
      <a-tooltip :placement="placement">
        <template #title>学生提问</template>
        <QuestionOutlined :style="props.styleSetting.iconStyle"/>
      </a-tooltip>
    </a-button>
    <!--学生提问模态框列表-->
    <a-modal v-model:open="openStudentQuestionModal" :maskClosable="false"
             title="学生提问列表" style="width: 70vw;"
             okText="确认" cancelText="取消" :footer="null"
             @ok="handleOkStudentQuestionModal">

      <a-row style="margin-top: 10px">
        <a-col :span="24">
          <a-table
              :columns="studentQuestionColumns"
              :data-source="studentQuestionData"
              @resizeColumn="handleResizeColumn"
              :row-key="record => record.key"
              :pagination="pagination">
            <template #bodyCell="{ column, record }">
              <template v-if="column.key === 'question_title'">
                <a-typography-text style="color: #1677ff;cursor: pointer;"
                                   @click="showDrawer(record)"
                                   :ellipsis="ellipsis ? { tooltip: `点击查看详情` } : false"
                                   :content="record.question_title">
                </a-typography-text>
              </template>
            </template>
          </a-table>
        </a-col>
      </a-row>
    </a-modal>
    <!--学生提问详情信息-->
    <a-drawer width="640" placement="right" :closable="true" :open="open" @close="onClose">

      <template #extra>
        <a-button style="margin-right: 8px" @click="onClose">关闭</a-button>
        <a-button type="primary" @click="Submit">提交</a-button>
      </template>

      <h4>ID:{{ drawerData.question_id }}-学生提问</h4>
      <a-col :span="24">
        App Name：{{ drawerData.app_name }}
      </a-col>
      <a-divider/>

      <a-row>
        <a-col :span="12"> 学生:{{ drawerData.user_name }}</a-col>
        <a-col :span="12"> 提交时间:{{ drawerData.time }}</a-col>
      </a-row>
      <a-divider/>

      <a-row>
        <a-typography-title :level="4">问题:</a-typography-title>
        <RichEditor id="drawerDataQuestion" :height="301" :editMode="'readonly'" v-model="drawerData.question"/>
      </a-row>
      <a-divider/>

      <a-row>
        <a-typography-title :level="4">实验环境列表:</a-typography-title>
        <a-col :span="24">
          <a-table size="small" bordered
                   :columns="columns"
                   :pagination="false"
                   :data-source="drawerData.app_env_link_list">
            <template #renderItem="{ item }">
              <a-list-item>{{ item }}</a-list-item>
            </template>
            <template #bodyCell="{ column, record }">
              <template v-if="column.key === 'link'">
                <router-link :to="record.link" target="_blank">
                  访问
                </router-link>
              </template>
              <template v-if="column.key === 'environment_ids'">
                <div v-for="item in drawerData['environment_ids']" :key="item.name">
                  <a-typography-paragraph copyable class="mb-0">{{ item.name }}</a-typography-paragraph>
                </div>
              </template>

            </template>
          </a-table>
        </a-col>
      </a-row>
      <a-divider/>
      <a-row>
        <a-typography-title :level="4">问题解答:</a-typography-title>
        <RichEditor id="drawerDataSolution" :height="301" :editMode="'edit'" v-model="drawerData.solution"/>
      </a-row>
      <a-button type="primary" @click="Submit">提交解答</a-button>
    </a-drawer>
  </div>
</template>

<script setup>
import {markRaw, onMounted, reactive, ref} from 'vue'
import {QuestionOutlined} from '@ant-design/icons-vue';
import {logComponents, logDebug, logError} from "@/utils/logger";
import {getFailedMessage, getResponseData, jsonRPC} from "@/utils/http_utils";
import {message} from "ant-design-vue";
import RichEditor from "@/components/RichEditor.vue";

logDebug("TerminalFunctionAppSnapshot setup!");
logComponents([QuestionOutlined]);

const placement = ref(window.location.search.includes('desktop') ? 'right' : 'bottom');
const props = defineProps({
  primal: {type: String},
  data: {type: Object},
  styleSetting: {type: Object},
});

const open = ref(false);
const drawerData = reactive({
  'solution': '',
  'user_name': '',
  'time': '',
  'question': '',
  'question_id': '',
  'app_env_link_list': '',
  'environment_ids': [],
  'is_solution': [],
});
const showDrawer = (e) => {
  logDebug(e);
  Object.assign(drawerData, e);
  open.value = true;
};
const ellipsis = ref(true);
const onClose = () => {
  open.value = false;
};

const columns = [
  {
    title: "实验环境名称",
    dataIndex: "name",
    key: "name",
  },
  {
    title: "实验环境",
    key: "environment_ids",
    dataIndex: "environment_ids",
  },
  {
    title: "链接",
    dataIndex: "link",
    key: "link",
  },
];

const primal = props.primal;
const data = props.data;
const liveId = data["live_id"];

const openStudentQuestionModal = ref(false);

const showStudentQuestionModal = () => {
  loadStudentQuestion().then(() => {
    openStudentQuestionModal.value = true;
  });
};

const handleOkStudentQuestionModal = () => {
  openStudentQuestionModal.value = false;
};

const studentQuestionColumns = [
  {
    title: "ID",
    key: "question_id",
    dataIndex: "question_id",
    fixed: 'left',
  },
  {
    title: "学生",
    key: "user_name",
    dataIndex: "user_name",
    width: 150,
    fixed: 'left',
  },
  {
    title: "标题",
    key: "question_title",
    dataIndex: "question_title",
    fixed: 'left',
  },
  {
    title: "提问时间",
    key: "time",
    dataIndex: "time",
    width: 300,
    fixed: 'left',
  },
];
const studentQuestionData = ref([]);

function handleResizeColumn(w, col) {
  col.width = w;
}

const handlePageChange = (page, pageSize) => {
  logDebug(`page[${page}], pageSize[${pageSize}]`);
  pagination.current = page;
  loadStudentQuestion()
};

const pagination = reactive({
  // 分页配置
  pageSize: 5, // 每页显示的条数
  showSizeChanger: false, // 是否可以改变每页显示的条数
  pageSizeOptions: ["5", "10", "20"], // 可选的每页显示条数
  showQuickJumper: false, // 是否可以快速跳转到指定页
  showTotal: (total) => `共 ${total} 条`, // 显示总条数和当前数据范围
  hideOnSinglePage: true, // 只有一页时是否隐藏分页器 true为不显示 false为显示
  current: 1, // 当前页数
  total: 0, // 总条数
  onChange: markRaw(handlePageChange), // 页码改变时的回调函数
});

const searchString = ref("");

const adjustStudentQuestionData = (studentQuestions) => {
  for (const record of studentQuestions) {
    record["status"] = record["environment_ids"]
        .map((x) => x.status)
        .join("; ");
    const solutionId = record["app_solution_id"];
    const appEnvLinkList = record["inner_service_name"].map((inner_service_name) => {
      return {
        name: inner_service_name,
        link: `/terminal/solution/${solutionId}/inner_service_name/${inner_service_name}`,
      };
    });
    record["app_env_link_list"] = appEnvLinkList;
  }
  return studentQuestions;
};

const loadStudentQuestion = () => {
  const getAppSnapshotResult = jsonRPC({
    url: "/api/experiment/live/question",
    params: {
      live_id: liveId,
      page_size: pagination.pageSize,
      page_index: pagination.current,
      search: searchString.value,
    },
    success(res) {
      const data = getResponseData(res);
      logDebug("loadAppSnapshot", data);
      studentQuestionData.value = adjustStudentQuestionData(data["records"]);
      logDebug("studentQuestionData", studentQuestionData.value)
      pagination.total = data.record_count;
    },
    fail(error) {
      logError(`获取学生问题列表失败, `, error);
      message.error(
          `获取学生问题列表失败，[${JSON.stringify(getFailedMessage(error))}]`,
          3
      );
    },
  });

  return getAppSnapshotResult.then((res) => {
    logDebug(`getAppSnapshotResult, `, res);
  });
};


function Submit() {
  jsonRPC({
    url: "/api/experiment/live/question/solution",
    params: {
      live_id: liveId,
      question_id: drawerData.question_id,
      solution: drawerData.solution,
      is_solution: drawerData.is_solution,
    },
    success(res) {
      const data = getResponseData(res);
      open.value = false
      logDebug("loadAppSnapshot", data);
      logDebug("提交成功了！", data);
      message.success(
          `提交成功`,
          3
      );
    },
    fail(error) {
      logError(`提交失败, `, error);
      message.error(
          `提交失败，[${JSON.stringify(getFailedMessage(error))}]`,
          3
      );
    },
  });

}

onMounted(() => {
  // 在onMounted中获取数据
  logDebug(`TerminalFunctionService onMounted.`);
});

defineExpose({
  primal,
  data,
});
</script>

<style scoped lang='scss'>

</style>
