<template>
  <div class="exercise p-4">
    <a-flex :justify="'space-between'" :align="'center'" style="margin-bottom: 20px;">
      {{ data_list1.name }}
      <a-affix :offset-top="10">
        <a-button v-if="editorMode == 'create' && data_list1.question_count != 0" type="primary"
                  class="submitHomework" @click="submitHomework">提交练习
        </a-button>
        <a-button v-else type="primary" class="saveHomework" @click="_Back">返回</a-button>
      </a-affix>
    </a-flex>
    <a-spin :spinning="spinning" size="large" tip="Loading...">
      <a-flex :justify="'center'" :gap="30" class="mb-3">
        <div>学生姓名：{{ data_list1.user_name }}</div>
        <div>学生编号：{{ data_list1.student_barcode }}</div>
        <div style="color: #49cfca;">倒计时：{{ formatTime(timeRemaining) }}</div>
      </a-flex>
      <a-flex v-if="editorMode == 'readonly'" :justify="'center'" :gap="30" class="mb-3">
        <div>总分：{{ data_list1.total }}</div>
        <div>及格分：{{ data_list1.pass_score }}</div>
        <div>得分：{{ data_list1.score }}</div>
        <div>
          结果：
          <a-typography-text v-if="data_list1.exercise_result == '通过'" type="success">
            {{ data_list1.exercise_result }}
          </a-typography-text>
          <a-typography-text v-else-if="data_list1.exercise_result == '未提交'" type="warning">
            {{ data_list1.exercise_result }}
          </a-typography-text>
          <a-typography-text v-else-if="data_list1.exercise_result == '未通过'" type="danger">
            {{ data_list1.exercise_result }}
          </a-typography-text>
          <a-typography-text v-else>{{ data_list1.exercise_result }}</a-typography-text>
        </div>
      </a-flex>
      <a-form ref="exerciseformRef" :model="create_list" :label-col="{style: {width: '150px'} }"
              :disabled="editorMode == 'readonly'">
        <div v-for="(item,index) in data_list1.question_ids" :key="index" class="mb-3">
          <a-flex justify="space-between">
            <a-flex>{{ index + 1 }}、
              <div style="margin-bottom: -1em;" v-html="item.name"></div>
            </a-flex>
            <a-flex>{{ item.type }}
              <div class="mx-2"/>
              {{ item.score }}分
            </a-flex>
          </a-flex>
          <a-flex v-if="editorMode == 'readonly'" justify="flex-end">
            答案：
            <template v-if="item.type == 'QA'">
              <div v-html="item.qa_solution"/>
            </template>
            <template v-else-if="item.type == 'CODE'">
              <div v-html="item.code_solution"/>
            </template>
            <template v-else>{{ item.solution }}</template>
            <div class="mx-3"></div>
            得分：{{ create_list[index].answer_score }}
          </a-flex>
          <a-radio-group v-if="item.type == '单选'" v-model:value="create_list[index].answer"
                          :style=style>
            <div v-for="option in options" :key="option" style="cursor: initial;">
              <a-radio v-if="`option_${option.toLowerCase()}` in data_list1.question_ids[index]"
                        :value="option">
                <a-flex>{{ option }}、<span style="margin-bottom: -1em;"
                                            v-html="data_list1.question_ids[index]['option_' + option.toLowerCase()]"></span>
                </a-flex>
              </a-radio>
            </div>
          </a-radio-group>
          <a-checkbox-group v-else-if="item.type == '多选'" v-model:value="create_list[index].answer"
                            :style=style>
            <div v-for="option in options" :key="option" style="cursor: initial;">
              <a-checkbox v-if="`option_${option.toLowerCase()}` in data_list1.question_ids[index]"
                          :value="option">
                <a-flex>{{ option }}、<span style="margin-bottom: -1em;"
                                            v-html="data_list1.question_ids[index]['option_' + option.toLowerCase()]"></span>
                </a-flex>
              </a-checkbox>
            </div>
          </a-checkbox-group>
          <a-form-item v-else-if="item.type == '填空'">
            <a-textarea v-model:value="create_list[index].answer"/>
          </a-form-item>
          <a-radio-group v-else-if="item.type == '判断'" v-model:value="create_list[index].answer"
                          :style=style>
            <div v-for="option in ['A','B']" :key="option" style="cursor: initial;">
              <a-radio v-if="`option_${option.toLowerCase()}` in data_list1.question_ids[index]"
                        :value="option">
                <a-flex>{{ option }}、<span style="margin-bottom: -1em;"
                                            v-html="data_list1.question_ids[index]['option_' + option.toLowerCase()]"></span>
                </a-flex>
              </a-radio>
            </div>
          </a-radio-group>
          <a-form-item v-else-if="item.type == '问答'">
            <RichEditor v-model="create_list[index].answer" :height="301" :editMode="editorMode"/>
          </a-form-item>
          <a-form-item v-else-if="item.type == '编程'">
            <RichEditor v-model="create_list[index].answer" :height="301" :editMode="editorMode"/>
          </a-form-item>
        </div>
        <a-empty v-if="data_list1.question_count == 0" :image="simpleImage"/>
      </a-form>
    </a-spin>
  </div>
</template>

<script setup>
const emits = defineEmits(['call-parent-method', 'call-parent-refresh']);
const props = defineProps({
  exercise_id: Number,
});
const exercise_id = ref(props.exercise_id)
const _Back = ()=>{
  emits('call-parent-method', {
    component: 'list',
  });
}
import { logDebug, logError } from "@/utils/logger";
import { message } from "ant-design-vue";
import { onMounted, onBeforeUnmount, ref, reactive } from "vue";
import { jsonRPC, getResponseData } from "@/utils/http_utils";
import RichEditor from "@/components/RichEditor.vue";
import {Empty} from "ant-design-vue";
const simpleImage = Empty.PRESENTED_IMAGE_SIMPLE;

const exerciseformRef = ref();
const editorMode = ref('create');
const data_list1 = reactive({})
const create_list = reactive({})
const spinning = ref(false);
const options = Array.from({length: 8}, (_, i) => String.fromCharCode(65 + i))
const style = {display: 'flex', flexDirection: 'column'}

const timeRemaining = ref(data_list1.exercise_duration * 60); // 转换为秒
const timer = ref();
// 格式化时间为 时：分：秒
function formatTime(seconds) {
  if (seconds>0) {
    const h = Math.floor(seconds / 3600).toString().padStart(2, '0');
    const m = Math.floor((seconds % 3600) / 60).toString().padStart(2, '0');
    const s = (seconds % 60).toString().padStart(2, '0');
    return `${h}:${m}:${s}`;
  } else {
    return `00:00:00`;
  }
}
// 启动倒计时
function startCountdown() {
  if (editorMode.value == 'create') {
    timeRemaining.value = data_list1.exercise_duration * 60;
    timer.value = setInterval(() => {
      if (timeRemaining.value > 0) {
        timeRemaining.value--;
      } else {
        submitHomework();
      }
    }, 1000);
  }
}
function fetchData(e=0) {
  jsonRPC({
    url: "/vue/console/class/exercise/detail",
    params: {
      workId: exercise_id.value,
      record_id: e,
    },
    success(res) {
      const data = getResponseData(res);
      logDebug(`获取成功`, data);
      Object.assign(data_list1, data.records);
      Object.assign(create_list, data.answer_ids);
      startCountdown();
    },
    fail(error) {
      logError(`查询失败, `, error);
    },
  }).then(() => {
    spinning.value = false; // 加载完成后关闭加载状态
  });
}
onMounted(() => {
  fetchData();
});
onBeforeUnmount(() => {
  if (timer.value) {
    clearInterval(timer.value); // 清除计时器，防止内存泄漏
  }
});
// 提交作业
const submitHomework = () => {
  clearInterval(timer.value);
  spinning.value = true;
  jsonRPC({
    url: "/vue/console/class/exercise/submit",
    params: {
      workId: exercise_id.value,
      create_list: create_list,
    },
    success(res) {
      const data = getResponseData(res)
      logDebug(`获取成功`, data)
      message.success('提交成功')
      editorMode.value = 'readonly';
      fetchData(data);
      emits('call-parent-refresh');
    },
    fail(error) {
      logError(`查询失败, `, error)
    },
  });
}
</script>

<style scoped lang="scss">

</style>
