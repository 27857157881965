<template>
  <div id="status">
    <a-row>
      <a-col :span="24" style="height: 40vh"></a-col>
    </a-row>
    <a-row>
      <a-col :span="24" style="display: flex; justify-content: center">
        <div>
          <a-typography-title :level="4">
            <span>应用环境已停止。如需登入应用环境，请先点击左下角“<PoweroffOutlined/>”按钮，进入电源管理页面启动应用环境。</span>
          </a-typography-title>
        </div>
      </a-col>
    </a-row>
  </div>
</template>

<script setup>
import {PoweroffOutlined} from '@ant-design/icons-vue';
import {onMounted, reactive, ref} from "vue";
import {getResponseData, jsonRPC} from "@/utils/http_utils";
import {message} from "ant-design-vue";
import {logDebug, logError} from "@/utils/logger";

logDebug("TerminalStatus setup!");

const props = defineProps({
  primal: {type: String},
  data: {type: Object},
  styleSetting: {type: Object},
});
const contentHeight = ref(props.styleSetting.contentStyle.height)

const primal = props.primal;
const data = props.data;
const envId = data["env_id"];

const envStatus = reactive({
  'env_id': envId,
  'env_status': 'Running',
  'name': '',
  'status': '',
  'custom_name': '',
  'deployment_id': 0,
  'app_env_id': 0,
  'app_deploy_id': 0,
})

onMounted(() => {
  // 在onMounted中获取数据
  logDebug(`TerminalStatus onMounted. envId[${envId}]`);
});


const loadEnvStatus = function () {
  return jsonRPC({
    url: "/api/experiment/app/env_status",
    params: {
      env_id: envId,
    },
    success(res) {
      const data = getResponseData(res);
      logDebug(`获取实验环境状态成功`, data);
      Object.assign(envStatus, data);
      return true
    },
    fail(error) {
      logError(`获取实验环境状态失败`, error);
      message.error(`获取实验环境状态失败`, 3)
      return false
    },
  });
}


const onSelected = function () {
  logDebug(`TerminalStatus onSelected. data[${JSON.stringify(data)}]`);

  loadEnvStatus();
};


defineExpose({
  primal,
  data,
  onSelected,
});
</script>

<style scoped lang="scss">

#status {
  background: gainsboro;
  width: 100%;
  height: v-bind(contentHeight);
}

</style>