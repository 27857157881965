<template>
  <iframe :id='liveAppFrameId' :src="url" :style="iframeStyle" class="vnc-frame" frameborder="0"/>
</template>

<script setup>
import {logDebug} from "@/utils/logger";
import {onMounted, ref} from 'vue';
import {isFalse, isNotNullOrUndefined, isTrue} from "@/utils/common_utils";
import {isFrontendDeploy} from "@/utils/config_utils";

const props = defineProps({
  primal: {type: String},
  data: {type: Object},
})

const primal = props.primal
const data = props.data

const solutionId = data['solution_id']
const innerServiceName = data['inner_service_name']
const liveAppFrameId = `live_app_frame_${solutionId}_${innerServiceName}`

const url = ref('')

onMounted(() => {
  // 在onMounted中获取数据
  logDebug(`LiveDetailApp onMounted.`)
})

const isFirstSelected = ref(true)

const doFocusOnFrame = function (try_times) {
  logDebug(`focusOnFrame start, try_times[${try_times}]`)
  const vncFrameDom = document.getElementById(liveAppFrameId);
  logDebug(`vncFrameDom[${vncFrameDom}]`)
  if (isNotNullOrUndefined(vncFrameDom)) {
    logDebug(`vncFrameDom.focus() start`)
    vncFrameDom.blur()
    vncFrameDom.focus()
    logDebug(`vncFrameDom.focus() end`)
    if (isNotNullOrUndefined(vncFrameDom.contentWindow)) {
      logDebug(`vncFrameDom.contentWindow.postMessage start`)
      setTimeout(function () {
        vncFrameDom.contentWindow.postMessage(JSON.stringify({
          focus: 'true'
        }), "*")
      }, 1000)
      logDebug(`vncFrameDom.contentWindow.postMessage end`)
    }
  }
  logDebug(`focusOnFrame end, try_times[${try_times}]`)
}

const focusOnFrame = function (try_times, try_interval_ms) {
  if (try_times <= 0) {
    return
  }

  doFocusOnFrame(try_times)

  const timeout = setTimeout(function (inner_try_times) {
    clearTimeout(timeout)
    focusOnFrame(inner_try_times, try_interval_ms)
  }, try_interval_ms, try_times - 1)
}

// function debounce(func, wait) {
//   let timeout;
//   return function () {
//     const context = this;
//     const args = arguments;
//     clearTimeout(timeout);
//     timeout = setTimeout(function () {
//       func.apply(context, args);
//     }, wait);
//   };
// }

// function throttle(func, delay) {
//   let lastCall = 0;
//   return function(...args) {
//     const now = new Date().getTime();
//     if (now - lastCall < delay) {
//       return;
//     }
//     lastCall = now;
//     return func.apply(this, args);
//   };
// }


const doOnSelected = function () {
  logDebug(`LiveDetailApp doOnSelected. data[${JSON.stringify(data)}]`)

  if (isFalse(isFirstSelected.value)) {
    // 这个地方只能通知子frame聚焦一次，否则会造成子frame里的其它focus事件被这里的focus事件覆盖掉。
    focusOnFrame(1, 1000)
    return
  }

  const now = new Date().getTime()
  if (isFrontendDeploy()) {
    url.value = `/?desktop_t=${now}#/terminal2/solution/${solutionId}/inner_service_name/${innerServiceName}?scene=live`
  } else {
    url.value = `/hw_frontend/static/app/index.html?desktop_t=${now}#/terminal2/solution/${solutionId}/inner_service_name/${innerServiceName}?scene=live`
  }
  isFirstSelected.value = false
}


const onSelected = function () {
  // setTimeout(debounce(doOnSelected, 1000), 100)
  logDebug(`LiveDetailApp onSelected. data[${JSON.stringify(data)}]`)
  doOnSelected()
}

const iframeStyle = ref('pointer-events: auto')
const setPointerEvents = function (enablePointerEvents = true) {
  if (isTrue(enablePointerEvents)) {
    iframeStyle.value = 'pointer-events: auto'
  } else {
    iframeStyle.value = 'pointer-events: none'
  }
}

const onConfig = function (args) {
  logDebug(`LiveDetailApp onConfig. args[${args}]`)
}

defineExpose({
  primal,
  data,
  onSelected,
  setPointerEvents,
  onConfig,
})
</script>

<style scoped>
.vnc-frame {
  background-color: white;
  width: 100%;
  height: 100%;
  min-width: 10vw;
}
</style>

