<template>
  <div class="console_image_container_create">
    <a-breadcrumb separator=">">
      <a-breadcrumb-item>
        <router-link to="/console/image/container">我的容器镜像</router-link>
      </a-breadcrumb-item>
      <a-breadcrumb-item>新建容器镜像</a-breadcrumb-item>
    </a-breadcrumb>
    <a-row>
      <a-col :span="24" :lg="16">
        <a-form ref="formRef" :model="data_list" :rules="rules" :label-col="labelCol" :wrapper-col="wrapperCol"
                layout="horizontal" :disabled="editorMode === 'readonly'">
          <a-row>
            <a-col :span="24">
              <a-form-item label="容器镜像ID：" name='id' v-show="editorMode!=='create'">
                <a-input v-model:value="data_list.id"/>
              </a-form-item>
              <a-form-item label="容器镜像名称：" name='name'>
                <a-input v-model:value="data_list.name"
                         placeholder="只能由小写英文字母和数字构成，且不能超过14位，比如：ubuntu2404v1"/>
              </a-form-item>
              <a-form-item label="容器镜像描述：" name='description'>
                <a-input v-model:value="data_list.description"
                         placeholder="填写对虚拟机镜像的简单描述，比如：这是Ubuntu24.04虚拟机镜像"/>
              </a-form-item>
              <a-form-item label="容器镜像仓库地址：" name='image_tag'>
                <a-input v-model:value="data_list.image_tag" placeholder="比如：ubuntu:24.04"/>
              </a-form-item>
              <a-form-item label="容器Shell类型：" name='shell_type'>
                <a-select v-model:value="data_list.shell_type">
                  <a-select-option value="bash">bash</a-select-option>
                  <a-select-option value="sh">sh</a-select-option>
                </a-select>
              </a-form-item>
            </a-col>
          </a-row>
          <a-flex justify="center" align="center">
            <a-button type="primary" v-if="editorMode === 'create'" @click="confirmFormCreate">创建</a-button>
            <a-button type="primary" v-if="editorMode === 'update'" @click="confirmFormUpdate">保存</a-button>
            <a-button v-if="editorMode !== 'readonly'" style="margin-left: 10px" @click="cancelForm">取消</a-button>
          </a-flex>
        </a-form>
      </a-col>
    </a-row>
  </div>
</template>

<script setup>
import {logDebug, logError} from "@/utils/logger";
import {reactive, ref} from "vue";
import {useRouter} from 'vue-router';
import {routerBack, routerReplace} from "@/utils/router_utils";
import {getFailedMessage, getResponseData, jsonRPC} from "@/utils/http_utils";
import {message} from "ant-design-vue";

const router = useRouter();

const image_id = router.currentRoute.value.params.image_id
logDebug(image_id)

const editorMode = ref('create')

const labelCol = {
  style: {
    width: '150px',
  },
};
const wrapperCol = {
  span: 20,
};
const formRef = ref();
const validateVmImageName = (rule, value) => {
  if (!value) {
    return Promise.reject(`请输入容器镜像名称`);
  }

  const pattern = /^[a-z0-9]{1,14}$/;
  if (!pattern.test(value)) {
    return Promise.reject(`容器镜像名称必须由“小写英文字母”或“数字”两种字符组成，且长度不能超过14个字符。`)
  }
  return Promise.resolve()
};
const rules = {
  name: [
    {
      required: true,
      trigger: 'change',
      validator: validateVmImageName,
    },
  ],
  description: [
    {
      required: true,
      message: '请输入容器镜像描述',
      trigger: 'change',
    },
  ],
  image_tag: [
    {
      required: true,
      trigger: 'change',
    },
  ],
  shell_type: [
    {
      required: true,
      trigger: 'change',
    },
  ],
}

const data_list = reactive({
  name: '',
  description: '',
  image_tag: '',
  image_node: 'hi168-master2',
  shell_type: 'bash',
  pull_status: '',
  registry: '',
  pull_image_connecting: false,
  record: {
    data: {
      image_registry_id: ''
    }
  },
});

const confirmFormCreate = () => {
  formRef.value.validate()
      .then(function () {
        logDebug('confirmFormCreate')
        jsonRPC({
          url: `/api/make/image/create`,
          params: {
            'name': data_list.name,
            'description': data_list.description,
            'image_tag': data_list.image_tag,
            'shell_type': data_list.shell_type,
          },
          success(res) {
            const data = getResponseData(res);
            logDebug(`创建容器镜像成功`, data);
            routerReplace(router, `/console/image/container/${data.id}`)
          },
          fail(error) {
            logError(`创建容器镜像失败`, error);
            const errMsg = getFailedMessage(error)
            if (`${errMsg}`.includes('已经存在')) {
              message.error('容器镜像已经存在，请直接使用同名仓库容器镜像', 3)
              setTimeout(function () {
                routerReplace(router, `/console/image/container`)
              }, 3000)
            }
          },
        })
      })
      .catch(error => {
        logError('error', error);
        return
      });
};

const confirmFormUpdate = () => {
  formRef.value.validate()
      .then(function () {
        logDebug('confirmFormUpdate')
        jsonRPC({
          url: `/api/make/image/update`,
          params: {
            'name': data_list.name,
            'description': data_list.description,
            'image_tag': data_list.image_tag,
            'shell_type': data_list.shell_type,
          },
          success(res) {
            logDebug(`更新容器镜像成功`, res.data);
          },
          fail(error) {
            logError(`更新容器镜像失败`, error);
          },
        })
      })
      .catch(error => {
        logError('error', error);
        return
      });
};

const cancelForm = () => {
  routerBack(router)
};
</script>

<style scoped lang="scss">

</style>