<template>
  <a-row :gutter="[20]" class="console_home">
    <a-col :span="24" :lg="16">
      <div class="live_arrange">
        <h5>直播安排</h5>
        <div class="table-responsive">
          <table class="table table-bordered text-center">
              <thead>
                <tr>
                  <th>直播名称</th>
                  <th>直播类型</th>
                  <th>讲师</th>
                  <th>直播时间</th>
                </tr>
              </thead>
              <tbody>
                <tr v-for="(item,index) in data_list.trainings" :key="index">
                  <td>{{item.name}}</td>
                  <td>{{item.type}}</td>
                  <td>{{item.teacher}}</td>
                  <td>{{item.start_time}}</td>
                </tr>
                <tr v-for="index in emptyRows" :key="index" style="height: 46px;">
                  <td></td>
                  <td></td>
                  <td></td>
                  <td></td>
                </tr>
              </tbody>
          </table>
        </div>
      </div>
      <div class="training_progress">
        <h5>培训进度</h5>
        <div class="info" v-for="(item,index) in data_list.courses" :key="index">
          <h5>
            {{item.name}}
            <div>
              <span v-for="(category,index) in item.category_ids" :key="index">{{category}}</span>
            </div>
          </h5>
          <div class="progres">
            视频课进度：<span>{{item.progress}}%</span>
            作业习题：{{item.exercise_pass_nums}} / <span>{{item.exercise_paper_ids}}</span>
            案例实验：{{item.code_pass_nums}} / <span>{{item.code_paper_ids}}</span>
          </div>
          <div class="live">
            在线直播：
            <ul>
              <li v-for="(training,index) in item.training_ids" :key="index">{{training}}</li>
              <!-- <li>dsadafasf <span>已预约</span></li>
              <li>第二次人工智能直播实训 <span class="dis">未预约</span></li> -->
            </ul>
          </div>
        </div>
      </div>
    </a-col>
    <a-col :span="24" :lg="8">
      <div class="my_resources">
        <h5>我的资源
          <a-button type="primary" @click="goToTopup">算力点充值</a-button>
        </h5>
        <h1>{{parseFloat(quotas.total_points.toFixed(8))}} 算力点</h1>
        <span>当前应用算力消耗总额：{{parseFloat(quotas.consume_points.toFixed(8))}} 算力点 / 小时</span>
      </div>
      <div class="notice">
        <h5>公告通知</h5>
        <!-- <div class="info">
          <p>直播实训开始提醒</p>
          MySQL 第二次直播实训课程即将开始，培训时间：2024.06.04  13:00-17:00
        </div>
        <div class="info">
          <p>培训开班提醒</p>
          MySQL 5月基础班即将开班，开班日期：2024.05.24，请及时联系班主任进群
        </div> -->
      </div>
    </a-col>
  </a-row>
</template>

<script setup>
import {logDebug, logError} from "@/utils/logger";
import { onMounted, reactive, computed } from "vue";
import {getResponseData, jsonRPC} from "@/utils/http_utils";
import { useRouter } from 'vue-router'
const router = useRouter()

const data_list = reactive({
  trainings: [],
  courses: [],
});
const quotas = reactive({
  total_points: 0,
  consume_points: 0,
});
onMounted(() => {
  jsonRPC({
    url: "/vue/console/home",
    params: {},
    success(res) {
      const data = getResponseData(res)
      logDebug(`查询成功`, data);
      Object.assign(data_list, data);
    },
    fail(error) {
      logError(`查询失败, `, error);
    },
  });
  jsonRPC({
    url: "/vue/cost/info",
    params: {},
    success(res) {
      const data = getResponseData(res)
      logDebug(`查询成功`, data);
      Object.assign(quotas,data)
    },
    fail(error) {
      logError(`查询失败, `, error);
    },
  });
});
// 计算需要补齐的空行数
const emptyRows = computed(() => {
  return Math.max(0, 5 - data_list.trainings.length);
});
const goToTopup = () => {
  router.push('/cost/topup')
}

import {trackUV} from "@/utils/trackPage";
// 在页面加载时触发 UV 埋点
onMounted(() => {
  // 调用 trackUV 来统计 UV 数据
  trackUV(router.currentRoute);
});
</script>

<style scoped lang="scss">
.console_home {
  margin: 30px 20px !important;
  > div {
    max-height: 100%;
    display: flex;
    flex-direction: column;
    > div {
      padding: 1.5rem;
      background-color: #fff;
      border: 1px solid #dcddde;
      box-shadow: 0px 0px 4px 0px rgba(0, 0, 0, 0.18);
      border-radius: 4px;
      margin-bottom: 20px;
      > h5 {
        margin-bottom: 15px;
      }
      &.live_arrange{
        .table{
          margin-bottom: 0;
          thead tr{
            background-color: #f9fcff;
            th{
              border: 1px solid #eef6ff;
            }
          }
          td{
            border-color: #eef6ff;
          }
        }
      }
      &.training_progress{
        flex-grow: 1;
        .info{
          padding: 15px;
          background-color: #FAFCFF;
          &:not(:last-child){
            margin-bottom: 15px;
          }
          h5{
            font-size: 16px;
            font-weight: bold;
            display: flex;
            flex-wrap: wrap;
            justify-content: space-between;
            align-items: center;
            span{
              display: inline-block;
              font-size: 14px;
              line-height: 30px;
              padding: 0 15px;
              border-radius: 15px;
              background-color: #C4E0FF;
              margin-left: 5px;
            }
          }
          .progres{
            margin-bottom: 10px;
            span{
              display: inline-block;
              color: #65B1FF;
              &:not(:last-child){
                margin-right: 50px;
              }
            }
          }
          .live{
            display: flex;
            ul{
              flex-grow: 1;
              margin-bottom: 0;
              list-style-type: none;
              span{
                display: inline-block;
                margin-left: 10px;
                color: #65B1FF;
                &.dis{
                  color: #FFC78B;
                }
              }
            }
          }
        }
      }
      &.my_resources{
        text-align: center;
        h5{
          display: flex;
          justify-content: space-between;
          align-items: center;
          margin-top: -4px;
        }
        h1{
          font-size: 28px;
          color: #2580FD;
          margin: 2rem 0;
        }
        span{
          color: #C7C7C7;
        }
      }
      &.notice{
        flex-grow: 1;
        .info{
          padding: 15px;
          background-color: #FAFCFF;
          margin-bottom: 15px;
          font-size: 16px;
        }
      }
    }
  }
}
</style>
