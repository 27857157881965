<template>
  <a-row class="mt-3">
    <a-col :span="4"></a-col>
    <a-col :span="16">
      <a-flex>
        <a-typography-title :level="3">服务列表</a-typography-title>
        <a-button type="primary" class="ml-3" @click="handlePpenAddServiceModal">添加服务</a-button>
      </a-flex>
      <a-modal v-model:open="openAddServiceModal" title="添加服务" @ok="handlePpenAddServiceModalOk">
        <a-form
            :label-col="labelCol"
            :wrapper-col="wrapperCol"
            layout="horizontal">
          <a-form-item label="服务类型" :rules="[{ required: true, message: '请填写服务类型' }]">
            <a-select v-model:value="createServiceForm.protocol">
              <a-select-option value="TCP">TCP</a-select-option>
              <a-select-option value="HTTP">HTTP</a-select-option>
              <a-select-option value="UDP">UDP</a-select-option>
            </a-select>
          </a-form-item>
          <a-form-item label="内部端口" :rules="[{ required: true, message: '请填写内部端口' }]">
            <a-input-number v-model:value="createServiceForm.servicePort"/>
          </a-form-item>
        </a-form>
      </a-modal>
      <a-table :columns="serviceColumns" :data-source="serviceData"
               :pagination="false" :locale="{emptyText: '暂无数据'}">
        <template #bodyCell="{ column, record }">
          <template v-if="column.key === 'action'">
            <a-popconfirm title="确认删除？" ok-text="是" cancel-text="否"
                          @confirm="()=>deleteService(record)">
              <a-button type="link">
                删除
              </a-button>
            </a-popconfirm>
          </template>
          <template v-if="column.key === 'adjust_access_url'">
            <a v-if="record['service_type'] === 'HTTP服务'"
               :href="record['adjust_access_url']" target="_blank">
              {{ record['adjust_access_url'] }}
            </a>
            <span v-else>{{ record['adjust_access_url'] }}</span>
          </template>
        </template>
      </a-table>
      <p>TCP服务和UDP服务请使用专用工具访问。</p>
    </a-col>
    <a-col :span="4"></a-col>
  </a-row>
</template>

<script setup>
import {onMounted, reactive, ref} from 'vue';
import {logDebug, logError} from "@/utils/logger";
import {getFailedMessage, getResponseData, jsonRPC} from "@/utils/http_utils";
import {message} from "ant-design-vue";
import {isTrue} from "@/utils/common_utils";

logDebug('TerminalService setup!')

const props = defineProps({
  primal: {type: String},
  data: {type: Object},
  styleSetting: {type: Object},
})

const emit = defineEmits(['refresh']); //声明 emits

const primal = props.primal
const data = props.data

const envId = data['env_id']

const labelCol = {
  style: {
    width: '150px',
  },
};
const wrapperCol = {
  span: 14,
};

const createServiceForm = reactive({
  protocol: '',
  servicePort: '',
})

const openAddServiceModal = ref(false)
const handlePpenAddServiceModal = function () {
  openAddServiceModal.value = true
}

const addService = function () {
  logDebug(`addService, createServiceForm[${JSON.stringify(createServiceForm)}]`)

  const port = parseInt(`${createServiceForm.servicePort}`);

  let protocol
  if (createServiceForm.protocol === "TCP") {
    protocol = "TCP"
  } else if (createServiceForm.protocol === "HTTP") {
    protocol = "HTTP"
  } else if (createServiceForm.protocol === "UDP") {
    protocol = "UDP"
  } else {
    protocol = "TCP"
  }

  const is_http = protocol === "HTTP";
  if (is_http) {
    protocol = "TCP"
  }

  const params = {
    target_port: port,
    env_id: envId,
    is_http: is_http,
    protocol: protocol,
    external_domain_list: '',
  }

  const addServiceResult = jsonRPC({
    url: "/api/experiment/service/add",
    params: params,
    success(res) {
      const data = getResponseData(res)
      logDebug('addServiceResult', data)
      serviceData.value = data['services']
    },
    fail(error) {
      logError(`添加应用环境服务失败, `, error)
      message.error(`添加应用环境服务失败，[${JSON.stringify(getFailedMessage(error))}]`, 3);
    },
  })

  return addServiceResult.then(function (res) {
    logDebug(`addServiceResult, `, res)
    loadService()
    emit("refresh", {}); //触发事件
  })
}

const handlePpenAddServiceModalOk = function () {
  addService()
  openAddServiceModal.value = false
}

const serviceColumns = ref([
  {
    title: 'ID',
    // dataIndex是JSON数据的字段名称
    dataIndex: 'id',
    // key是column的索引名称
    key: 'id',
  },
  {
    title: '服务类型',
    dataIndex: 'service_type',
    key: 'service_type',
  },
  {
    title: '内部端口',
    dataIndex: 'target_port',
    key: 'target_port',
  },
  {
    title: '外部端口',
    key: 'adjust_node_port',
    dataIndex: 'adjust_node_port',
  },
  {
    title: '访问地址',
    key: 'adjust_access_url',
    dataIndex: 'adjust_access_url',
  },
  {
    title: '操作',
    key: 'action',
  },
]);
const serviceData = ref([]);

const deleteService = function (record) {
  logDebug(`deleteService[${JSON.stringify(record)}]`)

  const portId = record['id']

  const deleteServiceResult = jsonRPC({
    url: "/api/experiment/service/delete",
    params: {
      port_id: portId,
    },
    success(res) {
      const data = getResponseData(res)
      logDebug('getServiceResult', data)
      serviceData.value = data['services']
    },
    fail(error) {
      logError(`删除指定应用环境服务失败, `, error)
      message.error(`删除指定应用环境服务失败，[${JSON.stringify(getFailedMessage(error))}]`, 3);
    },
  })

  return deleteServiceResult.then(function (res) {
    logDebug(`deleteServiceResult, `, res)
    loadService()
    emit("refresh", {}); //触发事件
  })
}

onMounted(() => {
  // 在onMounted中获取数据
  logDebug(`TerminalService onMounted.`)
})

const adjustServiceData = function (services) {
  for (const service of services) {
    if (service['node_port'] === 0) {
      service['adjust_node_port'] = '无'
    } else {
      service['adjust_node_port'] = service['node_port']
    }

    if (isTrue(service['http'])) {
      service['adjust_access_url'] = service['access_url']
    } else {
      service['adjust_access_url'] = service['host']
    }
  }
  return services
}

const loadService = function () {
  const getServiceResult = jsonRPC({
    url: "/api/experiment/services",
    params: {
      env_id: envId,
    },
    success(res) {
      const data = getResponseData(res)
      logDebug('getServiceResult', data)
      serviceData.value = adjustServiceData(data['services'])
    },
    fail(error) {
      logError(`获取服务列表失败, `, error)
      message.error(`获取服务列表失败，[${JSON.stringify(getFailedMessage(error))}]`, 3);
    },
  })

  return getServiceResult.then(function (res) {
    logDebug(`getServiceResult, `, res)
  })
}

const onSelected = function () {
  logDebug(`TerminalService onSelected. data[${JSON.stringify(data)}]`)

  loadService()
}

defineExpose({
  primal,
  data,
  onSelected,
})

</script>

<style scoped lang="scss">

</style>

