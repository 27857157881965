<template>
  <div style="height: calc(100vh - 50px);">
    <a-menu v-model:selectedKeys="selectedKeys" mode="horizontal">
      <a-menu-item key="container">
        <span><router-link to="/console/image/container">容器</router-link></span>
      </a-menu-item>
      <a-menu-item key="vm">
        <span><router-link to="/console/image/vm">虚拟机</router-link></span>
      </a-menu-item>
    </a-menu>
    <a-layout style="height: calc(100vh - 96px);overflow: auto;">
      <a-layout-content>
        <router-view></router-view>
      </a-layout-content>
    </a-layout>
  </div>
</template>

<script setup>
import {logDebug} from "@/utils/logger";
import {onMounted, ref} from "vue";
import {watchRouterSelectedKeys} from "@/utils/router_utils";
import {useRouter} from "vue-router";

logDebug('ConsoleImage setup.')

const router = useRouter()

const selectedKeys = ref([]);
watchRouterSelectedKeys(router, selectedKeys, {
  'container': '/console/image/container',
  'vm': '/console/image/vm',
})

onMounted(() => {

})

</script>

<style scoped>

</style>
