import {defineStore} from 'pinia';
import {reactive, toRefs} from 'vue';
import {logDebug} from "@/utils/logger";

const chatMessageStore = defineStore('chat_message', () => {
    const state = reactive({
        count: 0,
        current_chat_list: [],  // 使用数组存储消息
    });

    const pushChatMessage = function (message_list) {
        // 检查消息是否为空
        if (!message_list || Object.keys(message_list).length === 0) {
            return;
        }
        // 遍历消息列表
        message_list.forEach(message => {
            // 确保每个消息都是一个对象，并且有一个有效的 id
            if (message && message.id) {
                // 检查消息是否已经存在，基于消息的 id
                if (!state.current_chat_list.some(msg => msg.id === message.id)) {
                    state.current_chat_list.push(message);
                    state.count = state.current_chat_list.length;
                } else {
                    logDebug(`消息 ID ${message.id} 已存在`);
                }
            } else {
                logDebug("无效的消息对象", message);
            }
        });
    }


    const cleanChatMessage = function () {
        state.current_chat_list = [];
        state.count = 0;
    }

    const deleteChatMessage = function (id) {
        const index = state.current_chat_list.findIndex(msg => msg.id === id);
        if (index !== -1) {
            state.current_chat_list.splice(index, 1);
            state.count = state.current_chat_list.length;
        } else {
            logDebug(`消息 ID ${id} 不存在`);
        }
    }

    const pullChatMessage = function () {
        if (state.current_chat_list.length > 0) {
            return state.current_chat_list.shift();  // 挨个输出
        } else {
            logDebug('没有更多消息');
            return null;
        }
    }
    return {
        pushChatMessage, cleanChatMessage, deleteChatMessage, pullChatMessage,
        ...toRefs(state)
    }
});

export default chatMessageStore;
