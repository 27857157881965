<template>
  <div class="console_image_container">
    <div class="top">
      <h4>我的容器镜像</h4>
      <div class="search">
        <a-input-search placeholder="请输入名称" enter-button allowClear @search="onSearch" style="width: 200px"/>
        <a-button type="primary" @click="createCourse">新建容器镜像</a-button>
      </div>
    </div>
    <a-list :grid="{ gutter: 16, column: 4, xs: 1, sm: 2 }" :data-source="data_list">
      <template #renderItem="{ item }">
        <a-list-item class="px-0">
          <a-card hoverable style="width: 100%; height: 100%" @click="enterDetail(item.id)">
            <template #cover>
              <div class="info">
                <a class="img_box">
                  <img class="top-theme" src="@/assets/image_default.jpg">
                </a>
                <div>
                  <a class="line-clamp-1" :title="item.name">{{ item.name }}</a>
                </div>
              </div>
            </template>
          </a-card>
        </a-list-item>
      </template>
    </a-list>
    <a-flex justify="center">
      <a-pagination
          v-model:current="current"
          :showSizeChanger="showSizeChanger"
          v-model:page-size="pageSize"
          :total="total"
          :locale="zhCn"
          :pageSizeOptions="pageSizeOptions"
          :hideOnSinglePage="true"
      />
    </a-flex>
  </div>
</template>

<script setup>
import {onMounted, ref, watch} from "vue";
import {getResponseData, jsonRPC} from "@/utils/http_utils";
import {logDebug, logError} from "@/utils/logger";
import {zhCn} from "@/utils/zhCn";
import {useRouter} from 'vue-router';

const router = useRouter();

const createCourse = () => {
  router.push('/console/image/container/create');
};
const enterDetail = (e) => {
  router.push(`/console/image/container/${e}`);
}

const data_list = ref([]);
const total = ref(0);
const showSizeChanger = ref(true);
const pageSize = ref(8);
const current = ref(1);
const search = ref("");
const pageSizeOptions = ref(
    Array.from({length: 4}, (_, index) =>
        (pageSize.value * (index + 1)).toString()
    )
);

function fetchData() {
  jsonRPC({
    url: "/vue/desktop/image",
    params: {
      page_index: current.value,
      page_size: pageSize.value,
      search: search.value,
    },
    success(res) {
      const data = getResponseData(res);
      logDebug(`获取成功`, data);
      data_list.value = data.records;
      total.value = data.record_count;
    },
    fail(error) {
      logError(`查询失败, `, error);
    },
  });
}

onMounted(fetchData);
watch([pageSize, current], fetchData);

const onSearch = (searchValue) => {
  if (search.value !== searchValue) {
    current.value = 1;
    search.value = searchValue;
    fetchData();
  }
};
</script>

<style scoped lang="scss">
.console_image_container {
  padding: 16px;

  .top {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 16px;

    h4 {
      font-size: 18px;
      margin-bottom: 0;
    }

    .search {
      > button {
        margin-left: 16px;
      }
    }
  }

  .info {
    display: flex;
    flex-direction: column;
    height: 100%;
    background-color: #fff;
    position: relative;
    border-radius: 8px;
    overflow: hidden;

    > span {
      display: inline-block;
      position: absolute;
      top: 0;
      left: 0;
      padding: 0 15px;
      line-height: 30px;
      border-bottom-right-radius: 8px;
      z-index: 1;
      background-color: #90FAFF;

      &.new {
        background-color: #90FFA5;
      }
    }

    > div {
      flex: 1 0 auto;
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      padding: 1rem;

      .line-clamp-1 {
        margin-bottom: 5px;
        font-size: 16px;
        color: #000;
      }

      .summary {
        span {
          display: inline-block;
          color: #52c9ff;
          font-size: 12px;
          border: 1px solid #52c9ff;
          border-radius: 4px;
          padding: 1px 4px;
          margin-right: 5px;
          margin-bottom: 5px;
        }
      }

      .price {
        span {
          font-size: 16px;
          color: #39BFED;
        }
      }
    }
  }
}
</style>