<template>
  <div class="blog_home">
    <a-row gutter="20">
      <a-col :span="19">
        <div class="info">
          <div class="user_info">
            <div class="base">
              <img v-if="data_list.user?.has_image" :src="`/web/image/res.users/${data_list.user?.id}/image_512`"/>
              <img v-else src="/hw_web/static/src/img/avatar_live.png"/>
              <div>
                <h5>{{data_list.user?.name}}</h5>
                粉丝<span style="margin-right: 20px;">{{data_list.blog_attention_count}}</span>
                关注<span>{{data_list.blog_fan_count}}</span>
              </div>
            </div>
            <div class="tag" v-if="userId!=data_list.user?.id">
              <a-button v-if="data_list.is_attention" shape="round" size="small" @click="onAttention">已关注</a-button>
              <a-button v-else type="primary" shape="round" size="small" @click="onAttention">关注</a-button>
              <!-- <a-button shape="round" size="small">私信</a-button> -->
            </div>
          </div>
          <div class="blog_list" v-for="item in data_list.records" :key="item.id">
            <div class="blog_list_top">
              <router-link :to="`/blog/homepage?user_id=${item.user.id}`" class="user">
                <img v-if="item.user.has_image" :src="`/web/image/res.users/${item.user.id}/image_128`"/>
                <img v-else src="/hw_web/static/src/img/avatar_live.png"/>
                {{item.user.name}}
              </router-link>
              <span class="ml-3">{{ item.post_date }}</span>
            </div>
            <div class="blog_list_content">
              <div>
                <h5>
                  <router-link :to="`/blog/${item.id}`">{{ item.name }}</router-link>
                  <span v-for="i in item.tag_ids" :key="i.id">{{i.name}}</span>
                </h5>
                <a-typography-text
                  :style="item.ellipsis ? {maxHeight: '200px',overflow: 'hidden',textOverflow: 'ellipsis',whiteSpace: 'normal',width: '100%'} : {}"
                  :ellipsis="item.ellipsis"
                >
                  <div v-html="item.content"></div>
                </a-typography-text>
                <a-flex justify="center">
                  <a-button type="text" @click="item.ellipsis=!item.ellipsis" style="color: #429BFF;">
                    <template v-if="item.ellipsis" #icon><DownOutlined style="display: inline-flex;"/></template>
                    <template v-else #icon><UpOutlined style="display: inline-flex;"/></template>
                    <template v-if="item.ellipsis">展开全文</template>
                    <template v-else>收起全文</template>
                  </a-button>
                </a-flex>
              </div>
              <img v-if="item.is_image" :src="`/web/image/hw.blog.post/${item.id}/image_512`"/>
            </div>
            <div class="blog_list_bottom">
              <a-tooltip placement="bottom">
                <template #title>浏览量</template>
                <span>
                  <img src="/hw_blog/static/src/img/visited.png"/>
                  <span>{{ item.visits }}</span>
                </span>
              </a-tooltip>
              <a-tooltip placement="bottom">
                <template #title>评论人数</template>
                <span>
                  <img v-if="item.is_comments" src="/hw_blog/static/src/img/info.png"/>
                  <img v-else src="/hw_blog/static/src/img/comment.png"/>
                  <span>{{ item.comments }}</span>
                </span>
              </a-tooltip>
              <a-tooltip placement="bottom">
                <template #title>点赞</template>
                <span>
                  <img v-if="item.is_likes" @click="onBlogLike(item.id)" src="/hw_blog/static/src/img/liked.png"/>
                  <img v-else @click="onBlogLike(item.id)" src="/hw_blog/static/src/img/praise.png"/>
                  <span>{{ item.likes }}</span>
                </span>
              </a-tooltip>
              <a-tooltip placement="bottom" v-if="userId==data_list.user?.id">
                <template #title>删除</template>
                <span>
                  <a-popconfirm title="确定要删除?" @confirm="onDelete(item.id)">
                    <DeleteOutlined style="font-size: 20px;color: #bfbfbf;"/>
                  </a-popconfirm>
                </span>
              </a-tooltip>
            </div>
          </div>
          <!-- 分页 -->
          <a-flex justify="center" class="mt-3">
            <a-pagination
              v-model:current="current"
              :showSizeChanger="showSizeChanger"
              v-model:page-size="pageSize"
              :total="data_list.record_count"
              :locale="zhCn"
              :pageSizeOptions="pageSizeOptions"
              :hideOnSinglePage="true"
            />
          </a-flex>
          <div v-if="data_list.records.length==0" class="empty">
            <a-empty />
          </div>
        </div>
      </a-col>
      <a-col :span="5">
        <div class="blog_interactive">
          <h5>互动数据
            <div v-show="data_list.is_interactive">
              <a-button type="primary" shape="round" size="small">本月</a-button>
              <a-button shape="round" size="small" @click="data_list.is_interactive = false">本年</a-button>
            </div>
            <div v-show="!data_list.is_interactive">
              <a-button shape="round" size="small" @click="data_list.is_interactive = true">本月</a-button>
              <a-button type="primary" shape="round" size="small">本年</a-button>
            </div>
          </h5>
          <ul v-show="data_list.is_interactive">
            <li>本月新发帖子：<span>{{data_list.interactive?.month_blog}}</span> 条</li>
            <li>本月新增粉丝：<span>{{data_list.interactive?.month_fan}}</span> 个</li>
            <li>本月论坛互动量：<span>{{data_list.interactive?.month_comment}}</span> 人次</li>
            <li>本月帖子阅读量：<span>{{data_list.interactive?.month_visits}}</span> 人次</li>
            <li>本月热门帖子：<span>{{data_list.interactive?.month_post_one}}</span></li>
          </ul>
          <ul v-show="!data_list.is_interactive">
            <li>本年新发帖子：<span>{{data_list.interactive?.year_blog}}</span> 条</li>
            <li>本年新增粉丝：<span>{{data_list.interactive?.year_fan}}</span> 个</li>
            <li>本年论坛互动量：<span>{{data_list.interactive?.year_comment}}</span> 人次</li>
            <li>本年帖子阅读量：<span>{{data_list.interactive?.year_visits}}</span> 人次</li>
            <li>本年热门帖子：<span>{{data_list.interactive?.year_post_one}}</span></li>
          </ul>
        </div>
        <div class="blog_message">
          <h5>互动消息</h5>
          <router-link to="/blog/message?key=reply">
            <img src="/hw_frontend/static/img/blog_@my_icon.png"/>
            @我的
            <a-badge :count="data_list.reply_comment_count" :number-style="{ backgroundColor: '#FF8110' }"/>
          </router-link>
          <router-link to="/blog/message?key=comment">
            <img src="/hw_frontend/static/img/blog_comment_icon.png"/>
            评论
            <a-badge :count="data_list.comment_count" :number-style="{ backgroundColor: '#FF8110' }"/>
          </router-link>
          <router-link to="/blog/message?key=like">
            <img src="/hw_frontend/static/img/blog_like_icon.png"/>
            点赞
            <a-badge :count="data_list.like_count" :number-style="{ backgroundColor: '#FF8110' }"/>
          </router-link>
        </div>
      </a-col>
    </a-row>
    <a-back-top />
  </div>
</template>

<script setup>
import { DeleteOutlined } from '@ant-design/icons-vue';
import { logDebug, logError } from "@/utils/logger";
import { onMounted, ref, reactive, watch } from "vue";
import { getResponseData, jsonRPC } from "@/utils/http_utils";
import { getRouterQuery } from "@/utils/router_utils";
import { zhCn } from "@/utils/zhCn";
import { useRouter } from "vue-router";
import { message } from "ant-design-vue";
import { trackUV } from "@/utils/trackPage";

const router = useRouter();
const user_id = ref(getRouterQuery(router, "user_id"));
const userId = sessionStorage.getItem('user_id');

const search = ref('');
const data_list = reactive({
  records: [],
  record_count: 0,
  user_blog_num: 0,
  reply_comment_count: 0,
  comment_count: 0,
  like_count: 0,
  blog_attention_count: 0,
  blog_fan_count: 0,
  is_attention: false,
  is_interactive: true,
});
const showSizeChanger = ref(true);
const pageSize = ref(8);
const current = ref(1);
const pageSizeOptions = ref(
  Array.from({ length: 4 }, (_, index) =>
    (pageSize.value * (index + 1)).toString()
  )
);
function fetchData() {
  jsonRPC({
    url: `/api/blog/homepage`,
    params: {
      user_id: user_id.value,
      page_index: current.value,
      page_size: pageSize.value,
      search: search.value,
    },
    success(res) {
      const data = getResponseData(res);
      logDebug(`获取个人主页成功`, data);
      Object.assign(data_list, data);
    },
    fail(error) {
      logError(`获取个人主页失败`, error);
      message.error(`获取个人主页失败，请稍后再试`);
    },
  });
}
onMounted(() => {
  fetchData();
  trackUV();
});
watch([pageSize, current], fetchData);
const onAttention = ()=>{
  jsonRPC({
    url: `/api/blog/homepage/attention/${user_id.value}`,
    params: {},
    success(res) {
      const data = getResponseData(res);
      logDebug(`关注成功`, data);
      fetchData();
    },
    fail(error) {
      logError(`关注失败`, error);
    },
  });
}
const onDelete = (e) => {
  jsonRPC({
    url: "/api/blog/homepage/post/delete",
    params: {
      post_id: e,
    },
    success() {
      message.success('删除帖子成功')
      fetchData()
    },
    fail(error) {
      logError(`删除帖子失败, `, error)
    },
  })
};
const onBlogLike = (e) => {
  jsonRPC({
    url: `/api/blog/like/${e}`,
    params: {},
    success(res) {
      logDebug(res);
      fetchData();
    },
    fail(error) {
      logError(`操作失败`, error);
    },
  });
};
</script>

<style scoped lang="scss">
.blog_home {
  margin: 20px 10px 30px;
  .info{
    height: calc(100% - 15px);
    background-color: #fff;
    padding: 15px;
    .user_info{
      display: flex;
      justify-content: space-between;
      .base{

        display: flex;
        align-items: flex-end;
        gap: 25px;
        color: #ABABAB;
        position: relative;
        top: -80px;
        left: 25px;
        h5{
          color: #000;
          font-weight: bold;
        }
        span{
          margin-left: 0.25rem;
          color: #000;
        }
      }
      .tag{
        display: flex;
        gap: 15px;
      }
      img{
        width: 130px;
        height: 130px;
        border-radius: 50%;
        object-fit: cover;
      }
    }
    .blog_list{
      padding: 15px;
      background-color: #fff;
      border-radius: 8px;
      box-shadow: 2px 2px 2px 0px rgba(128, 128, 128, 0.4);
      margin-bottom: 15px;
      .blog_list_top{
        display: flex;
        align-items: center;
        color: #A1A1A1;
        margin-bottom: 5px;
        .user{
          display: flex;
          align-items: center;
          color: #A1A1A1;
          &:hover{
            color: #ff8c00;
          }
        }
        img{
          height: 25px;
          width: 25px;
          margin-right: 5px;
          border-radius: 50%;
        }
      }
      .blog_list_content{
        display: flex;
        gap: 10px;
        >div{
          flex-grow: 1;
          h5{
            display: flex;
            flex-flow: wrap;
            gap: 5px;
            a{
              color: #000;
              font-size: 18px;
              font-weight: bold;
              &:hover{
                color: #ff8c00;
              }
            }
            span{
              background-color: #E4EFFF;
              color: #2580fd;
              padding: 3px 7px;
              border-radius: 4px;
              margin-bottom: -3px;
              position: relative;
              top: -2px;
              margin-left: 6px;
              font-size: 14px;
            }
          }
        }
        >img{
          flex-shrink: 0;
          width: 100%;
          max-width: 200px;
          object-fit: cover;
        }
      }
      .blog_list_bottom{
        display: flex;
        justify-content: space-around;
        margin-top: 5px;
        img{
          width: 25px;
          height: 25px;
          margin-right: 5px;
        }
      }
    }
    .empty{
      height: calc(100% - 130px);
      background-color: #fff;
      display: flex;
      justify-content: center;
      align-items: center;
    }
  }
  .blog_interactive{
    border-radius: 8px;
    background-color: #fff;
    padding: 15px;
    margin-bottom: 15px;
    h5{
      font-weight: bold;
      font-size: 16px;
      display: flex;
      justify-content: space-between;
      align-items: center;
      div{
        display: flex;
        gap: 10px;
        span{
          color: #047aff;
        }
      }
    }
    ul{
      list-style: none;
      margin: 0;
      li:not(:last-child){
        margin-bottom: 10px;
      }
      span{
        color: #047cff;
        font-weight: bold;
      }
    }
  }
  .blog_message{
    border-radius: 8px;
    background-color: #fff;
    padding: 15px;
    margin-bottom: 15px;
    h5{
      font-weight: bold;
      font-size: 16px;
    }
    img{
      width: 25px;
      height: 25px;
    }
    a{
      display: flex;
      gap: 10px;
      padding: 10px 0;
      color: #101010;
      &:hover{
        color: #ff8c00;
      }
      span{
        flex-shrink: 0;
        margin-left: auto;
        .ant-badge-count{
          min-width: 24px;
          height: 24px;
          font-size: 14px;
          line-height: 24px;
          border-radius: 12px;
        }
      }
    }
  }
}
:deep(.ant-btn.ant-btn-sm){
  height: 30px;
}
</style>
