<template>
  <div class="batch">
    <a-flex justify="space-between" align="center">
      <a-breadcrumb separator=">">
        <a-breadcrumb-item>
          <router-link to="/console/eduAdmin">教务管理</router-link>
        </a-breadcrumb-item>
        <a-breadcrumb-item>
          <router-link :to="`/console/eduAdmin/class/?id=${class_id}&mode=readonly`">
            {{data_list.class_name}}
          </router-link>
        </a-breadcrumb-item>
        <a-breadcrumb-item>
          <router-link :to="`/console/eduAdmin/class/course?id=${course_id}&class_id=${class_id}`">
            {{ data_list.course_name }}
          </router-link>
        </a-breadcrumb-item>
        <a-breadcrumb-item>报告打分</a-breadcrumb-item>
      </a-breadcrumb>
      <a-flex gap="8">
        <a-button type="primary" @click="confirmSubmitScores">提交分数</a-button>
        <a-button type="primary" @click="reportNext()">下一份</a-button>
        <a-button type="primary" @click="reportPrevious()">上一份</a-button>
      </a-flex>
    </a-flex>
    <div class="info">
      <a-flex justify="space-between" align="center" class="mb-3">
        <h4><b>报告打分</b></h4>
        <!-- <a-flex align="center">
          切换学生：
          <a-select v-model:value="data_list.semester_id" :allowClear="true"
                    show-search :options="data_list.semester_ids" :filter-option="filterOption"
                    placeholder="请选择学生">
          </a-select>
        </a-flex> -->
      </a-flex>
      <a-flex justify="center" align="center">
        学生姓名：{{ current_paper_id.partner_name }}
        <div class="mx-3"></div>
        学生编号:{{ current_paper_id.student_number }}
      </a-flex>
      <a-form ref="formClassRef" :model="classForm" :label-col="{style: {width: '100px'} }" :wrapper-col="{ span: 16 }">
        <a-form-item label="报告打分">
          <a-input-number v-model:value="current_paper_id.report_content_score" :min="0" :max="current_paper_id.max_score" :keyboard="true"/>
        </a-form-item>
        <a-form-item label="问题">
          <div v-html="current_paper_id.name"/>
        </a-form-item>
        <a-form-item label="提交内容">
          <RichEditor :height="301" :editMode="editorMode" v-model="current_paper_id.report_content"/>
        </a-form-item>
        <a-form-item label="附件">
          <a :href="`/web/image/hw.exercise.record/${current_paper_id.record_id}/update_file`" target="_blank">{{current_paper_id.update_file_name}}</a>
        </a-form-item>
        <a-form-item label="附件地址">
          <a-typography-paragraph copyable class="mb-0">{{current_paper_id.file_path}}</a-typography-paragraph>
        </a-form-item>
      </a-form>
    </div>
  </div>
</template>

<script setup>
import {logDebug, logError} from "@/utils/logger";
import {onMounted, ref, reactive} from 'vue'
import {jsonRPC, getResponseData} from "@/utils/http_utils";
import {getRouterQuery} from "@/utils/router_utils";
import {useRouter} from "vue-router";
import RichEditor from "@/components/RichEditor.vue";
import {message} from "ant-design-vue";

const router = useRouter();
const course_id = ref(getRouterQuery(router, "course_id"));
const class_id = ref(getRouterQuery(router, "class_id"));
const paper_id = ref(getRouterQuery(router, "paper_id"));
const data_list = reactive({
  class_name: '',
  course_name: '',
  report_ids: [],
  type_id: '',
  max_score: 0,
})
const editorMode = ref('readonly')
const currentIndex = ref(0);
let current_paper_id = reactive({
  id: '',
  name: '',
  partner_name: '',
  report_content: '',
  report_content_score: 0,
  student_number: '',
  record_id: 0,
  update_file_name: '',
  file_path: '',
});


const confirmSubmitScores = () => {
  // 确认提交评分
  logDebug('确认提交评分')
  submitScores(current_paper_id.id)
}

const reportPrevious = () => {
  // 检查当前索引是否大于 0，确保有前一个元素
  if (currentIndex.value > 0) {
    currentIndex.value -= 1; // 将索引减 1，获取上一份数据
    // 获取前一个元素
    const previousReport = data_list.report_ids[currentIndex.value];
    // 合并前一个元素的内容到 current_paper_id
    Object.assign(current_paper_id, previousReport);
  } else {
    message.info('已经是第一份')
  }
};

const reportNext = () => {
  if (currentIndex.value < data_list.report_ids.length - 1) {
    currentIndex.value += 1
    // 获取下一个元素
    const nextReport = data_list.report_ids[currentIndex.value];
    // 合并下一个元素的内容到 current_paper_id
    Object.assign(current_paper_id, nextReport);
  } else {
    message.info('已经是最后一个')
  }
};

async function submitScores(user_report_id) {
  await jsonRPC({
    url: `/api/class/course/exercise/user/${user_report_id}/score`,
    params: {
      record_id: current_paper_id.record_id,
      score: current_paper_id.report_content_score,
    },
    success() {
      message.success("提交分数成功")
    },
    fail(error) {
      logError(`查询失败, `, error);
    },
  })
}

async function fetchData() {
  await jsonRPC({
    url: `/api/class/course/exercise/report/${paper_id.value}/score`,
    params: {
      course_id: course_id.value,
      class_id: class_id.value,
    },
    success(res) {
      const data = getResponseData(res);
      Object.assign(data_list, data)
    },
    fail(error) {
      logError(`查询失败, `, error);
    },
  })
}

onMounted(async () => {
  await fetchData()
  if (data_list.report_ids.length > 0) {
    Object.assign(current_paper_id, data_list.report_ids[currentIndex.value])
  }
});
</script>

<style scoped lang="scss">
.batch {
  height: 100%;
  width: 100%;
  overflow: auto;
  padding: 0 16px;

  .menu {
    button {
      margin-bottom: 10px;
    }
  }

  .info {
    background-color: #fff;
    padding: 1.5rem;

    h4 {
      display: inline-block;
      font-size: 18px;
      padding-bottom: 5px;
      padding-right: 30px;
      border-bottom: 2px solid #63B1FF;
    }

    table {
      text-align: center;

      td {
        height: 46px;
      }
    }
  }
}

:deep(.ant-flex) {
  white-space: nowrap;
}
</style>
