<template>
  <div>
    <a-button id="function-rank" type="primary" @click="showRankModal" :style="props.styleSetting.buttonStyle">
      <a-tooltip>
        <template #title>排名</template>
        <OrderedListOutlined :style="props.styleSetting.iconStyle"/>
      </a-tooltip>
    </a-button>
    <a-modal v-model:open="openRankModal" title="排名" style="width: 70vw" :footer="null">
      <a-flex justify="flex-end">
        <a-input-search placeholder="请输入名称" enter-button allowClear @search="onSearch" style="width: 200px"/>
      </a-flex>
      <a-table :columns="rankColumns" :data-source="rankData" :pagination="pagination"
               style="margin-top: 10px" :scroll="{ y: '50vh' }">
        <template #bodyCell="{ column, index, record }">
          <template v-if="column.dataIndex == 'id'">
            {{ index + 1 }}
          </template>
          <template v-if="column.dataIndex == 'user_id'">
            <img v-if="record.user_id" :src="`/web/image/res.partner/${record.user_id}/image_1920`" width="30"
                 height="30" style="border-radius: 50%;">
          </template>
        </template>
      </a-table>
    </a-modal>
  </div>
</template>

<script setup>
import {markRaw, onMounted, reactive, ref} from 'vue'
import {OrderedListOutlined} from '@ant-design/icons-vue';
import {logComponents, logDebug, logError} from "@/utils/logger";
import {getResponseData, jsonRPC} from "@/utils/http_utils";

logDebug('TerminalFunctionAppSnapshot setup!')
logComponents([OrderedListOutlined])

const props = defineProps({
  primal: {type: String},
  data: {type: Object},
  styleSetting: {type: Object},
})

const primal = props.primal
const data = props.data
const appEnvId = data['live_id']

const openRankModal = ref(false);
const handlePageChange = function (page, pageSize) {
  logDebug(`page[${page}], pageSize[${pageSize}]`)
  pagination.current = page
  pagination.pageSize = pageSize
  loadRankData()
}

const pagination = reactive({
  // 分页配置
  pageSize: 5, // 每页显示的条数
  showSizeChanger: true, // 是否可以改变每页显示的条数
  pageSizeOptions: ['5', '10', '20'], // 可选的每页显示条数
  showQuickJumper: false, // 是否可以快速跳转到指定页
  showTotal: total => `共 ${total} 条`, // 显示总条数和当前数据范围
  hideOnSinglePage: true, // 只有一页时是否隐藏分页器 true为不显示 false为显示
  current: 1, // 当前页数
  total: 0, // 总条数
  onChange: markRaw(handlePageChange), // 页码改变时的回调函数
})

const rankColumns = [
  {
    title: '序号',
    dataIndex: 'id',
    key: 'id',
  },
  {
    title: '头像',
    dataIndex: 'user_id',
    key: 'user_id',
  },
  {
    title: '用户名',
    dataIndex: 'user_name',
    key: 'user_name',
  },
  {
    title: '分数',
    dataIndex: 'score',
    key: 'score',
    sorter: (a, b) => a.score - b.score,
  },
];
const rankData = ref([]);

onMounted(() => {
  // 在onMounted中获取数据
  logDebug(`LiveDetailFunctionRank onMounted.`)
})

const search = ref("");
const onSearch = (searchValue) => {
  if (search.value != searchValue) {
    pagination.current = 1;
    search.value = searchValue;
    loadRankData()
  }
};
const loadRankData = function () {
  const getRankResult = jsonRPC({
    url: "/api/experiment/live/rank",
    params: {
      app_env_id: appEnvId,
      search: search.value,
      page_index: pagination.current,
      page_size: pagination.pageSize,
    },
    success(res) {
      const data = getResponseData(res)
      logDebug('loadRankData', data)
      rankData.value = data.records
      pagination.total = data.record_count
    },
    fail(error) {
      logError(`获取排名列表失败, `, error)
    },
  })

  return getRankResult.then(function (res) {
    logDebug(`getRankResult, `, res)
  })
}

const showRankModal = function () {
  loadRankData().then(function () {
    openRankModal.value = true
  })
}

defineExpose({
  primal,
  data,
})

</script>

<style scoped>
#function-app-snapshot {
  background: #00BFFF;
}
</style>