<template>
  <div class="notification">
  </div>
</template>

<script setup>
import { watch } from 'vue';
import useNotificationStore from "@/stores/notification";
const notificationStore = useNotificationStore();

// 监听通知列表的变化
watch(
    () => notificationStore.notifications,
    (newNotifications) => {
      // 监听到通知列表变化时，由 Pinia 处理通知
      if (newNotifications.length > 0) {
        const message = newNotifications[0]; // 获取第一个未处理的通知
        // 这里只需要移除已处理的通知，不需要再手动调用 notification.open
        notificationStore.removeNotification(message.id); // 处理完后移除
      }
    },
    {immediate: true} // 在组件创建时就立即触发一次，避免第一次加载时无反应
);

</script>
<style lang="less" scoped>
.notification {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  z-index: 9999; /* 确保在其他元素之上 */
}
</style>