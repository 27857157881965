import moment from "moment";

export function newShowTimeOption() {
    return {
        defaultValue: moment()
            .hour(0)
            .minute(0)
            .second(0)
    }
}

export function newShowTimedDateFormat() {
    return "YYYY-MM-DD HH:mm"
}

export function sleep(ms) {
    return new Promise(resolve => {
        setTimeout(() => {
            resolve()
        }, ms)
    })
}
