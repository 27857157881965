<template>
  <div class="console_course_history">
    <a-breadcrumb separator=">">
      <a-breadcrumb-item>
        <router-link to="/console/course/create">新建课程</router-link>
      </a-breadcrumb-item>
      <a-breadcrumb-item>
        <router-link :to="`/console/course/${course_id}`">{{ course_name }}</router-link>
      </a-breadcrumb-item>
      <a-breadcrumb-item>操作历史</a-breadcrumb-item>
    </a-breadcrumb>
    <a-flex justify="space-between" align="center" class="mb-3">
      <a-menu v-model:selectedKeys="menu_current" mode="horizontal" :items="menu_items"/>
      <div class="search">
        <a-input-search placeholder="请输入名称" enter-button allowClear @search="onSearch" style="width: 200px"/>
      </div>
    </a-flex>
    <a-table :columns="columns" :data-source="data_list" :pagination="pagination">
      <template #bodyCell="{ column, record }">
        <template v-if="column.key == 'old_data'">
          <div v-if="record.old_data" v-html="record.old_data"></div>
        </template>
        <template v-if="column.key == 'new_data'">
          <div v-if="record.new_data" v-html="record.new_data"></div>
        </template>
      </template>
    </a-table>
  </div>
</template>

<script setup>
import {logDebug, logError} from "@/utils/logger";
import {onMounted, reactive, ref, markRaw, watch} from "vue";
import {jsonRPC, getResponseData, getFailedMessage} from "@/utils/http_utils";
import {useRouter} from 'vue-router';
import { message } from "ant-design-vue";
const router = useRouter();

const course_id = router.currentRoute.value.params.course_id
logDebug(course_id)


const handlePageChange = (page, pageSize) => {
  logDebug(`page[${page}], pageSize[${pageSize}]`)
  pagination.current = page;
  fetchData();
}
const pagination = reactive({
  // 分页配置
  pageSize: 8, // 每页显示的条数
  showSizeChanger: false, // 是否可以改变每页显示的条数
  pageSizeOptions: ['5', '10', '20'], // 可选的每页显示条数
  showQuickJumper: false, // 是否可以快速跳转到指定页
  showTotal: total => `共 ${total} 条`, // 显示总条数和当前数据范围
  hideOnSinglePage: true, // 只有一页时是否隐藏分页器 true为不显示 false为显示
  current: 1, // 当前页数
  total: 0, // 总条数
  onChange: markRaw(handlePageChange), // 页码改变时的回调函数
})
const columns = [
  {
    title: '制作人',
    dataIndex: 'create_name',
    key: 'create_name',
  },
  {
    title: '内容板块',
    dataIndex: 'name',
    key: 'name',
  },
  {
    title: '操作',
    dataIndex: 'history_type',
    key: 'history_type',
  },
  {
    title: '旧数据',
    dataIndex: 'old_data',
    key: 'old_data',
    ellipsis: true,
  },
  {
    title: '新数据',
    dataIndex: 'new_data',
    key: 'new_data',
    ellipsis: true,
  },
  {
    title: '操作时间',
    dataIndex: 'create_date',
    key: 'create_date',
  },
];
const menu_current = ref(['all']);
const menu_items = ref([
  {
    key: 'all',
    label: '全部',
  },
  {
    key: 'week',
    label: '本周',
  },
])
const course_name = ref()
const data_list = ref([]);
const search = ref("");

onMounted(fetchData);
function fetchData() {
  jsonRPC({
    url: `/api/make/course/${course_id}/history`,
    params: {
      page_index: pagination.current,
      page_size: pagination.pageSize,
      search: search.value,
      demand_state: menu_current.value[0],
    },
    success(res) {
      const data = getResponseData(res);
      logDebug(`获取历史记录成功`, data);
      data_list.value = data.records;
      pagination.total = data.record_count;
      course_name.value = data.course_name;
    },
    fail(error) {
      logError(`查询失败, `, error);
      const errMsg = getFailedMessage(error)
      message.error(errMsg)
    },
  });
}
watch([menu_current], fetchData);
const onSearch = (searchValue) => {
  if (search.value != searchValue) {
    pagination.current = 1;
    search.value = searchValue;
    fetchData();
  }
};
</script>

<style scoped lang="scss">
.console_course_history {
  padding: 16px;
}
:where(.ant-menu-light){
  background-color: transparent;
  border-bottom: none;
}
:where(.ant-btn >span){
  display: inline-flex !important;
}
</style>