<template>
  <div class="console_image_container_detail">
    <a-breadcrumb separator=">">
      <a-breadcrumb-item>
        <router-link to="/console/image/container">我的容器镜像</router-link>
      </a-breadcrumb-item>
      <a-breadcrumb-item>{{ data_list.name }}</a-breadcrumb-item>
    </a-breadcrumb>
    <a-row style="padding: 0 20px">
      <a-col :span="24">
        <div>
          <a-row>
            <a-col :span="8">
              <a-typography-title :level="4">容器镜像信息</a-typography-title>
            </a-col>
            <a-col :span="10">
            </a-col>
            <a-col :span="6" style="display: flex; justify-content: flex-end">
              <a-button type="primary" @click="onDeleteImage" style="display: none">删除容器镜像</a-button>
            </a-col>
          </a-row>
          <a-form ref="formRef" :disabled="editorMode === 'readonly'" :label-col="labelCol"
                  :model="data_list"
                  :wrapper-col="wrapperCol"
                  layout="horizontal"
          >
            <a-row>
              <a-col :span="24">
                <a-form-item label="容器镜像ID：" name='id'>
                  <a-input v-model:value="data_list.id" disabled/>
                </a-form-item>
                <a-form-item label="容器镜像名称：" name='name'>
                  <a-input v-model:value="data_list.name" disabled/>
                </a-form-item>
                <a-form-item label="容器镜像描述：" name='description'>
                  <a-input v-model:value="data_list.description"/>
                </a-form-item>
                <!--              <a-form-item label="VNC支持：" name='vnc_support'>-->
                <!--                <a-input v-model:value="data_list.vnc_support"/>-->
                <!--              </a-form-item>-->
                <!--              <a-form-item label="VNC端口：" name='vnc_port'>-->
                <!--                <a-input v-model:value="data_list.vnc_port"/>-->
                <!--              </a-form-item>-->
                <!--              <a-form-item label="容器镜像ShortID：" name='image_short_id'>-->
                <!--                <a-input v-model:value="data_list.image_short_id"/>-->
                <!--              </a-form-item>-->
                <!--              <a-form-item label="容器镜像Registry：" name='image_registry_id'>-->
                <!--                <a-input v-model:value="data_list.image_registry_id"/>-->
                <!--              </a-form-item>-->
                <!--              <a-form-item label="仓库前缀：" name='image_registry_prefix'>-->
                <!--                <a-input v-model:value="data_list.image_registry_prefix"/>-->
                <!--              </a-form-item>-->
                <!--              <a-form-item label="仓库Repository：" name='repository'>-->
                <!--                <a-input v-model:value="data_list.repository"/>-->
                <!--              </a-form-item>-->
                <!--              <a-form-item label="Tag：" name='tag'>-->
                <!--                <a-input v-model:value="data_list.tag"/>-->
                <!--              </a-form-item>-->
                <a-form-item label="容器镜像仓库地址：" name='image_tag'>
                  <a-input v-model:value="data_list.image_tag" disabled/>
                </a-form-item>
                <a-form-item label="容器Shell类型：" name='shell_type'>
                  <a-select v-model:value="data_list.shell_type">
                    <a-select-option value="bash">bash</a-select-option>
                    <a-select-option value="sh">sh</a-select-option>
                  </a-select>
                </a-form-item>
                <a-form-item label="容器镜像ID：" name='image_id'>
                  <a-input :value="data_list.image_id ? data_list.image_id : '无'" disabled/>
                </a-form-item>
                <!--              <a-form-item label="容器镜像标签列表：" name='image_tags'>-->
                <!--                <a-input v-model:value="data_list.image_tags"/>-->
                <!--              </a-form-item>-->
                <!--              <a-form-item label="时间戳：" name='image_crate_timestamp'>-->
                <!--                <a-input v-model:value="data_list.image_crate_timestamp"/>-->
                <!--              </a-form-item>-->
                <a-form-item label="容器镜像拉取状态：" name='image_pull_status'>
                  <a-input :value="image_pull_status_map[`${data_list.image_pull_status}`]" disabled/>
                </a-form-item>
                <a-form-item label="容器镜像推送状态：" name='image_push_status'>
                  <a-input :value="image_push_status_map[`${data_list.image_push_status}`]" disabled/>
                </a-form-item>
                <!--              <a-form-item label="容器镜像更新时间：" name='image_update_time'>-->
                <!--                <a-input v-model:value="data_list.image_update_time"/>-->
                <!--              </a-form-item>-->
                <!--              <a-form-item label="容器镜像信息：" name='image_info'>-->
                <!--                <a-input v-model:value="data_list.image_info"/>-->
                <!--              </a-form-item>-->
              </a-col>
            </a-row>
            <a-flex justify="center" align="center" gap="middle" class="mb-5">
              <a-button v-if="editorMode === 'create'" type="primary" @click="confirmFormCreate">创建</a-button>
              <a-button v-if="editorMode === 'update'" type="primary" @click="confirmFormUpdate">保存</a-button>
              <a-button v-if="editorMode !== 'readonly'" style="margin-left: 10px" @click="cancelForm">取消</a-button>
            </a-flex>
          </a-form>
        </div>
        <div v-if="isImageReady" style="margin-top: 20px">
          <a-row>
            <a-col :span="8">
              <a-typography-title :level="4">容器镜像应用</a-typography-title>
            </a-col>
            <a-col :span="10">
            </a-col>
            <a-col :span="6" style="display: flex; justify-content: flex-end">
              <a-button type="primary" @click="onCreateApp">制作应用模板</a-button>
            </a-col>
          </a-row>
          <a-table :columns="appColumns" :dataSource="appDataSource" style="width: 100%">
            <template #bodyCell="{ column, record}">
              <template v-if="column.dataIndex === 'action'">
                <span>
                  <a-button type="link" @click="()=>onDetailImageApp(record.user_app_id)">详情</a-button>
                  <a-divider type="vertical"/>
                  <!--                  <a @click="()=>onDeleteImageApp(record.user_app_id)">发布</a>-->
                  <!--                  <a-divider type="vertical"/>-->
                  <!--                  <a @click="()=>onDeleteImageApp(record.user_app_id)">取消发布</a>-->
                  <!--                  <a-divider type="vertical"/>-->
                  <a-button type="link" @click="()=>_onCreateDeployment(record.template_id)">部署</a-button>
                  <a-divider type="vertical"/>
                  <a-button type="link" @click="()=>onDeleteImageApp(record.user_app_id)">删除</a-button>
                  <a-divider type="vertical"/>
                </span>
              </template>
            </template>
          </a-table>
          <!--          <div v-for="row in data_list.template_ids" :key="row.app_id" class="col-md-4">-->
          <!--            <div>应用ID：{{ row.app_id }}， 应用名称：{{ row.name }}</div>-->
          <!--          </div>-->
          <a-modal v-model:open="openCreateAppModal" :confirm-loading="confirmCreateAppLoading"
                   title="创建应用" width="60%" @cancel="cancelCreateApp" @ok="confirmCreateApp">
            <a-form ref="formCreateAppRef" :model="createAppForm" :rules="rules" @submit="confirmCreateApp"
                    :label-col="labelCol" :wrapper-col="wrapperCol" layout="horizontal">
              <a-form-item label="应用名称" name="app_name">
                <a-input v-model:value="createAppForm.app_name"/>
              </a-form-item>
              <a-form-item label="空间类型" name="ns_type">
                <a-select v-model:value="createAppForm.ns_type" :disabled="createAppForm.disable_namespace_selection">
                  <a-select-option v-for="item in createAppForm.ns_types" :key="item[0]" :value="item[0]">
                    {{ item[1] }}
                  </a-select-option>
                </a-select>
              </a-form-item>
              <a-form-item label="命名空间" name="namespace_id">
                <a-select v-model:value="createAppForm.namespace_id"
                          :disabled="createAppForm.disable_namespace_selection">
                  <template v-if="createAppForm.ns_type === 'private'">
                    <a-select-option v-for="item in createAppForm.private_namespaces" :key="item.id" :value="item.id">
                      {{ item.name }}
                    </a-select-option>
                  </template>
                  <a-select-option v-for="item in createAppForm.public_namespaces" v-else :key="item.id"
                                   :value="item.id">
                    {{ item.name }}
                  </a-select-option>
                </a-select>
              </a-form-item>
              <a-form-item label="部署方式" name="deployment_method">
                <a-select v-model:value="createAppForm.deployment_method">
                  <a-select-option value="consumer_compute_power">消耗算力点先用后结</a-select-option>
                  <!-- <a-select-option value="use_resource">使用现有资源</a-select-option> -->
                </a-select>
              </a-form-item>
              <template v-if="createAppForm.deployment_method=='consumer_compute_power'">
                <a-form-item label="算力服务" name="compute_power_service">
                  <a-radio-group v-model:value="createAppForm.compute_power_service" button-style="solid">
                    <a-radio-button v-if="createAppForm.capacity_type_level.base?.length>0" value="base">初级
                    </a-radio-button>
                    <a-radio-button v-if="createAppForm.capacity_type_level.middle?.length>0" value="middle">中级
                    </a-radio-button>
                    <a-radio-button v-if="createAppForm.capacity_type_level.high?.length>0" value="high">高级
                    </a-radio-button>
                  </a-radio-group>
                </a-form-item>
                <div>
                  服务配置：
                  <ul v-if="createAppForm.compute_power_service">
                    <li v-for="item in createAppForm.capacity_type_level[createAppForm.compute_power_service]"
                        :key="item">
                      <a-flex justify="space-between" align="center">
                        <div>
                          <div>{{ item.template_inner_service_name }}:</div>
                          <a-flex :gap="15">
                            <span v-for="op in item.capacity.capacity_type_op" :key="op">
                              {{ op.hardware_id.hardware_type }}: {{ op.use_number }}
                              <template
                                  v-if="op.hardware_id.hardware_unit">{{ op.hardware_id.hardware_unit }}</template><br/>
                              <template
                                  v-if="op.hardware_id.hardware_name">({{ op.hardware_id.hardware_name }})</template>
                            </span>
                          </a-flex>
                        </div>
                        <div style="color: #a0c8ff;white-space: nowrap;">
                          {{ item.capacity.capacity_type_op_sum_point }} 算力/{{ displayUnit(item.capacity.unit_op) }}
                        </div>
                      </a-flex>
                    </li>
                  </ul>
                </div>
                <div style="border-top: 3px solid #2580fd;"></div>
                <a-flex justify="space-between" align="center" class="my-3">
                  <div style="font-size: 16px;">以上配置当前环境算力点总计：</div>
                  <span style="color: #FF5F5F;font-size: 16px;">{{ sum_point }} 算力/{{ sum_point_unit }}</span>
                </a-flex>
              </template>
            </a-form>
          </a-modal>
        </div>
        <div style="margin-top: 20px">
          <a-typography-title :level="4">容器镜像制作</a-typography-title>
          <a-row style="margin-right: 0;">
            <a-col :span="24">
              <a-row>
                <a-col :span="8" style="display: flex; align-items: center">
                  <a-typography-title :level="5">镜像拉取日志：</a-typography-title>
                </a-col>
                <a-col :span="10">
                </a-col>
                <a-col :span="6" style="display: flex; justify-content: flex-end">
                  <a-button type="primary" @click="pull_from_repo">从仓库地址拉取容器镜像</a-button>
                </a-col>
              </a-row>
              <a-row style="margin-top: 10px; width: 100%">
                <a-col style="width: 100%">
                  <div v-if="data_list.isPulling" style="width: 100%; background: white">
                    <pre style="display:block; width: 100%; height: 240px; font-size: 14px;">
正在拉取容器镜像：{{ data_list.image_tag }}...{{ data_list.pulling_log_dot }}
                    </pre>
                  </div>
                  <div v-else style="width: 100%; background: white">
                    <pre style="display:block; width: 100%; height: 240px; font-size: 14px">
{{ data_list.pulling_message }}
                    </pre>
                  </div>
                </a-col>
              </a-row>
            </a-col>
          </a-row>
          <a-row v-if="data_list.image_pull_status==='pulled'"
                 style="margin-right: 0;">
            <a-col :span="24">
              <a-row>
                <a-col :span="8" style="display: flex; align-items: center">
                  <a-typography-title :level="5">镜像推送日志：</a-typography-title>
                </a-col>
                <a-col :span="10">
                </a-col>
                <a-col :span="6" style="display: flex; justify-content: flex-end">
                  <a-button type="primary" @click="push_to_hi168_repo">向Hi168平台推送容器镜像</a-button>
                </a-col>
              </a-row>
              <a-row style="margin-top: 10px; width: 100%">
                <a-col style="width: 100%">
                  <div v-if="data_list.isPushing" style="width: 100%; background: white">
                    <pre style="width: 100%; height: 240px; font-size: 14px">
正在推送容器镜像：{{ data_list.image_tag }}...{{ data_list.pushing_log_dot }}
                    </pre>
                  </div>
                  <div v-else style="width: 100%; background: white">
                    <pre style="width: 100%; height: 240px; font-size: 14px">
{{ data_list.pushing_message }}
                    </pre>
                  </div>
                </a-col>
              </a-row>
            </a-col>
          </a-row>
        </div>
      </a-col>
    </a-row>
  </div>
</template>

<script setup>
import {logDebug, logError} from "@/utils/logger";
import {computed, onMounted, onUnmounted, reactive, ref, watch} from "vue";
import {getFailedMessage, getResponseData, jsonRPC} from "@/utils/http_utils";
import {useRouter} from 'vue-router';
import {routerBack, routerReplace} from "@/utils/router_utils";
import {sleep} from "@/utils/time_utils";
import {isEmpty} from "@/utils/common_utils";
import {message, Modal} from "ant-design-vue";

const router = useRouter();

const image_id = router.currentRoute.value.params.image_id
logDebug(`image_id[${image_id}]`)

const editorMode = ref('update')

const labelCol = {
  style: {
    width: '150px',
  },
};
const wrapperCol = {
  span: 20,
};
const formRef = ref();

const data_list = reactive({
  id: '',
  name: '',
  shell_type: 'bash',
  image_registry_id: '',
  image_id: '',
  repository: '',
  tag: '',
  pushing_message: '',
  pulling_message: '',
  image_status: '',
  isPulling: false,
  isPushing: false,
  template_ids: [],
  image_pull_status: '',
  image_push_status: '',
  pulling_log_dot: '',
  pushing_log_dot: '',
});

const image_pull_status_map = {
  "notpull": "未拉取",
  "pulled": "已拉取",
  "pullfailed": "拉取失败",
  "false": "无",
  "undefined": "无",
  "none": "无",
}

const image_push_status_map = {
  "notpush": "未推送",
  "pushed": "已推送",
  "pushfailed": "推送失败",
  "false": "无",
  "undefined": "无",
  "none": "无",
}

const isImageReady = computed(() => {
  return ['push_succeed', 'image_created'].includes(data_list.image_status)
  // return ['image_created'].includes(data_list.image_status)
})

const appColumns = [
  {
    title: '用户应用模板ID',
    dataIndex: 'user_app_id',
    key: 'user_app_id',
  },
  {
    title: '用户应用名称',
    dataIndex: 'app_name',
    key: 'app_name',
  },
  {
    title: '动作',
    dataIndex: 'action',
    key: 'action',
  },
]

const exitRefresh = ref(false)

const appDataSource = computed(() => {
  if (isEmpty(data_list.template_ids)) {
    return []
  }

  const template_ids = []
  for (const template of data_list.template_ids) {
    template_ids.push({
      app_id: template.app_id,
      app_name: template.name,
      user_app_id: template.user_app_id,
      template_id: template.id,
    })
  }
  return template_ids
})


const confirmFormCreate = () => {
  formRef.value.validate()
      .then(function () {
        logDebug('confirmFormUpdate')
      })
      .catch(error => {
        logError('error', error);
        return
      });
};

const confirmFormUpdate = () => {
  formRef.value.validate()
      .then(function () {
        logDebug('confirmFormUpdate')
        jsonRPC({
          url: `/api/make/image/update`,
          params: {
            'id': image_id,
            'name': data_list.name,
            'description': data_list.description,
            'image_tag': data_list.image_tag,
            'shell_type': data_list.shell_type,
          },
          success(res) {
            logDebug(`更新容器镜像成功`, res.data);
          },
          fail(error) {
            logError(`更新容器镜像失败`, error);
          },
        }).then(function () {
          window.location.reload()
        })
      })
      .catch(error => {
        logError('error', error);
        return
      });
};

const cancelForm = () => {
  routerBack(router)
};

const onCreateApp = () => {
  router.push(`/console/image/container/${image_id}/app/create`);
};

const max_dot_count_per_line = 100
const add_log_dot_interval_ms = 1000
const add_log_dot = function () {
  data_list.pulling_log_dot += '.'
  data_list.pushing_log_dot += '.'

  if (data_list.pulling_log_dot.replaceAll('\n', '').length % max_dot_count_per_line === 0) {
    data_list.pulling_log_dot += '\n'
  }
  if (data_list.pushing_log_dot.replaceAll('\n', '').length % max_dot_count_per_line === 0) {
    data_list.pushing_log_dot += '\n'
  }
}

const refreshStatus = async function (targetImageStatusList = [
  'pull_succeed', 'pull_failed', 'push_succeed', 'push_failed', 'image_created']) {
  data_list.pulling_log_dot = ''
  data_list.pushing_log_dot = ''
  for (let i = 0; i < 10000; i++) {
    if (exitRefresh.value) {
      logDebug("exit refreshStatus")
      return
    }
    await sleep(add_log_dot_interval_ms)
    await jsonRPC({
      url: `/api/make/image/${image_id}/status`,
      params: {},
      success(res) {
        const data = getResponseData(res)
        logDebug(`获取容器镜像状态成功`, data);
        Object.assign(data_list, data);
        return true
      },
      fail(error) {
        logError(`获取容器镜像状态失败`, error);
        return false
      },
    })
    add_log_dot()
    if (targetImageStatusList.includes(data_list.image_status)) {
      for (let j = 0; j < 1000; j++) {
        await sleep(add_log_dot_interval_ms)
        add_log_dot()
        await fetchData()
        logDebug(`data_list.image_status[${data_list.image_status}], data_list.pulling_message[${data_list.pulling_message}]`)
        if (['pull_succeed', 'pull_failed'].includes(data_list.image_status) && data_list.pulling_message !== '') {
          break
        }
        if (['push_succeed', 'push_failed'].includes(data_list.image_status) && data_list.pushing_message !== '') {
          break
        }
      }
      data_list.isPulling = false
      data_list.isPushing = false
      return
    }
  }
}

const pull_image = async function () {
  jsonRPC({
    url: `/api/make/image/${image_id}/pull`,
    params: {},
    success(res) {
      const data = getResponseData(res)
      logDebug(`触发拉取容器镜像成功`, data);
      Object.assign(data_list, data);
      data_list.isPulling = true
      refreshStatus(['pull_succeed', 'pull_failed'])
      return true
    },
    fail(error) {
      logError(`触发拉取容器镜像失败`, error);
      return false
    },
  })
}

const pull_from_repo = function () {
  logDebug('pull_from_repo')
  pull_image()
}

// 用于将远程容器镜像的tag修改为hi168平台harbor的tag
const tag_image = async function () {
  await jsonRPC({
    url: `/api/make/image/${image_id}/tag`,
    params: {},
    success(res) {
      const data = getResponseData(res)
      logDebug(`给容器镜像打标签成功`, data);
      Object.assign(data_list, data);
      return true
    },
    fail(error) {
      logError(`给容器镜像打标签失败`, error);
      return false
    },
  })
}

const push_image = async function () {
  jsonRPC({
    url: `/api/make/image/${image_id}/push`,
    params: {},
    success(res) {
      const data = getResponseData(res)
      logDebug(`触发推送容器镜像成功`, data);
      Object.assign(data_list, data);
      data_list.isPushing = true
      refreshStatus(['push_succeed', 'push_failed', 'image_created'])
      return true
    },
    fail(error) {
      logError(`触发推送容器镜像失败`, error);
      return false
    },
  })
}

const push_to_hi168_repo = async function () {
  logDebug('push_to_hi168_repo')
  await tag_image()
  await push_image()
}

const onDetailImageApp = function (user_app_id) {
  logDebug(`onDetailImageApp app_id[${user_app_id}]`)
  router.push(`/console/image/container/${image_id}/app/${user_app_id}`)
}

const onDeleteImageApp = function (user_app_id) {
  if (confirm("确定要删除当前应用模板吗？")) {
    jsonRPC({
      url: "/vue/console/app/template/delete",
      params: {
        app_id: user_app_id,
      },
      success(res) {
        logDebug(`删除应用模板成功`, res.data.result);
        message.info(`删除应用模板成功`)
        fetchData();
      },
      fail(error) {
        logError(`删除应用模板失败, `, error);
        message.info(`删除应用模板失败`)
      },
    });
  }
}
const formCreateAppRef = ref();
const rules = {
  app_name: [
    {
      required: true,
      message: '请输入应用名称',
    },
  ],
  ns_type: [
    {
      required: true,
      message: '请选择空间类型',
      trigger: 'change',
    },
  ],
  namespace_id: [
    {
      required: true,
      message: '请选择命名空间',
      trigger: 'change',
    },
  ],
  deployment_method: [
    {
      required: true,
      message: '部署方式',
      trigger: 'change',
    },
  ],
  compute_power_service: [
    {
      required: true,
      message: '算力服务',
    },
  ],
}
const openCreateAppModal = ref(false);
const confirmCreateAppLoading = ref(false);
const createAppForm = reactive({
  app_name: "",
  ns_type: "",
  namespace_id: "",
  app_id: "",
  template_id: "",
  disable_namespace_selection: false,
  capacity_type_level: {},
  deployment_method: 'consumer_compute_power',
  compute_power_service: 'base',
});

watch(() => createAppForm.ns_type, function (newValue, oldValue) {
  logDebug(`createAppForm.ns_type发生了变化: ${oldValue} -> ${newValue}`);
  if (oldValue !== "") {
    createAppForm.namespace_id = ""
  }
})

function fetchCreateDeploymentData() {
  jsonRPC({
    url: "/vue/console/experiment/deployment/create/setting",
    params: {},
    success(res) {
      logDebug(`查询成功`, res.data.result.data);
      Object.assign(createAppForm, res.data.result.data);
    },
    fail(error) {
      logError(`查询失败, `, error);
    },
  });
}

watch(() => createAppForm.ns_type, function () {
  logDebug(`createAppForm.ns_type[${createAppForm.ns_type}]`)
  if (createAppForm.ns_type === 'private') {
    if (createAppForm['private_namespaces'] != null && typeof createAppForm['private_namespaces'] !== "undefined" &&
        createAppForm['private_namespaces'].length >= 1) {
      createAppForm.namespace_id = createAppForm['private_namespaces'][0].id
    } else {
      createAppForm.namespace_id = false
    }
  } else {
    if (createAppForm['public_namespaces'] != null && typeof createAppForm['public_namespaces'] !== "undefined" &&
        createAppForm['public_namespaces'].length >= 1) {
      createAppForm.namespace_id = createAppForm['public_namespaces'][0].id
    } else {
      createAppForm.namespace_id = false
    }
  }
})

const _onCreateDeployment = (template_id) => {
  createAppForm.template_id = template_id
  createAppForm.disable_namespace_selection = false
  if (createAppForm['ns_types'] != null && typeof createAppForm['ns_types'] !== "undefined" &&
      createAppForm['ns_types'].length >= 1) {
    createAppForm.ns_type = createAppForm['ns_types'][0][0]
  }
  if (createAppForm.ns_type === 'private') {
    if (createAppForm['private_namespaces'] != null && typeof createAppForm['private_namespaces'] !== "undefined" &&
        createAppForm['private_namespaces'].length >= 1) {
      createAppForm.namespace_id = createAppForm['private_namespaces'][0].id
    }
  } else {
    if (createAppForm['public_namespaces'] != null && typeof createAppForm['public_namespaces'] !== "undefined" &&
        createAppForm['public_namespaces'].length >= 1) {
      createAppForm.namespace_id = createAppForm['public_namespaces'][0].id
    }
  }
  openCreateAppModal.value = true;
  jsonRPC({
    url: "/api/get/template/capacity_type_level",
    params: {
      app_id: createAppForm.template_id,
    },
    success(res) {
      const data = getResponseData(res)
      logDebug(`创建实验环境成功`, data);
      if (data.capacity_type_level) {
        createAppForm.capacity_type_level = JSON.parse(data.capacity_type_level)
      }
    },
    fail(error) {
      logError(`创建实验环境失败, `, error);
    },
  })
};
const sum_point = computed(() => {
  let sum = 0;
  const ser = createAppForm.compute_power_service;
  for (const x of createAppForm.capacity_type_level[ser] || []) {
    sum += x.capacity.capacity_type_op_sum_point;
  }
  return sum;
});
const unitMap = {
  hours: '小时',
  day: '天',
  month: '月'
};
const displayUnit = (e) => {
  return unitMap[e] || '小时';
}
const sum_point_unit = computed(() => {
  let sum = '小时';
  const ser = createAppForm.compute_power_service;
  for (const x of createAppForm.capacity_type_level[ser] || []) {
    if (unitMap[x.capacity.unit_op]) {
      sum = unitMap[x.capacity.unit_op];
      break;
    }
  }
  return sum;
});
const confirmCreateApp = () => {
  formCreateAppRef.value.validate()
      .then(() => {
        confirmCreateAppLoading.value = true
        jsonRPC({
          url: "/vue/console/experiment/deployment/create",
          params: {
            name: createAppForm.app_name,
            template_id: createAppForm.template_id,
            namespace_id: createAppForm.namespace_id,
            is_compute_point: sum_point.value > 0,
            capacity_type_level: createAppForm.compute_power_service,
            need_deduct_compute_point: sum_point.value,
            capacity_unit_option: createAppForm.capacity_type_level[createAppForm.compute_power_service]?.[0]?.capacity?.unit_op,
          },
          success(res) {
            const data = getResponseData(res)
            logDebug(`创建实验环境成功`, data);
            message.success(`创建实验环境成功`)
            setTimeout(()=>{
              jsonRPC({
                url: "/vue/template/used_nums",
                params: {
                  template_id: createAppForm.template_id,
                },
                success() {
                  logDebug(`模板使用次数增加成功`);
                },
                fail(error) {
                  logError(`模板使用次数增加失败, `, error);
                },
              });
            },10*1000)
          },
          fail(error) {
            try {
              try {
                let errMsg = JSON.parse(error);
                let user = errMsg.user || '';
                let message = errMsg.message || '资源不足';
                let details = errMsg.details || '';
                Modal.error({
                  title: "创建实验环境失败",
                  content: (
                      <div>
                        <div>{user}</div>
                        <div style={{textIndent: '2em'}}>{message}</div>
                        <div>{details}</div>
                      </div>
                  ),
                  width: '60%', // 设置宽度为 60%
                  closable: true, // 右上角关闭按钮
                  onCancel: () => {
                    Modal.destroyAll(); // 点击关闭按钮时，关闭模态框
                  },
                  footer: () => (
                      <div class="d-flex justify-content-center my-3">
                        <button type="button" class="btn btn-primary" onClick={async () => {
                          router.push('/cost/topup')
                          Modal.destroyAll(); // 关闭所有弹窗
                        }}>补充资源
                        </button>
                      </div>
                  ),
                });
              } catch (err) {
                logError("查询失败: 无法解析错误信息", err);
                Modal.error({
                  title: "创建实验环境失败",
                  content: (
                      <div style={{textIndent: '2em'}}>{JSON.stringify(getFailedMessage(error))}</div>
                  ),
                  width: '60%', // 设置宽度为 60%
                  closable: true, // 右上角关闭按钮
                  onCancel: () => {
                    Modal.destroyAll(); // 点击关闭按钮时，关闭模态框
                  },
                  footer: () => (
                      <div class="d-flex justify-content-center my-3">
                        <button type="button" class="btn btn-primary" onClick={() => {
                          Modal.destroyAll(); // 关闭所有弹窗
                        }}>关闭
                        </button>
                      </div>
                  ),
                });
              }
            } catch (er) {
              logError("部署创建过程中发生未捕获的错误：", er);
              message.error('部署创建失败，请稍后重试。')
            }
          },
        }).then(function () {
          openCreateAppModal.value = false;
          confirmCreateAppLoading.value = false;
          routerReplace(router, '/console/app')
        });
      })
      .catch(error => {
        logDebug('error', error);
      });
};

const cancelCreateApp = () => {
  createAppForm.app_name = ""
  createAppForm.ns_type = ""
  createAppForm.namespace_id = ""
  createAppForm.disable_namespace_selection = false
}

const onDeleteImage = () => {
  Modal.confirm({
    title: '删除容器镜像',
    content: '您正在为当前删除容器镜像，请再次确认！',
    async onOk() {
      try {
        // 使用Promise控制Modal的关闭时机
        return new Promise((resolve, reject) => {
          jsonRPC({
            url: `/api/make/image/delete`,
            params: {
              id: image_id
            },
            success(res) {
              const data = getResponseData(res);
              logDebug('删除容器镜像成功，', data)
              message.info('删除容器镜像成功', 3)
              resolve();  // 请求成功后，关闭 Modal
              setTimeout(function () {
                router.push(`/console/image/container`)
              }, 1000)
            },
            fail(error) {
              logError(`删除容器镜像失败, `, error);
              message.error('删除容器镜像失败，请重试！', 3)
              reject();  // 请求失败时，不关闭 Modal，并且显示错误消息
            },
          });
        });
      } catch (error) {
        logError('Oops errors!', error);
        message.error('操作失败，请稍后再试');
      }
    },
  });
}

onMounted(() => {
  fetchData()
  fetchCreateDeploymentData()
});

onUnmounted(() => {
  exitRefresh.value = true
})

async function fetchData() {
  await jsonRPC({
    url: `/api/make/image/${image_id}/info`,
    params: {},
    success(res) {
      const data = getResponseData(res);
      logDebug(`获取成功`, data);
      Object.assign(data_list, data);
    },
    fail(error) {
      logError(`查询失败, `, error);
    },
  });
}
</script>

<style lang="scss" scoped>
:where(.ant-radio-button-wrapper) {
  height: 30px;
  min-width: 80px;
  line-height: 28px;
  border-radius: 4px;
  margin: 0 5px;
  border-inline-start-width: 1px;
  text-align: center;

  &:not(:first-child)::before {
    content: none
  }

  &:first-child {
    border-start-start-radius: 4px;
    border-end-start-radius: 4px;
  }

  &:last-child {
    border-start-end-radius: 4px;
    border-end-end-radius: 4px;
  }
}
</style>