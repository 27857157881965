<template>
  <div id="live-form">
    <a-row v-if="editorMode === 'create'" style="margin: 10px">
      <a-col :span="20">
        <a-typography-title :level="4">
          创建直播
        </a-typography-title>
      </a-col>
      <a-col :span="4" style="display: flex; justify-content: center">
        <a-button type="primary" @click="cancelForm">
          取消创建
        </a-button>
      </a-col>
    </a-row>
    <a-row v-else-if="editorMode === 'update'" style="margin: 10px">
      <a-col :span="20">
        <a-typography-title :level="4">
          编辑直播
        </a-typography-title>
      </a-col>
      <a-col :span="4" style="display: flex; justify-content: center">
        <a-button type="primary" @click="cancelForm">
          取消编辑
        </a-button>
      </a-col>
    </a-row>
    <a-row v-else-if="editorMode === 'readonly'" style="margin: 10px">
      <a-col :span="18">
        <a-typography-title :level="4">
          直播详情
        </a-typography-title>
      </a-col>
      <a-col :span="6" style="display: flex; justify-content: space-around">
        <div>
          <a-button type="primary" @click="openDeleteModal=true">删除</a-button>
          <a-modal v-model:open="openDeleteModal" title="删除" @ok="confirmFormDelete">
            <p>确定删除？ 删除ID：{{ liveId }}</p>
          </a-modal>
        </div>
        <a-button type="primary" @click="cancelForm">
          返回
        </a-button>
        <a-button type="primary" @click="editorMode = 'update'">
          进入编辑
        </a-button>
      </a-col>
    </a-row>
    <a-row v-else style="margin: 10px">
      <a-col :span="20">
        <a-typography-title :level="4">
          直播详情
        </a-typography-title>
      </a-col>
      <a-col :span="4" style="display: flex; justify-content: center">
        <a-button type="primary" v-if="editorMode === 'readonly'" @click="editorMode = 'update'">
          进入编辑
        </a-button>
      </a-col>
    </a-row>
    <a-form ref="formRef" :model="createLiveForm" :rules="rules"
        :label-col="labelCol"
        :wrapper-col="wrapperCol"
        layout="horizontal"
        :disabled="editorMode === 'readonly'"
    >
      <a-row>
        <a-col :span="12">
          <a-form-item label="直播名称：" name="live_name">
            <a-input v-model:value="createLiveForm.live_name"/>
          </a-form-item>
          <a-form-item label="开始日期：" name="start_time">
            <a-date-picker
                v-model:value="createLiveForm.start_time"
                :showTime="showTimeOption"
                :format="showTimedDateFormat"
            />
          </a-form-item>
          <a-form-item label="结束日期：" name="end_time">
            <a-date-picker
                v-model:value="createLiveForm.end_time"
                :showTime="showTimeOption"
                :format="showTimedDateFormat"
            />
          </a-form-item>
          <a-form-item label="直播讲师：" name="teacher_id">
            <a-select v-model:value="createLiveForm.teacher_id" 
              :options="createLiveForm.training_teacher_options" 
              show-search :filter-option="filterOption">
            </a-select>
          </a-form-item>
          <a-form-item label="助教老师：">
            <a-select
                v-model:value="createLiveForm.training_tutors_ids"
                mode="multiple" :max-tag-count="3"
                :size="size"
                placeholder="Please select"
                style="width: 200px"
                @popupScroll="popupScroll"
                :options="createLiveForm.training_tutors_options" 
                show-search :filter-option="filterOption" :allowClear="true"
            >
            </a-select>
          </a-form-item>
          <a-form-item label="职业发展老师：">
            <a-select
                v-model:value="createLiveForm.training_career_ids"
                mode="multiple" :max-tag-count="3"
                :size="size"
                placeholder="Please select"
                style="width: 200px"
                @popupScroll="popupScroll"
                :options="createLiveForm.training_career_teacher_options" 
                show-search :filter-option="filterOption" :allowClear="true"
            >
            </a-select>
          </a-form-item>
          <a-form-item label="直播分类：">
            <a-select
                v-model:value="createLiveForm.category_ids"
                mode="multiple" :max-tag-count="3"
                :size="size"
                placeholder="Please select"
                style="width: 200px"
                @popupScroll="popupScroll"
            >
              <a-select-option v-for="item in createLiveForm.category_options" :key="item.id" :value="item.id">
                {{ item.name }}
              </a-select-option>
            </a-select>
          </a-form-item>
          <a-form-item label="直播类型：" name="live_type">
            <a-select v-model:value="createLiveForm.live_type" style="width: 200px">
              <a-select-option v-for="item in createLiveForm.category_type_options" :key="item.id" :value="item.id">
                {{ item.name }}
              </a-select-option>
            </a-select>
          </a-form-item>
          <a-form-item label="活动标签：">
            <a-select
                v-model:value="createLiveForm.training_tags"
                mode="multiple" :max-tag-count="3"
                :size="size"
                placeholder="Please select"
                style="width: 200px"
                @popupScroll="popupScroll"
            >
              <a-select-option v-for="item in createLiveForm.training_tags_options" :key="item.id" :value="item.id">
                {{ item.name }}
              </a-select-option>
            </a-select>
          </a-form-item>
        </a-col>
        <a-col :span="12">
          <a-form-item label="直播封面：" name="file_list">
            <a-upload
                v-model:file-list="createLiveForm.file_list"
                :customRequest="uploadDummyRequest"
                list-type="picture-card"
                @preview="handlePreview"
                :max-count="1"
                accept=".png,.jpg,.jpeg"
            >
              <div v-if="createLiveForm.file_list.length < 1">
                <plus-outlined/>
                <div class="ant-upload-text">上传图片</div>
              </div>
            </a-upload>
            <a-modal :open="previewVisible" :title="previewTitle" :footer="null" @cancel="cancelPreview">
              <img alt="example" style="width: 100%" :src="previewImage"/>
            </a-modal>
          </a-form-item>
          <a-form-item label="模板类型：">
            <a-select v-model:value="createLiveForm.app_type">
              <a-select-option value="office">官方模板</a-select-option>
              <!--<a-select-option value="user">我的模板</a-select-option>-->
            </a-select>
          </a-form-item>
          <a-form-item label="官方模板：" v-if="createLiveForm.app_type == 'office'" name='experiment_app_id'>
            <a-select v-model:value="createLiveForm.experiment_app_id">
              <a-select-option v-for="item in createLiveForm.app_options" :key="item.id" :value="item.id">
                {{ item.name }}
              </a-select-option>
            </a-select>
          </a-form-item>
          <a-form-item label="我的模板：" v-if="createLiveForm.app_type == 'user'" name='experiment_user_app_id'>
            <a-select v-model:value="createLiveForm.experiment_user_app_id">
              <a-select-option v-for="item in createLiveForm.user_app_options" :key="item.id" :value="item.id">
                {{ item.name }}
              </a-select-option>
            </a-select>
          </a-form-item>
          <a-form-item label="直播价格：" name="list_price">
            <a-input-number v-model:value="createLiveForm.list_price" :min="0" :max="10000" />
          </a-form-item>
        </a-col>
      </a-row>
      <a-row>
        <a-col :span="24">
          <a-row>
            <a-col :span="1"></a-col>
            <a-col :span="23">
<!--              <p>-->
<!--                注意，“官方模板”和“我的模板”不可同时使用，若该直播下同时存在“官方模板”及“我的模板”，仅为直播用户部署“我的模板”下的内容-->
<!--              </p>-->
            </a-col>
          </a-row>
          <a-form-item label="直播详情：">
            <div id='live-detail-editor' style="display: block; width: 60vw">
              <RichEditor v-model="createLiveForm.description" :height="400" :editMode="editorMode"/>
            </div>
          </a-form-item>
        </a-col>
      </a-row>
      <a-flex justify="center" align="center" class="mt-4">
        <a-button type="primary" v-if="editorMode === 'create'" @click="confirmFormCreate">创建</a-button>
        <a-button type="primary" v-if="editorMode === 'update'" @click="confirmFormUpdate">保存</a-button>
        <a-button v-if="editorMode !== 'readonly'" style="margin-left: 10px" @click="cancelForm">取消</a-button>
      </a-flex>
    </a-form>
  </div>
</template>

<script setup>
import {logDebug, logError} from "@/utils/logger";
import {logComponents} from "@/utils/logger";
import {onMounted, reactive, ref} from 'vue'
import {getRouterParam, getRouterQuery, routerBack, routerReplace} from "@/utils/router_utils";
import {PlusOutlined} from "@ant-design/icons-vue";
import {useRouter} from "vue-router";
import {resolveEditorMode, setEditorMode} from "@/utils/form_utils";
import {newCancelPreview, newHandlePreview, newPreviewState, newUploadDummyRequest,getBase64} from "@/utils/file_utils";
import {newShowTimedDateFormat, newShowTimeOption} from "@/utils/time_utils";
import {jsonRPC} from "@/utils/http_utils";
import moment from 'moment';
import dayjs from 'dayjs';
import RichEditor from "@/components/RichEditor"
const router = useRouter()
logDebug('ConsoleLiveForm setup.')
logComponents([RichEditor])

const labelCol = {
  style: {
    width: '150px',
  },
};
const wrapperCol = {
  span: 14,
};
const filterOption = (input, option) => {
  return option.label.toLowerCase().indexOf(input.toLowerCase()) >= 0;
};
const formRef = ref();
const rules = {
  live_name: [
    {
      required: true,
      message: '请输入直播名称',
    },
  ],
  start_time: [
    {
      required: true,
      message: '请输入开始日期',
    },
  ],
  end_time: [
    {
      required: true,
      validator: (rule, value) => {
        if (!value) {
          return Promise.reject('请输入结束时间');
        } else if (createLiveForm.start_time && value <= createLiveForm.start_time) {
          return Promise.reject('结束时间必须大于开始时间');
        } else {
          return Promise.resolve();
        }
      },
      trigger: 'change',
    },
  ],
  teacher_id: [
    {
      required: true,
      message: '请选择直播讲师',
      trigger: 'change',
    },
  ],
  file_list: [
    {
      required: true,
      message: '请选择直播封面',
      trigger: 'change',
    },
  ],
  experiment_app_id: [
    {
      required: true,
      message: '请选择官方模板',
      trigger: 'change',
    },
  ],
  experiment_user_app_id: [
    {
      required: true,
      message: '请选择我的模板',
      trigger: 'change',
    },
  ],
  live_type: [
    {
      required: true,
      message: '请选择直播类型',
      trigger: 'change',
    }
  ],
}

const uploadDummyRequest = newUploadDummyRequest()
const createLiveForm = reactive({
  live_name: '',
  start_time: '',
  end_time: '',
  teacher_id: '',
  training_tutors_ids: [],
  training_career_ids: [],
  category_ids: [],
  live_type: '',
  training_tags: [],
  file_list: [],
  app_type: 'office',
  experiment_app_id: '',
  experiment_user_app_id: '',
  list_price: 0,
  description: '',
})
// 创建
const confirmFormCreate = () => {
  formRef.value.validate()
  .then(async () => {
    const filePromises = await Promise.all(createLiveForm.file_list.map(async file => {
      const base64Data = await getBase64(file.originFileObj);
      return {
          ...file,
          raw_data: base64Data,
      };
    }));
    await jsonRPC({
      url: "/vue/console/live/create",
      params: {
        live_name: createLiveForm.live_name,
        start_time: createLiveForm.start_time,
        end_time: createLiveForm.end_time,
        teacher_id: createLiveForm.teacher_id,
        training_tutors_ids: createLiveForm.training_tutors_ids,
        training_career_ids: createLiveForm.training_career_ids,
        category_ids: createLiveForm.category_ids,
        live_type: createLiveForm.live_type,
        training_tags: createLiveForm.training_tags,
        file_list: filePromises,
        app_type: createLiveForm.app_type,
        experiment_app_id: createLiveForm.experiment_app_id,
        experiment_user_app_id: createLiveForm.experiment_user_app_id,
        list_price: createLiveForm.list_price,
        description: createLiveForm.description,
      },
      success(res) {
        logDebug(`创建成功`, res.data.result.data)
        routerBack(router)
      },
      fail(error) {
        logError(`创建失败, `, error)
      },
    });
    return
  })
  .catch(error => {
    logDebug('error', error);
    return
  });
};
// 保存
const confirmFormUpdate = () => {
  formRef.value.validate()
  .then(async () => {
    const filePromises = await Promise.all(createLiveForm.file_list.map(async file => {
      const base64Data = await getBase64(file.originFileObj);
      return {
          ...file,
          raw_data: base64Data,
      };
    }));
    await jsonRPC({
      url: "/vue/console/live/write",
      params: {
        id: liveId.value,
        live_name: createLiveForm.live_name,
        start_time: createLiveForm.start_time,
        end_time: createLiveForm.end_time,
        teacher_id: createLiveForm.teacher_id,
        training_tutors_ids: createLiveForm.training_tutors_ids,
        training_career_ids: createLiveForm.training_career_ids,
        category_ids: createLiveForm.category_ids,
        live_type: createLiveForm.live_type,
        training_tags: createLiveForm.training_tags,
        file_list: filePromises,
        app_type: createLiveForm.app_type,
        experiment_app_id: createLiveForm.experiment_app_id,
        experiment_user_app_id: createLiveForm.experiment_user_app_id,
        list_price: createLiveForm.list_price,
        description: createLiveForm.description,
      },
      success(res) {
        logDebug(`保存成功`, res.data.result.data)
        setEditorMode(editorMode, 'readonly')
        routerBack(router)
      },
      fail(error) {
        logError(`保存失败, `, error)
      },
    });
    return
  })
  .catch(error => {
    logDebug('error', error);
    return
  });
};
// 取消
const cancelForm = () => {
  routerBack(router)
};

const {previewVisible, previewImage, previewTitle} = newPreviewState();
const cancelPreview = newCancelPreview(previewVisible, previewTitle)
const handlePreview = newHandlePreview(previewImage, previewVisible, previewTitle)

const popupScroll = () => {
  logDebug('popupScroll');
};
const size = ref('middle');

logComponents([PlusOutlined])

const showTimeOption = newShowTimeOption()
const showTimedDateFormat = newShowTimedDateFormat()

const editorMode = ref('update')
logDebug(`ConsoleLiveForm getRouterParam(router)[${JSON.stringify(getRouterParam(router))}]`)
const liveId = ref(getRouterParam(router, 'live_id'))
resolveEditorMode(editorMode, liveId.value, getRouterQuery(router, 'mode'))

const openDeleteModal = ref(false)
const confirmFormDelete = function () {
  logDebug("confirmFormDelete")
  openDeleteModal.value = false
  routerReplace(router, '/console/live')
}

onMounted(() => {
  jsonRPC({
    url: "/vue/console/live/setting",
    params: {
      id: editorMode.value != 'create' ? liveId.value : 0,
    },
    success(res) {
      logDebug(`查询成功`, res.data.result.data)
      Object.assign(createLiveForm, res.data.result.data)
      if (editorMode.value != 'create') {
        createLiveForm.start_time = dayjs(moment(new Date(res.data.result.data.start_time1)).toISOString());
        createLiveForm.end_time = dayjs(moment(new Date(res.data.result.data.end_time1)).toISOString());
      }
    },
    fail(error) {
      logError(`查询失败, `, error)
    },
  });
})
</script>

<style scoped>

</style>
