<template>
  <div class="setting p-5">
    <a-form layout="horizontal" :disabled="componentDisabled">
      <a-form-item v-if="!onlyDebugShell && resizeResourceForm.type === 'VM'"
                   label="是否启用调试终端" class="mb-0">
        <a-radio-group v-model:value="resizeResourceForm.is_debug" @change="toggleDebug()">
          <a-radio :value="true">是</a-radio>
          <a-radio :value="false">否</a-radio>
        </a-radio-group>
      </a-form-item>
      <a-form-item name="cpu_cores" label="CPU">
        <a-row>
          <a-col :span="12">
            <Slider v-model="resizeResourceForm.cpu_cores" :marks="cpu_marks"
                    :disabled="usage_label=='live'||componentDisabled"/>
          </a-col>
          <a-col :span="12">
            <a-flex>
              <span style="color: #000;flex-shrink: 0;">{{ resizeResourceForm.cpu_cores }} 核</span>
              <span
                  v-if="resizeResourceForm.Price_unit.name">{{
                  calculateUnit('CPU', resizeResourceForm.cpu_cores)
                }}算力/{{ calculatePrice() }}</span>
            </a-flex>
          </a-col>
        </a-row>
        <span v-if="resizeResourceForm.Price_unit.name">{{ findModelName('CPU') }}</span>
      </a-form-item>
      <a-form-item name="memory" label="内存">
        <a-row>
          <a-col :span="12">
            <Slider v-model="resizeResourceForm.memory" :marks="memory_marks"
                    :disabled="usage_label=='live'||componentDisabled"/>
          </a-col>
          <a-col :span="12">
            <a-flex>
              <span style="color: #000;flex-shrink: 0;">{{ resizeResourceForm.memory }} G</span>
              <span
                  v-if="resizeResourceForm.Price_unit.name">{{
                  calculateUnit('RAM', resizeResourceForm.memory)
                }}算力/{{ calculatePrice() }}</span>
            </a-flex>
          </a-col>
        </a-row>
        <span v-if="resizeResourceForm.Price_unit.name">{{ findModelName('RAM') }}</span>
      </a-form-item>
      <a-form-item label="磁盘" v-if="resizeResourceForm.type === 'Container'">
        <a-input-number
            v-model:value="resizeResourceForm.disk" :disabled="usage_label=='live'||componentDisabled"
            addon-after="GB" :precision="0" min="10" :max="max_disk"
        />
        <span
            v-if="resizeResourceForm.Price_unit.name">{{
            calculateUnit('Disk', resizeResourceForm.disk)
          }}算力/{{ calculatePrice() }}</span>
      </a-form-item>
      <a-form-item label="磁盘" v-if="resizeResourceForm.type === 'VM'">
        <a-input-number
            v-model:value="resizeResourceForm.disk" :disabled="true"
            addon-after="GB" :precision="0" min="10" :max="max_disk"
        />
        <span
            v-if="resizeResourceForm.Price_unit.name">{{
            calculateUnit('Disk', resizeResourceForm.disk)
          }}算力/{{ calculatePrice() }}</span>
      </a-form-item>
      <a-form-item label="带宽">
        <a-input-number
            v-model:value="resizeResourceForm.ingress_bandwidth" :disabled="usage_label=='live'||componentDisabled"
            addon-after="Mbps" :precision="0" min="1" max="20"
        />
      </a-form-item>
      <template v-if="usage_label!='live'">
        <div style="border-top: 3px solid #2580fd;"></div>
        <a-flex justify="space-between" align="center" class="my-3">
          <div style="font-size: 16px;">以上配置当前环境算力点总计：</div>
          <span style="color: #2580FD;font-size: 16px;">{{ total_price || 0 }} 算力/{{ calculatePrice() }}</span>
        </a-flex>
      </template>
    </a-form>
    <template v-if="usage_label!='live'">
      <div v-if="resizeResourceForm.type==='VM'" v-show="false">
        <div>附加网卡：
          <a-button type="link" @click="()=>showAddNicModal()">新增网卡</a-button>
        </div>
        <a-table :columns="nicColumns" :data-source="nicData">
          <template #bodyCell="{ column, record }">
            <template v-if="column.key === 'action'">
            <span>
              <a-popconfirm title="确定删除该网卡？"
                            @confirm="()=>confirmRemoveNic(record.name)"
                            @cancel="()=>{}">
                <a-button type="text">删除</a-button>
              </a-popconfirm>
            </span>
            </template>
          </template>
        </a-table>
        <a-modal v-model:open="openAdjustNicModal" title="新增网卡"
                 :mask="true"
                 :maskClosable="false"
                 :confirm-loading="confirmLoadingAdjustNic"
                 @ok="()=>handleOkAdjustNic()">
          确定添加网卡？
        </a-modal>
      </div>
      <div v-if="resizeResourceForm.type==='VM'">
        <div>附加磁盘：
          <a-button type="link" @click="()=>showAddDiskModal()" :disabled="usage_label=='live'||componentDisabled">
            新增磁盘
          </a-button>
        </div>
        <a-table :columns="diskColumns" :data-source="diskData">
          <template #bodyCell="{ column, record }">
            <template v-if="column.key === 'action'">
            <span>
              <a-popconfirm title="确定删除该磁盘？"
                            @confirm="()=>confirmRemoveDisk(record.name)"
                            @cancel="()=>{}">
                <a-button type="text">删除</a-button>
              </a-popconfirm>
            </span>
            </template>
          </template>
        </a-table>
        <a-modal v-model:open="openAdjustDiskModal" title="新增磁盘"
                 :mask="true"
                 :maskClosable="false"
                 :confirm-loading="confirmLoadingAdjustDisk"
                 @ok="()=>handleOkAdjustDisk()">
          <a-form
              :label-col="labelCol"
              :wrapper-col="wrapperCol"
              layout="horizontal">
            <a-form-item label="磁盘">
              <a-input-number v-model:value="adjustDiskForm.storage"
                              addon-after='GB'
                              :precision="0"
                              max="100" min="1"/>
            </a-form-item>
          </a-form>
        </a-modal>
      </div>
      <div class="operation">
        <a-button type="primary" :loading="loading" :disabled="resizeResourceBase.total_price==total_price && total_price!=0"
                  @click="handleOkResizeResource">应用
        </a-button>
        <a-button class="buy" type="primary" @click="handleBuy">获取更多算力</a-button>
      </div>
    </template>
    <a-modal v-model:visible="modalVisible" title="调整配置需要重启此服务，请确认:" :confirm-loading="loading" @ok="handleOk">
      <div style="line-height: 1.6; font-family: Arial, sans-serif; color: #333">
        <p><strong>当前配置：</strong></p>
        <p>
          <strong>CPU：</strong>{{resizeResourceBase.cpu_cores}} 核 &nbsp;
          <strong>内存：</strong>{{resizeResourceBase.memory}}G <br/>
          <strong>硬盘：</strong>{{resizeResourceBase.disk}}G &nbsp;
          <strong>带宽：</strong>{{resizeResourceBase.ingress_bandwidth}}M
        </p>
        <p><strong>调整为：</strong></p>
        <p>
          <strong>CPU：</strong>{{resizeResourceForm.cpu_cores}} 核 &nbsp;
          <strong>内存：</strong>{{resizeResourceForm.memory}}G <br/>
          <strong>硬盘：</strong>{{resizeResourceForm.disk}}G &nbsp;
          <strong>带宽：</strong>{{resizeResourceForm.ingress_bandwidth}}M
        </p>
        <p><strong>当前算力成本：</strong>{{resizeResourceBase.total_price}} 算力/{{calculatePrice()}}</p>
        <p><strong>调整后算力成本：</strong>{{total_price}} 算力/{{calculatePrice()}}</p>
      </div>
    </a-modal>
  </div>
</template>

<script setup>
import {logDebug, logError} from "@/utils/logger";
import {computed, onMounted, reactive, ref, watch} from "vue";
import {getResponseData, jsonRPC} from "@/utils/http_utils";
import {message} from "ant-design-vue";
import Slider from "@/components/Slider.vue";
import useDebugStore from '@/stores/debugStore';

const props = defineProps({
  primal: {type: String},
  data: {type: Object},
  styleSetting: {type: Object},
});

const primal = props.primal;
const data = props.data;
const envId = data["env_id"];
const onlyDebugShell = data["onlyDebugShell"]

const max_cpu = ref(64);
const max_memory = ref(128);
const max_disk = ref(500)
const getMarks = (max, marks) => {
  return Object.fromEntries(Object.entries(marks).filter(([key]) => key <= max));
};
const cpu_marks = computed(() => getMarks(max_cpu.value, {
  1: '1核',
  2: '2核',
  4: '4核',
  8: '8核',
  16: '16核',
  32: '32核',
  64: '64核'
}));
const memory_marks = computed(() => getMarks(max_memory.value, {
  2: '2G',
  4: '4G',
  8: '8G',
  16: '16G',
  32: '32G',
  64: '64G',
  128: '128G'
}));
const usage_label = ref('')
onMounted(() => {
  jsonRPC({
    url: "/api/experiment/terminal/setting/usage_label",
    params: {
      env_id: envId,
    },
    success(res) {
      const data = getResponseData(res)
      logDebug(data)
      usage_label.value = data.usage_label
    },
    fail(error) {
      logError(`获取终端配置用法失败`, error)
    }
  })
});
const loading = ref(false);
const componentDisabled = ref(false);
const resizeResourceForm = reactive({
  is_debug: true,
  env_id: 0,
  type: "",
  cpu_cores: 1,
  memory: 1,
  disk: 10,
  ingress_bandwidth: 1,
  egress_bandwidth: 1,
  Price_unit: {},
});
const resizeResourceBase = reactive({
  cpu_cores: 0,
  memory: 0,
  disk: 0,
  ingress_bandwidth: 0,
  total_price: 0,
});
const unitMap = {
  hours: '小时',
  day: '天',
  month: '月'
};
const total_price = ref(0)
const calculateUnit = (e, unit) => {
  let sum = 1
  for (const x of resizeResourceForm.Price_unit.capacity_type_op || []) {
    if ((x?.hardware_id?.hardware_type || '').toUpperCase() === (e || '').toUpperCase()) {
      sum = e == 'RAM' ? x.use_point * unit * 1024 : x.use_point * unit;
      break
    }
  }
  // 保留8位小数并去除末尾零
  return parseFloat(sum.toFixed(8));
}
watch(resizeResourceForm, () => {
  try {
    let types = ['Disk', 'CPU', 'RAM', 'IngressBandwidth', 'EgressBandwidth', 'Other'];
    let sum = 0;
    for (const x of resizeResourceForm.Price_unit.capacity_type_op || []) {
      if (types.includes(x.hardware_id.hardware_type)) {
        sum += x.use_point * total_unit(x.hardware_id.hardware_type).toFixed(8)
      } else {
        sum += x.use_point * x.use_number.toFixed(8)
      }
    }
    total_price.value = parseFloat(sum.toFixed(8));
  } catch (error) {
    logError('计算价格时出错:', error);
  }
})
const findModelName = (e) => {
  let sum = ''
  for (const x of resizeResourceForm.Price_unit.capacity_type_op || []) {
    if ((x?.hardware_id?.hardware_type || '').toUpperCase() === (e || '').toUpperCase()) {
      sum = x.name;
      break
    }
  }
  return sum; // 查找型号
}
const calculatePrice = () => {
  let sum = '时'
  if (unitMap[resizeResourceForm.Price_unit.unit_op]) {
    sum = unitMap[resizeResourceForm.Price_unit.unit_op]
  }
  return sum;
}
const total_unit = (e) => {
  let sum = 1
  if (e == 'CPU') {
    sum = resizeResourceForm.cpu_cores
  } else if (e == 'RAM') {
    sum = resizeResourceForm.memory * 1024
  } else if (e == 'Disk') {
    sum = resizeResourceForm.disk
  }
  return sum;
}

const debugStore = useDebugStore();
// 使用计算属性来获取 debugStore 中 envId 对应的调试状态
const isDebug = computed(() => debugStore.getDebugSettings(envId));
// 切换调试状态
const toggleDebug = () => {
  const currentDebugStatus = isDebug.value;
  debugStore.setDebugSettings(envId, !currentDebugStatus);
  setEnvShowDebug()
};

const setEnvShowDebug = function () {
  jsonRPC({
    url: `/api/experiment/app/envId/debug/status`,
    params: {
      env_id: envId,
      is_debug: resizeResourceForm.is_debug,
    },
    async success(res) {
      const data = getResponseData(res)
      logDebug(`是否显示Debug图标设置成功`, data);
    },
    fail(error) {
      logError(`是否显示Debug图标设置失败, `, error);
    },
  }).then(() => {
    loading.value = false;
    componentDisabled.value = false;
  });
}
const modalVisible = ref(false);
const handleOkResizeResource = function () {
  modalVisible.value = true;
}
const handleOk = async () => {
  try {
    loading.value = true;
    componentDisabled.value = true;
    jsonRPC({
      url: `/api/experiment/app/resource/resize`,
      params: {
        env_id: envId,
        cpu_cores: resizeResourceForm.cpu_cores,
        memory: resizeResourceForm.memory,
        disk: resizeResourceForm.disk,
        ingress_bandwidth: resizeResourceForm.ingress_bandwidth,
        // egress_bandwidth与ingress_bandwidth保持一致，简化配置项
        egress_bandwidth: resizeResourceForm.ingress_bandwidth,
        total_price: total_price.value,  // 新的总价
        is_compute_point: total_price.value > 0,
        is_debug: resizeResourceForm.is_debug,
      },
      async success(res) {
        const data = getResponseData(res)
        logDebug(`调整资源成功`, data);
        message.success('调整资源成功')
        await window.parent.postMessage(JSON.stringify({
          event: 'restartApplication',
          data: {
            desktop_app_id: Number(envId) + 10000,
          }
        }), "*");
      },
      fail(error) {
        logError(`调整资源失败, `, error);
        message.error(`调整资源失败！`, 3);
      },
    }).then(() => {
      loading.value = false;
      componentDisabled.value = false;
    });
  } catch (error) {
    loading.value = false;
    componentDisabled.value = false;
    logError(error);
    message.error('调整资源失败！');
  }
};
// 查询资源
const loadResource = function (env_id) {
  return jsonRPC({
    url: `/api/experiment/app/resource`,
    params: {
      env_id: env_id,
    },
    success(res) {
      const data = getResponseData(res)
      logDebug(`查询实验环境资源成功`, data);
      Object.assign(resizeResourceForm, data)
      resizeResourceForm.capacity_type_level = JSON.parse(data.capacity_type_level)
      try {
        resizeResourceForm.Price_unit = resizeResourceForm.capacity_type_level?.[resizeResourceForm.app_solution_id?.capacity_type_level]?.[0]?.capacity || {};
      } catch (error) {
        logError(error);
        resizeResourceForm.Price_unit = {};
      }
      resizeResourceForm.env_id = env_id;
      const diskDataTemp = []
      for (const disk of data.extra_disk_ids) {
        diskDataTemp.push({
          disk_id: disk.disk_id,
          name: disk.name,
          pvc_name: disk.pvc_name,
          pvc_namespace: disk.pvc_namespace,
          storage: disk.storage,
          mount_path: disk.mount_path,
          deployment_id: disk.deployment_id,
        })
      }
      diskData.value = diskDataTemp

      const nicDataTemp = []
      for (const nic of data.extra_nic_ids) {
        nicDataTemp.push({
          nic_id: nic.nic_id,
          name: nic.name,
          nic_type: nic.nic_type,
          nic_mode: nic.nic_mode,
          deployment_id: nic.deployment_id,
        })
      }
      nicData.value = nicDataTemp
      debugStore.setDebugSettings(env_id, data.is_debug);
      return true
    },
    fail(error) {
      logError(`查询实验环境资源失败！`, error)
      message.error(`查询实验环境资源失败！`, 3);
      return false
    },
  });
}
// 添加网卡
const openAdjustNicModal = ref(false)
const confirmLoadingAdjustNic = ref(false)
const nicColumns = [
  {
    title: '网卡名称',
    dataIndex: 'name',
    key: 'name',
  },
  {
    title: '网卡类型',
    dataIndex: 'nic_type',
    key: 'nic_type',
  },
  {
    title: '网卡模式',
    dataIndex: 'nic_mode',
    key: 'nic_mode',
  },
  {
    title: '操作',
    key: 'action',
  },
];
const nicData = ref([]);
const showAddNicModal = function () {
  logDebug(`showAddNicModal`)
  openAdjustNicModal.value = true
}
const confirmRemoveNic = function (nic_name) {
  logDebug(`confirmRemoveNic`)
  const result = jsonRPC({
    url: `/api/experiment/app/nic/remove`,
    params: {
      env_id: resizeResourceForm.env_id,
      nic_name: nic_name,
    },
    success(res) {
      const data = getResponseData(res)
      logDebug(`删除网卡成功`, data);
      message.info(`删除网卡成功！`, 3);
    },
    fail(error) {
      logError(`添加网卡失败, `, error);
      message.error(`添加网卡失败！`, 3);
    },
  });

  return new Promise(resolve => {
    result.then(function () {
      loadResource(resizeResourceForm.env_id).then(function () {
        resolve(true)
      })
    })
  });
}
const handleOkAdjustNic = function () {
  logDebug(`handleOkAdjustNic`)
  confirmLoadingAdjustNic.value = true;
  const result = jsonRPC({
    url: `/api/experiment/app/nic/add`,
    params: {
      env_id: resizeResourceForm.env_id,
    },
    success(res) {
      const data = getResponseData(res)
      logDebug(`添加网卡成功`, data);
      message.info(`添加网卡成功！`, 3);
    },
    fail(error) {
      logError(`添加网卡失败, `, error);
      message.error(`添加网卡失败！`, 3);
    },
  });
  result.then(function () {
    openAdjustNicModal.value = false
    confirmLoadingAdjustNic.value = false;
  }).then(function () {
    loadResource(resizeResourceForm.env_id)
  })
}
// 添加磁盘
const openAdjustDiskModal = ref(false)
const confirmLoadingAdjustDisk = ref(false)
const adjustDiskForm = reactive({
  "storage": 20,
})
const diskColumns = [
  {
    title: '磁盘名称',
    dataIndex: 'name',
    key: 'name',
  },
  {
    title: '磁盘大小（GB）',
    dataIndex: 'storage',
    key: 'storage',
  },
  {
    title: '操作',
    key: 'action',
  },
];
const diskData = ref([]);
const showAddDiskModal = function () {
  logDebug(`showAddDiskModal`)
  openAdjustDiskModal.value = true
}
const confirmRemoveDisk = function (disk_name) {
  logDebug(`confirmRemoveDisk`)
  const result = jsonRPC({
    url: `/api/experiment/app/disk/remove`,
    params: {
      env_id: resizeResourceForm.env_id,
      disk_name: disk_name,
    },
    success(res) {
      const data = getResponseData(res)
      logDebug(`删除磁盘成功`, data);
      message.info(`删除磁盘成功！`, 3);
    },
    fail(error) {
      logError(`删除磁盘失败, `, error);
      message.error(`删除磁盘失败！`, 3);
    },
  });
  return new Promise(resolve => {
    result.then(function () {
      loadResource(resizeResourceForm.env_id).then(function () {
        resolve(true)
      })
    })
  });
}
const handleOkAdjustDisk = function () {
  logDebug(`handleOkAdjustDisk`)
  confirmLoadingAdjustDisk.value = true;
  const result = jsonRPC({
    url: `/api/experiment/app/disk/add`,
    params: {
      env_id: resizeResourceForm.env_id,
      storage: adjustDiskForm.storage,
    },
    success(res) {
      const data = getResponseData(res)
      logDebug(`添加磁盘成功`, data);
      message.info(`添加磁盘成功！`, 3);
    },
    fail(error) {
      logError(`添加磁盘失败, `, error);
      message.error(`添加磁盘失败！`, 3);
    },
  });
  result.then(function () {
    openAdjustDiskModal.value = false
    confirmLoadingAdjustDisk.value = false;
  }).then(function () {
    loadResource(resizeResourceForm.env_id)
  })
}
const handleBuy = async () => {
  await window.parent.postMessage(JSON.stringify({
    event: 'closeApplication',
    data: {
      desktop_app_id: '8014',
    }
  }), "*");
  await sessionStorage.setItem('openTopUp', true);
  await window.parent.postMessage(JSON.stringify({
    event: 'openApplication',
    data: {
      desktop_app_id: '8014',
    }
  }), "*");
}

const onSelected = async function () {
  logDebug(`TerminalSetting onSelected. data[${JSON.stringify(data)}]`);

  await loadResource(envId);
  resizeResourceBase.cpu_cores = resizeResourceForm.cpu_cores;
  resizeResourceBase.memory = resizeResourceForm.memory;
  resizeResourceBase.disk = resizeResourceForm.disk;
  resizeResourceBase.ingress_bandwidth = resizeResourceForm.ingress_bandwidth;
  resizeResourceBase.total_price = total_price.value;
};

defineExpose({
  primal,
  data,
  onSelected,
});
</script>

<style scoped lang="scss">
.setting {
  height: 100vh;
  overflow: auto;

  form {
    span {
      display: inline-block;
      color: #3894FF;
      margin-left: 15px;
      margin-top: 5px;
    }
  }

  .operation {
    display: flex;
    justify-content: center;

    button {
      width: 116px;
      height: 40px;
      background-color: #0B68FF;
      color: #fff;
      border-radius: 8px;

      &:disabled {
        opacity: 0.6;
        border-color: initial;
      }

      &.buy {
        background-color: #D3E8FF;
        color: #0B68FF;
        margin-left: 20px;
      }
    }
  }
}

ul {
  list-style: none;
  margin: 0;
}
</style>