<template>
  <div class="top-up container" ref="selectContainer">
    <a-breadcrumb separator=">">
      <a-breadcrumb-item>
        <router-link to="/cost">费用中心</router-link>
      </a-breadcrumb-item>
      <a-breadcrumb-item>算力点充值</a-breadcrumb-item>
    </a-breadcrumb>
    <div class="top-up-card">
      <h5>
        充值金额
        <span>1 算力点 = 1 元</span>
      </h5>
      <div class="m-4">
        <a-radio-group v-model:value="selectedAmount" button-style="solid">
          <a-radio-button value="5">5元</a-radio-button>
          <a-radio-button value="10">10元</a-radio-button>
          <a-radio-button value="20">20元</a-radio-button>
          <a-radio-button value="30">30元</a-radio-button>
          <a-radio-button value="50">50元</a-radio-button>
          <a-radio-button value="100">100元</a-radio-button>
          <a-radio-button value="200">200元</a-radio-button>
          <a-radio-button value="500">500元</a-radio-button>
        </a-radio-group>
        <a-form-item label="自定义金额" name="selectedAmount">
          <a-flex align="center" :gap="10">
            <a-input-number v-model:value="selectedAmount" :min="1" :max="1000"/>
            <span style="color: #2481E9;">*请输入整数数字</span>
          </a-flex>
        </a-form-item>
      </div>
      <h5>支付方式</h5>
      <div class="m-4">
        <a-radio-group v-model:value="payment_type" class="payment-methods">
          <a-radio value="alipay">
            <img src="/hw_web/static/src/img/Alipay_payment.png" alt="支付宝" class="payment-icon" /> 支付宝
          </a-radio>
          <a-radio value="wechatpay">
            <img src="/hw_web/static/src/img/WeChat_payment.png" alt="微信支付" class="payment-icon" /> 微信支付
          </a-radio>
        </a-radio-group>
      </div>
      <div class="payment">
        <div v-if="selectedAmount" class="sum">将充值算力点: {{selectedAmount}}</div>
        <a-button type="primary" @click="onRecharge" :loading="loading">立即充值</a-button>
        <div class="remark">*支付二维码仅限Hi168账户充值使用， 谨慎向他人分享，禁止用于诈骗不法行为</div>
      </div>
    </div>

    <!-- 支付流程 -->
    <a-drawer title="支付中" v-model:open="paymentModalVisible" width="100%" :getContainer="()=>$refs.selectContainer" 
      placement="right" :closable="false" :style="{ position: 'absolute' }">
      <template #extra>
        <a-button type="primary" @click="onClose">
          返回
        </a-button>
      </template>
      <iframe v-if="payment_type == 'alipay'" width="100%" style="height: calc(100% - 5px)" :src="url" frameborder="0"/>
      <div v-if="payment_type == 'wechatpay'">
        <h2 class="text-center">微信支付</h2>
        <canvas ref="qrcodeCanvas"></canvas>
      </div>
    </a-drawer>
  </div>
</template>

<script setup>
import { onMounted, ref, onBeforeUnmount } from "vue";
import { jsonRPC, jsonOwlRPC, getResponseData } from "@/utils/http_utils";
import {logDebug, logError} from "@/utils/logger";
import { message } from "ant-design-vue";
import { useRouter } from 'vue-router'
const router = useRouter()
import QRCode from 'qrcode';
const qrcodeCanvas = ref(null);
const url = ref()
const timerIds = ref([])

const selectedAmount = ref(null);
const payment_type = ref("alipay");
const paymentModalVisible = ref(false);
const loading = ref(false);
const product_id = ref(0)

onMounted(()=>{
  jsonRPC({
    url: "/vue/cost/topup/product_list",
    params: {},
    success(res) {
      const data = getResponseData(res);
      product_id.value = data.id;
    },
    fail(error) {
      logError(`查询失败, `, error);
    },
  });
});
// 支付操作
const onRecharge = () => {
  if (!selectedAmount.value) {
    message.error("请选择充值金额");
    return;
  }
  loading.value = false;
  jsonRPC({
    url: "/api/shop_cart/point",
    params: {
      product_id_list: [product_id.value],
      product_uom_qty: selectedAmount.value,
    },
    success(res) {
      const data = getResponseData(res);
      logDebug(`获取订单列表`, data.order_line_ids);
      jsonRPC({
        url: "/vue/desktop/shop/order/delete/all",
        params: {
          order_line_ids: data.order_line_ids,
        },
        success(res1) {
          logDebug(`清空商品列表成功`, getResponseData(res1));
        },
        fail(error) {
          logError(`查询失败, `, error);
        },
      });
      jsonOwlRPC({
        url: "/payment/create_order",
        params: {
          order_lines: data.order_line_ids,
          code_ids: [],
        },
        success(res1) {
          logDebug(`创建订单成功`, getResponseData(res1));
          jsonOwlRPC({
            url: `/payment/order/checkout`,
            params:{
              order_id: res1.order_id,
              sales_order_id: res1.sales_order_id,
            },
            success(res2) {
              logDebug(`核对订单页面成功`, res2);
              _submitOrderBtn(res1.order_id,res1.sales_order_id,res2.product_info_list)
            },
            fail(error) {
              logError(`查询失败, `, error);
            },
          })
        },
        fail(error) {
          logError(`查询失败, `, error);
        },
      });
    },
    fail(error) {
      logError(`查询失败, `, error);
    },
  });
};
// 检测是否在手机端打开
import bowser from 'bowser';
const isMobile = ()=>{
  if (bowser.mobile || bowser.tablet) {
    return true
  } else {
    return false
  }
}
const _submitOrderBtn = async (order_id,sales_order_id,productList)=>{
  try {
    if (selectedAmount.value>0) {
      // 获取数据
      let shopping_cart_id = productList[0].shopping_cart_id;
      let order_lines = productList.map(product => product.order_line_id);
      let isMobilePay = false;
      if (isMobile()) {
          isMobilePay = true
      }
      // 开始支付
      if (payment_type.value == 'alipay') {
        let paymentResult = await PaymentUrl('alipay', order_id, sales_order_id, shopping_cart_id, order_lines, isMobilePay)
        logDebug(JSON.stringify(paymentResult));
        if (paymentResult.status == '200') {
          if (paymentResult.url.length > 0) {
            url.value = paymentResult.url;
            paymentModalVisible.value = true;
            // 计时器
            let intervalID = setInterval(() => {
                _queryPaymentStatus(order_id, sales_order_id);
            }, 5000);
            timerIds.value.push(intervalID)
            let timeoutID = setTimeout(()=>{
                clearInterval(intervalID);
            }, 1000 * 60 * 6);
            timerIds.value.push(timeoutID)
          }
        } else {
          logError(paymentResult.error);
          message.error('支付遇到错误,请稍后重试')
          return
        }
      } else if (payment_type.value == 'wechatpay') {
        let wechatpayResult = await PaymentUrl('wechatpay', order_id, sales_order_id, shopping_cart_id, order_lines, isMobilePay)
        if (wechatpayResult.status == '200') {
          if (wechatpayResult.url.length > 0) {
            paymentModalVisible.value = true;
            let canvas = setInterval(()=>{
              if (qrcodeCanvas.value) {
                QRCode.toCanvas(qrcodeCanvas.value, wechatpayResult.url, { width: window.innerWidth * 0.2 }, (error) => {
                  if (error) logError(error);
                  logDebug('QR code generated!');
                });
                clearInterval(canvas);
              }
            },1000)
            // 计时器
            let intervalID = setInterval(() => {
                _queryPaymentStatus(order_id, sales_order_id);
            }, 5000);
            timerIds.value.push(intervalID)
            let timeoutID = setTimeout(()=>{
                clearInterval(intervalID);
            }, 1000 * 60 * 6);
            timerIds.value.push(timeoutID)
          }
        } else {
          logError(wechatpayResult.error);
          message.error('支付遇到错误,请稍后重试')
          return
        }
      }
    }else{
      message.info("应付总额要大于0")
    }
  } catch (err) {
    // 捕获错误并处理
    logError('支付请求出错:', err);
    message.error('支付失败，请稍后再试或联系客服');
  }
}
// 开始支付宝支付
const PaymentUrl = async (payment_type, order_id, trade_order_id, shopping_cart_id, order_lines, isMobilePay) => {
  return new Promise((resolve, reject) => {
    jsonOwlRPC({
      url: `/payment/create/${payment_type}/${order_id}/${trade_order_id}`,
      params: {
        shopping_cart_id: shopping_cart_id,
        order_lines: order_lines,
        is_mobile: isMobilePay,
        is_qrcode: true,
      },
      success(res) {
        resolve(res); // 成功时返回res
      },
      fail(error) {
        reject(error); // 失败时返回错误
      },
    });
  });
};
onBeforeUnmount(()=>{
  // 清除所有定时器
  timerIds.value.forEach(id => {
    clearInterval(id)
    clearTimeout(id)
  })
})
const onClose = ()=>{
  paymentModalVisible.value = false;
  timerIds.value.forEach(id => {
    clearInterval(id)
    clearTimeout(id)
  })
  message.info("用户关闭了支付")
}
// 支付结果查询
const _queryPaymentStatus = async (order_id, sales_order_id)=>{
  if (payment_type.value == 'alipay') {
    jsonOwlRPC({
      url: `/payment/query/alipay/${order_id}/${sales_order_id}`,
      params: {},
      success(res) {
        logDebug(res);
        ModifyRecord(order_id)
      },
      fail(error) {
        logError(`查询失败, `, error);
      },
    });
  }
  if (payment_type.value == 'wechatpay') {
    jsonOwlRPC({
      url: `/payment/query/wechatpay/${order_id}/${sales_order_id}`,
      params: {},
      success(res) {
        logDebug(res);
        ModifyRecord(order_id)
      },
      fail(error) {
        logError(`查询失败, `, error);
      },
    });
  }
}
// 修改记录
const ModifyRecord = (order_id)=>{
  jsonOwlRPC({
    url: `/search/order/info`,
    params:{
      order_id: order_id,
    },
    async success(res) {
      if (res.ordinary.length > 0) {
        await jsonOwlRPC({
          url: `/console/experiment/deployment/create_v2`,
          params:{
            data: res.ordinary,
          },
          success(res1) {
            logDebug(res1);
            message.success('支付成功！部署已调整')
            router.push('/console')
          },
          fail(error) {
            logError(`查询失败, `, error);
          },
        })
      }
      if (res.adjust.length > 0) {
        await jsonOwlRPC({
          url: `/console/experiment/app/env/adjust_v2`,
          params:{
            data: res.adjust,
          },
          success(res1) {
            logDebug(res1);
            message.success('支付成功！部署已创建')
            router.push('/console')
          },
          fail(error) {
            logError(`查询失败, `, error);
          },
        })
      }
      if (res.renew.length > 0) {
        await jsonOwlRPC({
          url: `/console/experiment/app/env/adjust_time`,
          params:{
            data: res.renew,
          },
          success(res1) {
            logDebug(res1);
            message.success('支付成功！部署已调整')
            router.push('/console')
          },
          fail(error) {
            logError(`查询失败, `, error);
          },
        })
      }
      if (res.payment_success) {
        router.push('/console')
        message.success('购买成功')
      }
    },
    fail(error) {
      logError(`查询失败, `, error);
    },
  })
}
</script>

<style lang="scss" scoped>
.top-up {
  min-height: calc(100vh - 100px);
  .top-up-card{
    height: 100%;
    background-color: #fff;
    padding: 40px;
    margin-bottom: 20px;
    h5{
      background-color: #F2F8FF;
      font-size: 16px;
      font-weight: bold;
      height: 42px;
      padding: 0 20px;
      display: flex;
      align-items: center;
      margin-bottom: 0;
      span{
        font-size: 14px;
        color: #2580FD;
        margin-left: 1rem;
      }
    }
    .payment{
      display: flex;
      flex-direction: column;
      align-items: flex-end;
      .sum{
        color: #2580FD;
        font-size: 14px;
        margin-bottom: 20px;
      }
      .remark{
        color: #FF5F5F;
        margin-top: 10px;
      }
    }
  }
  .payment-methods {
    margin-top: 15px;
    .payment-icon {
      width: 24px;
      height: 24px;
      margin-right: 8px;
    }
  }
}
:where(.ant-radio-button-wrapper){
  height: 30px;
  min-width: 80px;
  line-height: 28px;
  border-radius: 4px;
  margin: 0 5px;
  border-inline-start-width: 1px;
  text-align: center;
  &:not(:first-child)::before{
    content: none
  }
  &:first-child{
    border-start-start-radius: 4px; 
    border-end-start-radius: 4px;
  }
  &:last-child{
    border-start-end-radius: 4px;
    border-end-end-radius: 4px;
  }
}
:where(.ant-form-item){
  margin: 12px 5px 0;
}
:where(.ant-drawer-body){
  >div{
    height: 100%;
    width: 100%;
    overflow: hidden !important;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }
}
</style>