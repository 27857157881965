<template>
  <div class="rich-editor">
    <div class="rich-editor-text">
      <Toolbar class="rich-editor-toolbar"
               v-show="showToolBar"
               :editor="editorRef"
               :defaultConfig="toolbarConfig"
               @click="focusEditor"
               :mode="mode"/>
      <Editor class="rich-editor-editor"
              :style="{ height: height + 'px'}"
              v-model="valueHtml"
              :defaultConfig="editorConfig"
              :mode="mode"
              @click="focusEditor"
              @onChange="handleChange"
              @onCreated="handleCreated"/>
    </div>


    <a-drawer :width="900" title="导出文件预览" placement="right" :open="pdfExporterVisible" @close="closePdfExporter">
      <template #extra>
        <a-button style="margin-right: 8px; width: 100px" @click="closePdfExporter">取消</a-button>
        <a-button v-if="!isExporting" style="width: 100px" type="primary" @click="handlePdfExporterOk">确认</a-button>
        <a-button v-else style="width: 100px" type="primary" loading>
          导出中
        </a-button>
      </template>
      <div id="pdfDom" style="padding: 0 20px; width: 847px;"></div>
    </a-drawer>

    <!--    <div v-if="props.catalogShow" style="width: 200px; background-color: #f1f1f1;"  class="flex-container">-->
    <!--        <div class="table-of-title">-->
    <!--          <span>目录</span>-->
    <!--        </div>-->
    <!--        <button @click="tableOfContentsButton()">生成目录</button>-->
    <!--        &lt;!&ndash; 目录 &ndash;&gt;-->
    <!--        <div v-if="tableOfContents.length > 0" class="table-of-contents">-->
    <!--          &lt;!&ndash; 目录内容 &ndash;&gt;-->
    <!--          <ul>-->
    <!--            <li v-for="(item, index) in tableOfContents" :key="item.id" :style="{ paddingLeft: item.level * 20 + 'px' }">-->
    <!--              <a :class="{ active: activeIndex === index }" :href="`#${item.id}`"-->
    <!--                 @click="handleItemClick(index)">{{ item.text }}</a>-->
    <!--            </li>-->
    <!--          </ul>-->
    <!--        </div>-->
    <!--    </div>-->

  </div>
</template>

<script setup>
import {computed, nextTick, onBeforeUnmount, onMounted, ref, shallowRef, watch} from 'vue';
import {newWangEditorConfig, newWangEditorToolbarConfig} from "@/utils/file_utils";
import {logError} from "@/utils/logger";
import '@wangeditor/editor/dist/css/style.css';
import {Editor, Toolbar} from '@wangeditor/editor-for-vue';
import {isEmpty, isNotNullOrUndefined, isNullOrUndefined} from "@/utils/common_utils";
import {Boot} from '@wangeditor/editor'
import {loadStyle, removeStyle} from "@/utils/load_utils";
import {saveWordV2} from "@/utils/doc_utils";
import $ from 'jquery'


const props = defineProps({
  modelValue: {type: [String]},
  height: {type: [Number, String]},
  editMode: {type: [String]},
  theme: {type: String},  // 假设从父组件接收主题信息
  catalogShow: {
    type: Boolean,
    default: false,
  },
});

const emits = defineEmits(['update:modelValue', 'update:theme']); // 添加 update:theme 事件

class ThemeSelectMenu {

  constructor() {
    this.title = '代码块主题'
    this.tag = 'select'
    this.width = 120
  }

  // 下拉框的选项
  getOptions() {
    this.options = [
      {
        value: 'select-theme-title', text: '代码块主题',
        selected: theme.value === 'select-theme-title',
        styleForRenderMenuList: {'display': 'none'}
      },
      {
        value: 'atom-dark', text: '默认主题',
        selected: theme.value === 'atom-dark',
        styleForRenderMenuList: {'width': '120px'}
      },
      {
        value: 'base16-ateliersulphurpool-light', text: '亮色主题',
        selected: theme.value === 'base16-ateliersulphurpool-light',
      },
      {
        value: 'coldark-cold', text: 'ColdarkCold',
        selected: theme.value === 'coldark-cold',
      },
      {
        value: 'coldark-dark', text: 'ColdarkDark',
        selected: theme.value === 'coldark-dark',
      },
      {
        value: 'default', text: 'WangEditor',
        selected: theme.value === 'default',
      },
      {
        value: 'dracula', text: 'Dracula',
        selected: theme.value === 'dracula',
      },
      {
        value: 'ghcolors', text: 'GHColors',
        selected: theme.value === 'ghcolors',
      },
    ]
    return this.options
  }

  getValue() {
    return 'select-theme-title'
  }

  isActive() {
    return false // or true
  }

  isDisabled() {
    return false // or true
  }

  exec(editor, value) {
    // wangeditor.insertText(value) // value 即 this.getValue(wangeditor) 的返回值
    // wangeditor.insertText(' ')
    if (value === 'select-theme-title') {
      return
    }
    theme.value = value
    editor.insertText('\r')
  }
}

const themeSelectMenuConf = {
  key: 'hwTheme', // 定义 menu key ：要保证唯一、不重复（重要）
  title: '主题',
  factory() {
    return new ThemeSelectMenu() // 把 `YourMenuClass` 替换为你菜单的 class
  },
}

const theme = ref(props.theme);  // 使用 ref 来保存主题信息
// 在编辑器创建后生成目录
// const tableOfContents = ref([]);
// const activeIndex = ref();

const valueHtml = computed({
  get() {
    return props.modelValue || '';
  },
  set(value) {
    emits('update:modelValue', value);
  }
});

watch(() => props.editMode, function (newEditMode) {
  if (isNotNullOrUndefined(editorRef.value)) {
    configEditorMode(editorRef.value, newEditMode);
  }
});

const themeStyleId = 'rich-editor-theme'

watch(theme, function () {
  removeStyle(themeStyleId)
  loadStyle(`./theme/wangeditor/${theme.value}.css`, themeStyleId);
});


// const tableOfContentsButton = function () {
//   tableOfContents.value = generateTableOfContents();
// }
const configEditorMode = function (editor, editMode = 'readonly') {
  if (isNullOrUndefined(editor)) {
    return;
  }

  const normEditMode = `${editMode}`.toLowerCase();
  const readonlyEditMode = ['readonly', 'read'];
  if (readonlyEditMode.includes(normEditMode)) {
    showToolBar.value = false;
    editor.disable();
    // 添加复制代码功能
    $('.rich-editor-editor pre').hover(
      function () {
        // 鼠标移入
        $(this).css('position', 'relative')
        var copyBtn = $('<button class="btn btn-secondary px-1">复制</button>').css({
          position: 'absolute',
          top: '10px',
          right: '4px'
        })
        $(this).append(copyBtn)

        copyBtn.click(function () {
          // 点击复制按钮
          var code = $(this).parent().find('code').text()
          var temp = $('<textarea>')
          $('body').append(temp)
          temp.val(code).select()
          document.execCommand('copy')
          temp.remove()
          $(this).text('复制完成')
          setTimeout(() => {
            copyBtn.text('复制')
          }, 3000)
        })
      },
      function () {
        // 鼠标移出
        $(this).find('button').remove()
        $(this).css('position', 'static')
      }
    )
  } else {
    showToolBar.value = true;
    editor.enable();
    // 移出所有复制功能
    $('.rich-editor-editor pre').off('mouseenter mouseleave')
  }
};


const editorRef = shallowRef(null)
const showToolBar = ref(true)
// mode: 'default' 默认模式 - 集成了 wangEditor 所有功能
// mode: 'simple' 简洁模式 - 仅有部分常见功能，但更加简洁易用
let mode = 'default'
const toolbarConfig = newWangEditorToolbarConfig();
const editorConfig = newWangEditorConfig();

onMounted(() => {
  if (isEmpty(theme.value)) {
    theme.value = 'atom-dark'
  }
  removeStyle(themeStyleId)
  loadStyle(`./theme/wangeditor/${theme.value}.css`, themeStyleId);
});

toolbarConfig.insertKeys = {
  index: 1000, // 插入菜单key
  keys: ['exportWordCode', 'hwTheme']
}

onBeforeUnmount(() => {
  const {value} = editorRef;
  if (isNullOrUndefined(value)) {
    return;
  }
  value.destroy();
});

const exportWordMenuConf = {
  key: 'exportWordCode', // 定义 menu key ：要保证唯一、不重复（重要）
  factory() {
    return new ExportWordButtonMenu()
  },
}


const handleChange = (editor) => {
  if (isNotNullOrUndefined(editor)) {
    configEditorMode(editor, props.editMode)
  }
  // tableOfContents.value = generateTableOfContents();
}

// 添加锚点
// const addAnchorLinks = () => {
//   const headings = document.querySelectorAll(".rich-editor-editor h1, .rich-editor-editor h2, .rich-editor-editor h3");
//   headings.forEach((heading, index) => {
//     const anchorLink = document.createElement("a");
//     anchorLink.setAttribute("href", `#section-${index + 1}`);
//     anchorLink.textContent = heading.textContent; // 设置锚点文本为标题文本
//     anchorLink.style.pointerEvents = "none"; // 设置 pointer-events 为 none，使链接不可点击
//     // 设置标题的id属性
//     heading.setAttribute("id", `section-${index + 1}`);
//     // 将锚点链接插入到标题内
//     heading.innerHTML = anchorLink.outerHTML + heading.innerHTML;
//   });
// };

// 更新目录项点击事件处理函数
// const handleItemClick = (index) => {
//   activeIndex.value = index;
//   // 获取目标目录项的锚点链接 href 属性值
//   const targetItem = document.querySelector(`.table-of-contents a[href="#section-${index + 1}"]`);
//
//   // 滚动目录以确保当前点击的目录项可见
//   if (targetItem) {
//     const container = document.querySelector(".table-of-contents");
//     const containerRect = container.getBoundingClientRect();
//     const scrollTop = targetItem.offsetTop - containerRect.height / 2;
//     container.scrollTop = scrollTop;
//   }
// };


// 生成目录
// const generateTableOfContents = () => {
//   const headings = document.querySelectorAll(".rich-editor-editor h1, .rich-editor-editor h2, .rich-editor-editor h3");
//   const toc = [];
//   headings.forEach((heading, index) => {
//     const id = `section-${index + 1}`;
//     const level = heading.tagName === "H1" ? 1 : heading.tagName === "H2" ? 2 : 3; // 根据标题等级设置目录项的缩进
//     heading.setAttribute("id", id); // 设置标题的id属性
//     toc.push({id: id, text: heading.textContent, level: level, index: index}); // 将标题文本、id和等级添加到目录项中
//   });
//   return toc;
// };

// const handleScroll = () => {
//   requestAnimationFrame(() => {
//     const sections = document.querySelectorAll(".editor h1, .editor h2, .editor h3");
//     const scrollY = window.scrollY || window.pageYOffset;
//     let currentIndex = 0;
//
//     for (let i = 0; i < sections.length; i++) {
//       const sectionTop = sections[i].offsetTop;
//       if (scrollY >= sectionTop) {
//         currentIndex = i;
//       }
//     }
//
//     // 检查当前视图中是否有标题元素，如果有，将其索引赋给 currentIndex
//     const visibleSections = Array.from(sections).filter((section) => {
//       const sectionTop = section.offsetTop;
//       const sectionBottom = sectionTop + section.offsetHeight;
//       return scrollY >= sectionTop && scrollY <= sectionBottom;
//     });
//
//     if (visibleSections.length > 0) {
//       currentIndex = Array.from(sections).indexOf(visibleSections[visibleSections.length - 1]);
//     }
//
//     activeIndex.value = currentIndex;
//
//     // 滚动目录以确保当前高亮的目录项可见
//     const activeItem = document.querySelector(".table-of-contents .active");
//     if (activeItem) {
//       const container = document.querySelector(".table-of-contents");
//       const containerRect = container.getBoundingClientRect();
//       const activeRect = activeItem.getBoundingClientRect();
//       const scrollTop = activeItem.offsetTop - containerRect.height / 2 + activeRect.height / 2;
//       container.scrollTop = scrollTop;
//     }
//   });
// };
const handleCreated = (editor) => {
  if (isNotNullOrUndefined(editor)) {
    configEditorMode(editor, props.editMode);
  }
  editorRef.value = editor; // 记录 editor 实例，重要！
  if (!editor.getAllMenuKeys()?.includes("exportWordCode")) {
    //判断如果已经插入进去，不在二次插入
    Boot.registerMenu(exportWordMenuConf)
  }
  if (!editor.getAllMenuKeys()?.includes("hwTheme")) {
    //判断如果已经插入进去，不在二次插入
    Boot.registerMenu(themeSelectMenuConf)
  }
}


// onMounted(() => {
//   tableOfContents.value = generateTableOfContents();
//   addAnchorLinks();
//   window.addEventListener("scroll", handleScroll);
// });

const focusEditor = () => {
  if (isNotNullOrUndefined(editorRef.value)) {
    editorRef.value.focus();
    document.onselectstart = null;
  }
}

class ExportWordButtonMenu {
  constructor() {
    this.title = '导出'
    this.tag = 'button'
  }

  getValue() {
    return ''
  }

  isActive() {
    return false
  }

  isDisabled() {
    return false
  }

  exec(editor) {
    if (this.isDisabled(editor)) return
    try {
        let data = editor.getEditableContainer().outerHTML;
        if (data) {
            exportToPDF(data);
        } else {
            logError('Failed to get valid HTML data from editor.');
        }
    } catch (error) {
        logError('Error exporting to PDF:', error);
    }
  }

}

const pdfExporterVisible = ref(false);
const openPdfExporter = () => {
  pdfExporterVisible.value = true;
};
const closePdfExporter = () => {
  pdfExporterVisible.value = false;
};

const isExporting = ref(false)
const handlePdfExporterOk = async function () {
  isExporting.value = true
  // await saveWordV2("#pdfDom", '实验手册')
  // await saveWord("#pdfDom", '实验手册')
  await saveWordV2("#pdfDom", '实验手册')
  isExporting.value = false
  closePdfExporter()
};

const exportToPDF = async (htmlContent) => {
  openPdfExporter()
  await nextTick()
  let douTxt = document.querySelector("#pdfDom") // 获取元素
  if(isNullOrUndefined(douTxt)){
    window.location.reload();
    return
  }
  douTxt.innerHTML = htmlContent // 元素插入富文本内容
}


</script>

<style scoped>
.rich-editor {
  border: 1px solid #ccc;
  width: 100%;
}

.rich-editor-toolbar {
  border-bottom: 1px solid #ccc;
  width: 100%;
}

.rich-editor-editor {
  width: 100%;
}

.pre-dropdown-button {
  padding: 4px;
  background-color: #007bff;
  color: #fff;
  border: none;
  cursor: pointer;
  border-radius: 3px;
}

.pre-dropdown-menu {
  max-height: 200px;
  overflow-y: auto;
}

.pre-dropdown-menu p {
  margin: 0;
  padding: 8px 16px;
  cursor: pointer;
}

.pre-dropdown-menu p:hover {
  background-color: #f1f1f1;
}

</style>
