<template>
  <div class="console_lesson_edit">
    <a-flex justify="space-between">
      <a-breadcrumb separator=">">
        <a-breadcrumb-item>
          <router-link to="/console/course/create">新建课程</router-link>
        </a-breadcrumb-item>
        <a-breadcrumb-item>
          <router-link :to="`/console/course/${course_id}`">{{ data_list.course_name }}</router-link>
        </a-breadcrumb-item>
        <a-breadcrumb-item>
          <router-link :to="`/console/course/${course_id}`">{{ data_list.chapter_name }}</router-link>
        </a-breadcrumb-item>
        <a-breadcrumb-item>{{ data_list.lesson_name }}</a-breadcrumb-item>
      </a-breadcrumb>
      <!--      <div class="switch">-->
      <!--        <button>上一节</button>-->
      <!--        <button class="active">下一节</button>-->
      <!--      </div>-->
    </a-flex>
    <a-tabs v-model:activeKey="activeKey" centered :onChange="onChangeTabs">
      <a-tab-pane key="1" tab="基础信息">
        <a-form id="lessonDetail" :label-col="labelCol" :wrapper-col="wrapperCol"
                :disabled="editorMode === 'readonly'">
          <a-form-item label="本节名称">
            <a-input v-model:value="lessonUpdate.name"/>
          </a-form-item>
          <a-form-item label="本节视频">
            <a-select v-model:value="lessonUpdate.video_id" style="width: 100%" :options="data_list_options.video_ids"
                      show-search :filter-option="filterOption" :allowClear="true">
              <a-select-option value="">选择视频库里的视频</a-select-option>
            </a-select>
          </a-form-item>
          <a-form-item label="知识点">
            <RichEditor height="500" :editMode="editorMode" v-model="lessonUpdate.description"/>
          </a-form-item>
          <a-collapse v-model:activeKey="activeKey1">
            <a-collapse-panel key="1" header="更多信息">
              <a-row>
                <a-col :span="12">
                  <a-form-item label="官方模板" v-if="lessonUpdate.experiment_app_type == 'office'">
                    <a-select v-model:value="lessonUpdate.experiment_app_id" style="width: 100%"
                              :options="data_list_options.experiment_app_ids"
                              show-search :filter-option="filterOption">
                    </a-select>
                  </a-form-item>
                  <a-form-item label="我的模板" v-if="lessonUpdate.experiment_app_type == 'user'">
                    <a-select v-model:value="lessonUpdate.experiment_user_app_id" style="width: 100%"
                              :options="data_list_options.experiment_user_app_ids"
                              show-search :filter-option="filterOption">
                    </a-select>
                  </a-form-item>
                </a-col>
                <a-col :span="12">
                  <a-form-item label="模板类型">
                    <a-select v-model:value="lessonUpdate.experiment_app_type" style="width: 100%"
                              :options="data_list_options.experiment_app_type">
                    </a-select>
                  </a-form-item>
                </a-col>
              </a-row>
              <a-form-item label="案例题">
                <a-select v-model:value="lessonUpdate.exercise_code_ids" style="width: 100%" mode="multiple" :max-tag-count="3"
                          :maxTagCount="5" :allowClear="true"
                          show-search :options="data_list_options.exercise_code_ids" :filter-option="filterOption">
                  <a-select-option value="">选择案例库里的案例</a-select-option>
                </a-select>
              </a-form-item>
              <a-form-item label="练习卷">
                <a-select v-model:value="lessonUpdate.exercise_paper_id" style="width: 100%" :allowClear="true"
                          show-search :options="data_list_options.exercise_paper_ids" :filter-option="filterOption">
                  <a-select-option value="">选择案例库里的案例</a-select-option>
                </a-select>
              </a-form-item>
              <a-form-item label="课件">
                <a-upload
                    v-model:file-list="courseware"
                    :before-upload="beforeUpload"
                    @remove="handleRemove"
                    :max-count="1"
                    accept=".pdf"
                >
                  <a-tooltip>
                    <template #title>请上传 PDF 格式的文件</template>
                    <a-button>
                      <UploadOutlined style="display: inline-flex;"/>
                      上传
                    </a-button>
                  </a-tooltip>
                </a-upload>
              </a-form-item>
              <a-form-item label="视频">
                <a-upload
                    v-model:file-list="courseVideo"
                    :before-upload="beforeVideoUpload"
                    @remove="handleRemoveVideo"
                    @change="handleFileChange"
                    :max-count="1"
                    accept=".mp4,.mkv,.avi"
                >
                  <a-tooltip>
                    <template #title>请上传视频</template>
                    <a-button>
                      <UploadOutlined style="display: inline-flex;"/>
                      上传视频
                    </a-button>
                  </a-tooltip>
                </a-upload>
                <a-alert message="视频上传成功后，请耐心等待审核！" type="warning" v-if="uploadProgress == 100"/>
                <a-progress v-if="uploadProgress > 0" :steps="10" :percent="uploadProgress" />
              </a-form-item>
            </a-collapse-panel>
          </a-collapse>
        </a-form>
      </a-tab-pane>
      <a-tab-pane key="2" tab="实验手册">
        <a-spin :spinning="skeletonLessonsManualActive">
          <div id="live-form" style="color: #ffffff">
            <a-form id="lessonManualDetail"
                    :label-col="labelCol"
                    :wrapper-col="wrapperCol"
                    :disabled="editorMode === 'readonly'"
            >
              <a-form-item label="标题">
                <a-input v-model:value="lessonUpdate.lab_manual_name" enter-button>
                  <template #addonAfter>
                    <a-tooltip>
                      <template #title>查看历史</template>
                      <HistoryOutlined @click="showDrawer"/>
                    </a-tooltip>
                  </template>
                </a-input>
              </a-form-item>
              <a-drawer title="实验手册历史版本" :width="720" :open="open" @close="onClose">
                <template #extra>
                  <a-button style="margin-right: 8px" @click="onClose">取消</a-button>
                  <a-button type="primary" @click="onConfirmApply">确认应用</a-button>
                </template>
                <a-select ref="select"
                          v-model:value="manual_history_id"
                          style="width: 200px"
                          :options="manual_history_options"
                          @change="handleChangeHistoryOptions"
                ></a-select>
                <div>
                  <RichEditor height="700" :editMode="'readonly'" v-model="lab_manual_history.lab_manual_content"/>
                </div>
              </a-drawer>
              <a-form-item label="手册">
                <RichEditor height="600" :editMode="editorMode" v-model="lessonUpdate.lab_manual_content"/>
              </a-form-item>
            </a-form>
          </div>
        </a-spin>
      </a-tab-pane>
      <a-tab-pane key="3" tab="练习管理" v-if="lessonUpdate.question_ids.length>0">
        <div v-for="(item,index) in lessonUpdate.question_ids" :key="item.id" class="mb-3">
          <a-flex justify="space-between">
            <a-flex>{{ index + 1 }}、
              <div style="margin-bottom: -1em;" v-html="item.name"></div>
            </a-flex>
            <a-flex style="flex-shrink: 0;">{{ item.type }}
              <div class="mx-2"/>
              {{ item.score }}分
            </a-flex>
          </a-flex>
          <a-flex justify="flex-end">
            答案：
            <div class="mx-2"/>
            <template v-if="item.type == 'QA'">
              <div v-html="item.qa_solution"/>
            </template>
            <template v-else-if="item.type == 'CODE'">
              <div v-html="item.code_solution"/>
            </template>
            <template v-else>{{ item.solution }}</template>
          </a-flex>
          <a-radio-group v-if="item.type == '单选'" :style=style>
            <div v-for="option in options" :key="option" style="cursor: initial;">
              <a-flex v-if="`option_${option.toLowerCase()}` in lessonUpdate.question_ids[index]">{{ option }}、<span style="margin-bottom: -1em;"
                  v-html="lessonUpdate.question_ids[index]['option_' + option.toLowerCase()]"></span>
                </a-flex>
            </div>
          </a-radio-group>
          <a-checkbox-group v-else-if="item.type == '多选'" :style=style>
            <div v-for="option in options" :key="option" style="cursor: initial;">
              <a-flex v-if="`option_${option.toLowerCase()}` in lessonUpdate.question_ids[index]">{{ option }}、<span style="margin-bottom: -1em;"
                v-html="lessonUpdate.question_ids[index]['option_' + option.toLowerCase()]"></span>
              </a-flex>
            </div>
          </a-checkbox-group>
          <a-form-item v-else-if="item.type == '填空'">
            <a-textarea/>
          </a-form-item>
          <a-radio-group v-else-if="item.type == '判断'" :style=style>
            <div v-for="option in ['A','B']" :key="option" style="cursor: initial;">
              <a-flex v-if="`option_${option.toLowerCase()}` in lessonUpdate.question_ids[index]">{{ option }}、<span style="margin-bottom: -1em;"
                v-html="lessonUpdate.question_ids[index]['option_' + option.toLowerCase()]"></span>
              </a-flex>
            </div>
          </a-radio-group>
        </div>
      </a-tab-pane>
      <template #rightExtra>
        <template v-if="activeKey == '3'">
          <a-row>
            <a-col :span="10" style="display: flex; justify-content: space-around">
              <a-button type="primary" @click="addQuestion" size="small">编辑题目</a-button>
            </a-col>
          </a-row>
        </template>
        <template v-else>
          <a-row v-if="editorMode === 'create'">
            <a-col :span="10" style="display: flex; justify-content: center">
              <a-button type="primary" @click="lessonEditOk" size="small">保存</a-button>
            </a-col>
          </a-row>
          <a-row v-if="editorMode === 'update'">
            <a-col :span="10" style="display: flex; justify-content: center">
              <a-button type="primary" @click="editorMode = 'readonly'" size="small">取消</a-button>
              <a-button type="primary" @click="lessonEditOk" size="small" class="ml-3">保存</a-button>
            </a-col>
          </a-row>
          <a-row v-else-if="editorMode === 'readonly'">
            <a-col :span="10" style="display: flex; justify-content: space-around">
              <a-button type="primary" @click="editorMode = 'update'" size="small">进入编辑</a-button>
            </a-col>
          </a-row>
        </template>
      </template>
    </a-tabs>
  </div>
</template>

<script setup>
import { HistoryOutlined } from '@ant-design/icons-vue';
import {onMounted, reactive, ref,} from "vue";
import {jsonRPC, getResponseData} from "@/utils/http_utils";
import {logDebug, logError} from "@/utils/logger";
import {useRouter} from 'vue-router';
import {message} from "ant-design-vue";
import {UploadOutlined} from '@ant-design/icons-vue';
import SparkMD5 from "spark-md5"; // 引入SparkMD5用于计算文件的MD5值
const options = Array.from({length: 8}, (_, i) => String.fromCharCode(65 + i))
const style = {display: 'flex', flexDirection: 'column'}
import {getBase64} from "@/utils/file_utils";
import axios from "axios";

const activeKey = ref('1');
const activeKey1 = ref([]);
import RichEditor from "@/components/RichEditor.vue";

const router = useRouter();
const editorMode = ref('readonly')
const course_id = router.currentRoute.value.params.course_id
const chapter_id = router.currentRoute.value.params.chapter_id
const lesson_id = router.currentRoute.value.params.lesson_id
const data_list = reactive({
  course_name: '',
  chapter_name: '',
  lesson_name: '',
});
const data_list_options = reactive({});
const courseware = ref([])
const handleRemove = file => {
  const index = courseware.value.indexOf(file);
  const newFileList = courseware.value.slice();
  newFileList.splice(index, 1);
  courseware.value = newFileList;
};

const courseVideo = ref([])
const handleRemoveVideo = file => {
  const index = courseVideo.value.indexOf(file);
  const newFileList = courseVideo.value.slice();
  newFileList.splice(index, 1);
  courseVideo.value = newFileList;
  uploadProgress.value=0
};


const beforeVideoUpload = file => {
  const isMP4 = file.type === 'video/mp4';
  if (!isMP4) {
    courseVideo.value = []
    message.error(`${file.name} -文件不是MP4格式，请转换格式后上传`);
  }
  return false
};

let uploadProgress = ref(0) // 上传进度
let selectedFile = ref(null)// 用户选择的文件
let selectedFileName = ref('')// 用户选择的文件名称
let selectedFileType = ref('')// 用户选择的文件类型
let requestPool = ref([]) // 请求池，存储待上传的切片信息
let MAX_REQUEST = ref(6) // 最大并发请求数量
let chunkCount = ref(0) // 文件切片总数
let uploadedChunksCount = ref(0) // 已上传的切片数量

// 计算文件的MD5
function computeFileHash(file) {
  return new Promise((resolve) => {
    const spark = new SparkMD5.ArrayBuffer();
    const fileReader = new FileReader();
    fileReader.onload = (e) => {
      spark.append(e.target.result);
      const hash = spark.end();
      resolve(hash); // 返回计算得到的hash值
    };
    fileReader.readAsArrayBuffer(file);
  });
}

// 处理文件选择事件
const handleFileChange = async (event) => {
  logDebug("处理文件选择事件")
  logDebug(event)
  const file = event.file;

  selectedFileName.value = file.name
  selectedFileType.value = file.type
  selectedFile.value = file;
  if (!selectedFile.value) return; // 未选择文件则返回

  // 可以在这里添加文件格式校验
  const isMP4 = file.type === 'video/mp4';
  if (!isMP4) {
    return;
  }

  const fileHash = await computeFileHash(selectedFile.value); // 计算文件hash
  logDebug('计算文件hash')
  logDebug(fileHash)
  try {
    const {shouldUpload, uploadedChunks} = await checkFile(fileHash); // 检查文件是否需要上传
    if (!shouldUpload) {
      message.success('秒传成功！');
      uploadProgress.value = 100; // 直接设置进度为100%
      return;
    }
    sliceFileAndUpload(fileHash, uploadedChunks); // 切片并上传
  } catch (error) {
    logError("文件检查失败", error);
  }
}

// 检查文件是否已经上传过
const checkFile = (fileHash) => {
  return new Promise((resolve, reject) => {
    logDebug("检查文件是否已经上传过");
    logDebug(fileHash);
    jsonRPC({
      url: `/api/check/fileHash/${fileHash}`,
      success(res) {
        const data = getResponseData(res);
        logDebug(`检查成功`, data);
        resolve(data); // 在成功回调中解析 Promise
      },
      fail(error) {
        logError(`检查失败`, error);
        reject(error); // 在失败回调中拒绝 Promise
      },
    });
  });
}

// 切片并准备上传
const sliceFileAndUpload = (fileHash, uploadedChunks) => {
  const chunkSize = 10 * 1024 * 1024; // 切片大小，这里是10MB
  chunkCount.value = Math.ceil(selectedFile.value.size / chunkSize); // 计算总切片数

  uploadProgress.value = 0; // 重置上传进度
  for (let i = 0; i < chunkCount.value; i++) {
    if (uploadedChunks.includes(i)) continue; // 跳过已上传的切片
    const chunk = selectedFile.value.slice(i * chunkSize, (i + 1) * chunkSize); // 获取切片
    requestPool.value.push({chunk, index: i}); // 加入请求池
  }
  processPool(fileHash); // 开始处理请求池
}
// 处理请求池中的切片上传
const processPool = (fileHash) => {
  while (requestPool.value.length > 0 && MAX_REQUEST.value > 0) {
    const {chunk, index} = requestPool.value.shift(); // 取出一个待上传的切片
    uploadChunk(chunk, fileHash, index) // 上传切片
        .then(() => {
          uploadedChunksCount.value++; // 更新已上传切片数量
          uploadProgress.value = ((uploadedChunksCount.value / chunkCount.value) * 100).toFixed(2); // 更新上传进度
          if (requestPool.value.length > 0) {
            processPool(fileHash); // 继续处理请求池
          } else if (uploadedChunksCount.value === chunkCount.value) {
            // 所有切片都已上传，通知服务器合并
            notifyServerToMerge(fileHash, chunkCount.value, selectedFileName.value, selectedFileType.value);
          }
        })
        .finally(() => {
          MAX_REQUEST.value++; // 释放一个请求槽
        });
    MAX_REQUEST.value--; // 占用一个请求槽
  }
}
// 上传单个切片
const uploadChunk = async (chunk, fileHash, index) => {
  const formData = new FormData();
  formData.append("chunk", chunk);
  formData.append("fileHash", fileHash);
  formData.append("chunkIndex", index);
  // 替换为真实的上传URL，并根据需要实现onUploadProgress
  await axios.post("/api/upload/fileChunk", formData);
}
// 通知服务器合并切片
const notifyServerToMerge = (fileHash, totalChunks, filename, file_type) => {
  // 通知服务器合并切片，应替换为真实的合并API调用
  return new Promise((resolve, reject) => {
    logDebug(`通知服务器将文件与哈希合并: ${fileHash}`);

    jsonRPC({
      url: `/api/merge/fileChunks`,
      params: {
        "fileHash": fileHash,
        "totalChunks": totalChunks,
        'filename': filename,
        'file_type': file_type,
      },
      success(res) {
        const data = getResponseData(res);
        logDebug(`通知服务器将文件与哈希合并结果`, data);
        resolve(data); // 在成功回调中解析 Promise
        message.success('视频上传成功！');
      },
      fail(error) {
        logError(`通知服务器将文件与哈希合并失败`, error);
        message.success('视频上传失败！');
        reject(error); // 在失败回调中拒绝 Promise

      },
    });
  });

}

const beforeUpload = file => {
  courseware.value = [...(courseware.value || []), file];
  return false;
};
const lessonUpdate = reactive({
  id: '',
  name: '',
  video_id: '',
  video_url: '',
  experiment_app_type: '',
  experiment_app_id: false,
  experiment_user_app_id: false,
  exercise_code_ids: [],
  exercise_paper_id: '',
  lab_manual_id: false,
  lab_manual_name: '',
  lab_manual_content: '',
  description: '',
  question_ids: [],
});

let lab_manual_history = reactive({
  lab_manual_id: false,
  lab_manual_name: '',
  lab_manual_content: '',
})
let manual_history_id = ref()
let manual_history_options = ref([
  {
    value: '',
    label: '',
  }
])
const open = ref(false);
// 显示历史数据的抽屉
const showDrawer = async () => {
  lab_manual_history.lab_manual_content = '请选择历史版本'
  manual_history_id.value = 0
  if (lessonUpdate.lab_manual_id) {
    await queryCourseManualOptions(lessonUpdate.lab_manual_id)
  } else {
    skeletonLessonsManualActive.value = false
  }
  open.value = true;
};
const onClose = () => {
  open.value = false;
};
// 确认应用此版本
const onConfirmApply = async () => {
  lessonUpdate.lab_manual_content = lab_manual_history.lab_manual_content
  await updateCourseManual(lessonUpdate.lab_manual_id)
  if (lessonUpdate.lab_manual_id) {
    await queryCourseManualOptions(lessonUpdate.lab_manual_id)
  } else {
    skeletonLessonsManualActive.value = false
  }
  onClose()
  message.success('当前版本应用成功')
}

const handleChangeHistoryOptions = async value => {
  skeletonLessonsManualActive.value = true
  if (value == 0) {
    skeletonLessonsManualActive.value = false
    return
  } else {
    await queryCourseManualHistory(value)
  }
};
const filterOption = (input, option) => {
  return option.label.toLowerCase().indexOf(input.toLowerCase()) >= 0;
};
const onChangeTabs = async e => {
  if (e == 2) {
    if (skeletonLessonsManualActive.value) {
      if (lessonUpdate.lab_manual_id) {
        await queryCourseManual(lessonUpdate.lab_manual_id)
      }
      skeletonLessonsManualActive.value = false
    }
  }
}
let skeletonLessonsManualActive = ref(true);
const addQuestion = () => {
  // 打开新页面
  router.push(`/console/course/${course_id}/${chapter_id}/${lesson_id}/edit/exercise`);
}
const lessonEditOk = async () => {
  await updateCourseLesson()
  if (lessonUpdate.lab_manual_id) {
    await updateCourseManual(lessonUpdate.lab_manual_id)
  } else {
    await createManualData()
  }
}
const labelCol = {
  style: {
    width: '150px',
  },
};
const wrapperCol = {
  span: 24,
};

// 获取课程的节的基本信息
async function getLessonBaseInfo() {
  jsonRPC({
    url: "/api/make/course/lesson/base/info",
    params: {
      course_id: course_id,
      chapter_id: chapter_id,
      lesson_id: lesson_id,
    },
    success(res) {
      const data = getResponseData(res);
      logDebug(`获取成功`, data);
      Object.assign(data_list, data);
      Object.assign(data_list_options, data);
    },
    fail(error) {
      logError(`查询失败, `, error)
    },
  });
  return true
}

// 查询课程的节的数据
async function queryCourseLessonData() {
  jsonRPC({
    url: `/api/make/course/lesson/${lesson_id}`,
    success(res) {
      const data = getResponseData(res);
      Object.assign(lessonUpdate, data)
      courseware.value = data.courseware;
      logDebug(`查询课程的节的数据, `, lessonUpdate)
    },
    fail(error) {
      logError(`查询失败, `, error)
    },
  });
  return true
}

// 查询实验手册的数据
async function queryCourseManual(manual_id) {
  jsonRPC({
    url: `/api/course/lesson/lab/manual/${manual_id}`,
    success(res) {
      const data = getResponseData(res);
      manual_history_options.value = data.manual_history
      Object.assign(lessonUpdate, data)
      skeletonLessonsManualActive.value = false
    },
    fail(error) {
      logError(`查询失败, `, error)
    },
  })
}

// 查询实验手册的历史记录列表
async function queryCourseManualOptions(manual_id) {
  jsonRPC({
    url: `/api/course/lesson/lab/manual/history/list/${manual_id}`,
    success(res) {
      const data = getResponseData(res);
      logDebug(`查询实验手册的历史记录列表, `, data)
      manual_history_options.value = data.manual_history
    },
    fail(error) {
      logError(`查询失败, `, error)
    },
  })
}

// 查询实验手册的历史数据
async function queryCourseManualHistory(value) {
  jsonRPC({
    url: `/api/course/lesson/lab/manual/history/${value}`,
    success(res) {
      const data = getResponseData(res);
      Object.assign(lab_manual_history, data)
      logDebug(`handleChangeHistoryOptions data`, data)
      skeletonLessonsManualActive.value = false
    },
    fail(error) {
      logError(`查询失败, `, error)
      skeletonLessonsManualActive.value = false
    },
  })
}

// 创建实验手册的数据
async function createManualData() {
  jsonRPC({
    url: "/api/course/lesson/lab/manual/create",
    params: {
      lesson_id: lesson_id,
      name: lessonUpdate.lab_manual_name,
      data: JSON.stringify(lessonUpdate.lab_manual_content),
    },
    success(res) {
      const data = getResponseData(res);
      queryCourseManual(data)
    },
    fail(error) {
      logError(`查询失败, `, error)
    },
  });
  return true
}


async function updateCourseLesson() {
  const filePromises = await Promise.all(courseware.value.map(async file => {
    const base64Data = await getBase64(file.originFileObj);
    return {
      ...file,
      raw_data: base64Data,
      file_name: file.name,
    };
  }));
  jsonRPC({
    url: "/api/make/course/lesson/update",
    params: {
      lesson_id: lesson_id,
      lesson_name: lessonUpdate.name,
      video_id: lessonUpdate.video_id,
      experiment_app_type: lessonUpdate.experiment_app_type,
      experiment_app_id: lessonUpdate.experiment_app_id,
      experiment_user_app_id: lessonUpdate.experiment_user_app_id,
      exercise_code_ids: lessonUpdate.exercise_code_ids,
      exercise_paper_id: lessonUpdate.exercise_paper_id,
      courseware: filePromises,
      description: lessonUpdate.description,
    },
    success(res) {
      const data = getResponseData(res);
      logDebug(`获取成功`, data);
      editorMode.value = 'readonly';
      queryCourseLessonData()
      message.success('保存成功')
    },
    fail(error) {
      logError(`查询失败, `, error);
    },
  });
}

async function updateCourseManual(manual_id) {
  jsonRPC({
    url: `/api/course/lab/manual/${manual_id}/update`,
    params: {
      lesson_id: lesson_id,
      name: lessonUpdate.lab_manual_name,
      data: JSON.stringify(lessonUpdate.lab_manual_content),
    },
    success(res) {
      const data = getResponseData(res);
      logDebug(`获取成功`, data);
    },
    fail(error) {
      logError(`查询失败, `, error);
    },
  });
}

onMounted(async () => {
  await getLessonBaseInfo()
  await queryCourseLessonData()
})

</script>

<style scoped lang="scss">
.console_lesson_edit {
  padding: 16px;

  .switch {
    button {
      font-size: 16px;
      color: #fff;
      line-height: 30px;
      padding: 0 8px;
      border-radius: 8px;
      user-select: none;
      outline: none;

      &:not(:first-child) {
        margin-left: 10px;
      }

      background-color: #C3C3C3;

      &.active {
        background-color: #0B68FF;
      }

      &:hover {
        opacity: 0.8;
      }
    }
  }
}
:deep(.ant-collapse-item >.ant-collapse-header){
  position: static;
}
</style>