<template>
  <div class="exercise_detail p-4">
    <a-flex :justify="'space-between'" :align="'center'" style="margin-bottom: 20px;">
      <h4>{{ data_list.paper_info?.name }}</h4>
      <a-affix :offset-top="10">
        <a-button v-if="editorMode == 'create' && data_list.question_count != 0" type="primary"
                  class="submitHomework" @click="submitHomework">提交练习
        </a-button>
        <a-button v-else type="primary" class="saveHomework" @click="_Back">返回</a-button>
      </a-affix>
    </a-flex>
    <a-spin :spinning="spinning" size="large" tip="Loading...">
      <a-flex :justify="'center'" :gap="30" class="mb-3">
        <div style="color: #49cfca;">倒计时：{{ formatTime(timeRemaining) }}</div>
      </a-flex>
      <a-flex v-if="editorMode == 'readonly'" :justify="'center'" :gap="30" class="mb-3">
        <div>总分：{{ data_list.total }}</div>
        <div>及格分：{{ data_list.pass_score }}</div>
        <div>得分：{{ data_list.score }}</div>
        <div>
          结果：
          <a-typography-text v-if="data_list.exercise_result == '通过'" type="success">
            {{ data_list.exercise_result }}
          </a-typography-text>
          <a-typography-text v-else-if="data_list.exercise_result == '未提交'" type="warning">
            {{ data_list.exercise_result }}
          </a-typography-text>
          <a-typography-text v-else-if="data_list.exercise_result == '未通过'" type="danger">
            {{ data_list.exercise_result }}
          </a-typography-text>
          <a-typography-text v-else>{{ data_list.exercise_result }}</a-typography-text>
        </div>
      </a-flex>
      <a-form ref="exerciseformRef" :model="create_list" :label-col="{style: {width: '150px'} }"
              :disabled="editorMode == 'readonly'">
        <div v-for="(item,index) in data_list.exercise_paper_partner" :key="index" class="mb-3">
          <a-flex justify="space-between">
            <a-flex>{{ index + 1 }}、
              <div style="margin-bottom: -1em;" v-html="item.name"></div>
            </a-flex>
            <a-flex style="flex-shrink: 0;">
              {{ getTypeName(item.type) }}
              <div class="mx-2"/>
              {{ item.score }}分
            </a-flex>
          </a-flex>
          <a-flex v-if="editorMode == 'readonly'" justify="flex-end">
            <a-flex v-if="['S', 'M', 'TF'].includes(item.type)" gap="10">
              <div>正确答案: {{item.solution}}</div>
              <div>你的回答: {{create_list[index].answer}}</div>
            </a-flex>
            <a-flex v-else-if="item.type == 'QA' || item.type=='FB'" wrap="wrap" gap="3">
              参考答案: <div v-html="item.qa_solution"/>
            </a-flex>
            <a-flex v-else-if="item.type == 'CODE'" wrap="wrap" gap="3">
              参考答案: <div v-html="item.code_solution"/>
            </a-flex>
          </a-flex>
          <a-radio-group v-if="item.type == 'S'" v-model:value="create_list[index].answer"
                          :style=style>
            <div v-for="option in options" :key="option" style="cursor: initial;">
              <a-radio v-if="item['option_' + option.toLowerCase()]"
                        :value="option">
                <a-flex>{{ option }}、<span style="margin-bottom: -1em;"
                                            v-html="item['option_' + option.toLowerCase()]"></span>
                </a-flex>
              </a-radio>
            </div>
          </a-radio-group>
          <a-checkbox-group v-else-if="item.type == 'M'" v-model:value="create_list[index].answer"
                            :style=style>
            <div v-for="option in options" :key="option" style="cursor: initial;">
              <a-checkbox v-if="item['option_' + option.toLowerCase()]" :value="option">
                <a-flex>{{ option }}、<span style="margin-bottom: -1em;"
                                            v-html="item['option_' + option.toLowerCase()]"></span>
                </a-flex>
              </a-checkbox>
            </div>
          </a-checkbox-group>
          <a-form-item v-else-if="item.type == 'FB'">
            <a-textarea v-model:value="create_list[index].answer"/>
          </a-form-item>
          <a-radio-group v-else-if="item.type == 'TF'" v-model:value="create_list[index].answer"
                          :style=style>
            <div v-for="option in ['A','B']" :key="option" style="cursor: initial;">
              <a-radio v-if="item['option_' + option.toLowerCase()]"
                        :value="option">
                <a-flex>{{ option }}、<span style="margin-bottom: -1em;"
                                            v-html="item['option_' + option.toLowerCase()]"></span>
                </a-flex>
              </a-radio>
            </div>
          </a-radio-group>
          <a-form-item v-else-if="item.type == 'QA'">
            <RichEditor v-model="create_list[index].answer" :height="301" :editMode="editorMode"/>
          </a-form-item>
          <a-form-item v-else-if="item.type == 'CODE'">
            <RichEditor v-model="create_list[index].answer" :height="301" :editMode="editorMode"/>
          </a-form-item>
        </div>
        <a-empty v-if="data_list.question_count == 0" :image="simpleImage"/>
      </a-form>
    </a-spin>
    <a-back-top />
  </div>
</template>

<script setup>
const emits = defineEmits(['call-parent-method', 'call-parent-refresh']);
const props = defineProps({
  exercise_id: Number,
});
const exercise_id = ref(props.exercise_id)
const _Back = ()=>{
  emits('call-parent-method', {
    component: 'list',
  });
}
import { logDebug, logError } from "@/utils/logger";
import { message } from "ant-design-vue";
import { onMounted, onBeforeUnmount, ref, reactive } from "vue";
import { jsonOwlRPC } from "@/utils/http_utils";
import RichEditor from "@/components/RichEditor.vue";
import {Empty} from "ant-design-vue";
const simpleImage = Empty.PRESENTED_IMAGE_SIMPLE;

const exerciseformRef = ref();
const editorMode = ref('create');
const data_list = reactive({
  exe_score_id: null,
  exercise_paper_partner: [],
  exe_score_partner: [],
})
const create_list = reactive({})
const spinning = ref(false);
const options = Array.from({length: 8}, (_, i) => String.fromCharCode(65 + i))
const style = {display: 'flex', flexDirection: 'column'}

const timeRemaining = ref(data_list.exercise_duration * 60); // 转换为秒
const timerInterval = ref();
// 格式化时间为 时：分：秒
function formatTime(seconds) {
  if (seconds>0) {
    const h = Math.floor(seconds / 3600).toString().padStart(2, '0');
    const m = Math.floor((seconds % 3600) / 60).toString().padStart(2, '0');
    const s = (seconds % 60).toString().padStart(2, '0');
    return `${h}:${m}:${s}`;
  } else {
    return `00:00:00`;
  }
}
// 字典映射
const typeMapping = {
  'S': '单选',
  'M': '多选',
  'FB': '填空',
  'TF': '判断',
  'QA': '问答',
  'CODE': '编程'
};

// 获取类型名称
function getTypeName(type) {
  return typeMapping[type] || '未知类型'; // 如果没有对应的类型，返回'未知类型'
}
// 启动倒计时
function startCountdown() {
  if (editorMode.value == 'create' && data_list.paper_info && data_list.paper_info.duration>0) {
    timeRemaining.value = data_list.paper_info.duration * 60;
    timerInterval.value = setInterval(() => {
      if (timeRemaining.value > 0) {
        timeRemaining.value--;
      } else {
        submitHomework();
      }
    }, 1000);
  }
}
function fetchData() {
  spinning.value = true;
  jsonOwlRPC({
    url: "/job/exercise/paper/doing",
    params: {
      paper_id: exercise_id.value,
      paper_partner_id: data_list.exe_score_id,
    },
    success(res) {
      logDebug(`获取成功`, res);
      Object.assign(data_list, res);
      if (editorMode.value == 'create') {
        for (let index = 0; index < data_list.exercise_paper_partner.length; index++) {
          const element = data_list.exercise_paper_partner[index];
          create_list[index] = create_list[index] || {};  // 确保对象存在
          create_list[index].id = element.id;
          create_list[index].type = element.type;
          create_list[index].answer = '';  // 初始化 answer
        }
        startCountdown()
      }
    },
    fail(error) {
      logError(`查询失败, `, error);
    },
  }).then(() => {
    spinning.value = false; // 加载完成后关闭加载状态
  });
}
onMounted(() => {
  fetchData();
});
onBeforeUnmount(() => {
  if (timerInterval.value) {
    clearInterval(timerInterval.value); // 清除计时器，防止内存泄漏
  }
});
// 提交作业
const submitHomework = () => {
  clearInterval(timerInterval.value);
  spinning.value = true;
  let timer = 0
  if (data_list.paper_info) {
    let timers = data_list.paper_info.duration * 60 - timeRemaining.value
    timer = Math.ceil(timers/60)
  }
  timeRemaining.value = data_list.paper_info.duration * 60;
  jsonOwlRPC({
    url: "/job/exercise/paper/doing/submit",
    params: {
      paper_id: exercise_id.value,
      exe_score_id: data_list.exe_score_id,
      data: JSON.stringify(Object.values(create_list)),
      timer: timer,
    },
    success(res) {
      logDebug(`获取成功`, res)
      message.success('提交成功')
      editorMode.value = 'readonly';
      emits('call-parent-refresh');
      fetchData()
    },
    fail(error) {
      logError(`查询失败, `, error)
    },
  });
}
</script>

<style scoped lang="scss">
.exercise_detail{
  background-color: #fff;
}
</style>
