<template>
  <a-button id="function-full-screen" type="primary" @click="clickHandler"
            :style="props.styleSetting.buttonStyle">
    <a-tooltip v-if="!fullscreen">
      <template #title>全屏</template>
      <FullscreenOutlined :style="props.styleSetting.iconStyle"/>
    </a-tooltip>
    <a-tooltip v-else>
      <template #title>退出全屏</template>
      <FullscreenExitOutlined :style="props.styleSetting.iconStyle"/>
    </a-tooltip>
  </a-button>
</template>

<script setup>
import {onMounted, ref} from 'vue';
import {FullscreenExitOutlined, FullscreenOutlined} from '@ant-design/icons-vue';
import {logComponents, logDebug} from "@/utils/logger";

logComponents([FullscreenOutlined, FullscreenExitOutlined])
logDebug('TerminalFunctionFullScreen setup!')

const props = defineProps({
  primal: {type: String},
  data: {type: Object},
  styleSetting: {type: Object},
})

const primal = props.primal
const data = props.data

// 组件逻辑
const fullscreen = ref(false)

const handleFullScreen = function () {
  let element = document.documentElement;
  if (fullscreen.value) {
    if (document.exitFullscreen) {
      document.exitFullscreen();
    } else if (document.webkitCancelFullScreen) {
      document.webkitCancelFullScreen();
    } else if (document.mozCancelFullScreen) {
      document.mozCancelFullScreen();
    } else if (document.msExitFullscreen) {
      document.msExitFullscreen();
    }
  } else {
    if (element.requestFullscreen) {
      element.requestFullscreen();
    } else if (element.webkitRequestFullScreen) {
      element.webkitRequestFullScreen();
    } else if (element.mozRequestFullScreen) {
      element.mozRequestFullScreen();
    } else if (element.msRequestFullscreen) {
      // IE11
      element.msRequestFullscreen();
    }
  }
  fullscreen.value = !fullscreen.value;
}

const clickHandler = function () {
  logDebug('全屏')
  logDebug(JSON.stringify(props.data))

  handleFullScreen()

  // 下面是触发父组件回调方法的示例：
  // emit("ok", "我是子组件fullscreen传入的ok值"); //触发事件
  // emit("cancel", "我是子组件fullscreen传入的cancel值"); //触发事件
  // emit("refresh", "我是子组件fullscreen传入的refresh值"); //触发事件
  // emit("customize", {
  //   'arg1': '参数1',
  //   'arg2': 2,
  // }) //触发事件
}

onMounted(() => {
  // 在onMounted中获取数据
  logDebug(`TerminalFunctionFullScreen onMounted.`)
})

const onSelected = function () {
  logDebug(`TerminalFunctionFullScreen onSelected.`)
}

defineExpose({
  primal,
  data,
  onSelected,
})


</script>

<style scoped>
/* #function-full-screen {
  background: #00BFFF;
} */
</style>

