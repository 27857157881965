<template>
  <div class="eduAdminClassCourse">
    <a-breadcrumb separator=">">
      <a-breadcrumb-item><router-link to="/console/eduAdmin">教务管理</router-link></a-breadcrumb-item>
      <a-breadcrumb-item><router-link :to="`/console/eduAdmin/class/?id=${class_id}&mode=readonly`">{{ data_list.class_name }}</router-link></a-breadcrumb-item>
      <a-breadcrumb-item>{{ data_list.name }}</a-breadcrumb-item>
    </a-breadcrumb>
    <div class="info">
      <a-flex justify="space-between" align="center">
        <h4><b>{{ data_list.name }}</b></h4>
        <div class="menu">
          <a-button type="primary" @click="exportScore">下载成绩单</a-button>
          <a-button type="primary" @click="signIn">签到详情</a-button>
          <a-button type="primary" @click="work">课程作业</a-button>
          <a-button type="primary" @click="report">实验报告</a-button>
          <a-button type="primary" @click="scoreAlgorithm">成绩算法</a-button>
          <!-- <a-button type="primary">录入成绩</a-button> -->
        </div>
      </a-flex>
      <a-table :columns="columns" :dataSource="data_list.student_ids" :pagination="pagination"
        :scroll="{ x: '100vw'}">
        <template #bodyCell="{ column, record, text }">
          <template v-if="column.dataIndex == 'video_course'">{{text}}%</template>
          <template v-if="column.dataIndex == 'usual_score'">
            <div>
              <a-input
                v-if="editableData[record.id]"
                v-model:value="editableData[record.id][column.dataIndex]"
                style="margin: -5px 0"
              />
              <template v-else>
                {{ text }}
              </template>
            </div>
          </template>
          <template v-if="column.dataIndex == 'exam_score' && data_list.exam_type != 'online'">
            <div>
              <a-input
                v-if="editableData[record.id]"
                v-model:value="editableData[record.id][column.dataIndex]"
                style="margin: -5px 0"
              />
              <template v-else>
                {{ text }}
              </template>
            </div>
          </template>
          <template v-if="column.dataIndex === 'operation'">
            <div class="editable-row-operations">
              <span v-if="editableData[record.id]">
                <a-typography-link @click="save(record.id)">保存</a-typography-link>
                <a @click="cancel(record.id)">取消</a>
              </span>
              <span v-else>
                <a @click="edit(record.id)">编辑</a>
              </span>
            </div>
          </template>
        </template>
      </a-table>
    </div>
  </div>
</template>

<script setup>
import {logDebug, logError} from "@/utils/logger";
import {onMounted, ref, reactive, watch, markRaw } from 'vue'
import {jsonRPC, getResponseData} from "@/utils/http_utils";
import {getRouterQuery } from "@/utils/router_utils";
import { useRouter } from "vue-router";
import * as XLSX from 'xlsx';
import {saveAs} from 'file-saver';
const router = useRouter();
const course_id = ref(getRouterQuery(router, "id"));
const class_id = ref(getRouterQuery(router, "class_id"));

const data_list = reactive({
  class_name: '',
  name: '',
  student_ids: [],
})
const scoreAlgorithm = ()=>{
  router.push(`/console/eduAdmin/class/course/algorithm?course_id=${course_id.value}&class_id=${class_id.value}`);
}
const signIn = ()=>{
  router.push(`/console/eduAdmin/class/course/signIn?course_id=${course_id.value}&class_id=${class_id.value}`);
}
const work = ()=>{
  router.push(`/console/eduAdmin/class/course/work?course_id=${course_id.value}&class_id=${class_id.value}`);
}
const report = ()=>{
  router.push(`/console/eduAdmin/class/course/report?course_id=${course_id.value}&class_id=${class_id.value}`);
}
function fetchData() {
  jsonRPC({
    url: `/vue/console/eduAdmin/class/course/detail`,
    params:{
      course_id: course_id.value,
      class_id: class_id.value,
    },
    success(res) {
      const data = getResponseData(res);
      logDebug(`获取成功需求数据成功`, data);
      Object.assign(data_list, data);
    },
    fail(error) {
      logError(`查询失败, `, error);
    },
  })
}
onMounted(fetchData)
const columns = [
  {
    title: '班级',
    dataIndex: 'class',
    align: 'center',
    fixed: 'left',
  },
  {
    title: '学生编号',
    dataIndex: 'student_barcode',
    align: 'center',
    fixed: 'left',
  },
  {
    title: '姓名',
    dataIndex: 'name',
    align: 'center',
    fixed: 'left',
  },
  {
    title: '视频课进度',
    dataIndex: 'video_course',
    align: 'center',
  },
  {
    title: '签到考勤情况',
    dataIndex: 'attendance',
    align: 'center',
  },
  {
    title: '作业完成情况',
    dataIndex: 'homework',
    align: 'center',
  },
  {
    title: '实验报告提交情况',
    dataIndex: 'report',
    align: 'center',
  },
  {
    title: '平时成绩',
    dataIndex: 'usual_score',
    align: 'center',
  },
  {
    title: '考试成绩',
    dataIndex: 'exam_score',
    align: 'center',
  },
  {
    title: '总成绩',
    dataIndex: 'semester_total_score',
    align: 'center',
  },
  {
    title: '操作',
    dataIndex: 'operation',
    align: 'center',
  },
];
import { cloneDeep } from 'lodash-es';
import { message } from "ant-design-vue";
const editableData = reactive({});
const edit = id => {
  editableData[id] = cloneDeep(data_list.student_ids.filter(item => id === item.id)[0]);
};
const save = async id => {
  const usualScore = Number(editableData[id].usual_score);
  const examScore = Number(editableData[id].exam_score);

  // 校验分数范围是否在0到100之间
  if (isNaN(usualScore) || isNaN(examScore) || usualScore < 0 || usualScore > 100 || examScore < 0 || examScore > 100) {
    return message.error('成绩必须是0到100之间的数字');
  }

  await jsonRPC({
    url: `/vue/console/eduAdmin/class/course/edit/score`,
    params: {
      score_id: editableData[id].score_id,
      usual_score: usualScore,
      exam_score: examScore,
    },
    success() {
      fetchData()
      delete editableData[id];
    },
    fail(error) {
      logError(`查询失败, `, error);
    },
  });
};
const cancel = id => {
  delete editableData[id];
};
const handlePageChange = (page, pageSize) => {
  logDebug(`page[${page}], pageSize[${pageSize}]`)
  pagination.current = page;
  fetchData();
}
const pagination = reactive({
  // 分页配置
  pageSize: 8, // 每页显示的条数
  showSizeChanger: false, // 是否可以改变每页显示的条数
  pageSizeOptions: ['5', '10', '20'], // 可选的每页显示条数
  showQuickJumper: false, // 是否可以快速跳转到指定页
  showTotal: total => `共 ${total} 条`, // 显示总条数和当前数据范围
  hideOnSinglePage: true, // 只有一页时是否隐藏分页器 true为不显示 false为显示
  current: 1, // 当前页数
  total: 0, // 总条数
  onChange: markRaw(handlePageChange), // 页码改变时的回调函数
})
watch([pagination.current], fetchData);


function formattedTimeStr() {
  const now = new Date();
  const year = now.getFullYear();
  const month = (now.getMonth() + 1).toString().padStart(2, '0'); // 月份需要+1，并确保两位数
  const day = now.getDate().toString().padStart(2, '0');
  const hour = now.getHours().toString().padStart(2, '0');
  const minute = now.getMinutes().toString().padStart(2, '0');

  const formattedTime = `${year}${month}${day}${hour}${minute}`;
  return formattedTime
}

const exportScore = () => {
  // 检查数据是否存在
  if (!data_list) {
    logError('Data list or check list is undefined');
    return;
  }

  // 准备外层数据的工作表
  const outerData = data_list.student_ids.map(item => ({
    "班级": item.class,
    "学生编号	": item.student_barcode,
    "学生姓名": item.name,
    "视频课进度": item.video_course,
    "签到考勤情况": item.attendance,
    "作业完成情况": item.homework,
    "实验报告提交情况": item.report,
    "平时成绩": item.usual_score,
    "考试成绩": item.exam_score,
    "总成绩": item.semester_total_score,
  }));

  const outerSheet = XLSX.utils.json_to_sheet(outerData); // 将外层数据转换为工作表
  const wb = XLSX.utils.book_new(); // 创建工作簿
  XLSX.utils.book_append_sheet(wb, outerSheet, `${data_list.name}-成绩单`); // 将外层数据工作表添加到工作簿

  // 生成文件并触发下载
  const wbout = XLSX.write(wb, { bookType: 'xlsx', type: 'array' });
  const blob = new Blob([wbout], { type: 'application/octet-stream' });

  const formattedTime = formattedTimeStr();
  saveAs(blob, `成绩单-${formattedTime}.xlsx`); // 保存为Excel文件
};

</script>

<style scoped lang="scss">
.eduAdminClassCourse{
  height: 100%;
  width: 100%;
  overflow: auto;
  padding: 16px;
  .info{
    background-color: #fff;
    padding: 1.5rem;
    h4{
      display: inline-block;
      font-size: 18px;
      padding-bottom: 5px;
      padding-right: 30px;
      border-bottom: 2px solid #63B1FF;
    }
  }
  .menu{
    button{
      margin-bottom: 10px;
      &:not(:last-child){
        margin-right: 10px;
      }
      &:first-child{
        background-color: #3FD787;
      }
    }
  }
}
:deep(.ant-table-wrapper .ant-table-thead >tr>th){
  background-color: #F9FCFF;
}
.editable-row-operations a {
  margin-right: 8px;
}
</style>
