import {jsonRPC} from "@/utils/http_utils";
import {logDebug, logError} from "@/utils/logger";

export function trackUV(currentRoute) {
    try {
        const path = currentRoute.value.path
        const currentPath = path || window.location.pathname;
        const getUserID = function () {
            const userID = sessionStorage.getItem('user_id');
            return userID || 'guest';  // 如果没有用户 ID，则使用 'guest' 作为标识
        }

        // 获取今日日期，格式：YYYY-MM-DD
        const getCurrentDate = function () {
            const today = new Date();
            return today.toISOString().split('T')[0];
        }

        // 拼接存储的 key
        const getStorageKey = function (userID, currentDate) {
            return `UV_${userID}_${currentDate}`;
        }

        // 发送 UV 数据统计请求
        const sendUVData = function () {
            const userID = getUserID();  // 获取当前用户 ID
            jsonRPC({
                url: "/api/user/view",
                params: {
                    page_url: currentPath,
                    user_id: userID,
                },
                success(response) {
                    logDebug('UV 数据统计成功:', response);
                },
                fail(error) {
                    logDebug('UV 数据统计失败', error);
                },
            });
        }


        const updateUV = function () {

            const userID = getUserID();
            const currentDate = getCurrentDate();
            const storageKey = getStorageKey(userID, currentDate);

            // 检查 localStorage 是否可用
            if (typeof (Storage) !== "undefined") {
                try {
                    const lastTrackedDate = localStorage.getItem(storageKey);

                    if (!lastTrackedDate) {
                        sendUVData();
                        const dataToStore = {date: currentDate};  // 只存储简单的数据
                        localStorage.setItem(storageKey, JSON.stringify(dataToStore)); // 存储数据
                    }
                } catch (e) {
                    logError('无法访问 localStorage:', e.message);
                }
            } else {
                logError('浏览器不支持 localStorage');
            }
        }


        // 页面加载完成后执行 UV 更新
        if (document.readyState === 'loading') {
            document.addEventListener('DOMContentLoaded', updateUV);
        } else {
            updateUV();
        }
    } catch (e) {
        logError("trackUV failed")
    }
}

let lastPageClickTime = Date.now(); // 记录页面加载时的时间戳

export function trackPage(currentRoute) {

    try {
        const path = currentRoute.value.path
        const currentPath = path || window.location.pathname;  // 如果没有传递 path，默认使用当前页面的路径

        // 发送 PV 数据
        const sendPVData = function () {
            const now = Date.now();
            const stayTime = (now - lastPageClickTime) / 1000; // 计算停留时间（秒）
            lastPageClickTime = now
            logDebug("发送 PV 数据");

            jsonRPC({
                url: "/api/hw/page",
                params: {
                    page_url: currentPath,
                    stay_time: stayTime, // 上报停留时间
                },
                success(response) {
                    logDebug('PV 数据统计成功:', response);
                },
                fail(error) {
                    logDebug('PV 数据统计成功失败', error);
                },
            });
        }

        sendPVData()
    } catch (e) {
        logError("trackPage failed")
    }

}
