<template>
  <div class="student_detail">
    <a-breadcrumb separator=">">
      <a-breadcrumb-item><router-link to="/console/eduAdmin">教务管理</router-link></a-breadcrumb-item>
      <a-breadcrumb-item>{{ data_list.name }}</a-breadcrumb-item>
    </a-breadcrumb>
    <div class="info">
      <a-flex justify="space-between" align="center">
        <h4>学生基本信息</h4>
        <div>
          <a-button type="primary" v-if="editorMode == 'readonly'" @click="editorMode = 'update'">编辑</a-button>
          <a-button type="primary" v-if="editorMode == 'update'" @click="confirmFormUpdate">保存</a-button>
        </div>
      </a-flex>
      <a-form ref="formRef" :model="data_list" :label-col="{ span: 6 }" :wrapper-col="{ span: 14 }" :disabled="editorMode == 'readonly'">
        <a-row :gutter="[16,16]">
          <a-col :span="24" :md="7">
            <a-form-item label="照片" name="file_list" :rules="[{ required: true, message: '请上传照片!' }]">
              <img v-if="editorMode == 'readonly'" :src="`/web/image/res.partner/${student_id}/image_1920?t=${new Date().getTime()}`"/>
              <template v-if="editorMode == 'update'">
                <a-upload
                    v-model:file-list="data_list.file_list"
                    :customRequest="uploadDummyRequest"
                    list-type="picture-card"
                    @preview="handlePreview"
                    :max-count="1"
                    accept=".png,.jpg,.jpeg"
                >
                  <div v-if="data_list.file_list.length < 1">
                    <plus-outlined/>
                    <div class="ant-upload-text">上传图片</div>
                  </div>
                </a-upload>
                <a-modal :open="previewVisible" :title="previewTitle" :footer="null" @cancel="cancelPreview">
                  <img alt="example" style="width: 100%" :src="previewImage"/>
                </a-modal>
              </template>
            </a-form-item>
          </a-col>
          <a-col :span="24" :md="8">
            <a-form-item label="学生编号" name="student_barcode" :rules="[{ required: true, message: '学生编号不能为空!' }]">
              <a-input v-model:value="data_list.student_barcode" />
            </a-form-item>
            <a-form-item label="学生姓名" name="name" :rules="[{ required: true, message: '学生姓名不能为空!' }]">
              <a-input v-model:value="data_list.name" />
            </a-form-item>
            <a-form-item label="性别" name="sex" :rules="[{ required: true, message: '请选择性别!' }]">
              <a-select v-model:value="data_list.sex" :allowClear="true"
                show-search :options="data_list.sex_ids" :filter-option="filterOption">
              </a-select>
            </a-form-item>
            <a-form-item label="手机号" name="phone" :rules="[{ required: true, message: '手机号不能为空!' }, { pattern: /^1[3-9]\d{9}$/, message: '请输入有效的手机号!' }]">
              <a-input v-model:value="data_list.phone" />
            </a-form-item>
            <a-form-item label="就读院校" name="school" :rules="[{ required: true, message: '请选择就读院校!' }]">
              <a-select v-model:value="data_list.school" :allowClear="true"
                show-search :options="data_list.school_ids" :filter-option="filterOption">
              </a-select>
            </a-form-item>
            <a-form-item label="就读专业" name="major" :rules="[{ required: true, message: '请选择就读专业!' }]">
              <a-select v-model:value="data_list.major" :allowClear="true"
                show-search :options="data_list.major_ids" :filter-option="filterOption">
              </a-select>
            </a-form-item>
          </a-col>
          <a-col :span="24" :md="9">
            <!-- <a-form-item label="能力雷达">
              <div class="radarChart"><canvas id="radarChart"></canvas></div>
            </a-form-item> -->
          </a-col>
        </a-row>
      </a-form>
      <h4>学习内容</h4>
      <a-flex align="center" gap="small" class="mb-2">
        <!-- 学期：<a-select v-model:value="data_list.semester_id" :allowClear="true" style="width: 200px;"
                show-search :options="data_list.semester_options" :filter-option="filterOption">
              </a-select>
        班级：<a-select v-model:value="data_list.class_id" :allowClear="true" style="width: 200px;"
                show-search :options="data_list.class_options" :filter-option="filterOption">
              </a-select> -->
        <!-- <a-button type="primary" style="background-color: #3FD787;">下载成绩单</a-button>
        <a-button type="primary" style="background-color: #3FD787;">下载签到表</a-button> -->
      </a-flex>
      <a-table :columns="columns" :data-source="content_ids" :pagination="false" :scroll="{ x: '100vw', y: '40vh' }">
        <template #customFilterDropdown="{ setSelectedKeys, selectedKeys, confirm, clearFilters, column }">
          <div style="padding: 8px">
            <a-input
              ref="searchInput"
              :placeholder="`搜索 ${column.dataIndex}`"
              :value="selectedKeys[0]"
              style="width: 188px; margin-bottom: 8px; display: block"
              @change="e => setSelectedKeys(e.target.value ? [e.target.value] : [])"
              @pressEnter="handleSearch(selectedKeys, confirm, column.dataIndex)"
            />
            <a-button
              type="primary"
              size="small"
              style="width: 90px; margin-right: 8px"
              @click="handleSearch(selectedKeys, confirm, column.dataIndex)"
            >
              <template #icon><SearchOutlined /></template>
              搜索
            </a-button>
            <a-button size="small" style="width: 90px" @click="handleReset(clearFilters)">
              重置
            </a-button>
          </div>
        </template>
      </a-table>
    </div>
  </div>
</template>

<script setup>
import {logDebug, logError} from "@/utils/logger";
import {onMounted, ref, reactive } from 'vue'
import {jsonRPC, getResponseData} from "@/utils/http_utils";
import { Chart, RadialLinearScale, PointElement, LineElement, Filler, Tooltip, Legend, RadarController } from 'chart.js';
Chart.register(RadialLinearScale, PointElement, LineElement, Filler, Tooltip, Legend, RadarController);
import {newCancelPreview, newHandlePreview, newPreviewState, newUploadDummyRequest,getBase64} from "@/utils/file_utils";
import {resolveEditorMode, setEditorMode} from "@/utils/form_utils";
import { SearchOutlined } from '@ant-design/icons-vue';
import { getRouterQuery } from "@/utils/router_utils";
import { useRouter } from "vue-router";
const router = useRouter();
const student_id = ref(getRouterQuery(router, "id"));

const editorMode = ref('readonly')
resolveEditorMode(editorMode, student_id.value, getRouterQuery(router, 'mode'))
const data_list = reactive({
  searchText: '',
  searchedColumn: '',
  sex_ids: [],
  school_ids: [],
  major_ids: [],
  is_image: false,
  file_list: [],
  student_barcode: '',
  name: '',
  sex: '',
  phone: '',
  school: '',
  major: '',
  semester_id: '',
  semester_options: [],
  class_id: '',
  class_options: [],
});
const uploadDummyRequest = newUploadDummyRequest()
const {previewVisible, previewImage, previewTitle} = newPreviewState();
const cancelPreview = newCancelPreview(previewVisible, previewTitle)
const handlePreview = newHandlePreview(previewImage, previewVisible, previewTitle)
onMounted(()=>{
  fetchData()
  fetchContentData();
  // // 获取 canvas 元素
  // const ctx = document.getElementById('radarChart').getContext('2d');
  // // 初始化雷达图
  // new Chart(ctx, {
  //   type: 'radar',
  //   data: {
  //     labels: ['创新力', '认知力', '领导力', '学习力', '协作力'],
  //     datasets: [{
  //       data: [65, 59, 90, 81, 56],
  //       backgroundColor: 'rgba(54, 162, 235, 0.2)',
  //       borderColor: 'rgba(54, 162, 235, 1)',
  //       borderWidth: 1
  //     }]
  //   },
  //   options: {
  //     scales: {
  //       r: {
  //         beginAtZero: true
  //       }
  //     },
  //     plugins: {
  //       legend: {
  //         display: false  // 隐藏图例
  //       },
  //       tooltip: {
  //         callbacks: {
  //           label: (context) => context.raw  // 只显示数据值
  //         }
  //       }
  //     }
  //   }
  // });
});
function fetchData() {
  jsonRPC({
    url: `/vue/console/eduAdmin/student/detail`,
    params:{
      id: student_id.value,
    },
    success(res) {
      const data = getResponseData(res);
      logDebug(`获取成功需求数据成功`, data);
      Object.assign(data_list, data);
    },
    fail(error) {
      logError(`查询失败, `, error);
    },
  })
}
const filterOption = (input, option) => {
  return option.label.toLowerCase().indexOf(input.toLowerCase()) >= 0;
};
const formRef = ref();
// 保存
const confirmFormUpdate = () => {
  formRef.value.validate()
  .then(async () => {
    const filePromises = await Promise.all(data_list.file_list.map(async file => {
      const base64Data = await getBase64(file.originFileObj);
      return {
          ...file,
          raw_data: base64Data,
      };
    }));
    await jsonRPC({
      url: `/vue/console/student/write`,
      params: {
        id: student_id.value,
        file_list: filePromises,
        student_barcode: data_list.student_barcode,
        name: data_list.name,
        sex: data_list.sex,
        phone: data_list.phone,
        school: data_list.school,
        major: data_list.major,
      },
      success(res) {
        const data = getResponseData(res);
        logDebug(`保存成功`, data);
        setEditorMode(editorMode, 'readonly')
      },
      fail(error) {
        logError(`保存失败, `, error)
      },
    });
    return
  })
  .catch(error => {
    logDebug('error', error);
    return
  });
};
const handleSearch = (selectedKeys, confirm, dataIndex) => {
  confirm();
  data_list.searchText = selectedKeys[0];
  data_list.searchedColumn = dataIndex;
};
const handleReset = clearFilters => {
  clearFilters({
    confirm: true,
  });
  data_list.searchText = '';
};
const searchInput = ref();
const columns = [
  {
    title: '内容类型',
    dataIndex: 'content_type',
    align: 'center',
    width: 130,
    filters: [
      {
        text: '班级课程',
        value: '班级课程',
      },
      {
        text: '课程',
        value: '课程',
      },
      {
        text: '培训',
        value: '培训',
      },
      {
        text: '认证',
        value: '认证',
      },
    ],
    onFilter: (value, record) => record.content_type && record.content_type.indexOf(value) === 0,
    fixed: 'left',
  },
  {
    title: '内容名称',
    dataIndex: 'content_name',
    align: 'center',
    width: 140,
    customFilterDropdown: true,
    onFilter: (value, record) =>record.content_name.toString().toLowerCase().includes(value.toLowerCase()),
    onFilterDropdownOpenChange: visible => {
      if (visible) {
        setTimeout(() => {
          searchInput.value.focus();
        }, 100);
      }
    },
    fixed: 'left',
  },
  {
    title: '所属学期',
    dataIndex: 'semester',
    align: 'center',
  },
  {
    title: '作业情况',
    dataIndex: 'homework',
    align: 'center',
  },
  {
    title: '实验报告',
    dataIndex: 'report',
    align: 'center',
  },
  {
    title: '签到考勤',
    dataIndex: 'attendance',
    align: 'center',
  },
  {
    title: '应用环境',
    dataIndex: 'environment',
    align: 'center',
  },
  {
    title: '平时成绩',
    dataIndex: 'usual_score',
    align: 'center',
  },
  {
    title: '考试成绩',
    dataIndex: 'exam_score',
    align: 'center',
  },
  {
    title: '学期成绩',
    dataIndex: 'semester_total_score',
    align: 'center',
  },
];
const content_ids = ref([])
async function fetchContentData() {
  await jsonRPC({
    url: `/vue/console/eduAdmin/student/content`,
    params:{
      id: Number(student_id.value),
    },
    success(res) {
      const data = getResponseData(res);
      logDebug(`获取成功需求数据成功`, data);
      content_ids.value = data.records;
    },
    fail(error) {
      logError(`查询失败, `, error);
    },
  })
}
</script>

<style scoped lang="scss">
.student_detail{
  height: 100%;
  width: 100%;
  overflow: auto;
  padding: 16px;
  .info{
    background-color: #fff;
    padding: 25px 40px;
    h4{
      display: inline-block;
      font-size: 18px;
      padding-bottom: 5px;
      padding-right: 5vw;
      border-bottom: 2px solid #63B1FF;
    }
    img{
      width: 100%;
      max-width: 240px;
      max-height: 240px;
    }
    .radarChart{
      height: 0;
      padding-bottom: 100%;
    }
  }
}
:deep(.ant-table-wrapper .ant-table-thead >tr>th){
  background-color: #F9FCFF;
}
:deep(.ant-form-item){
  margin-bottom: 2px;
}
</style>
