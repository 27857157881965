<template>
  <div id="template">
    <a-row style="margin: 10px 0">
      <a-col :span="16"></a-col>
      <a-col :span="8" style="display: flex; flex-direction: row; justify-content: space-around">
        <a-input-search placeholder="请输入名称" style="width: 200px" enter-button allowClear @search="onSearch"/>
      </a-col>
    </a-row>
    <div class="content">
      <div v-for="row in data_list" :key="row.id" class="row mx-0">
        <div class="col-md-3">
          <div class="img_box">
            <img :src="row.image_url"/>
          </div>
        </div>
        <div class="col-md-4">
          <h5 class="line-clamp-1">{{ row.name }}</h5>
          <!--<div class="d-flex text-muted">-->
          <!--<div title="环境数量">-->
          <!--<img src="/hw_web/static/src/img/case_questions.png" height="20" wigth="20"-->
          <!--style="margin-right: 5px;"/>-->
          <!--<span>5</span>-->
          <!--</div>-->
          <!--<div title="查看次数">-->
          <!--<img src="/hw_web/static/src/img/case_study.png" height="20" wigth="20"-->
          <!--style="margin-left: 10px;margin-right: 5px;"/>-->
          <!--<span><t t-esc="row.view_nums"/></span>-->
          <!--</div>-->
          <!--</div>-->
          <p v-html="row.description"></p>
        </div>
        <div class="col-md-5">
          <!--<span>IDE</span>-->
          <div class="d-flex">
            <a v-if="row.publish" class="btn btn-primary" style="display: none"
               @click="_onPublishApp(row.id, 'false')">取消发布</a>
            <a v-else class="btn btn-primary" style="display:none;"
               @click="_onPublishApp(row.id, 'true')">发布</a>
            <a class="btn btn-primary" @click="_onCreateDeployment(row.template_id)">部署应用</a>
            <button v-if="row.is_delete" class="btn btn-secondary" @click="_onDeleteApp(row.id)">删除模板</button>
          </div>
        </div>
        <!--<span v-if="row.publish">已发布</span>-->
        <!--<span v-else>未发布</span>-->
      </div>
      <a-modal v-model:open="openCreateAppModal" :confirm-loading="confirmCreateAppLoading" 
        title="创建应用" @ok="confirmCreateApp" @cancel="cancelCreateApp" width="60%">
        <a-form ref="formRef" :model="createAppForm" :rules="rules" :label-col="labelCol" 
          :wrapper-col="wrapperCol" layout="horizontal" @submit="confirmCreateApp">
          <a-form-item label="应用名称" name="app_name">
            <a-input v-model:value="createAppForm.app_name"/>
          </a-form-item>
          <a-form-item label="空间类型" name="ns_type">
            <a-select v-model:value="createAppForm.ns_type" :disabled="createAppForm.disable_namespace_selection">
              <a-select-option v-for="item in createAppForm.ns_types" :key="item[0]" :value="item[0]">
                {{ item[1] }}
              </a-select-option>
            </a-select>
          </a-form-item>
          <a-form-item label="命名空间" name="namespace_id">
            <a-select v-model:value="createAppForm.namespace_id" :disabled="createAppForm.disable_namespace_selection">
              <template v-if="createAppForm.ns_type == 'private'">
                <a-select-option v-for="item in createAppForm.private_namespaces" :key="item.id" :value="item.id">
                  {{ item.name }}
                </a-select-option>
              </template>
              <a-select-option v-else v-for="item in createAppForm.public_namespaces" :key="item.id" :value="item.id">
                {{ item.name }}
              </a-select-option>
            </a-select>
          </a-form-item>
          <a-form-item label="部署方式" name="deployment_method">
            <a-select v-model:value="createAppForm.deployment_method">
              <a-select-option value="consumer_compute_power">消耗算力点先用后结</a-select-option>
              <!-- <a-select-option value="use_resource">使用现有资源</a-select-option> -->
            </a-select>
          </a-form-item>
          <template v-if="createAppForm.deployment_method=='consumer_compute_power'">
            <a-form-item label="算力服务" name="compute_power_service">
              <a-radio-group v-model:value="createAppForm.compute_power_service" button-style="solid">
                <a-radio-button v-if="createAppForm.capacity_type_level.base?.length>0" value="base">初级</a-radio-button>
                <a-radio-button v-if="createAppForm.capacity_type_level.middle?.length>0" value="middle">中级</a-radio-button>
                <a-radio-button v-if="createAppForm.capacity_type_level.high?.length>0" value="high">高级</a-radio-button>
              </a-radio-group>
            </a-form-item>
            <div>
              服务配置：
              <ul v-if="createAppForm.compute_power_service">
                <li v-for="item in createAppForm.capacity_type_level[createAppForm.compute_power_service]" :key="item">
                  <a-flex justify="space-between" align="center">
                    <div>
                      <div>{{item.template_inner_service_name}}:</div>
                      <a-flex :gap="15">
                        <span v-for="op in item.capacity.capacity_type_op" :key="op">
                          {{ op.hardware_id.hardware_type }}: {{op.use_number}}
                          <template v-if="op.hardware_id.hardware_unit">{{op.hardware_id.hardware_unit}}</template><br/>
                          <template v-if="op.hardware_id.hardware_name">({{op.hardware_id.hardware_name}})</template>
                        </span>
                      </a-flex>
                    </div>
                    <div style="color: #a0c8ff;white-space: nowrap;">
                      {{item.capacity.capacity_type_op_sum_point}} 算力/{{displayUnit(item.capacity.unit_op)}}</div>
                  </a-flex>
                </li>
              </ul>
            </div>
            <div style="border-top: 3px solid #2580fd;"></div>
            <a-flex justify="space-between" align="center" class="my-3">
              <div style="font-size: 16px;">以上配置当前环境算力点总计：</div>
              <span style="color: #FF5F5F;font-size: 16px;">{{sum_point}} 算力/{{sum_point_unit}}</span>
            </a-flex>
          </template>
        </a-form>
      </a-modal>
    </div>
    <a-flex justify="center" class="py-3">
      <a-pagination
          v-model:current="current"
          :showSizeChanger="showSizeChanger"
          v-model:page-size="pageSize"
          :total="total"
          :locale="zhCn"
          :pageSizeOptions="pageSizeOptions"
          :hideOnSinglePage="true"
      />
    </a-flex>
  </div>
</template>

<script setup>
import {logDebug, logError} from "@/utils/logger";
import {onMounted, reactive, ref, watch, computed} from "vue";
import {routerReplace} from "@/utils/router_utils";
import {getFailedMessage, getResponseData, jsonRPC} from "@/utils/http_utils";
import {zhCn} from "@/utils/zhCn";
import {useRouter} from "vue-router";
import {message, Modal} from "ant-design-vue";

logDebug("ConsoleAppTemplate setup.");

const router = useRouter();

const data_list = ref([]);
const total = ref(0);
const showSizeChanger = ref(true);
const pageSize = ref(4);
const current = ref(1);
const search = ref("");
const pageSizeOptions = ref(
    Array.from({length: 4}, (_, index) =>
        (pageSize.value * (index + 1)).toString()
    )
);

function fetchData() {
  jsonRPC({
    url: "/vue/console/app/template",
    params: {
      page_index: current.value,
      page_size: pageSize.value,
      search: search.value,
    },
    success(res) {
      logDebug(`查询成功`, res.data.result.data);
      data_list.value = res.data.result.data.records;
      for (let index = 0; index < data_list.value.length; index++) {
        const element = data_list.value[index];
        element.capacity_type_level = JSON.parse(element.capacity_type_level)
      }
      total.value = res.data.result.data.record_count;
    },
    fail(error) {
      logError(`查询失败, `, error);
    },
  });
}

onMounted(() => {
  fetchData();
  fetchCreateDeploymentData()
});
watch([pageSize, current], fetchData);

const onSearch = (searchValue) => {
  if (search.value != searchValue) {
    current.value = 1;
    search.value = searchValue;
    fetchData();
  }
};

let _onPublishApp = (app_id, published) => {
  jsonRPC({
    url: "/vue/console/app/template/publish",
    params: {
      app_id: app_id,
      published: `${published}`.toLowerCase() === 'true',
    },
    success(res) {
      logDebug(`查询成功`, res.data.result);
      fetchData();
    },
    fail(error) {
      logError(`查询失败, `, error);
    },
  });
}
const formRef = ref();
const rules = {
  app_name: [
    {
      required: true,
      message: '请输入应用名称',
    },
  ],
  ns_type: [
    {
      required: true,
      message: '请选择空间类型',
      trigger: 'change',
    },
  ],
  namespace_id: [
    {
      required: true,
      message: '请选择命名空间',
      trigger: 'change',
    },
  ],
  deployment_method: [
    {
      required: true,
      message: '部署方式',
      trigger: 'change',
    },
  ],
  compute_power_service: [
    {
      required: true,
      message: '算力服务',
    },
  ],
}
const openCreateAppModal = ref(false);
const confirmCreateAppLoading = ref(false);
const createAppForm = reactive({
  app_name: "",
  ns_type: "",
  namespace_id: "",
  app_id: "",
  template_id: "",
  disable_namespace_selection: false,
  capacity_type_level: {},
  deployment_method: 'consumer_compute_power',
  compute_power_service: 'base',
});

watch(() => createAppForm.ns_type, function (newValue, oldValue) {
  logDebug(`createAppForm.ns_type发生了变化: ${oldValue} -> ${newValue}`);
  if (oldValue !== "") {
    createAppForm.namespace_id = ""
  }
})

function fetchCreateDeploymentData() {
  jsonRPC({
    url: "/vue/console/experiment/deployment/create/setting",
    params: {},
    success(res) {
      logDebug(`查询成功`, res.data.result.data);
      Object.assign(createAppForm, res.data.result.data);
    },
    fail(error) {
      logError(`查询失败, `, error);
    },
  });
}

watch(() => createAppForm.ns_type, function () {
  logDebug(`createAppForm.ns_type[${createAppForm.ns_type}]`)
  if (createAppForm.ns_type === 'private') {
    if (createAppForm['private_namespaces'] != null && typeof createAppForm['private_namespaces'] !== "undefined" &&
        createAppForm['private_namespaces'].length >= 1) {
      createAppForm.namespace_id = createAppForm['private_namespaces'][0].id
    } else {
      createAppForm.namespace_id = false
    }
  } else {
    if (createAppForm['public_namespaces'] != null && typeof createAppForm['public_namespaces'] !== "undefined" &&
        createAppForm['public_namespaces'].length >= 1) {
      createAppForm.namespace_id = createAppForm['public_namespaces'][0].id
    } else {
      createAppForm.namespace_id = false
    }
  }
})

const _onCreateDeployment = (template_id) => {
  createAppForm.template_id = template_id
  createAppForm.disable_namespace_selection = false
  if (createAppForm['ns_types'] != null && typeof createAppForm['ns_types'] !== "undefined" &&
      createAppForm['ns_types'].length >= 1) {
    createAppForm.ns_type = createAppForm['ns_types'][0][0]
  }
  if (createAppForm.ns_type === 'private') {
    if (createAppForm['private_namespaces'] != null && typeof createAppForm['private_namespaces'] !== "undefined" &&
        createAppForm['private_namespaces'].length >= 1) {
      createAppForm.namespace_id = createAppForm['private_namespaces'][0].id
    }
  } else {
    if (createAppForm['public_namespaces'] != null && typeof createAppForm['public_namespaces'] !== "undefined" &&
        createAppForm['public_namespaces'].length >= 1) {
      createAppForm.namespace_id = createAppForm['public_namespaces'][0].id
    }
  }
  openCreateAppModal.value = true;
  const item = data_list.value.find(obj => obj.template_id == template_id);
  if (item) {
    createAppForm.capacity_type_level = item.capacity_type_level
  }
};
const cancelCreateApp = () => {
  createAppForm.app_name = ""
  createAppForm.ns_type = ""
  createAppForm.namespace_id = ""
  createAppForm.disable_namespace_selection = false
}
const sum_point = computed(() => {
  let sum = 0;
  const ser = createAppForm.compute_power_service;
  for (const x of createAppForm.capacity_type_level[ser] || []) {
    sum += x.capacity.capacity_type_op_sum_point;
  }
  return sum;
});
const unitMap = {
  hours: '小时',
  day: '天',
  month: '月'
};
const displayUnit = (e) => {
  return unitMap[e] || '小时';
}
const sum_point_unit = computed(() => {
  let sum = '小时';
  const ser = createAppForm.compute_power_service;
  for (const x of createAppForm.capacity_type_level[ser] || []) {
    if (unitMap[x.capacity.unit_op]) {
      sum = unitMap[x.capacity.unit_op];
      break;
    }
  }
  return sum;
});
const confirmCreateApp = () => {
  formRef.value.validate()
  .then(() => {
    confirmCreateAppLoading.value = true
    jsonRPC({
      url: "/vue/console/experiment/deployment/create",
      params: {
        name: createAppForm.app_name,
        template_id: createAppForm.template_id,
        namespace_id: createAppForm.namespace_id,
        is_compute_point: sum_point.value > 0,
        capacity_type_level: createAppForm.compute_power_service,
        need_deduct_compute_point: sum_point.value,
        capacity_unit_option: createAppForm.capacity_type_level[createAppForm.compute_power_service]?.[0]?.capacity?.unit_op,
      },
      success(res) {
        const data = getResponseData(res)
        logDebug(`创建实验环境成功`, data);
        message.success(`创建实验环境成功`)
        setTimeout(()=>{
          jsonRPC({
            url: "/vue/template/used_nums",
            params: {
              template_id: createAppForm.template_id,
            },
            success() {
              logDebug(`模板使用次数增加成功`);
            },
            fail(error) {
              logError(`模板使用次数增加失败, `, error);
            },
          });
        },10*1000)
      },
      fail(error) {
        try {
          try {
            let errMsg = JSON.parse(error);
            let user = errMsg.user || '';
            let message = errMsg.message || '资源不足';
            let details = errMsg.details || '';
            Modal.error({
              title: "创建实验环境失败",
              content: (
                <div>
                  <div>{user}</div>
                  <div style={{ textIndent: '2em' }}>{message}</div>
                  <div>{details}</div>
                </div>
              ),
              width: '60%', // 设置宽度为 60%
              closable: true, // 右上角关闭按钮
              onCancel: () => {
                Modal.destroyAll(); // 点击关闭按钮时，关闭模态框
              },
              footer: () => (
                <div class="d-flex justify-content-center my-3">
                  <button type="button" class="btn btn-primary" onClick={async () => {
                      router.push('/cost/topup')
                      Modal.destroyAll(); // 关闭所有弹窗
                    }}>补充资源</button>
                </div>
              ),
            });
          } catch (err) {
            logError("查询失败: 无法解析错误信息", err);
            Modal.error({
              title: "创建实验环境失败",
              content: (
                <div style={{ textIndent: '2em' }}>{JSON.stringify(getFailedMessage(error))}</div>
              ),
              width: '60%', // 设置宽度为 60%
              closable: true, // 右上角关闭按钮
              onCancel: () => {
                Modal.destroyAll(); // 点击关闭按钮时，关闭模态框
              },
              footer: () => (
                <div class="d-flex justify-content-center my-3">
                  <button type="button" class="btn btn-primary" onClick={() => {
                      Modal.destroyAll(); // 关闭所有弹窗
                    }}>关闭</button>
                </div>
              ),
            });
          }
        } catch (er) {
          logError("部署创建过程中发生未捕获的错误：", er);
          message.error('部署创建失败，请稍后重试。')
        }
      },
    }).then(function () {
      openCreateAppModal.value = false;
      confirmCreateAppLoading.value = false;
      routerReplace(router, '/console/app')
    });
  })
  .catch(error => {
    logDebug('error', error);
  });
};
const _onDeleteApp = (app_id) => {
  if (confirm("确定要删除当前模板吗？")) {
    jsonRPC({
      url: "/vue/console/app/template/delete",
      params: {
        app_id: app_id,
      },
      success(res) {
        logDebug(`查询成功`, res.data.result);
        fetchData();
      },
      fail(error) {
        logError(`查询失败, `, error);
      },
    });
  }
};
</script>

<style scoped lang="scss">
.content {
  .row {
    > div {
      padding: 0;
    }

    position: relative;
    background-color: #fff;
    border-radius: 8px;
    padding: 15px 20px;
    overflow: hidden;
    margin-bottom: 15px;

    > span {
      position: absolute;
      left: 0;
      top: 0;
      background-color: #82b1ff;
      height: 35px;
      line-height: 35px;
      width: 100px;
      border-radius: 8px 0px 8px 0px;
      text-align: center;
      color: #fff;
    }

    .col-md-4 {
      padding-left: 10px;

      h5,
      .text-muted {
        margin-bottom: 10px;
      }

      p {
        color: #939393;
        margin-bottom: 0;
      }
    }

    .col-md-5 {
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      align-items: flex-end;

      span {
        display: inline-block;
        height: 20px;
        width: 50px;
        border-radius: 8px;
        background-color: #9dcbff;
        color: #fff;
        text-align: center;
      }

      .btn {
        margin-left: 10px;
        font-size: 16px;
        width: 100px;
        height: 40px;
        border-radius: 8px;
      }

      .btn-secondary {
        background-color: #d5d5d5;
        border-color: #d5d5d5;
      }
    }
  }
}
:where(.ant-radio-button-wrapper){
  height: 30px;
  min-width: 80px;
  line-height: 28px;
  border-radius: 4px;
  margin: 0 5px;
  border-inline-start-width: 1px;
  text-align: center;
  &:not(:first-child)::before{
    content: none
  }
  &:first-child{
    border-start-start-radius: 4px; 
    border-end-start-radius: 4px;
  }
  &:last-child{
    border-start-end-radius: 4px;
    border-end-end-radius: 4px;
  }
}
</style>
