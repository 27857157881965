<template>
  <div class="container job_detail" ref="selectContainer">
    <a-breadcrumb separator=">">
      <a-breadcrumb-item>
        <router-link to="/job">校园招聘</router-link>
      </a-breadcrumb-item>
      <a-breadcrumb-item>{{ data_list.name }}</a-breadcrumb-item>
    </a-breadcrumb>
    <div class="row">
      <div class="col-md-8">
        <div class="office">
          <div>
            <h4>
              {{ data_list.name }}
              <span v-for="skill in data_list.skill_ids" :key="skill.id">
                <span>{{ skill.name }}</span>
              </span>
            </h4>
            <h4 class="wage">
              {{ (data_list.salary_minimum / 10000).toFixed(2) }} - {{ (data_list.salary_maximum / 10000).toFixed(2) }}万
            </h4>
          </div>
          <div>
            <div>
              <p>
                <span v-if="data_list.city_id">{{ data_list.city_id }} - {{ data_list.county_id }}</span>
                <span v-if="data_list.experience_id">{{data_list.experience_id}}</span>
                <span v-if="data_list.education_id">{{data_list.education_id}}</span>
                <span v-if="data_list.age_limit">{{ data_list.age_limit }}</span>
              </p>
              <div class="welfare">
                <span v-for="welfare in data_list.welfare_ids" :key="welfare.id">{{ welfare.name }}</span>
              </div>
            </div>
            <div>
              <button v-if="data_list.applied" class="btn" disabled>已投递</button>
              <button v-else class="btn" @click="showModal = true">投递简历</button>
            </div>
          </div>
        </div>
        <div class="info">
          <h5>职位信息</h5>
          <div v-html="data_list.responsibility"></div>
          <h5>任职资格</h5>
          <div v-html="data_list.qualification"></div>
          <h5>工作地址</h5>
          <div v-html="data_list.work_address"></div>
          <h5>单位信息</h5>
          <div v-html="data_list.company_details"></div>
        </div>
      </div>
      <div class="col-md-4">
        <div class="company" v-if="data_list.company_id">
          <h5>
            <img v-if="data_list.company_id.is_logo" :src="`/web/image/hw.job.recruit.company/${data_list.company_id.id}/logo`"/>
            <img v-else src="/hw_web/static/src/img/company_logo.png" />
            {{ data_list.company_id.name }}
          </h5>
          <div>
            <span v-if="data_list.company_id.company_type">
              <img src="/hw_web/static/src/img/company_type.png" height="18" width="18">
              {{data_list.company_id.company_type}}
            </span>
            <span v-if="data_list.company_id.company_size">
              <img src="/hw_web/static/src/img/company_people.png" height="20" width="20">
              {{data_list.company_id.company_size}}
            </span>
            <span v-if="data_list.company_id.industry_str">
              <img src="/hw_web/static/src/img/company_operate.png" height="26" width="26">
              {{data_list.company_id.industry_str}}
            </span>
            <span v-for="category in data_list.company_business" :key="category.id">
              <img src="/hw_web/static/src/img/company_environment.png" height="18" width="18">
              {{ category.name }}
            </span>
          </div>
          <p>在招职位：<span>{{ data_list.company_id.job_num }}</span>个</p>
          <router-link :to="`/job/hiring/${data_list.company_id.id}`" class="form-control">
            <div><img src="/hw_web/static/src/img/position.png"></div>
            查看所有岗位
          </router-link>
        </div>
        <div class="contact" v-if="data_list.principal">
          <h5>职位联系人</h5>
          <div>
            <div class="d-flex">
              <div class="avatar">
                <img v-if="data_list.principal.image" :src="data_list.principal.image"/>
                <img v-else src="/hw_web/static/src/img/avatar_example.png"/>
              </div>
              <div>
                <p>{{ data_list.principal.name }}</p>
                <span>HRBP</span>
                <span v-if="data_list.principal.isActive">现在活跃</span>
                <span v-else>离线</span>
              </div>
            </div>
            <div v-if="data_list.principal.isActive">
              <a :href="`/hw_chat?active_id=${data_list.principal.channelId}`" class="btn" target="_blank">
                立即沟通
              </a>
            </div>
          </div>
        </div>
        <div class="similar_jobs">
          <h5>相似职位</h5>
          <div v-for="similarJob in data_list.resemble_recruit" :key="similarJob.id">
            <h5>
              <router-link :to="`/job/${similarJob.id}`">{{ similarJob.name }}</router-link>
              <span v-for="skill in similarJob.skill_ids" :key="skill.id">{{skill.name}}</span>
            </h5>
            <div>
              <p>{{ similarJob.company_id }}</p>
              <p class="wage">
                {{ (similarJob.salary_minimum / 10000).toFixed(2) }} - {{ (similarJob.salary_maximum / 10000).toFixed(2) }}万
              </p>
            </div>
            <p>{{ similarJob.city_id }} - {{ similarJob.county_id }}</p>
          </div>
        </div>
      </div>
    </div>
    <a-modal v-model:visible="showModal" title="投递简历" @ok="handleSubmit" :getContainer="()=>$refs.selectContainer">
      <template #footer>
        <a-button type="primary" :icon="h(PlusOutlined)" @click="newResume" class="float-left">新建简历</a-button>
        <a-button @click="showModal = false">取消</a-button>
        <a-button type="primary" @click="handleSubmit">确认投递</a-button>
      </template>
      <div class="modal-body">
        <a-form ref="formRef" :model="data_list" layout="horizontal" @submit="handleSubmit">
          <a-form-item label="简历模板" name="resume_id" :rules="[{ required: true, message: '请选择简历模板', trigger: 'change'}]">
            <a-select v-model:value="data_list.resume_id" :allowClear="true"
                      show-search :options="data_list.resume_ids" :filter-option="filterOption">
            </a-select>
          </a-form-item>
        </a-form>
      </div>
    </a-modal>
    <a-modal v-model:open="open" title="新建简历" width="60vw" @ok="handleOk">
      <a-form ref="resumeFormRef" :model="formState" :label-col="{style: {width: '100px'} }" :wrapper-col="{span: 20}" @submit="handleOk">
        <a-form-item label="简历名称" name="name" :rules="[{ required: true, message: '请输入简历名称!' }]">
          <a-input v-model:value="formState.name" />
        </a-form-item>
        <a-form-item label="岗位意向" name="job_intention" :rules="[{ required: true, message: '请输入岗位意向!' }]">
          <a-input v-model:value="formState.job_intention" />
        </a-form-item>
        <a-form-item label="最高学历" name="education_id" :rules="[{ required: true, message: '请选择最高学历!' }]">
          <a-select v-model:value="formState.education_id" :allowClear="true" show-search 
            :options="data_list.education_ids" :filter-option="filterOption">
          </a-select>
        </a-form-item>
        <a-form-item label="出生年月" name="birthday" :rules="[{ required: true, message: '请选择出生年月!' }]">
          <a-date-picker v-model:value="formState.birthday" />
        </a-form-item>
        <a-form-item label="工作经验" name="experience_id" :rules="[{ required: true, message: '请选择工作经验!' }]">
          <a-select v-model:value="formState.experience_id" :allowClear="true" show-search 
            :options="data_list.experience_ids" :filter-option="filterOption">
          </a-select>
        </a-form-item>
        <a-form-item label="期望薪资" name="salary_indention_minimum" :rules="[{ required: true, message: '请输入期望薪资!' }]">
          <a-flex justify="space-between" align="center" gap="5">
            <a-input-number v-model:value="formState.salary_indention_minimum" :min="0" :max="100000" style="flex-grow: 1;"/>
            ~
            <a-form-item-rest>
              <a-input-number v-model:value="formState.salary_indention_maximum" :min="0" :max="100000" style="flex-grow: 1;"/>
            </a-form-item-rest>
          </a-flex>
        </a-form-item>
        <a-form-item label="个人优势" name="advantage">
          <RichEditor height="200" :editMode="'create'" v-model="formState.advantage"/>
        </a-form-item>
        <a-form-item label="求职期望" name="expectation">
          <RichEditor height="200" :editMode="'create'" v-model="formState.expectation"/>
        </a-form-item>
        <a-form-item label="工作经历" name="work_experience">
          <RichEditor height="200" :editMode="'create'" v-model="formState.work_experience"/>
        </a-form-item>
        <a-form-item label="项目经历" name="project_experience">
          <RichEditor height="200" :editMode="'create'" v-model="formState.project_experience"/>
        </a-form-item>
        <a-form-item label="教育经历" name="edu_experience">
          <RichEditor height="200" :editMode="'create'" v-model="formState.edu_experience"/>
        </a-form-item>
        <a-form-item label="资质证书" name="certificate">
          <RichEditor height="200" :editMode="'create'" v-model="formState.certificate"/>
        </a-form-item>
        <a-form-item label="上传简历" name="user_file">
          <a-upload
            v-model:file-list="user_file"
            :before-upload="beforeUpload"
            @remove="handleRemove"
            :max-count="1"
            accept=".pdf"
          >
            <a-tooltip>
              <template #title>请上传 PDF 格式的文件</template>
              <a-button>
                <UploadOutlined style="display: inline-flex;"/>
                上传
              </a-button>
            </a-tooltip>
          </a-upload>
        </a-form-item>
      </a-form>
    </a-modal>
  </div>
</template>

<script setup>
import RichEditor from "@/components/RichEditor.vue";
import { PlusOutlined, UploadOutlined } from '@ant-design/icons-vue';
import { logDebug, logError } from "@/utils/logger";
import { onMounted, ref, reactive, h } from "vue";
import { getResponseData, jsonRPC } from "@/utils/http_utils";
import { getRouterParam } from "@/utils/router_utils";
import { useRouter } from "vue-router";
import { message } from "ant-design-vue";
import { trackUV } from "@/utils/trackPage";

const router = useRouter();
const job_id = ref(getRouterParam(router, "id"));

const filterOption = (input, option) => {
  return option.label.toLowerCase().indexOf(input.toLowerCase()) >= 0;
};
const showModal = ref(false);
const formRef = ref()
const handleSubmit = () => {
  // 校验表单并提交数据
  formRef.value.validate()
  .then(async () => {
    await jsonRPC({
      url: "/api/job/deliver_resume",
      params: {
        job_id: job_id.value,
        resume_id: data_list.resume_id,
      },
      success(res) {
        const data = getResponseData(res);
        logDebug(`投递简历成功`, data);
        fetchData()
      },
      fail(error) {
        logError(`投递简历失败, `, error)
        message.error(`投递简历失败，请稍后再试`);
      },
    }).then(()=>[
      showModal.value = false
    ]);
    return
  })
  .catch(error => {
    logError('error', error);
    return
  });
};

const data_list = reactive({
  resume_id: null,
  salary_minimum: 0,
  salary_maximum: 0,
});
function fetchData() {
  jsonRPC({
    url: `/api/job/detail`,
    params: {
      job_id: job_id.value,
    },
    success(res) {
      const data = getResponseData(res);
      logDebug(`获取证书培训详情成功`, data);
      Object.assign(data_list, data);
    },
    fail(error) {
      logError(`获取证书培训详情失败`, error);
      message.error(`获取证书培训详情失败，请稍后再试`);
    },
  });
}
onMounted(() => {
  fetchData();
  trackUV();
});
import {getBase64} from "@/utils/file_utils";
const beforeUpload = file => {
  user_file.value = [...(user_file.value || []), file];
  return false;
};
const user_file = ref([])
const handleRemove = file => {
  const index = user_file.value.indexOf(file);
  const newFileList = user_file.value.slice();
  newFileList.splice(index, 1);
  user_file.value = newFileList;
};
const open = ref(false);
const newResume = () => {
  open.value = true;
};
const resumeFormRef = ref();
const formState = reactive({
  name: '',
  job_intention: null,
  education_id: null,
  birthday: null,
  experience_id: null,
  salary_indention_minimum: null,
  salary_indention_maximum: null,
  work_experience: null,
  project_experience: null,
  edu_experience: null,
  certificate: null,
  advantage: null,
  expectation: null,
});
const loading = ref(false);
const handleOk = () => {
  resumeFormRef.value.validate()
  .then(async () => {
    loading.value = true;
    const filePromises = await Promise.all(user_file.value.map(async file => {
      const base64Data = await getBase64(file.originFileObj);
      return {
          ...file,
          raw_data: base64Data,
          file_name: file.name,
      };
    }));
    await jsonRPC({
      url: `/api/job/resume/create`,
      params: {
        user_file: filePromises,
        name: formState.name,
        job_intention: formState.job_intention,
        education_id: formState.education_id,
        birthday: formState.birthday,
        experience_id: formState.experience_id,
        salary_indention_minimum: formState.salary_indention_minimum,
        salary_indention_maximum: formState.salary_indention_maximum,
        work_experience: formState.work_experience,
        project_experience: formState.project_experience,
        edu_experience: formState.edu_experience,
        certificate: formState.certificate,
        advantage: formState.advantage,
        expectation: formState.expectation,
      },
      success(res) {
        const data = getResponseData(res);
        logDebug(`获取成功`, data);
        open.value = false;
        resumeFormRef.value.resetFields();
        fetchData();
      },
      fail(error) {
        logError(`保存失败, `, error)
        message.error(`新建简历失败，请稍后再试`);
      },
    }).then(()=>{
      loading.value = false;
    });
    return
  })
  .catch(error => {
    logDebug('error', error);
    return
  });
};
</script>

<style scoped lang="scss">
.job_detail {
  margin-bottom: 30px;
  > .row {
    > .col-md-8 {
      display: flex;
      flex-direction: column;
      > .office {
        flex-shrink: 0;
        background-color: #fff;
        padding: 20px;
        margin-bottom: 15px;
        > div {
          display: flex;
          justify-content: space-between;
          &:not(:last-child) {
            margin-bottom: 10px;
          }
          > h4 {
            font-size: 18px;
            font-weight: bold;
            margin: 0;
            &.wage {
              color: #ff5200;
            }
            > span {
              background-color: #d9ebff;
              padding: 3px 7px;
              border-radius: 4px;
              margin-bottom: -3px;
              position: relative;
              top: -2px;
              margin-left: 0.5rem;
              font-size: 14px;
            }
          }
          > div {
            > p {
              margin-bottom: 10px;
              > span {
                margin-right: 15px;
                color: #989898;
              }
            }
            > .welfare {
              > span {
                margin-right: 15px;
                color: #0078ff;
              }
            }
            > .btn {
              background-color: #ff925e;
              font-size: 16px;
              color: #fff;
              padding: 9px 27px;
            }
          }
        }
      }
      > .info {
        flex-grow: 1;
        background-color: #fff;
        padding: 20px;
        > h5 {
          font-size: 16px;
          font-weight: bold;
          line-height: 30px;
          padding-left: 5px;
          border-left: 5px solid #0078ff;
          margin: 1rem 0;
          &:first-child{
            margin-top: 0;
          }
        }
        > ol {
          padding-left: 18px;
        }
      }
    }
    > .col-md-4 {
      display: flex;
      flex-direction: column;
      > .company {
        background-color: #fff;
        padding: 20px;
        margin-bottom: 20px;
        > h5 {
          font-size: 16px;
          font-weight: bold;
          display: flex;
          align-items: center;
          gap: 10px;
          img {
            height: 40px;
            width: 40px;
            border-radius: 50%;
            object-fit: cover;
          }
        }
        > div {
          > span {
            display: inline-block;
            color: #868686;
            margin-right: 1rem;
            margin-bottom: 10px;
          }
        }
        > p {
          > span {
            color: #0078ff;
          }
        }
        > .form-control {
          display: flex;
          justify-content: center;
          align-items: center;
          gap: 5px;
          text-align: center;
          width: calc(100% - 10px);
          margin: 0 5px;
          border: 1px solid #a0cdff;
          height: auto;
          &:focus {
            box-shadow: 0 0 0 0.2rem #bedcff;
          }
          > div {
            display: inline-block;
            background-color: #499eff;
            height: 28px;
            width: 28px;
            border-radius: 50%;
            text-align: center;
            img{
              height: 20px;
              width: 20px;
            }
          }
        }
      }
      > .contact {
        background-color: #fff;
        padding: 20px;
        margin-bottom: 20px;
        > h5 {
          font-size: 16px;
          font-weight: bold;
          margin-bottom: 1rem;
        }
        > div {
          display: flex;
          justify-content: space-between;
          > div {
            > .avatar {
              height: 40px;
              width: 40px;
              border-radius: 50%;
              overflow: hidden;
              flex-shrink: 0;
              margin-right: 10px;
            }
            > div {
              > p {
                margin-bottom: 5px;
              }
              > span {
                color: #0078ff;
                margin-right: 10px;
                white-space: nowrap;
              }
            }
            > a {
              color: #0078ff;
              border: 1px solid #57a6ff;
            }
          }
        }
      }
      > .similar_jobs {
        flex: 1 0 auto;
        background-color: #fff;
        padding: 20px;
        > h5 {
          font-size: 16px;
          font-weight: bold;
        }
        > div {
          padding: 10px 0;
          border-bottom: 1px solid #a3ceff;
          > h5 {
            font-size: 16px;
            margin-bottom: 0;
            a{
              font-size: 16px;
              font-weight: bold;
              color: #101010;
              &:hover{
                color: #ff8c00;
              }
            }
            > a {
              font-size: 16px;
            }
            > span {
              display: inline-block;
              background-color: #d9ebff;
              padding: 3px 7px;
              border-radius: 4px;
              position: relative;
              top: -2px;
              margin-left: 6px;
              font-size: 14px;
            }
          }
          > div {
            margin-bottom: 0.5rem;
            display: flex;
            justify-content: space-between;
            align-items: center;
          }
          p {
            color: #989898;
            margin: 0;
            &.wage {
              color: #ff5200;
              font-size: 18px;
              flex-shrink: 0;
            }
          }
        }
      }
    }
  }
}
:deep(.ant-btn >span){
  display: inline-flex;
}
</style>
