import {isEmpty, isNotNullOrUndefined} from "@/utils/common_utils";

export function loadStyle(url, id = undefined) {
    const link = document.createElement('link')
    link.type = 'text/css'
    link.rel = 'stylesheet'
    link.href = url
    if (isEmpty(id)) {
        link.id = url
    } else {
        link.id = id
    }
    const head = document.getElementsByTagName('head')[0]
    head.appendChild(link)
}

export function removeStyle(id, url = undefined) {
    if (isNotNullOrUndefined(url)) {
        id = url
    }
    const link = document.getElementById(id)
    if (isNotNullOrUndefined(link)) {
        link.remove()
    }
}

