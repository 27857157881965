<template>
  <div id="terminal-shell">
    <div id="right-key-setting" style="height: 0">
      <div id="Rmenu">
        <ul>
          <li>复制</li>
          <li>粘贴</li>
        </ul>
      </div>
      <label class="m-0" for="select_text_elem"></label>
      <textarea id="select_text_elem" style="display: none"></textarea>
    </div>

    <div v-if="isEnvReady">
      <div id="environment_terminal"
           ref="terminal"
           class="terminal"
      />
    </div>
    <div v-else>
      <a-flex class="loading" justify="center" align="center">
        <div>
          <LoadingOutlined style="font-size: 80px"/>
          <p>连接中，请稍后...</p>
        </div>
      </a-flex>
    </div>
  </div>
</template>

<script setup>
import {nextTick, onMounted, ref} from 'vue';
import {logDebug, logError} from "@/utils/logger";
import {isFalse, isNotEmpty, isNotNullOrUndefined, isTrue} from "@/utils/common_utils";

import 'xterm/css/xterm.css'
import {Terminal} from 'xterm'
import {FitAddon} from 'xterm-addon-fit'
import SockJS from 'sockjs-client'
import axios from "axios";
import {LoadingOutlined} from "@ant-design/icons-vue";
import $ from "jquery";

logDebug('TerminalShell setup!')

const props = defineProps({
  primal: {type: String},
  data: {type: Object},
  styleSetting: {type: Object},
})

const contentHeight = ref(props.styleSetting.contentStyle.height)

const primal = props.primal
const data = props.data

logDebug(`primal[${primal}], data[${JSON.stringify(data)}]`)

const terminal = ref(null)
const vmshell_ingress_url = data['vmshell_ingress_url']
const shell_type = data['shell_type'] ? data['shell_type'] : 'bash'
logDebug(`shell_type[${shell_type}]`)
const deploy_token = data['deploy_token']
logDebug(`deploy_token[${deploy_token}]`)
const isEnvReady = ref(false)

let globalFitAddon = null
let globalTerm = null
let globalSocket = null
let globalSessionId = null

const global_resize_interval_ms = 80
const global_resize_times = 6
let global_resize_timer = null
let global_focus_timer = null
const onSocketReadyWaitTimeMS = 800
const onSocketReadyTryCount = 5

const setTerminalCount = ref(1)


const onSocketReady = function (inputSocket, waitTimeMS, callback, logTags) {
  let timeoutHandler = null
  const checkSocketReady = function (tryCount) {
    logDebug(`onSocketReady, inputSocket.readyState[${inputSocket.readyState}], logTags[${logTags}], tryCount[${tryCount}]`)
    if (tryCount < 1) {
      logError(`onSocketReady failed after try ${onSocketReadyTryCount} times, inputSocket.readyState[${inputSocket.readyState}], logTags[${logTags}]`)
      return;
    }
    if (timeoutHandler != null) {
      clearTimeout(timeoutHandler)
    }
    if (inputSocket.readyState === SockJS.CLOSED) {
      logDebug(`inputSocket has been closed`)
      setTerminal(false)
      return;
    }
    if (inputSocket.readyState !== SockJS.OPEN) {
      logDebug(`inputSocket not ready`)
      timeoutHandler = setTimeout(checkSocketReady, waitTimeMS, tryCount - 1)
      return
    }
    callback()
  }
  checkSocketReady(onSocketReadyTryCount)
}

function select_text(term) {
  const content = term.getSelection();
  logDebug(`content = ${content}`)
  return content;
}

function insert_text(socket, text, term) {
  if (socket == null || typeof socket === 'undefined') {
    logError('insert_text, socket not ready')
    return
  }

  onSocketReady(socket, onSocketReadyWaitTimeMS, function () {
    text = text.replaceAll("\r\n", "\n")
    socket.send(JSON.stringify({
      Op: 'stdin',
      Data: text,
      Cols: term.cols,
      Rows: term.rows,
    }), 'insert_text')
  })
}

function right_setting(term, socket) {
  const select_text_elem_id = "#select_text_elem"
  const environment_terminal = document.querySelector("#environment_terminal");
  const xterm_area_id = ".xterm-helper-textarea"

  const menu = document.getElementById("Rmenu");
  environment_terminal.oncontextmenu = function (ev) {
    ev = ev || event;
    menu.style.display = "block";
    if (ev.clientX > window.innerWidth / 2) {
      menu.style.left = (ev.clientX - 80) + "px";
    } else {
      menu.style.left = (ev.clientX - 20) + "px";
    }
    if (ev.clientY > window.innerHeight / 2) {
      menu.style.top = (ev.clientY - 80) + "px";
    } else {
      menu.style.top = (ev.clientY + 20) + "px";
    }
    //阻止默认右键事件
    return false;
  }

  menu.onclick = function (e) {
    //click事件可以关闭右键菜单
    const clicked_item = e.target.outerText;
    if ("复制" === clicked_item) {
      const select_text_elem = document.querySelector(select_text_elem_id)
      const select_text = select_text_elem.value
      if (navigator.clipboard) {
        navigator.clipboard.writeText(select_text).then(() => {
          logDebug(`复制内容：${select_text}`)
        })
      } else {
        select_text_elem.select(); // 选择对象
        document.execCommand("copy")
        logDebug(`复制内容：${select_text}`)
      }
    } else if ("粘贴" === clicked_item) {
      const xterm_area = document.querySelector(xterm_area_id)
      xterm_area.focus()
      if (navigator.clipboard) {
        navigator.clipboard.readText().then(text => {
          logDebug(`粘贴内容：${text}`)
          insert_text(socket, text, term)
        });
      } else {
        document.execCommand("paste")
        logDebug(`粘贴内容成功！`)
      }

    }
    menu.style.display = "none";
  }

  window.addEventListener("click", function () {
    menu.style.display = "none";
  });

  environment_terminal.onmouseup = function () {
    const select_text_elem = document.querySelector(select_text_elem_id)
    if (select_text_elem == null) {
      logDebug("select_text_elem == null")
      return
    }
    select_text_elem.value = select_text(term)
  };
}

const send_stdin = function (socket, msg, cols, rowls) {
  if (socket == null || typeof socket === 'undefined') {
    logError('send_stdin, socket not ready')
    return
  }

  onSocketReady(socket, onSocketReadyWaitTimeMS, function () {
    socket.send(
        JSON.stringify({
          Op: 'stdin',
          Data: msg,
          Cols: cols,
          Rows: rowls,
        })
    );
  }, 'send_stdin')
}

const send_heartbeat = function (socket, msg, cols, rowls) {
  if (socket == null || typeof socket === 'undefined') {
    logDebug('send_heartbeat, socket not ready')
    return
  }

  onSocketReady(socket, onSocketReadyWaitTimeMS, function () {
    socket.send(
        JSON.stringify({
          Op: 'heartbeat',
          Data: msg,
          Cols: cols,
          Rows: rowls,
        })
    );
  }, 'heartbeat')
}

// 心跳函数
let sendHeartBeatInterval = null
const sendHeartBeat = function (heartBeatIntervalMS = 5000) {
  if (isNotNullOrUndefined(sendHeartBeatInterval)) {
    clearTimeout(sendHeartBeatInterval)
  }
  send_heartbeat(globalSocket, '', globalTerm.cols, globalTerm.rows)
  // 设置下一次心跳
  sendHeartBeatInterval = setTimeout(function () {
    sendHeartBeat(heartBeatIntervalMS)
  }, heartBeatIntervalMS);
}

const needRefresh = ref(false)

const setTerminal = function (isInitialized = true) {
  if (!isEnvReady.value) {
    logDebug("setTerminal, env is not ready yet")
    return;
  }

  logDebug(`第${setTerminalCount.value}次调用setTerminal，isInitialized[${isInitialized}]`)
  setTerminalCount.value += 1
  if (isTrue(isInitialized)) {
    needRefresh.value = false
  }
  // TODO-Alain: 如果isInitialized===false，需要做以下几件事：
  // 1. 不再重新初始化terminal
  // 2. 重新创建一个新的socket，并且使用之前的sessionId
  const zone_domain = data['zone_domain']
  const zone_domain_ssl = `${data['zone_domain_ssl']}`.toLowerCase().trim() !== 'false'
  const zone_domain_port = data['zone_domain_port'] ? data['zone_domain_port'] : 443
  const protocol = `${zone_domain_ssl}`.toLowerCase().trim() === 'false' ? "http" : "https"
  const pod = data['env_name']
  const namespace = data['namespace']

  if (isTrue(isInitialized)) {
    //初始化xterm
    const term = new Terminal({
      foreground: '#17FFEF', //字体
      background: 'black', //背景色
      fontSize: '18',
      cursor: 'help',//设置光标
      rendererType: "canvas", //渲染类型
      convertEol: true, //启用时，光标将设置为下一行的开头
      scrollback: 500,//终端中的回滚量
      disableStdin: false, //是否应禁用输入。
      cursorStyle: 'underline', //光标样式
      cursorBlink: true, //光标闪烁
      fontFamily: 'Consolas,Liberation Mono,Menlo,Courier,monospace',
      theme: {
        foreground: '#17FFEF', //字体
        background: 'black', //背景色
        cursor: 'help',//设置光标
        fontSize: '18',
      }
    });
    const fitAddon = new FitAddon();
    term.loadAddon(fitAddon);
    term.open(terminal.value);
    fitAddon.fit()
    globalFitAddon = fitAddon
    globalTerm = term

    sendHeartBeat(3000)
  }


  // 连接webshell服务器
  // namespace = "ybmkpuoitshcyjrjnomdvgxv"
  // pod = "ubuntu-79b498bfdc-9w46g"
  // abc_domain_name = '127.0.0.1:7069'

  let url1 = `${protocol}://${zone_domain}:${zone_domain_port}/t?shell=${shell_type}&namespace=${namespace}&pod=${pod}`
  let url2 = `${protocol}://${zone_domain}:${zone_domain_port}/t/s?shell=${shell_type}&namespace=${namespace}&pod=${pod}`
  if (isNotEmpty(data['vmshell_ingress_url'])) {
    url1 = `${data['vmshell_ingress_url']}/t?shell=${shell_type}&namespace=${namespace}&pod=${pod}`
    url2 = `${data['vmshell_ingress_url']}/t/s?shell=${shell_type}&namespace=${namespace}&pod=${pod}`
  }
  if (deploy_token) {
    url1 = `${url1}&deploy_token=${deploy_token}`
    url2 = `${url2}&deploy_token=${deploy_token}`
  }


  // pod = "ubuntu-79b498bfdc-9w46g"
  // namespace = "ybmkpuoitshcyjrjnomdvgxv"
  // abc_domain_name = '10.0.8.34:7069'
  // abc_domain_name = '127.0.0.1:7069'
  //
  // const url1 = `http://${abc_domain_name}/t`
  // const url2 = `http://${abc_domain_name}/t/s?namespace=${namespace}&pod=${pod}`

  function connect_sockjs(session_id, isInitialized = true) {
    let url = url2
    logDebug(`url = ${url}`)

    const socket = new SockJS(url, undefined, {
      transports: ['websocket'],
      timeout: 30000,
    });  //建立链接
    if (isNotNullOrUndefined(globalSocket)) {
      try {
        globalSocket.close()
      } catch (e) {
        logError('关闭旧的socket失败')
      }
    }
    globalSocket = socket;

    const onOpenHandler = function (inputSocket) {
      const silent_period_ms = 1500
      const start_time = new Date().getTime();

      if (isTrue(isInitialized)) {
        globalTerm.onData((data) => {
          logDebug(`globalTerm.onData[${data}]`)
          if (isTrue(needRefresh.value)) {
            needRefresh.value = false
            window.location.reload()
            return;
          }
          const now_time = new Date().getTime();
          // logDebug(`start_time=${start_time}, now_time=${now_time}, diff=${now_time - start_time}`)
          if (now_time - start_time < silent_period_ms && isTrue(isInitialized)) {
            return;
          }
          send_stdin(globalSocket, data, globalTerm.cols, globalTerm.rows)
        });
      }

      inputSocket.onerror = function (event) {
        logDebug('error:' + event);
      };

      let last_message = ""
      let shell_ready = false
      let welcome_shown = false
      inputSocket.onmessage = async function (evt) {
        const event = JSON.parse(evt.data);
        if (event.Op === 'toast') {
          // globalTerm.write(连接失效或者环境不可用，请刷新重试！);
          if (isFalse(needRefresh.value)) {
            globalTerm.write('\n\r\x1B[1;3;31m由于应用环境已重启或连接已失效，请按“任意键”或“刷新”重连！\x1B[0m');
          }
          needRefresh.value = true
          return;
        }
        if (isFalse(isInitialized)) {
          // logDebug(`inputSocket.onmessage[${event.Data}]`)
          globalTerm.write(event.Data);
          return;
        }
        if (shell_ready === false) {
          last_message = `${last_message}${event.Data}`
          // logDebug(`last_message = ${last_message}`)

          if (last_message.includes("export PS1='[\\u \\W]\\$'") ||
              last_message.includes("export PS1='[root]\\$'") ||
              last_message.includes("export PS1='[$(whoami) $(pwd)]\\$'") ||
              last_message.includes("/ # \r\n")) {
            if (welcome_shown === false) {
              globalTerm.write("" +
                  " ┌─────────────────────────────────────────────────────────────┐\n" +
                  " │           • ABC Platform Shell Service v2.0 •               │\n" +
                  " │               (Powered by HWUA Co.,Ltd.)                    │\n" +
                  " │ For more information, please visit: https://www.hwua.com/   │\n" +
                  " └─────────────────────────────────────────────────────────────┘\n\n")
              welcome_shown = true
            }
            last_message = ""
            for (let i = 0; i < 1; i++) {
              send_stdin(globalSocket, "\n", globalTerm.cols, globalTerm.rows)
            }
            return
          }
          if (!last_message.trim().endsWith("]#") && !last_message.trim().endsWith("]$")
              && !last_message.trim().endsWith("/ #")) {
            for (let i = 0; i < 1; i++) {
              send_stdin(globalSocket, "\n", globalTerm.cols, globalTerm.rows)
            }
            return;
          } else {
            const splits = event.Data.split("\n")
            let text = splits[splits.length - 1]
            // logDebug(`splits=${splits}`)
            // logDebug(`text=${text}`)
            if (text.trim() === "") {
              send_stdin(globalSocket, "\n", globalTerm.cols, globalTerm.rows)
              return;
            }
            shell_ready = true
            last_message = ""
            globalTerm.write(text);
            return;
          }
        }
        if (shell_ready) {
          const now_time = new Date().getTime();
          // logDebug(`start_time=${start_time}, now_time=${now_time}, diff=${now_time - start_time}`)
          if (now_time - start_time < silent_period_ms) {
            resizeTerminal()
            return;
          }
          globalTerm.write(event.Data);
        }
      };
      inputSocket.onopen = function () {
        onSocketReady(inputSocket, onSocketReadyWaitTimeMS, function () {
          resize(inputSocket, globalTerm.cols, globalTerm.rows)
        }, 'inputSocket.onopen')
      };
      inputSocket.onclose = function (event) {
        // socket连接断开
        logDebug('inputSocket closed, 22222222222', event)
        setTimeout(function () {
          logDebug('inputSocket reconnect, 22222222222')
          // let element = document.querySelector('.xterm');
          // if (element) {
          //   element.remove();
          // }
          setTerminal(false);
        }, 0)
      };

      const bind_session_data = {
        Op: 'bind',
        SessionID: session_id,
        Data: JSON.stringify({
          pod: pod,
          namespace: namespace,
          session_id: data.session_id,
          user_id: data.user_id,
          env_id: data.env_id,
        })
      };
      inputSocket.send(JSON.stringify(bind_session_data));

      if (isTrue(isInitialized)) {
        send_stdin(globalSocket, "\n", globalTerm.cols, globalTerm.rows)
        send_stdin(globalSocket, "\n", globalTerm.cols, globalTerm.rows)
        if (shell_type === 'bash') {
          send_stdin(globalSocket, "export PS1='[\\u \\W]\\$'", globalTerm.cols, globalTerm.rows)
        } else {
          send_stdin(globalSocket, "export PS1='[$(whoami) $(pwd)]\\$'", globalTerm.cols, globalTerm.rows)
        }
        send_stdin(globalSocket, "\n", globalTerm.cols, globalTerm.rows)
      }
    }

    socket.onopen = function () {
      onSocketReady(socket, onSocketReadyWaitTimeMS, function () {
        onOpenHandler(socket)
      }, 'socket.onopen')
    };

    right_setting(globalTerm, socket)
  }

  if (isTrue(isInitialized)) {
    const session_request_data = {
      "namespace": namespace,
      "pod": pod,
    };
    axios({
      method: 'get',
      url: url1,
      data: session_request_data,
      headers: {'Content-Type': 'application/x-www-form-urlencoded'}
    }).then(function (response) {
      globalSessionId = response.data.ID;
      logDebug("globalSessionId: " + globalSessionId);
      connect_sockjs(globalSessionId, true);
    }).catch(function (error) {
      logDebug(error);
    });
  } else {
    logDebug("globalSessionId: " + globalSessionId);
    connect_sockjs(globalSessionId, false);
  }
}


function resize(socket, cols, rows) {
  if (socket == null || typeof socket === 'undefined') {
    // logError('resize, socket not ready')
    return
  }

  onSocketReady(socket, onSocketReadyWaitTimeMS, function () {
    if (global_resize_timer != null) {
      clearTimeout(global_resize_timer);
    }
    global_resize_timer = setTimeout(function () {
      socket.send(JSON.stringify({
        Op: 'resize',
        Cols: cols,
        Rows: rows,
      }))
      logDebug('socket', cols, rows)
    }, 4 * global_resize_interval_ms)
  }, `resize, cols[${cols}], rows[${rows}]`)
}


function focusTerminal() {
  if (!isEnvReady.value) {
    logDebug("focusTerminal, env is not ready yet")
    return;
  }

  if (global_focus_timer != null) {
    clearTimeout(global_focus_timer);
  }
  global_focus_timer = setTimeout(function () {
    logDebug(".xterm-helper-textarea 获取焦点");
    const ele = document.querySelector(".xterm-helper-textarea")
    if (isNotEmpty(ele)) {
      ele.blur()
      ele.focus({preventScroll: true});
    }
  }, 400)
}

function resizeTerminal() {
  if (!isEnvReady.value) {
    logDebug("resizeTerminal, env is not ready yet")
    return;
  }

  const environment_terminal = document.getElementById('environment_terminal')
  if (environment_terminal === null || typeof environment_terminal === 'undefined') {
    logDebug("environment_terminal is empty")
    return
  }

  if (globalTerm == null) {
    logDebug("resizeTerminal globalTerm is empty")
    return;
  }

  const resize_terminal_function = function (count) {
    if (globalFitAddon != null) {
      // logDebug("globalFitAddon called!")
      try {
        globalFitAddon.fit()
        resize(globalSocket, globalTerm.cols, globalTerm.rows)
      } catch (e) {
        // logDebug(`unexpect exception[${e}]`)
      }

      focusTerminal()

      if (count > 1) {
        setTimeout(resize_terminal_function, global_resize_interval_ms, count - 1);
      }
    }
  };
  setTimeout(resize_terminal_function, global_resize_interval_ms, global_resize_times);
}

function waitEnvReady(callback = undefined) {
  logDebug('waitEnvReady')
  const totalTryCount = 60
  const tryIntervalMS = 3000
  const checkEnvReadyHandler = function (tryCount) {
    if (tryCount < 1) {
      logError(`reloadIframeHandler failed after try ${tryCount} times`)
      return;
    }
    const testURL = `${vmshell_ingress_url}/t`
    $.ajax({
      url: testURL,
      type: 'GET',
      // 告诉jQuery不要去处理发送的数据
      processData: false,
      // 告诉jQuery不要去设置Content-Type请求头
      contentType: false,
      success: function () {
        isEnvReady.value = true
        if (callback && typeof callback === 'function') {
          callback()
        }
      },
      error: function (responseStr) {
        logDebug("error:", responseStr);
        setTimeout(checkEnvReadyHandler, tryIntervalMS, tryCount - 1)
      }
    });
  }
  checkEnvReadyHandler(totalTryCount)
}

onMounted(() => {
  // 在onMounted中获取数据
  logDebug(`TerminalShell onMounted.`)

  // 将shell初始化从onSelected移到onMounted里，这样用户在切换到shell终端标签前，shell就可以就绪了。
  window.addEventListener("resize", resizeTerminal);

  const startShellTerminal = function () {
    isEnvReady.value = true
    nextTick(function () {
      setTimeout(function () {
        setTerminal(true)
      }, 500)
    })
  }

  if (data['env_type'] === 'VM') {
    waitEnvReady(function () {
      startShellTerminal()
    })
  } else {
    startShellTerminal()
  }
})

const onUpdate = function () {
  logDebug(`TerminalShell onUpdate.`)
  resizeTerminal()
}

const resizeAndFocusTerminal = function () {
  const resizeIntervalMS = 100
  const focusIntervalMS = 200
  setTimeout(function () {
    resizeTerminal()
  }, resizeIntervalMS)
  setTimeout(function () {
    focusTerminal()
  }, focusIntervalMS)
}

const onSelected = function () {
  logDebug(`TerminalShell onSelected.`)
  resizeAndFocusTerminal()
}

defineExpose({
  primal,
  data,
  onSelected,
  onUpdate,
})
</script>

<style scoped>
#terminal-shell {
  background: black;
  width: 100%;
  height: v-bind(contentHeight);
}

#environment_terminal {
  background: black;
  font-size: 18px;
  color: #17FFEF;
  width: 100%;
  height: v-bind(contentHeight);
  padding: 1px 0 3px 3px
}

.xterm-helper-textarea {
  font-family: "Monaco", "Menlo", "Consolas", "Courier New", "monospace";
}


#Rmenu {
  display: none;
  position: absolute;
  z-index: 9999;
  width: 150px;
  background: white;
  opacity: 0.92;
  border-radius: 5px;
}

#Rmenu ul {
  padding: 0;
  margin: 10px 0 10px 0;
}

#Rmenu li {
  height: 32px;
  line-height: 32px;
  color: #21232E;
  font-size: 16px;
  text-align: center;
  cursor: default;
  list-style-type: none;
  border-bottom: 1px dashed #cecece;
  border-top: 1px dashed #cecece;
  margin: -1px 0 -1px 0;
}

#Rmenu li:hover {
  background-color: #cccccc;
}

.loading {
  background: lightgray;
  height: v-bind(contentHeight);
}
</style>