<template>
  <div class="console_course">
    <div class="top">
      <h4>我的课程</h4>
      <div class="search">
        <a-input-search placeholder="请输入名称" enter-button allowClear @search="onSearch" style="width: 200px"/>
        <a-button type="primary" v-if="!is_student" @click="createCourse">制作课程</a-button>
      </div>
    </div>
    <a-list :grid="{ gutter: 16, column: 4, xs: 1, sm: 2 }" :data-source="data_list">
      <template #renderItem="{ item }">
        <a-list-item class="px-0">
          <a-card hoverable style="width: 100%; height: 100%" @click="enterDetail(item.id)">
            <template #cover>
              <div class="info">
                <!-- <span>系列课程</span> -->
                <span v-if="item.list_price > 0" class="new">新课特惠</span>
                <span v-else>限时免费</span>
                <a class="img_box">
                  <img v-if="item.image" :src="`/web/image/hw.course/${item.id}/image_512?t=${new Date().getTime()}`"/>
                  <img v-else src="/hw_web/static/src/img/course_default.png"/>
                </a>
                <div>
                  <a class="line-clamp-1" :title="item.name">{{ item.name }}</a>
                  <div class="summary">
                    <span v-for="category in item.course_category_ids" :key="category.id">{{ category.name }}</span>
                  </div>
                  <a-flex justify="space-between" align="center">
                    {{ item.student_num }} 人已参加
                    <div class="price">
                      <span v-if="item.is_purchase">免费</span>
                      <span v-else-if="item.list_price > 0">￥{{item.list_price}}</span>
                    </div>
                  </a-flex>
                </div>
              </div>
            </template>
          </a-card>
        </a-list-item>
      </template>
    </a-list>
    <a-flex justify="center">
      <a-pagination
        v-model:current="current"
        :showSizeChanger="showSizeChanger"
        v-model:page-size="pageSize"
        :total="total"
        :locale="zhCn"
        :pageSizeOptions="pageSizeOptions"
        :hideOnSinglePage="true"
      />
    </a-flex>
  </div>
</template>

<script setup>
import { onMounted, ref, watch } from "vue";
import { jsonRPC, getResponseData } from "@/utils/http_utils";
import {logDebug, logError} from "@/utils/logger";
import { zhCn } from "@/utils/zhCn";
import { useRouter } from 'vue-router';
const router = useRouter();

const createCourse = () => {
  router.push('/console/course/create');
};
const enterDetail = (e)=>{
  router.push(`/console/course/${e}`);
}

const data_list = ref([]);
const total = ref(0);
const showSizeChanger = ref(true);
const pageSize = ref(8);
const current = ref(1);
const search = ref("");
const pageSizeOptions = ref(
  Array.from({ length: 4 }, (_, index) =>
    (pageSize.value * (index + 1)).toString()
  )
);

function fetchData() {
  jsonRPC({
    url: "/vue/desktop/course",
    params: {
      page_index: current.value,
      page_size: pageSize.value,
      search: search.value,
    },
    success(res) {
      const data = getResponseData(res);
      logDebug(`获取成功`, data);
      data_list.value = data.records;
      total.value = data.record_count;
    },
    fail(error) {
      logError(`查询失败, `, error);
    },
  });
}
const is_student = ref(true)
onMounted(()=>{
  fetchData();
  jsonRPC({
    url: `/vue/users/appIcon`,
    success(res) {
      const data = getResponseData(res);
      is_student.value = data.is_student;
    },
    fail(error) {
      logError('判断是学生用户出错', error);
    },
  });
});
watch([pageSize, current], fetchData);

const onSearch = (searchValue) => {
  if (search.value != searchValue) {
    current.value = 1;
    search.value = searchValue;
    fetchData();
  }
};

import {trackUV} from "@/utils/trackPage";
// 在页面加载时触发 UV 埋点
onMounted(() => {
  // 调用 trackUV 来统计 UV 数据
  trackUV(router.currentRoute);
});

</script>

<style scoped lang="scss">
.console_course{
  padding: 16px;
  .top{
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 16px;
    h4{
      font-size: 18px;
      margin-bottom: 0;
    }
    .search{
      >button{
        margin-left: 16px;
      }
    }
  }
  .info {
    display: flex;
    flex-direction: column;
    height: 100%;
    background-color: #fff;
    position: relative;
    border-radius: 8px;
    overflow: hidden;
    >span {
      display: inline-block;
      position: absolute;
      top: 0;
      left: 0;
      padding: 0 15px;
      line-height: 30px;
      border-bottom-right-radius: 8px;
      z-index: 1;
      background-color: #90FAFF;
      &.new{
        background-color: #90FFA5;
      }
    }
    >div{
      flex: 1 0 auto;
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      padding: 1rem;
      .line-clamp-1{
        margin-bottom: 5px;
        font-size: 16px;
        color: #000;
      }
      .summary {
        span {
          display: inline-block;
          color: #52c9ff;
          font-size: 12px;
          border: 1px solid #52c9ff;
          border-radius: 4px;
          padding: 1px 4px;
          margin-right: 5px;
          margin-bottom: 5px;
        }
      }
      .price{
        span{
          font-size: 16px;
          color:#39BFED;
        }
      }
    }
  }
}
</style>