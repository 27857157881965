<template>
  <Teleport to="body">
    <transition enter-active-class="slideInRight" leave-active-class="slideOutRight">
      <div class="sidebar" v-show="sidebarShow">
        <div class="sidebar-header">
          <div class="item" :class="{ active: data.tag === 1 }" @click="onTag(1)">消息</div>
          <!-- <div class="item" :class="{ active: data.tag === 2 }" @click="onTag(2)">通讯录</div> -->
          <!-- <div class="item" :class="{ active: data.tag === 3 }" @click="onTag(3)">主题</div> -->
        </div>
        <div class="sidebar-body" v-if="data.tag === 1">
          <div class="message-header">
            <span></span>
            <a-button type="link" @click="onCleanMessage()" v-if="messages.list.length > 0" style="float: right;">清空
            </a-button>
          </div>
          <ul class="message-list">
            <a-card size="small" :bordered="false" v-for="item in messages.list" hoverable :key="item.id"
                    style="margin-bottom: 8px;">
              <template #title>
                <a :title="item.title">{{ item.title.length > 8 ? item.title.slice(0, 8) + '...' : item.title }}</a>
                <small style="margin-left: 2px">{{ moment(item.timestamp, dateFormat).calendar() }}</small>
              </template>
              <template #extra>
                <a @click="onClose(item.id)" title="关闭">
                  <CloseCircleOutlined/>
                </a>
              </template>
              <div class="text">
                <p :title="item.content">{{
                    item.content.length > 50 ? item.content.slice(0, 50) + '...' : item.content
                  }}</p>
              </div>
            </a-card>
          </ul>
        </div>
        <div class="sidebar-body" v-if="data.tag === 2">
          <ul>
            <li class="address-item">
              <span class="address-item-cell">张三</span>
              <span class="address-item-cell">13800000001</span>
              <span class="address-item-cell">xxxx</span>
            </li>
            <li class="address-item">
              <span class="address-item-cell">张三</span>
              <span class="address-item-cell">13800000001</span>
            </li>
          </ul>
        </div>
        <div class="sidebar-body" v-if="data.tag === 3">
          <div class="sidebar-body-calendar">
            <div class="sidebar-body-calendar-date normal-date">xxxx</div>
          </div>
        </div>
      </div>
    </transition>
  </Teleport>
</template>

<script setup>
import {logDebug} from "@/utils/logger";
import {reactive, computed} from "vue";
import moment from "moment";
import "moment/locale/zh-cn";

moment.locale("zh-cn");
import messageStore from "@/stores/message.js";
import coreStore from "@/stores/core.js";

const $messageStore = messageStore();
const $coreStore = coreStore();
import {CloseCircleOutlined} from "@ant-design/icons-vue";

const props = defineProps({
  sidebarShow: {type: Boolean, default: false},
});
const data = reactive({
  tag: 1,
});
const dateFormat = computed(() => {
  return $coreStore.dateFormat;
});
const date = computed(() => {
  return moment(new Date(), dateFormat).calendar();
});
logDebug("Formatted date:", date.value);
logDebug("Formatted props:", JSON.stringify(props));
const messages = computed(() => {
  return {list: $messageStore.list, count: $messageStore.list.length};
});

const onTag = function (tag) {
  data.tag = tag;
};
const onClose = function (id) {
  $messageStore.deleteMessage(id);
};
const onCleanMessage = function () {
  $messageStore.cleanMessage();
};
</script>

<style lang="less" scoped>
.sidebar {
  position: fixed;
  right: 0;
  top: 0px;
  height: calc(100% - var(--task-bar-heihgt)); //calc(100% - @taskHeight);
  width: 300px;
  background-color: rgba(200, 200, 200, 0.7);
  backdrop-filter: blur(0.125rem); //毛玻璃蒙层
  z-index: 998;
}

.sidebar-header {
  height: 50px;
  text-align: center;
  border-bottom: 1px outset;
}

.sidebar-header .item {
  min-width: 30px;
  width: 20%;
  height: 20px;
  margin: 15px 0;
  display: inline-block;
  font-size: 14px;
  color: #eaeaea;
  background-color: rgba(121, 121, 121, 0.5);
}

.sidebar-header .item:hover {
  cursor: pointer;
}

.sidebar-header .item.active {
  background-color: rgba(255, 255, 255, 0.5);
  color: #000;
}

.sidebar-body {
  position: relative;
  height: calc(100% - 70px);
  overflow-y: auto;
  padding: 10px;
}

.sidebar-body-calendar {
  margin: 15px 0;
}

.sidebar-body-calendar .sidebar-body-calendar-date {
  padding: 0 33px;
  color: #585858;
}

.sidebar-body-calendar-date.normal-date {
  font-size: 30px;
}

.address-item {
  border: solid 1px transparent;
  display: block;
  padding: 5px;
  margin: 5px;
  color: #4b4b4b;
  background-color: #f9f9f9;
  border-radius: 3px;
  -moz-border-radius: 3px;
  -webkit-border-radius: 3px;
  text-align: left;
  text-decoration: none;
  box-shadow: 0 5px 10px rgba(0, 0, 0, 0.4);
}

.address-item-cell {
  padding: 0rem 15px 0px 15px;
}

.address-item:hover {
  border: solid 1px #7da2ce;
  box-shadow: inset 0 0 1px #fff;
  -moz-box-shadow: inset 0 0 1px #fff;
  -webkit-box-shadow: inset 0 0 1px #fff;
  background-color: #cfe3fd;
  background: -moz-linear-gradient(top, #dcebfd, #c2dcfd);
  background: -webkit-gradient(linear,
  center top,
  center bottom,
  from(#dcebfd),
  to(#c2dcfd));
}

.message-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 10px;
}

.message-list {
  list-style: none;
  padding: 0;
  margin: 0;
}

.text {
  color: #666;
}

a {
  color: #1890ff;
  text-decoration: none;
}

a:hover {
  text-decoration: underline;
}

.slideOutRight {
  animation: slideOutRight 0.9s;
}

.slideInRight {
  animation: slideInRight 0.7s;
}
</style>
