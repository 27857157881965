<template>
  <div class="login">
    <div class="info">
      <component :is="currentComponent" @call-parent-method="parentMethod"></component>
      <a-flex justify="center" align="center" class="bottom">
        登录 或 注册 代表您已阅读并同意 <a href="/protocol/licence" class="btn btn-link btn-sm" target="_blank">用户协议</a>
        和<a href="/protocol/privacy" class="btn btn-link btn-sm" target="_blank">隐私政策</a>
      </a-flex>
      <img class="d-none d-md-block" src="/hw_web/static/src/img/login_dolphin.png"/>
    </div>
  </div>
</template>

<script setup>
import {logDebug} from "@/utils/logger";
import { ref, computed } from 'vue'
import WebLogin from './WebLogin.vue'
import WebResetPassword from './WebResetPassword.vue'
import WebSignup from './WebSignup.vue'

const selectedKey = ref('login');
const components = {
  login: WebLogin,
  reset: WebResetPassword,
  signup: WebSignup,
}

const currentComponent = computed(() => {
  return components[selectedKey.value]
})

function parentMethod(e) {
  logDebug('父组件方法被调用了');
  logDebug(e);
  selectedKey.value = e.component;
}
</script>

<style scoped lang="scss">
.login {
  height: 80vh;
  display: flex;
  justify-content: center;
  align-items: center;
  background-image: url(@/assets/login_background.png);
  background-repeat: no-repeat;
  background-size: cover;
  .info{
    position: relative;
    background-color: #fff;
    padding: 37px;
    padding-bottom: 0;
    border-radius: 4px;
    outline: 8px solid #ffffff80;
    .bottom{
      font-size: 13px;
      margin-top: 40px;
      margin-bottom: 10px;
    }
    >img{
      pointer-events: none;
      position: absolute;
      bottom: -75px;
      left: -150px;
      width: 255px;
    }
  }
}
:deep(.ant-form-item){
  margin-bottom: 14px;
}
</style>