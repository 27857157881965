import {logDebug} from "@/utils/logger";
import {watch} from "vue";
import {isEmpty} from "@/utils/common_utils";

// router.currentRoute.value.params
// router.currentRoute.value.query

// 根据浏览器当前url的路径，自动判断选中对应路径的菜单。
// 比如，如果浏览器当前url是"http://192.168.102.103:8080/#/console/app/template"，
// 那么，/console、/console/app、/console/app/template这三个路径对应的菜单就会被选中。
export function routerSelectedKeys(router, selectedKeys, keyRouterLink) {
    // 主要用于在刷新或跳转等场景下，根据路由信息自动判断是哪个路由标签被选中，从而高亮显示。
    const {currentRoute} = router;
    let currentPath = currentRoute.value.path;
    // 将currentPath和router都加上“/”，避免类似于"/console/class3"匹配上"/console/class"的问题
    // 比如，加上”/“后，"/console/class3/"就不能匹配上“/console/class/”了
    if (!currentPath.endsWith('/')) {
        currentPath = `${currentPath}/`
    }
    logDebug(`currentPath[${currentPath}]`)
    const keys = Object.keys(keyRouterLink)
    let found = false
    for (const key of keys) {
        let route = keyRouterLink[key].trim()
        if (!route.endsWith('/')) {
            route = `${route}/`
        }
        if (currentPath.startsWith(route)) {
            selectedKeys.value = [key]
            found = true
            break
        }
    }
    if (!found) {
        if (keys.length > 0) {
            selectedKeys.value = [keys[0]]
        } else {
            selectedKeys.value = ['']
        }
    }
    logDebug(`selectedKeys[${selectedKeys.value}]`)
}

export function watchRouterSelectedKeys(router, selectedKeys, keyRouterLink) {
    routerSelectedKeys(router, selectedKeys, keyRouterLink)
    watch(router.currentRoute, function () {
        logDebug(`watch router.currentRoute.value.fullPath[${router.currentRoute.value.fullPath}]`)
        routerSelectedKeys(router, selectedKeys, keyRouterLink)
    })
}

export function routerBack(router) {
    router.back()
}

export function routerForward(router) {
    router.forward()
}

// 字符串路径
// router.push('/users/eduardo')
// 带有路径的对象
// router.push({ path: '/users/eduardo' })
// 命名的路由，并加上参数，让路由建立 url
// router.push({ name: 'user', params: { username: 'eduardo' } })
// 带查询参数，结果是 /register?plan=private
// router.push({ path: '/register', query: { plan: 'private' } })
// 带 hash，结果是 /about#team
// router.push({ path: '/about', hash: '#team' })
export function routerPush(router, path) {
    router.push(path)
}

export function routerReplace(router, path) {
    router.replace(path)
}

// 向前移动一条记录，与 router.forward() 相同
// router.go(1)
// 返回一条记录，与 router.back() 相同
// router.go(-1)
// 前进 3 条记录
// router.go(3)
// 如果没有那么多记录，静默失败
// router.go(-100)
// router.go(100)
export function routerGo(router, delta) {
    router.go(delta)
}

// getRouterValue返回值的格式如下：
// {
//   "fullPath": "/console/class/1",
//   "path": "/console/class/1",
//   "query": {},
//   "hash": "",
//   "params": {
//     "class_id": "1"
//   },
//   "matched": [路由信息]
//   "meta": {},
//   "href": "#/console/class/1"
// }
export function getRouterValue(router) {
    const {currentRoute} = router;
    return currentRoute.value;
}

// params是路径参数，比如当指定路由"/console/class/:class_id"时，
// 对于"http://192.168.102.103:8080/#/console/class/1"这个url，
// 就包含了路径参数class_id，且其值为"1"。
export function getRouterParam(router, param = undefined) {
    if (typeof param == 'undefined') {
        return getRouterValue(router).params;
    }
    return getRouterValue(router).params[param]
}

// query是查询参数，比如当指定路由"/console/class/:class_id"时，
// 对于"http://192.168.102.103:8080/#/console/class/1?mode=readonly"这个url，
// 就包含了查询参数"mode"，并且其值为"readonly"。
export function getRouterQuery(router, query = undefined, defaultValue = undefined) {
    if (typeof query == 'undefined') {
        return getRouterValue(router).query;
    }
    const result = getRouterValue(router).query[query]
    if (isEmpty(result)) {
        return defaultValue
    }
    return result
}
