<template>
  <div>
    <div class="loading-content" v-if="props.model != 'progress' && props.show">
      <div class="loading-spinner">
        <svg viewBox="25 25 50 50" class="circular"><circle cx="50" cy="50" r="20" fill="none" class="path"></circle></svg>
      </div>
      <div class="loading-text">{{ props.text }}</div>
    </div>
    <div class="progress-content" v-if="props.model == 'progress' && props.show">
      <div class="loading-timer">{{ printText }}</div>
      <div class="Loading-progress"></div>
    </div>
  </div>
</template>

<script setup>
import { onMounted, onUnmounted, reactive, computed } from 'vue';
const props = defineProps({
	text: { type: String, default: '加载中...' },
	model: { type: String, default: 'progress1' },
	show: { type: Boolean, default: true }
});

const data = reactive({ minute: 0, second: 0, millisecond: 0, content: '00:00:00', timer: {} });

const printText = computed(() => {
	let parts = [];
	parts[0] = '' + Math.floor(data.minute);
	parts[1] = '' + Math.floor(data.second);
	parts[2] = '' + Math.floor(data.millisecond);

	parts[0] = parts[0].length == 1 ? '0' + parts[0] : parts[0];
	parts[1] = parts[1].length == 1 ? '0' + parts[1] : parts[1];
	parts[2] = parts[2].length == 1 ? '0' + parts[2] : parts[2];
	return parts.join(':');
});
const calculate = () => {
	data.millisecond = data.millisecond + 1;
	if (data.millisecond >= 100) {
		data.millisecond = 0;
		data.second = data.second + 1;
	}
	if (data.second >= 60) {
		data.second = 0;
		data.minute = data.minute + 1;
	}
	if (data.minute >= 60) {
		data.minute = 0;
	}
};
const reset = ()=>{
	data.minute = 0
	data.second = 0
	data.millisecond = 0
}
onMounted(() => {
	reset()
	data.timer = setInterval(() => {
		calculate();
	}, 10);
});
onUnmounted(() => {
	clearTimeout(data.timer);
});
</script>

<style lang="less" scoped>
.loading-timer {
	font-family: 'Yanone Kaffeesatz', sans-serif;
	font-size: 76px;
	font-weight: 400;
}
.progress-content {
	position: absolute;
	margin: 0;
	top: 0;
	right: 0;
	bottom: 0;
	left: 0;
	background: rgba(170, 255, 255, 1);
	display: flex;
	justify-content: center;
	align-items: center;
	flex-direction: column;
}
.loading-content {
	position: absolute;
	margin: 0;
	top: 0;
	right: 0;
	bottom: 0;
	left: 0;
	transition: opacity 0.3s;
	background: rgba(170, 170, 255, 1);
	display: flex;
	justify-content: center;
	align-items: center;
	flex-direction: column;
	z-index: 0;
}
.loading-content::after {
	content: '';
	position: absolute;
	top: 0;
	right: 0;
	bottom: 0;
	left: 0;
	background: rgba(255, 255, 255, 0.25);
	z-index: -1;
}
.loading-content:before {
	content: '';
	position: absolute;
	top: 0;
	right: 0;
	bottom: 0;
	left: 0;
	background: inherit;
	filter: blur(5px);
	background: inherit;
	background-attachment: fixed;
	z-index: -1;
}
.loading-spinner {
	text-align: center;
	z-index: 1;
}
.loading-spinner .circular {
	height: 42px;
	width: 42px;
	animation: loading-rotate 2s linear infinite;
}
.loading-spinner .path {
	animation: loading-dash 1.5s ease-in-out infinite;
	stroke-dasharray: 90, 150;
	stroke-dashoffset: 0;
	stroke-width: 2;
	stroke: #409eff;
	stroke-linecap: round;
}
.loading-text {
	font-size: 14px;
	color: #000000;
}

.Loading-progress {
	height: 5px;
	width: 350px;
	border-radius: 3px;
	background: linear-gradient(90deg, red 0%, yellow 15%, lime 30%, cyan 50%, blue 65%, magenta 80%, red 100%);
	background-size: 200%;
	animation: moveGradient 3s linear infinite;
}
@keyframes moveGradient {
	0% {
		background-position: 0% 0%;
	}
	100% {
		background-position: -200% 0%;
	}
}

@keyframes loading-rotate {
	100% {
		transform: rotate(360deg);
	}
}
@keyframes loading-dash {
	0% {
		stroke-dasharray: 1, 200;
		stroke-dashoffset: 0;
	}

	50% {
		stroke-dasharray: 90, 150;
		stroke-dashoffset: -40px;
	}

	to {
		stroke-dasharray: 90, 150;
		stroke-dashoffset: -120px;
	}
}
</style>
