<template>
  <p class="msg">Hello World!</p>
</template>

<script setup>
import { ref } from 'vue'

const fontColor = ref('#ff0000')
</script>

<style scoped>
.msg {
  color: v-bind(fontColor);
}
</style>