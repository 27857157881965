export const EnvType = {
    DEV: 'development',
    TEST: 'test',
    PROD: 'production',
    HI168: 'hi168',
    ABC: 'abc',
    ABCTEST: 'abctest',
    NODOCKER: 'nodocker',
    HI168SHANGHAITEST001: 'shanghaitest001',
    HI168WWW4: 'hi168www4',
    HI168PROD: 'hi168prod',
    HI168TEST: 'hi168test',
}

export const HttpBaseUrlMap = {
    [EnvType.DEV]: 'http://127.0.0.1:8080',
    [EnvType.TEST]: 'https://abctest.hwua.com',
    [EnvType.PROD]: 'https://www.hi168.com',
    [EnvType.HI168]: 'https://www.hi168.com',
    [EnvType.ABC]: 'https://abc.hwua.com',
    [EnvType.ABCTEST]: 'https://abctest.hwua.com',
    [EnvType.NODOCKER]: 'https://nodocker.hwua.com',
    [EnvType.HI168SHANGHAITEST001]: 'https://shanghaitest001.hi168.com',
    [EnvType.HI168WWW4]: 'https://www4.hi168.com',
    [EnvType.HI168PROD]: 'https://www.hi168.com',
    [EnvType.HI168TEST]: 'https://shanghaitest001.hi168.com',
}

const EnableEnv = EnvType.HI168PROD
export const AppGlobalConfig = {
    env: EnableEnv,
    baseUrl: HttpBaseUrlMap[EnableEnv],
}

export const isFrontendDeploy = function () {
    return [
        EnvType.DEV,
        EnvType.HI168PROD,
        EnvType.HI168TEST,
    ].includes(AppGlobalConfig.env)
}

