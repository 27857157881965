<template>
  <a-table :columns="columns" :dataSource="data_list">
    <template #bodyCell="{ column, record, text }">
      <template v-if="column.dataIndex == 'exercise_duration'">{{text==0?'不限制':text+'分钟'}}</template>
      <template v-if="column.dataIndex == 'pass_criterion'">{{text+'%'}}</template>
      <template v-if="column.dataIndex == 'operation'">
        <a href="#" @click.prevent="create_exercise(record.id)">开始练习</a>
      </template>
    </template>
  </a-table>
</template>

<script setup>
import { ref } from "vue";
const emits = defineEmits(['call-parent-method']);
const props = defineProps({
  list: Array,
});
const data_list = ref(props.list)
const create_exercise = (e)=>{
  emits('call-parent-method', {
    component: 'detail',
    exercise_id: e,
  });
}
const columns = [
  {
    title: '练习ID',
    dataIndex: 'id',
    align: 'center',
  },
  {
    title: '练习名称',
    dataIndex: 'name',
    align: 'center',
  },
  {
    title: '题目总数',
    dataIndex: 'exercise_question_nums',
    align: 'center',
  },
  {
    title: '练习限时',
    dataIndex: 'exercise_duration',
    align: 'center',
  },
  {
    title: '练习总分',
    dataIndex: 'total',
    align: 'center',
  },
  {
    title: '通过标准',
    dataIndex: 'pass_criterion',
    align: 'center',
  },
  {
    title: '操作',
    dataIndex: 'operation',
    align: 'center',
  },
];
</script>

<style scoped lang="scss">
:deep(.ant-table-thead >tr>th){
  background-color: #dcedff;
}
</style>
