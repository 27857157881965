import {createApp} from 'vue';
import pinia from "@/stores";
import Antd, {message} from 'ant-design-vue';
import App from './App';
import router from './router'
import VueDraggableResizable from 'vue-draggable-resizable'
import 'ant-design-vue/dist/reset.css';
import 'vue-draggable-resizable/style.css';

import 'animate.css/animate.min.css'; //引入 //https://animate.style/
import '@/assets/css/bootstrap.css'
import '@/assets/css/common.scss'

message.config({
    top: `30vh`,
    duration: 2,
    maxCount: 3,
    rtl: true,
    prefixCls: 'my-message',
});

const app = createApp(App)

app.component("vue-draggable-resizable", VueDraggableResizable);
app.config.productionTip = false;
app.use(pinia);
app.use(Antd);
app.use(router);
app.mount('#app');