<template>
  <div class="homework_correct">
    <a-breadcrumb separator=">">
      <a-breadcrumb-item><router-link to="/console/class">培训班级</router-link></a-breadcrumb-item>
      <a-breadcrumb-item><router-link :to="`/console/class/${classId}`">{{class_name}}</router-link></a-breadcrumb-item>
      <a-breadcrumb-item>批改作业</a-breadcrumb-item>
    </a-breadcrumb>
    <div class="info">
      <a-flex :justify="'space-between'" :align="'center'" style="margin-bottom: 20px;">
        <h1>{{class_name}}</h1>
        <a-button type="primary" @click="AssignHomework">布置新作业</a-button>
      </a-flex>
      <a-table :columns="columns" :dataSource="data_list" :pagination="false">
        <template #bodyCell="{ text, column, record }">
          <template v-if="column.dataIndex == 'submit'">{{text}}/{{record.student_ids}}</template>
          <template v-if="column.dataIndex == 'correct'">{{text}}/{{record.submit}}</template>
          <template v-if="column.dataIndex == 'action'">
            <a-typography-text v-if="record.submit <= 0" type="secondary">暂无提交</a-typography-text>
            <router-link v-else :to="{ path: `/console/class/homework/${record.id}`, 
              query: { mode: 'correct', record_id: record.record_id, 
              classId: classId,class_name: class_name, name: record.name } }">
              <a-typography-text v-if="record.submit != record.correct" style="color: #3291f8;">开始批改</a-typography-text>
              <a-typography-text v-else type="success">批改完成</a-typography-text>
            </router-link>
          </template>
        </template>
      </a-table>
    </div>
  </div>
</template>

<script setup>
import { onMounted,ref } from "vue";
import {logDebug, logError} from "@/utils/logger";
import { getResponseData, jsonRPC } from "@/utils/http_utils";
import { getRouterParam, routerPush } from "@/utils/router_utils";
import { useRouter } from "vue-router";
const router = useRouter();
const classId = ref(getRouterParam(router, "class_id"));

const columns = ref([
  {
    title: '作业名称',
    dataIndex: 'name',
  },
  {
    title: '所属章节/直播',
    dataIndex: 'live_name',
  },
  {
    title: '作业提交截止日期',
    dataIndex: 'deadline',
  },
  {
    title: '提交情况',
    dataIndex: 'submit',
  },
  {
    title: '批改情况',
    dataIndex: 'correct',
  },
  {
    title: '操作',
    dataIndex: 'action',
  },
]);
let AssignHomework = ()=>{
  routerPush(router, `/console/class/${classId.value}/homework/form?mode=create`)
}
let class_name = ref()
const data_list = ref([]);
onMounted(() => {
  jsonRPC({
    url: "/vue/console/class/homework/correct",
    params: {
      id: Number(classId.value),
    },
    success(res) {
      const data = getResponseData(res)
      logDebug(`查询成功`,data);
      data_list.value = data.records;
      class_name.value = data.class_name;
    },
    fail(error) {
      logError(`查询失败, `, error);
    },
  });
});
</script>

<style scoped lang='scss'>
.homework_correct{
  height: 100%;
  .info{
    background-color: #fff;
    padding: 20px;
    height: 100%;
  }
  h1{
    font-size: 18px;
    font-weight: bold;
    padding-bottom: 5px;
    padding-right: 20px;
    border-bottom: 1px solid #63B1FF;
  }
  button{
    height: 40px;
    border-radius: 20px;
  }
}
</style>