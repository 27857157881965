<template>
  <div class="shop_order">
    <div class="container">
      <a-flex justify="space-between" align="center">
        <a-menu v-model:selectedKeys="menu_current" mode="horizontal" :items="menu_items"/>
        <div class="search">
          <a-button @click="_backtrack"><img src="/hw_web/static/src/img/order_shop_list.png" />购物车</a-button>
          <a-input-search placeholder="请输入商品名称" enter-button allowClear @search="onSearch" style="width: 200px"/>
        </div>
      </a-flex>
      <a-table :key="tableKey" :columns="columns" :data-source="data_list" :pagination="pagination" 
        :expandFixed="false" :defaultExpandAllRows="true" :expandIcon="() => null">
        <template #bodyCell="{ column, record, text }">
          <template v-if="record.order_id">
            <template v-if="column.dataIndex === 'order_id'">
              订单号：{{text}}<br/>
              <div class="text-info" v-if="record.order_status == 'TRADING'">自创建订单起，24小时后自动关闭</div>
            </template>
            <template v-if="column.dataIndex === 'product_price_subtotal'">
              <span style="white-space: nowrap;">¥ {{ (text ?? 0).toFixed(2) }}</span>
            </template>
            <template v-if="column.dataIndex === 'actual_payment'">
              <a v-if="record.order_status == 'TRADING'" style="color: #ff8c00;width: min-content;" class="dropdown-item px-2"
                @click.prevent="continue_payment(record.id,record.order_id)">继续付款</a>
              <span v-else style="white-space: nowrap;">¥ {{text?.toFixed(2)}}</span>
            </template>
            <template v-if="column.dataIndex === 'order_status'">
              <span class="badge badge-pill" style="background:blue;color:white" v-if="record.order_status == 'TRADING'">待付款</span>
              <span class="badge badge-pill" style="background:#144af1;color:white" v-if="record.order_status == 'PROCESSING'">交易完成，处理中</span>
              <span class="badge badge-pill" style="background:#0bd734;color:white" v-if="record.order_status == 'FINISHED'">已完成</span>
              <span class="badge badge-pill" style="background:#ffa000;color:white" v-if="record.order_status == 'REFUNDING'">退款，处理中</span>
              <span class="badge badge-pill" style="background:#fc0707;color:white" v-if="record.order_status == 'PART_REFUNDED'">部分已退款</span>
              <span class="badge badge-pill" style="background:#fc0707;color:white" v-if="record.order_status == 'REFUNDED'">已退款</span>
              <span class="badge badge-pill" style="background:#a29b93;color:white" v-if="record.order_status == 'CLOSED'">已关闭</span>
            </template>
            <template v-if="column.dataIndex === 'operation'">
              <a-popconfirm title="确定删除该订单？" @confirm="()=>deleteProduct(record.id)">
                <a class="text-muted" style="white-space: nowrap;">删除订单</a>
              </a-popconfirm>
            </template>
          </template>
          <template v-else-if="!record.is_coupons">
            <template v-if="column.dataIndex === 'order_id'">
              <div class="product_info">
                <img v-if="record.image" :src="`/web/image/product.template/${record.product_id}/image_512`"/>
                <div>
                  <p>{{record.product_name}}</p>
                  <p>
                    <span v-for="(i,index) in record.product_tpl_variant_value_ids" :key="index"
                      class="badge badge-pill" style="background:blue;color:white">{{i}}
                    </span>
                  </p>
                </div>
              </div>
            </template>
            <template v-if="column.dataIndex === 'product_price_unit'">
              <span style="white-space: nowrap;">¥ {{text?.toFixed(2)}}
                <template v-if="record.product_categ_id[1]?.includes('实验环境资源')">/ 天</template>
              </span>
            </template>
            <template v-if="column.dataIndex === 'product_quantity'">
              <span style="white-space: nowrap;">x {{text}}</span>
              <template v-if="record.product_categ_id[1]?.includes('实验环境资源')">天</template>
            </template>
            <template v-if="column.dataIndex === 'product_price_subtotal'">
              <span style="white-space: nowrap;">¥ {{text?.toFixed(2)}}</span>
            </template>
            <template v-if="column.dataIndex === 'operation'">
              <a v-if="record.order_line_state == 'Paid' && record.product_price_subtotal > 0"
                @click.prevent="_requestOrderRefundBtn(record.payment_info_id,record.order_line_id,record.payment_info_id,record.product_price_subtotal)"
                style="color: #cf0000">申请退款</a>
            </template>
          </template>
        </template>
      </a-table>
    </div>
  </div>
</template>

<script setup>
import { useRouter } from "vue-router";
const router = useRouter();
const _backtrack = ()=>{
  router.push('/shop')
}
import {logDebug, logError} from "@/utils/logger";
import {onMounted, reactive, ref, watch, markRaw} from "vue";
import {jsonOwlRPC} from "@/utils/http_utils";
import {message, Modal} from "ant-design-vue";

const handlePageChange = (page) => {
  pagination.current = page;
  fetchData();
}
const pagination = reactive({
  // 分页配置
  pageSize: 4, // 每页显示的条数
  showSizeChanger: false, // 是否可以改变每页显示的条数
  pageSizeOptions: ['5', '10', '20'], // 可选的每页显示条数
  showQuickJumper: false, // 是否可以快速跳转到指定页
  showTotal: total => `共 ${total} 条`, // 显示总条数和当前数据范围
  hideOnSinglePage: true, // 只有一页时是否隐藏分页器 true为不显示 false为显示
  current: 1, // 当前页数
  total: 0, // 总条数
  onChange: markRaw(handlePageChange), // 页码改变时的回调函数
})

const menu_current = ref(['']);
const menu_items = ref([
  {
    key: '',
    label: '全部订单',
  },
  {
    key: 'TRADING',
    label: '待付款',
  },
  {
    key: 'REFUNDING',
    label: '处理中',
  },
  {
    key: 'FINISHED',
    label: '已完成',
  },
  {
    key: 'CLOSED',
    label: '已关闭',
  },
])

const data_list = ref([]);
const search = ref("");
const tableKey = ref(0)
const columns = [
  {
    title: '订单内容',
    dataIndex: 'order_id',
  },
  {
    title: '单价',
    dataIndex: 'product_price_unit',
  },
  {
    title: '数量',
    dataIndex: 'product_quantity',
  },
  {
    title: '小计',
    dataIndex: 'product_price_subtotal',
  },
  {
    title: '实付款',
    dataIndex: 'actual_payment',
  },
  {
    title: '订单状态',
    dataIndex: 'order_status',
  },
  {
    title: '操作',
    dataIndex: 'operation',
  },
];

function fetchData() {
  jsonOwlRPC({
    url: `/query/my/order/info`,
    params:{
      page_index: pagination.current,
      name: search.value,
      state: menu_current.value[0],
    },
    success(res) {
      data_list.value = res.records;
      pagination.total = res.record_count;
      tableKey.value += 1
    },
    fail(error) {
      logError(`查询失败, `, error);
    },
  })
}
onMounted(()=>{
  fetchData()
});
watch([menu_current], fetchData);
const onSearch = (searchValue) => {
  if (search.value != searchValue) {
    pagination.current = 1;
    search.value = searchValue;
    fetchData();
  }
};
const deleteProduct = (order_id)=>{
  jsonOwlRPC({
    url: `/shop/order/delete`,
    params:{
      order_id: order_id,
    },
    success(res) {
      logDebug(res);
      fetchData()
    },
    fail(error) {
      logError(`查询失败, `, error);
    },
  })
}
const continue_payment = (order_id,sales_order_id)=>{
  router.push(`/check?order_id=${order_id}&sales_order_id=${sales_order_id}`)
}
const _requestOrderRefundBtn = (order_id, line_id, payment_info_id, refund_amount)=>{
  Modal.confirm({
    title: '申请退款',
    content: '您正在为当前订单申请退款，请再次确认！',
    async onOk() {
      try {
        // 使用Promise控制Modal的关闭时机
        return new Promise((resolve, reject) => {
          jsonOwlRPC({
            url: `/payment/refund/application`,
            params: {
              sale_order: order_id,
              sale_order_line: line_id,
              payment_info_id: payment_info_id,
              refund_amount: refund_amount,
            },
            success(res) {
              logDebug(res);
              message.success('退款申请提交成功，我们将在第一时间进行处理，感谢您的耐心等待！');
              fetchData();
              resolve();  // 请求成功后，关闭 Modal
            },
            fail(error) {
              logError(`退款申请失败, `, error);
              message.error(`退款申请失败, ` + error);
              reject();  // 请求失败时，不关闭 Modal，并且显示错误消息
            },
          });
        });
      } catch (error) {
        logError('Oops errors!', error);
        message.error('操作失败，请稍后再试');
      }
    },
  });
}
</script>

<style scoped lang="scss">
.shop_order{
  min-height: 80vh;
  padding: 16px;
  background-color: #fff;
  .ant-flex{
    flex-shrink: 0;
    margin-bottom: 16px;
    .search{
      .ant-btn{
        color: #96CAFF;
        border: 1px solid #96CAFF;
        margin-right: 15px;
        img{
          height: 25px;
          width: 25px;
          margin-top: -2px;
          margin-right: 5px;
          margin-left: -5px;
        }
      }
    }
  }
  .product_info{
    display: flex;
    align-items: center;
    img{
      height: 90px;
      width: 160px;
    }
    >div{
      display: flex;
      flex-direction: column;
      margin-left: 16px;
      p{
        font-weight: bold;
        margin: 0;
      }
      span{
        margin-top: 10px;
      }
    }
  }
}
:where(.ant-menu-light){
  background-color: transparent;
  border-bottom: none;
}
:deep(.ant-menu-horizontal){
  line-height: 32px;
}
</style>