<template>
  <div id="function-create-app">
    <a-button type="primary" @click="showCreateAppModal" :style="props.styleSetting.buttonStyle">
      <a-tooltip>
        <template #title>创建实验环境</template>
        <RightSquareOutlined :style="props.styleSetting.iconStyle"/>
      </a-tooltip>
    </a-button>
    <a-modal v-model:open="openCreateApp" title="创建实验环境" :getContainer="getContainer" 
             :confirm-loading="confirmOkCreateApp" @ok="handleOkCreateApp">
      <p>是否确定创建实验环境？</p>
    </a-modal>
  </div>
</template>

<script setup>
import {onMounted, ref} from 'vue';
import {RightSquareOutlined} from '@ant-design/icons-vue';
import {logComponents, logDebug, logError} from "@/utils/logger";
import {getFailedMessage, getResponseData, jsonRPC} from "@/utils/http_utils";
import {message} from "ant-design-vue";
import {isNotNullOrUndefined} from "@/utils/common_utils";
logComponents([RightSquareOutlined])

const emit = defineEmits(['refresh']); //声明 emits

const props = defineProps({
  primal: {type: String},
  data: {type: Object},
  styleSetting: {type: Object},
})

const primal = props.primal
const data = props.data

const liveId = props.data['live_id']

const openCreateApp = ref(false)
const confirmOkCreateApp = ref(false)

const appSolutionId = ref(-1)

const updateAppStatusIntervalMS = 5000
const updateAppStatusTryTimes = 60

const getAppStatus = function (tryCount) {
  if (tryCount <= 0) {
    logError(`getAppStatus failed after try ${updateAppStatusTryTimes} times.`)
    return
  }

  try {
    let allReady = true
    const getAppStatusResult = jsonRPC({
      url: "/api/experiment/app/status",
      params: {
        app_solution_id: appSolutionId.value,
      },
      success(res) {
        const data = getResponseData(res)
        logDebug(`查询直播实验环境状态成功，data[${JSON.stringify(data)}]`)
        if (data.app_env_status !== 'Running') {
          allReady = false
        }
      },
      fail(error) {
        logError(`查询直播实验环境状态失败`, error)
        allReady = false
      }
    })
    getAppStatusResult.then(function () {
      if (!allReady) {
        setTimeout(function () {
          getAppStatus(tryCount - 1)
        }, updateAppStatusIntervalMS)
      } else {
        emit("refresh", {}); //触发事件
      }
    })
  } catch (e) {
    logError(`getAppStatus failed`, e)
    message.error(`查询直播实验环境状态失败，[${JSON.stringify(getFailedMessage(e))}]`, 3);
  }
}

const handleOkCreateApp = function () {
  confirmOkCreateApp.value = true;
  const createAppResult = jsonRPC({
    url: "/api/experiment/live/create_app",
    params: {
      live_id: liveId,
    },
    success(res) {
      const data = getResponseData(res)
      logDebug(`创建直播实验环境成功，data[${JSON.stringify(data)}]`)
      appSolutionId.value = data.app_solution_id
      message.info(`创建实验环境成功，实验环境入口将在准备就绪后展现出来。`, 5);
      return true
    },
    fail(error) {
      logError(`获取直播详情配置失败`, error)
      message.error(`获取直播详情配置失败，[${JSON.stringify(getFailedMessage(error))}]`, 3);
      return false
    }
  })

  createAppResult.then(function () {
    confirmOkCreateApp.value = false
    openCreateApp.value = false
  }).then(function () {
    if (isNotNullOrUndefined(appSolutionId.value) && appSolutionId.value > 0) {
      getAppStatus(updateAppStatusTryTimes)
    }
  })
}

const showCreateAppModal = function () {
  logDebug('打开创建应用模态框')
  logDebug(JSON.stringify(props.data))

  openCreateApp.value = true

  // emit("ok", "我是子组件fullscreen传入的ok值"); //触发事件
  // emit("cancel", "我是子组件fullscreen传入的cancel值"); //触发事件
  // emit("refresh", "我是子组件fullscreen传入的refresh值"); //触发事件
  // emit("customize", {
  //   'arg1': '参数1',
  //   'arg2': 2,
  // }) //触发事件
}
let container = null
const getContainer = () => {
  return container
}
onMounted(() => {
  container = document.getElementById('function-create-app')
})

const onSelected = function () {
  logDebug(`LiveDetailFunctionCreateApp onSelected.`)
}

defineExpose({
  primal,
  data,
  onSelected,
})
</script>

<style scoped>
#function-refresh {
  background: #00BFFF;
}
</style>

