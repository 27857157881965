<template>
  <div class="blog_bage">
    <a-row gutter="20">
      <a-col :span="5">
        <a-menu v-model:selectedKeys="selectedKeys" mode="vertical" :items="items" @click="handleClick"></a-menu>
      </a-col>
      <a-col :span="14">
        <div class="info">
          <div class="blog_title">
            <div class="tags">
              <a class="tag" :class="{ active: tag_id == 0 }" @click="onTag(0)">推荐</a>
              <a class="tag" :class="{ active: tag_id == 1 }" @click="onTag(1)">最新</a>
              <a class="tag" :class="{ active: tag_id == 2 }" @click="onTag(2)">我的关注</a>
            </div>
            <div class="search">
              <router-link to="/blog/write" class="write_blog">
                <img src="/hw_blog/static/src/img/write_blog.png"/>
                <span>发帖子</span>
              </router-link>
              <a-input-search placeholder="请输入帖子关键词" enter-button allowClear @search="onSearch" style="width: 200px"/>
            </div>
          </div>
          <div class="blog_list" v-for="item in data_list.records" :key="item.id">
            <div class="blog_list_top">
              <router-link :to="`/blog/homepage?user_id=${item.user.id}`" class="user">
                <img v-if="item.user.has_image" :src="`/web/image/res.users/${item.user.id}/image_128`"/>
                <img v-else src="/hw_web/static/src/img/avatar_live.png"/>
                {{item.user.name}}
              </router-link>
              <span class="ml-3">{{ item.post_date }}</span>
            </div>
            <div class="blog_list_content">
              <div>
                <h5>
                  <router-link :to="`/blog/${item.id}`">{{ item.name }}</router-link>
                  <span v-for="i in item.tag_ids" :key="i.id">{{i.name}}</span>
                </h5>
                <a-typography-text
                  :style="item.ellipsis ? {maxHeight: '200px',overflow: 'hidden',textOverflow: 'ellipsis',whiteSpace: 'normal',width: '100%'} : {}"
                  :ellipsis="item.ellipsis"
                >
                  <div v-html="item.content"></div>
                </a-typography-text>
                <a-flex justify="center">
                  <a-button type="text" @click="item.ellipsis=!item.ellipsis" style="color: #429BFF;">
                    <template v-if="item.ellipsis" #icon><DownOutlined style="display: inline-flex;"/></template>
                    <template v-else #icon><UpOutlined style="display: inline-flex;"/></template>
                    <template v-if="item.ellipsis">展开全文</template>
                    <template v-else>收起全文</template>
                  </a-button>
                </a-flex>
              </div>
              <img v-if="item.is_image" :src="`/web/image/hw.blog.post/${item.id}/image_512`"/>
            </div>
            <div class="blog_list_bottom">
              <a-tooltip placement="bottom">
                <template #title>浏览量</template>
                <span>
                  <img src="/hw_blog/static/src/img/visited.png"/>
                  <span>{{ item.visits }}</span>
                </span>
              </a-tooltip>
              <a-tooltip placement="bottom">
                <template #title>评论人数</template>
                <span>
                  <img v-if="item.is_comments" src="/hw_blog/static/src/img/info.png"/>
                  <img v-else src="/hw_blog/static/src/img/comment.png"/>
                  <span>{{ item.comments }}</span>
                </span>
              </a-tooltip>
              <a-tooltip placement="bottom">
                <template #title>点赞</template>
                <span>
                  <img v-if="item.is_likes" @click="onBlogLike(item.id)" src="/hw_blog/static/src/img/liked.png"/>
                  <img v-else @click="onBlogLike(item.id)" src="/hw_blog/static/src/img/praise.png"/>
                  <span>{{ item.likes }}</span>
                </span>
              </a-tooltip>
            </div>
          </div>
          <!-- 分页 -->
          <a-flex justify="center" class="mt-3">
            <a-pagination
              v-model:current="current"
              :showSizeChanger="showSizeChanger"
              v-model:page-size="pageSize"
              :total="data_list.record_count"
              :locale="zhCn"
              :pageSizeOptions="pageSizeOptions"
              :hideOnSinglePage="true"
            />
          </a-flex>
        </div>
      </a-col>
      <a-col :span="5">
        <div class="blog_user">
          <div class="user_info">
            <img v-if="data_list.user?.has_image" :src="`/web/image/res.users/${data_list.user?.id}/image_512`"/>
            <img v-else src="/hw_web/static/src/img/avatar_live.png"/>
            <h5>{{data_list.user?.id!=4?data_list.user?.name:'未登录'}}</h5>
          </div>
          <div class="blog_info">
            <router-link :to="`/blog/homepage`">
              <span class="count">{{data_list.user_blog_num}}</span>
              <span class="title">帖子</span>
            </router-link>
            <router-link :to="`/blog/attention`">
              <span class="count">{{data_list.blog_id?.blog_attention_ids.length}}</span>
              <span class="title">关注</span>
            </router-link>
            <router-link :to="`/blog/fan`">
              <span class="count">{{data_list.blog_id?.blog_fan_ids.length}}</span>
              <span class="title">粉丝</span>
            </router-link>
          </div>
        </div>
        <div class="blog_message">
          <h5>互动消息</h5>
          <router-link to="/blog/message?key=reply">
            <img src="/hw_frontend/static/img/blog_@my_icon.png"/>
            @我的
            <a-badge :count="data_list.reply_comment_count" :number-style="{ backgroundColor: '#FF8110' }"/>
          </router-link>
          <router-link to="/blog/message?key=comment">
            <img src="/hw_frontend/static/img/blog_comment_icon.png"/>
            评论
            <a-badge :count="data_list.comment_count" :number-style="{ backgroundColor: '#FF8110' }"/>
          </router-link>
          <router-link to="/blog/message?key=like">
            <img src="/hw_frontend/static/img/blog_like_icon.png"/>
            点赞
            <a-badge :count="data_list.like_count" :number-style="{ backgroundColor: '#FF8110' }"/>
          </router-link>
        </div>
        <div class="blog_hot_post">
          <h5>热帖推荐
            <span @click="onOneChange"><RedoOutlined />换一批</span>
          </h5>
          <ul>
            <li v-for="(item, index) in data_list.hot_blog" :key="index">
              <router-link :to="`/blog/${item.id}`" class="line-clamp-1" :title="item.name">
                {{ item.name }}
              </router-link>
            </li>
          </ul>
        </div>
      </a-col>
    </a-row>
    <a-back-top />
  </div>
</template>

<script setup>
import { RedoOutlined, UpOutlined, DownOutlined } from '@ant-design/icons-vue';
import { logDebug, logError } from "@/utils/logger";
import { onMounted, ref, reactive, watch } from "vue";
import { getResponseData, jsonRPC } from "@/utils/http_utils";
import { zhCn } from "@/utils/zhCn";
import { message } from "ant-design-vue";
import { trackUV } from "@/utils/trackPage";
import { useRouter } from "vue-router";

const router = useRouter();
const selectedKeys = ref([]);
const items = ref([]);
const handleClick = menuInfo => {
  router.push(`/blog/category/${menuInfo.key}`)
};
const tag_id = ref(0);
const search = ref('');
const data_list = reactive({
  records: [],
  record_count: 0,
  user_blog_num: 0,
  reply_comment_count: 0,
  comment_count: 0,
  like_count: 0,
  hot_blog: [],
  hot_blog_page_count: 2,
  hot_blog_page_index: 1,
});
const showSizeChanger = ref(true);
const pageSize = ref(8);
const current = ref(1);
const pageSizeOptions = ref(
  Array.from({ length: 4 }, (_, index) =>
    (pageSize.value * (index + 1)).toString()
  )
);
function fetchData() {
  jsonRPC({
    url: `/api/blog`,
    params: {
      tag_id: tag_id.value,
      page_index: current.value,
      page_size: pageSize.value,
      search: search.value,
    },
    success(res) {
      const data = getResponseData(res);
      logDebug(`获取技术论坛成功`, data);
      Object.assign(data_list, data);
      items.value = data.category_ids
    },
    fail(error) {
      logError(`获取技术论坛失败`, error);
      message.error(`获取技术论坛失败，请稍后再试`);
    },
  });
}
onMounted(() => {
  fetchData();
  trackUV();
});
watch([tag_id, pageSize, current], fetchData);
const onSearch = (searchValue) => {
  if (search.value != searchValue) {
    current.value = 1;
    search.value = searchValue;
    fetchData();
  }
};
const onTag = (e)=>{
  tag_id.value = e;
}
const onOneChange = ()=>{
  if (data_list.hot_blog_page_index>=data_list.hot_blog_page_count) {
    data_list.hot_blog_page_index = 1
  } else {
    data_list.hot_blog_page_index += 1
  }
  jsonRPC({
    url: `/api/blog/change`,
    params: {
      page_index: data_list.hot_blog_page_index,
    },
    success(res) {
      const data = getResponseData(res);
      logDebug(`获取热帖推荐成功`, data);
      Object.assign(data_list, data);
    },
    fail(error) {
      logError(`获取热帖推荐失败`, error);
      message.error(`获取热帖推荐失败，请稍后再试`);
    },
  });
}
const onBlogLike = (e) => {
  jsonRPC({
    url: `/api/blog/like/${e}`,
    params: {},
    success(res) {
      logDebug(res);
      fetchData();
    },
    fail(error) {
      logError(`操作失败`, error);
    },
  });
};
</script>

<style scoped lang="scss">
.blog_bage {
  margin-top: 20px;
  margin-bottom: 50px;
  margin-left: 10px;
  margin-right: 10px;
  .ant-menu{
    border-radius: 8px;
  }
  .info{
    // background-color: #fff;
    .blog_title{
      display: flex;
      margin-bottom: 15px;
      .tags {
        flex: 1 1 100%;
        display: flex;
        flex-wrap: wrap;
        .tag {
          color: #000;
          border-radius: 8px;
          padding: 0.3rem 1rem;
          cursor: pointer;
          &.active,
          &:hover {
            background-color: #D5E9FF;
          }
          &:not(:last-child){
            margin-right: 10px;
          }
        }
      }
      .search {
        flex: 0 0 auto;
        display: flex;
        align-items: center;
        .write_blog {
          display: flex;
          align-items: center;
          gap: 0.3rem;
          background-color: #77b7ff;
          color: #fff;
          border-radius: 15px;
          padding: 0.25rem 0.75rem;
          line-height: 20px;
          margin-right: 1rem;
          img {
            width: 16px;
            height: 16px;
          }
        }
        .ant-btn-primary{
          background-color: #77b7ff;
        }
      }
    }
    .blog_list{
      padding: 15px;
      background-color: #fff;
      border-radius: 8px;
      box-shadow: 2px 2px 2px 0px rgba(128, 128, 128, 0.4);
      margin-bottom: 15px;
      .blog_list_top{
        display: flex;
        align-items: center;
        color: #A1A1A1;
        margin-bottom: 5px;
        .user{
          display: flex;
          align-items: center;
          color: #A1A1A1;
          &:hover{
            color: #ff8c00;
          }
        }
        img{
          height: 25px;
          width: 25px;
          margin-right: 5px;
          border-radius: 50%;
        }
      }
      .blog_list_content{
        display: flex;
        gap: 10px;
        >div{
          flex-grow: 1;
          h5{
            display: flex;
            flex-flow: wrap;
            gap: 5px;
            a{
              color: #000;
              font-size: 18px;
              font-weight: bold;
              &:hover{
                color: #ff8c00;
              }
            }
            span{
              background-color: #E4EFFF;
              color: #2580fd;
              padding: 3px 7px;
              border-radius: 4px;
              margin-bottom: -3px;
              position: relative;
              top: -2px;
              margin-left: 6px;
              font-size: 14px;
            }
          }
        }
        >img{
          flex-shrink: 0;
          width: 100%;
          max-width: 200px;
          object-fit: cover;
        }
      }
      .blog_list_bottom{
        display: flex;
        justify-content: space-around;
        margin-top: 5px;
        img{
          width: 25px;
          height: 25px;
          margin-right: 5px;
        }
      }
    }
  }
  .blog_user{
    border-radius: 8px;
    background-color: #fff;
    padding: 25px;
    margin-bottom: 15px;
    .user_info{
      text-align: center;
      img{
        width: 50px;
        height: 50px;
        border-radius: 50%;
        margin-bottom: 1rem;
      }
      h5{
        font-size: 16px;
      }
    }
    .blog_info{
      display: flex;
      justify-content: space-around;
      a{
        display: flex;
        flex-direction: column;
        align-items: center;
        .count{
          font-size: 28px;
          color: #429bff;
        }
        .title{
          color: #000;
        }
      }
    }
  }
  .blog_message{
    border-radius: 8px;
    background-color: #fff;
    padding: 15px;
    margin-bottom: 15px;
    h5{
      font-weight: bold;
      font-size: 16px;
    }
    img{
      width: 25px;
      height: 25px;
    }
    a{
      display: flex;
      gap: 10px;
      padding: 10px 0;
      color: #101010;
      &:hover{
        color: #ff8c00;
      }
      span{
        flex-shrink: 0;
        margin-left: auto;
        .ant-badge-count{
          min-width: 24px;
          height: 24px;
          font-size: 14px;
          line-height: 24px;
          border-radius: 12px;
        }
      }
    }
  }
  .blog_hot_post{
    flex-grow: 1;
    border-radius: 8px;
    background-color: #fff;
    padding: 15px;
    h5{
      font-size: 16px;
      font-weight: bold;
      display: flex;
      justify-content: space-between;
      align-items: center;
      span{
        font-size: 14px;
        color: #949494;
        cursor: pointer;
        display: flex;
        gap: 5px;
      }
    }
    ul{
      padding: 0;
      list-style: none;
      li{
        margin: 10px 0;
        a{
          color: #000;
          &:hover{
            color: #ff8c00;
          }
        }
      }
    }
  }
}
</style>
