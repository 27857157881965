<template>
  <div class="homework-detail">
    <a-breadcrumb separator=">">
      <a-breadcrumb-item><router-link to="/console/class">培训班级</router-link></a-breadcrumb-item>
      <a-breadcrumb-item><router-link :to="`/console/class/${classId}`">{{className}}</router-link></a-breadcrumb-item>
      <a-breadcrumb-item><router-link :to="`/console/class/${classId}/homework/finish`">做作业</router-link></a-breadcrumb-item>
      <a-breadcrumb-item>刷错题集</a-breadcrumb-item>
    </a-breadcrumb>
    <div class="info">
      <a-flex :justify="'space-between'" :align="'center'" style="margin-bottom: 20px;">
        <h1>{{data_list.name}}</h1>
        <a-affix :offset-top="10">
          <a-button v-if="editorMode == 'create' && data_list.question_count != 0" type="primary" class="submitHomework" @click="submitHomework">提交练习</a-button>
          <a-button type="primary" class="saveHomework" @click="_Back">返回</a-button>
        </a-affix>
      </a-flex>
      <a-spin :spinning="spinning" size="large" tip="Loading...">
        <a-flex :justify="'center'" class="mb-3">
          学生姓名：{{data_list.user_name}} 
          <div class="mx-3"></div> 
          学生编号：{{data_list.student_barcode}}
        </a-flex>
        <a-flex v-if="editorMode == 'readonly'" :justify="'center'" class="mb-3">
          总分：{{data_list.total}} 
          <div class="mx-3"></div> 
          及格分：{{data_list.pass_score}}
          <div class="mx-3"></div> 
          得分：{{data_list.score}}
          <div class="mx-3"></div> 
          结果：
            <a-typography-text v-if="data_list.exercise_result == '通过'" type="success">{{data_list.exercise_result}}</a-typography-text>
            <a-typography-text v-else-if="data_list.exercise_result == '未提交'" type="warning">{{data_list.exercise_result}}</a-typography-text>
            <a-typography-text v-else-if="data_list.exercise_result == '未通过'" type="danger">{{data_list.exercise_result}}</a-typography-text>
            <a-typography-text v-else>{{data_list.exercise_result}}</a-typography-text>
        </a-flex>
        <a-form ref="formRef" :model="create_list" :label-col="{style: {width: '150px'} }" 
          :disabled="editorMode == 'readonly'">
          <div v-for="(item,index) in data_list.question_ids" :key="item.id" class="mb-3">
            <a-flex justify="space-between">
              <a-flex>{{index+1}}、<div style="margin-bottom: -1em;" v-html="item.name"></div></a-flex>
              <a-flex>{{item.type}} <div class="mx-2"/> {{item.score}}分</a-flex>
            </a-flex>
            <a-flex v-if="editorMode == 'readonly'" justify="flex-end">
              答案：<template v-if="item.type == 'QA'"><div v-html="item.qa_solution"/></template>
              <template v-else-if="item.type == 'CODE'"><div v-html="item.code_solution"/></template>
              <template v-else>{{item.solution}}</template>
              <div class="mx-3"></div> 
              得分：{{create_list[index].answer_score}}
            </a-flex>
            <a-radio-group v-if="item.type == '单选'" v-model:value="create_list[index].answer" :style=style>
              <div v-for="option in options" :key="option" style="cursor: initial;">
                <a-radio v-if="`option_${option.toLowerCase()}` in data_list.question_ids[index]" :value="option">
                  <a-flex>{{option}}、<span style="margin-bottom: -1em;" v-html="data_list.question_ids[index]['option_' + option.toLowerCase()]"></span></a-flex>
                </a-radio>
              </div>
            </a-radio-group>
            <a-checkbox-group v-else-if="item.type == '多选'" v-model:value="create_list[index].answer" :style=style>
              <div v-for="option in options" :key="option" style="cursor: initial;">
                <a-checkbox v-if="`option_${option.toLowerCase()}` in data_list.question_ids[index]" :value="option">
                  <a-flex>{{option}}、<span style="margin-bottom: -1em;" v-html="data_list.question_ids[index]['option_' + option.toLowerCase()]"></span></a-flex>
                </a-checkbox>
              </div>
            </a-checkbox-group>
            <a-form-item v-else-if="item.type == '填空'">
              <a-textarea v-model:value="create_list[index].answer"/>
            </a-form-item>
            <a-radio-group v-else-if="item.type == '判断'" v-model:value="create_list[index].answer" :style=style>
              <div v-for="option in ['A','B']" :key="option" style="cursor: initial;">
                <a-radio v-if="`option_${option.toLowerCase()}` in data_list.question_ids[index]" :value="option">
                  <a-flex>{{option}}、<span style="margin-bottom: -1em;" v-html="data_list.question_ids[index]['option_' + option.toLowerCase()]"></span></a-flex>
                </a-radio>
              </div>
            </a-radio-group>
            <a-form-item v-else-if="item.type == '问答'">
              <RichEditor v-model="create_list[index].answer" :height="301" :editMode="editorMode"/>
            </a-form-item>
            <a-form-item v-else-if="item.type == '编程'">
              <RichEditor v-model="create_list[index].answer" :height="301" :editMode="editorMode"/>
            </a-form-item>
          </div>
          <a-empty v-if="data_list.question_count == 0" :image="simpleImage"/>
        </a-form>
      </a-spin>
    </div>
  </div>
</template>

<script setup>
import {useRouter} from "vue-router";
import {logDebug, logError} from "@/utils/logger";
import {getRouterParam,routerBack} from "@/utils/router_utils";
import {jsonRPC,getResponseData} from "@/utils/http_utils";
import {onMounted, reactive, ref} from 'vue'
import RichEditor from "@/components/RichEditor"
import {message} from "ant-design-vue";
import {Empty} from 'ant-design-vue';
const simpleImage = Empty.PRESENTED_IMAGE_SIMPLE;

const router = useRouter()
const workId = ref(getRouterParam(router, 'work_id'))
const query = router.currentRoute.value.query;
const editorMode = ref('create');
const classId = query.classId;
const className = query.class_name;
const formRef = ref();
const spinning = ref(false);
const options = Array.from({length: 8}, (_, i) => String.fromCharCode(65 + i))
const style = { display: 'flex', flexDirection: 'column' }
logDebug('ConsoleClassWrongQuestionSetDetail setup.')
const data_list = reactive({})
const create_list = reactive({})

function fetchData(e) {
  jsonRPC({
    url: "/vue/console/class/wrong_question_set/detail",
    params: {
      workId: workId.value,
      record_id: e,
    },
    success(res) {
      const data = getResponseData(res);
      logDebug(`获取成功`, data);
      Object.assign(data_list, data.records);
      Object.assign(create_list, data.answer_ids);
    },
    fail(error) {
      logError(`查询失败, `, error);
    },
  }).then(() => {
    spinning.value = false; // 加载完成后关闭加载状态
  });
}
onMounted(fetchData);
// 提交作业
let submitHomework = () => {
  spinning.value = true;
  jsonRPC({
    url: "/vue/console/class/wrong_question_set/submit",
    params: {
      workId: workId.value,
      create_list: create_list,
    },
    success(res) {
      const data = getResponseData(res)
      logDebug(`获取成功`, data)
      message.success('提交成功')
      editorMode.value = 'readonly'
      fetchData(data)
    },
    fail(error) {
      logError(`查询失败, `, error)
    },
  });
}
// 返回
let _Back = () => {
  routerBack(router);
}
</script>

<style scoped lang='scss'>
.homework-detail{
  height: 100%;
  background-color: #fff;
  .info{
    background-color: #fff;
    padding: 20px;
    height: 100%;
  }
  h1{
    font-size: 18px;
    font-weight: bold;
    padding-bottom: 5px;
    padding-right: 20px;
    border-bottom: 1px solid #63B1FF;
  }
  button{
    border-radius: 16px;
    &.submitHomework{
      background-color: #6FA6FF;
    }
    &.saveHomework{
      background-color: #D4D4D4;
    }
    &:not(:last-child) {
      margin-right: 10px;
    }
  }
}
:where(.css-dev-only-do-not-override-556tl4).ant-empty{
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
}
</style>
