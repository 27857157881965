export function logComponents(components, ...data) {
    const enableLogComponents = false
    if (enableLogComponents) {
        const componentNames = components.map(x => x.name)
        console.log(`logComponents[${componentNames}]`, data)
    }
}

export function logDebug(debug, ...data) {
    const enableLogDebug = false
    if (enableLogDebug) {
        console.log(`logDebug[${debug}]`, ...data)
    }
}

export function logInfo(info, ...data) {
    const enableLogInfo = false
    if (enableLogInfo) {
        console.info(`logInfo[${info}]`, ...data)
    }
}

export function logError(error, ...data) {
    console.error(`logError[${error}]`, ...data)
}
