<template>
  <div id="terminal-ttyd">
    <div v-if="isTttdReady">
      <iframe id="ttyd-iframe" class="ttyd-iframe" :src="ttyd_ingress_url" frameborder="0" :style="iframeStyle"/>
    </div>
    <div v-else>
      <a-flex class="loading" justify="center" align="center">
        <div>
          <LoadingOutlined style="font-size: 80px"/>
          <p>连接中，请稍后...</p>
        </div>
      </a-flex>

    </div>
  </div>
</template>
<script setup>

import {onMounted, ref} from 'vue';
import {logDebug, logError} from "@/utils/logger";
import {isFalse, isTrue} from "@/utils/common_utils";
import {LoadingOutlined} from '@ant-design/icons-vue';
import $ from "jquery";

logDebug('TerminalTtyd setup!')

const props = defineProps({
  primal: {type: String},
  data: {type: Object},
  styleSetting: {type: Object},
})

const contentHeight = ref(props.styleSetting.contentStyle.height)

const primal = props.primal
const data = props.data


onMounted(() => {
  // 在onMounted中获取数据
  logDebug(`TerminalTtyd onMounted.`)
})

const ttyd_ingress_url = data['ttyd_ingress_url']
const isTttdReady = ref(false)

function reloadIframe() {
  logDebug('reloadIframe')
  const totalTryCount = 60
  const tryIntervalMS = 3000
  const reloadIframeHandler = function (tryCount) {
    if (tryCount < 1) {
      logError(`reloadIframeHandler failed after try ${tryCount} times`)
      return;
    }

    $.ajax({
      url: ttyd_ingress_url,
      type: 'GET',
      // 告诉jQuery不要去处理发送的数据
      processData: false,
      // 告诉jQuery不要去设置Content-Type请求头
      contentType: false,
      success: function () {
        isTttdReady.value = true
      },
      error: function (responseStr) {
        logDebug("error:", responseStr);
        setTimeout(reloadIframeHandler, tryIntervalMS, tryCount - 1)
      }
    });
  }
  reloadIframeHandler(totalTryCount)
}

const isFirstSelected = ref(true)
const onSelected = function () {
  logDebug(`TerminalTtyd onSelected. data[${JSON.stringify(data)}]`)

  if (isFalse(isFirstSelected.value)) {
    return
  }
  isFirstSelected.value = false
}

const iframeStyle = ref('pointer-events: auto')
const setPointerEvents = function (enablePointerEvents = true) {
  if (isTrue(enablePointerEvents)) {
    iframeStyle.value = 'pointer-events: auto'
  } else {
    iframeStyle.value = 'pointer-events: none'
  }
}

onMounted(function () {
  setTimeout(reloadIframe, 10)
})

defineExpose({
  primal,
  data,
  onSelected,
  setPointerEvents,
})

</script>
<style scoped>
#terminal-ttyd {
  background: white;
  width: 100%;
  height: v-bind(contentHeight);
}

.ttyd-iframe {
  background: white;
  width: 100%;
  height: v-bind(contentHeight);
}

.loading {
  background: lightgray;
  height: v-bind(contentHeight);
}
</style>

