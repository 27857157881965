<template>
  <div class="payment p-0">
    <!--
    使用sandbox并且排除allow-top-navigation功能的原因在于，阻止iframe里面的内容重定向顶层页面的window.location.href路径。
    否则在桌面端的iframe，有可能将整个应用的页面重定向为src的路径。
    参考以下资料：https://stackoverflow.com/questions/369498/how-to-prevent-iframe-from-redirecting-top-level-window
    -->
    <iframe v-if="payment_type == 'alipay'" :src="url" frameborder="0" sandbox="allow-forms allow-popups allow-pointer-lock allow-same-origin allow-scripts"/>
    <template v-if="payment_type == 'wechatpay'">
      <h2 class="text-center">微信支付</h2>
      <canvas ref="qrcodeCanvas"></canvas>
    </template>
  </div>
</template>

<script setup>
import { getRouterQuery } from "@/utils/router_utils";
import { useRouter } from "vue-router";
const router = useRouter();
import {logDebug, logError} from "@/utils/logger";
import {ref,onMounted,onBeforeUnmount} from 'vue';
const order_id = ref(getRouterQuery(router, "order_id"));
const sales_order_id = ref(getRouterQuery(router, "sales_order_id"));
const payment_type = ref(getRouterQuery(router, "payment_type"));
const url = ref(sessionStorage.getItem('shop_url'))

const timerIds = ref([])

import {jsonOwlRPC} from "@/utils/http_utils";
import { message } from "ant-design-vue";
import QRCode from 'qrcode';
const qrcodeCanvas = ref(null);

onMounted(()=>{
  if (qrcodeCanvas.value) {
    QRCode.toCanvas(qrcodeCanvas.value, url.value, { width: 200 }, (error) => {
      if (error) logError(error);
      logDebug('QR code generated!');
    });
  }
  // 计时器
  let intervalID = setInterval(() => {
      _queryPaymentStatus();
  }, 5000);
  timerIds.value.push(intervalID)
  let timeoutID = setTimeout(()=>{
      clearInterval(intervalID);
  }, 1000 * 60 * 6);
  timerIds.value.push(timeoutID)
});
onBeforeUnmount(()=>{
  // 清除所有定时器
  timerIds.value.forEach(id => {
    clearInterval(id) // 清除 setInterval
    clearTimeout(id)  // 清除 setTimeout
  })
})
// 支付结果查询
const _queryPaymentStatus = async ()=>{
  if (payment_type.value == 'alipay') {
    jsonOwlRPC({
      url: `/payment/query/alipay/${order_id.value}/${sales_order_id.value}`,
      params: {},
      success(res) {
        logDebug(res);
        ModifyRecord()
      },
      fail(error) {
        logError(`查询失败, `, error);
      },
    });
  }
  if (payment_type.value == 'wechatpay') {
    jsonOwlRPC({
      url: `/payment/query/wechatpay/${order_id.value}/${sales_order_id.value}`,
      params: {},
      success(res) {
        logDebug(res);
        ModifyRecord()
      },
      fail(error) {
        logError(`查询失败, `, error);
      },
    });
  }
}
// 修改记录
const ModifyRecord = ()=>{
  jsonOwlRPC({
    url: `/search/order/info`,
    params:{
      order_id: order_id.value,
    },
    async success(res) {
      if (res.ordinary.length > 0) {
        await jsonOwlRPC({
          url: `/console/experiment/deployment/create_v2`,
          params:{
            data: res.ordinary,
          },
          success(res1) {
            logDebug(res1);
            message.success('支付成功！部署已调整')
            router.push('/order')
          },
          fail(error) {
            logError(`查询失败, `, error);
          },
        })
      }
      if (res.adjust.length > 0) {
        await jsonOwlRPC({
          url: `/console/experiment/app/env/adjust_v2`,
          params:{
            data: res.adjust,
          },
          success(res1) {
            logDebug(res1);
            message.success('支付成功！部署已创建')
            router.push('/order')
          },
          fail(error) {
            logError(`查询失败, `, error);
          },
        })
      }
      if (res.renew.length > 0) {
        await jsonOwlRPC({
          url: `/console/experiment/app/env/adjust_time`,
          params:{
            data: res.renew,
          },
          success(res1) {
            logDebug(res1);
            message.success('支付成功！部署已调整')
            router.push('/order')
          },
          fail(error) {
            logError(`查询失败, `, error);
          },
        })
      }
      if (res.payment_success) {
        router.push('/order')
        // 通知
        message.success('购买成功')
      }
    },
    fail(error) {
      logError(`查询失败, `, error);
    },
  })
}
</script>

<style scoped lang="scss">
.payment{
  height: calc(100vh - 50px);
  width: 100%;
  overflow: hidden !important;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  iframe{
    height: 100%;
    width: 100%;
  }
  canvas{
    height: 20vw !important;
    width: 20vw !important;
  }
}
</style>