<template>
  <a-layout id="layout">
    <!-- 头部页 -->
    <a-layout-header :style="headerStyle">
      <img v-if="hashWithoutHashSymbol=='/live'" src="/hw_frontend/static/img/live_bg.png">
      <img v-else-if="hashWithoutHashSymbol=='/course'" src="/hw_frontend/static/img/course_bg.png">
      <img v-else-if="hashWithoutHashSymbol=='/app'" src="/hw_frontend/static/img/app_bg.png">
      <!-- <img v-else-if="hashWithoutHashSymbol=='training'" src="/hw_frontend/static/img/training_bg.png"> -->
      <img v-else-if="hashWithoutHashSymbol=='/job'" src="/hw_frontend/static/img/job_bg.png">
      <img v-else-if="hashWithoutHashSymbol=='/blog'" src="/hw_frontend/static/img/blog_bg.png">
      <img v-else-if="hashWithoutHashSymbol.includes('/blog/homepage')" src="/hw_frontend/static/img/blog_bg.png">
      <img v-else-if="data_list.is_Christmas" src="/hw_frontend/static/img/Christmas_bg.png"><!-- 圣诞背景 -->
      <img v-else-if="data_list.is_SpringFestival" src="/hw_frontend/static/img/SpringFestival_bg.png"><!-- 春节背景 -->
      <div>
        <div class="logo">
          <router-link to="/home">
            <img v-if="validKeys.includes(hashWithoutHashSymbol) || hashWithoutHashSymbol.includes('/blog/homepage')" 
              src="/hw_frontend/static/img/hi168_logo_white.png">
            <img v-else-if="data_list.is_Christmas" src="/hw_frontend/static/img/Christmas_logo_white.png"><!-- 圣诞logo -->
            <img v-else-if="data_list.is_SpringFestival" src="/hw_frontend/static/img/SpringFestival_logo_white.png"><!-- 春节logo -->
            <img v-else src="/hw_frontend/static/img/hi168_logo_black.png">
          </router-link>
        </div>
        <a-menu class="menu" theme="dark" mode="horizontal" v-model:selectedKeys="selectedKeys">
          <a-row class="menu-row">
            <a-col class="menu-left" :span="16">
              <a-menu-item class="menu-item" key="home">
                <router-link to="/home">首页</router-link>
              </a-menu-item>
              <a-menu-item class="menu-item" key="live">
                <router-link to="/live">在线直播</router-link>
              </a-menu-item>
              <a-menu-item class="menu-item" key="course">
                <router-link to="/course">实验课程</router-link>
              </a-menu-item>
              <a-menu-item class="menu-item" key="app">
                <router-link to="/app">应用中心</router-link>
              </a-menu-item>
              <a-menu-item class="menu-item" key="training">
                <router-link to="/training">证书培训</router-link>
              </a-menu-item>
              <a-menu-item class="menu-item" key="job">
                <router-link to="/job">校园招聘</router-link>
              </a-menu-item>
              <a-menu-item class="menu-item" key="blog">
                <router-link to="/blog">技术论坛</router-link>
              </a-menu-item>
            </a-col>
            <a-col class="menu-right" :span="8">
              <div v-show="isLogin">
                <a-tooltip v-if="data_list.download_show">
                  <template #title>桌面版下载中心</template>
                  <a-menu-item class="menu-item" key="download">
                    <router-link to="/download">
                      <img v-if="validKeys.includes(hashWithoutHashSymbol) 
                        || data_list.is_SpringFestival || data_list.is_Christmas
                        || hashWithoutHashSymbol.includes('/blog/homepage')" 
                        src="/hw_web/static/src/img/desktop_download.png"/>
                      <img v-else src="/hw_web/static/src/img/desktop_download1.png"/>
                    </router-link>
                  </a-menu-item>
                </a-tooltip>
                <a-tooltip>
                  <template #title>购物车</template>
                  <a-menu-item class="menu-item" key="shop">
                    <router-link to="/shop">
                      <img v-if="validKeys.includes(hashWithoutHashSymbol) 
                        || data_list.is_SpringFestival || data_list.is_Christmas
                        || hashWithoutHashSymbol.includes('/blog/homepage')" 
                        src="/hw_web/static/src/img/shopping_cart.png"/>
                      <img v-else src="/hw_web/static/src/img/shopping_cart1.png"/>
                    </router-link>
                  </a-menu-item>
                </a-tooltip>
                <!-- <a-tooltip>
                  <template #title>费用中心</template>
                  <a-menu-item class="menu-item" key="cost">
                    <a href="/cost">
                      <img src="@/assets/cost1.png" />
                    </a>
                  </a-menu-item>
                </a-tooltip> -->
                <!-- <a-tooltip>
                  <template #title>控制台</template>
                  <a-menu-item class="menu-item" key="console">
                    <router-link to="/console">
                      <img src="@/assets/console1.png" />
                    </router-link>
                  </a-menu-item>
                </a-tooltip> -->
                <a-tooltip>
                  <template #title>桌面端</template>
                  <a-menu-item class="menu-item" key="desktop">
                    <router-link to="/desktop">
                      <img v-if="validKeys.includes(hashWithoutHashSymbol) 
                        || data_list.is_SpringFestival || data_list.is_Christmas
                        || hashWithoutHashSymbol.includes('/blog/homepage')" 
                        src="/hw_web/static/src/img/console.png"/>
                      <img v-else src="/hw_web/static/src/img/console1.png"/>
                    </router-link>
                  </a-menu-item>
                </a-tooltip>
                <a-dropdown>
                  <a class="ant-dropdown-link" >
                    {{ data_list.user_name }}
                    <DownOutlined/>
                  </a>
                  <template #overlay>
                    <a-menu>
                      <a-menu-item key="web" v-if="!is_student">
                        <a-button type="text" href="/web">进入后台</a-button>
                      </a-menu-item>
                      <a-menu-item key="logout">
                        <a-button type="text" @click="logout">退出登录</a-button>
                      </a-menu-item>
                    </a-menu>
                  </template>
                </a-dropdown>
              </div>
              <div v-show="!isLogin">
                <a-menu-item class="menu-item" key="login">
                  <router-link to="/login" @click="()=>handleClick()">
                    <span>登录</span>
                  </router-link>
                </a-menu-item>
              </div>
            </a-col>
          </a-row>
        </a-menu>
      </div>
    </a-layout-header>
    <!-- 主体页 -->
    <a-layout-content>
      <router-view :key="`${new Date().getTime()}`"></router-view>
    </a-layout-content>
    <!-- 底部页 -->
    <a-layout-footer :style="{ backgroundColor: (data_list.is_Christmas || data_list.is_SpringFestival) ? '#c10100' : '#4F8BDE' }">
      <a-row :gutter="[30,30]">
        <a-col :span="24" :md="8">
          <div class="footer_top">
            <router-link to="/home" @click="()=>handleClick()">
              <img v-if="data_list.is_Christmas" src="/hw_frontend/static/img/Christmas_logo_white.png"><!-- 圣诞logo -->
              <img v-else-if="data_list.is_SpringFestival" src="/hw_frontend/static/img/SpringFestival_logo_white.png"><!-- 春节logo -->
              <img v-else src="/hw_web/static/src/web/img/hi168_logo_light.png"/>
            </router-link>
          </div>
          <p>
            Hi168云平台，集合直播实训、在线课程、云应用于一体。
            模拟线下教学环境，帮助用户快速进入学习节奏，省去下载系统、在本地搭建环境等一系列繁琐的前置条件，快速上手。
            平台搭载海量在线内容，涵盖人工智能、神经网络、元宇宙、区块链等多个热门领域。
          </p>
          <div class="exchange">
            <div name="home_config_official_account" v-if="data_list.home_page_footer_qrcode_a">
              <img :src="`/web/image/hw.config.parameter/${data_list.home_page_footer_qrcode_a.id}/image_256`"/>
              {{ data_list.home_page_footer_qrcode_a.name }}
            </div>
            <div name="WeChat_applet_Code" v-if="data_list.WeChat_applet_Code">
              <img :src="`/web/image/hw.config.parameter/${data_list.WeChat_applet_Code}/image_256`"/>
              Hi168小程序
            </div>
          </div>
        </a-col>
        <a-col :span="24" :md="8">
          <div class="footer_top">精品应用</div>
          <a-row :gutter="[16,16]">
            <a-col :span="12">
              <router-link to=""></router-link>
              <router-link to=""></router-link>
              <router-link :to="`/app/${item.id}`" v-for="item in data_list.footer_experiment_template" :key="item.id">
                {{ item.name }}
              </router-link>
            </a-col>
            <a-col :span="12">
              <router-link :to="`/app/${item.id}`" v-for="item in data_list.footer_experiment_template1" :key="item.id">
                {{ item.name }}
              </router-link>
            </a-col>
          </a-row>
        </a-col>
        <a-col :span="24" :md="8">
          <div class="footer_top">海文平台</div>
          <a-row :gutter="[16,16]">
            <a-col :span="12">
              <!-- <a href="/about">关于我们</a> -->
              <a href="/special/abc">ABC人工智能教学与实训平台</a>
              <a href="/special/hwerp">HWERP企业管理系统</a>
              <a href="/special/wwerp">WWERP教务管理系统</a>
              <a href="/special/wwerp">五维教育模式管理评价系统</a>
              <a href="/special/wwerp">五维综合管理应用系统</a>
              <router-link to="/blog">Hi168博客平台</router-link>
              <router-link to="/course">Hi168课程平台</router-link>
              <router-link to="/app">Hi168应用平台</router-link>
            </a-col>
            <a-col :span="12">
              <a href="/case">Hi168判题系统</a>
              <a href="/special/cloud">Cloud云学习流视频管理系统</a>
            </a-col>
          </a-row>
        </a-col>
      </a-row>
      <div name="home_config_copyright|home_config_filings" class="text-center">
        <span class="text-sm-left text-center">版权所有 © {{ data_list.home_config_copyright }}</span> |
        <a href="https://beian.miit.gov.cn/" target="_blank">{{ data_list.home_config_filings }}</a> |
        <a href="https://beian.mps.gov.cn/#/query/webSearch?code=31010602008113" target="_blank">
          <img src="https://gaj.sh.gov.cn/shga/resource/img/logo-small.png" style="height: 17px;margin-top: -4px;"/>
          沪公网安备31010602008113号</a> |
        <span>沪静人社3101060101057</span> |
        <span>违法和不良信息举报电话021-22500846</span>
      </div>
      <div class="honour mt-3">
        <a href="https://www.shjbzx.cn" title="上海市互联网违法与违规信息举报中心" target="_blank"></a>
        <a href="https://www.creditchina.gov.cn" title="信用中国" target="_blank"></a>
        <a href="https://www.12377.cn" title="网上有害信息举报专区" target="_blank"></a>
      </div>
    </a-layout-footer>
  </a-layout>
</template>

<script setup>
import {DownOutlined} from "@ant-design/icons-vue";
import {onMounted, reactive, ref, watch, computed} from "vue";
import {logDebug, logError} from "@/utils/logger";
import {watchRouterSelectedKeys} from "@/utils/router_utils";
import {getResponseData, jsonRPC} from "@/utils/http_utils";
import {message} from "ant-design-vue";
import {trackPage, trackUV} from "@/utils/trackPage";
import {useRouter} from "vue-router";
const router = useRouter();

// 创建响应式数据存储 hash 路径
const hashWithoutHashSymbol = ref('');
// 获取当前页面的 hash
const getHash = () => {
  const hash = window.location.hash.replace('#', '');  // 获取 hash 去掉 '#'
  hashWithoutHashSymbol.value = hash;
};
// 监听路由变化
watch(
  () => router.currentRoute.value.params,  // 监听路由中的 hash 值
  () => {
    getHash();  // 当 hash 值变化时，更新 hash
  },
  { immediate: true } // 立即触发一次以获取初始值
);

const selectedKeys = ref([]);
watchRouterSelectedKeys(router, selectedKeys, {
  home: "/home",
  live: "/live",
  course: "/course",
  app: "/app",
  job: "/job",
  blog: "/blog",
  test: "/test",
  shop: "/shop",
  cost: "/cost",
  console: "/console",
  desktop: "/desktop",
});
const validKeys = ['/app', '/live', '/course', '/job', '/blog', '/blog/homepage'];
const menuTextColor = computed(() => {
  const excludeKeys = ['/job'];
  // 使用 filter 排除多个元素
  const updatedKeys = validKeys.filter(key => !excludeKeys.includes(key));
  return updatedKeys.includes(hashWithoutHashSymbol.value) 
  || data_list.is_SpringFestival || data_list.is_Christmas
  || hashWithoutHashSymbol.value.includes('/blog/homepage')?'#fff':'#000';
});
const headerStyle = computed(() => {
  return {
    height: validKeys.includes(hashWithoutHashSymbol.value) || hashWithoutHashSymbol.value.includes('/blog/homepage')?'120px':'50px',
  };
});

const isLogin = ref(false);
const logout = () => {
  jsonRPC({
    url: "/hw_web/logout",
    params: {},
    success(res) {
      isLogin.value = false;
      logDebug(`退出登录成功`, res);
      router.push("/home")
    },
    fail(error) {
      logError(`退出登录失败`, error);
      message.error(`退出登录失败`);
    },
  });
};

const checkLogin = () => {
  jsonRPC({
    url: "/hw_web/validate_session",
    params: {},
    success(res) {
      logDebug(`validate_session success, `, res);
      isLogin.value = true;
      fetchData();
    },
    fail(error) {
      logError(`validate_session error, `, error);
      isLogin.value = false;
    },
  });
};

if (!isLogin.value) {
  checkLogin();
}

watch(router.currentRoute, () => {
  logDebug("检查登录状态");
  checkLogin();
});

const data_list = reactive({
  student_id: 2,
  home_page_footer_qrcode_a: false,
  WeChat_applet_Code: false,
  footer_experiment_template: [],
  home_config_copyright: '',
  home_config_filings: '',
  download_show: false,
  is_Christmas: false,
  is_SpringFestival: false,
});

function fetchData() {
  jsonRPC({
    url: "/vue/home/layout",
    params: {},
    success(res) {
      const data = getResponseData(res)
      logDebug(`获取layout数据成功`, data)
      Object.assign(data_list, data);
      sessionStorage.setItem('user_id', data.user_id);
    },
    fail(error) {
      logError(`获取失败`, error);
      message.error(`获取用户失败`);
    },
  });
}

const is_student = ref(true)
onMounted(() => {
  fetchData();
  jsonRPC({
    url: `/vue/users/appIcon`,
    success(res) {
      const data = getResponseData(res);
      is_student.value = data.is_student;
    },
    fail(error) {
      logError('判断是学生用户出错', error);
    },
  });
});

function handleClick() {
  // 调用 trackPage 函数来记录页面信息
  trackPage(router.currentRoute);
}

// 在页面加载时触发 UV 埋点
onMounted(() => {
  // 调用 trackUV 来统计 UV 数据
  trackUV(router.currentRoute);
});
</script>

<style lang="scss">
#layout {
  min-height: 100vh;
  >header {
    position: relative;
    width: 100%;
    height: 50px;
    background-color: #fff;
    overflow: hidden;
    >img{
      position: absolute;
      top: 0;
      bottom: 0;
      left: 0;
      right: 0;
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
    >div{
      position: absolute;
      top: 0;
      bottom: 0;
      left: 0;
      right: 0;
      width: 100%;
      height: 50px;
      line-height: 50px;
      padding: 0 3rem;
      display: flex;
      flex-flow: row;
      .logo {
        flex-shrink: 0;
        margin-right: 3rem;
        img{
          height: 38px;
        }
      }
      .menu {
        width: 100%;
        display: flex;
        flex-flow: row;
        background-color: transparent;
        .menu-item {
          border-radius: 0;
          background-color: transparent;
          height: 50px;
          line-height: 50px;
          font-weight: bold;
          padding: 0 1vw;
          a{
            color: v-bind(menuTextColor);
          }
          img {
            height: 24px;
            width: 24px;
            margin-bottom: 4px;
          }
        }
        .menu-row {
          width: 100%;
          .menu-left {
            .menu-item {
              &.ant-menu-item-selected,
              &:hover {
                a{
                  position: relative;
                  color: #206AD1;
                  padding: 3px 10px 4px;
                  border-radius: 13px;
                  background-color: #f0f6ff99;
                  vertical-align: bottom;
                  margin: 0 -10px;
                }
              }
            }
          }
          .menu-right {
            width: 100%;
            display: flex;
            flex-flow: row;
            justify-content: flex-end;
          }
        }
        .ant-dropdown-link {
          color: v-bind(menuTextColor);
          font-weight: bold;
          white-space: nowrap;
          margin-left: 1vw;
        }
      }
    }
  }
  >main {
    background: #f5f5f5;
    padding: 0;
    min-height: 80vh;
  }
  >footer {
    background-color: #4F8BDE;
    color: #fff;
    a {
      color: #fff;
      &:hover {
        color: #e57f14;
      }
    }
    .footer_top ~ div a {
      display: block;
      margin-bottom: 0.5rem;
      &.active {
        color: #3291f8;
      }
    }
    .footer_top {
      font-size: 18px;
      line-height: 40px;
      margin-bottom: 1rem;
      img {
        height: 40px;
      }
    }
    .exchange {
      display: flex;
      justify-content: space-evenly;
      text-align: center;
      margin-bottom: 1rem;
      img {
        width: 150px;
        height: 150px;
        display: block;
        margin-bottom: 0.5rem;
      }
    }
    .honour {
      display: flex;
      align-items: center;
      justify-content: center;
      a {
        &:not(:last-child) {
          margin-right: 1rem;
        }
        display: inline-block;
        height: 32px;
        background-image: url("@/assets/phone_list20190617.png");
        text-indent: -99em;
        &:first-child {
          width: 86px;
          background-position: 0 -387px;
        }
        &:nth-child(2) {
          width: 102px;
          background-position: 0 -429px;
        }
        &:last-child {
          width: 114px;
          background-position: 0 -155px;
        }
      }
    }
  }
}

.ant-layout-sider-trigger {
  position: relative !important;
}
</style>
