<template>
  <div class="container mb-4">
    <div class="menu_168">
      <div>
        <router-link to="/live" class="menu_basic">
          <div><img src="/hw_web/static/src/web/img/live_icon.png"/></div>
          直播
        </router-link>
        <div class="menu_basic">
          <div style="background-color: #F3CB68;">
            <img src="/hw_web/static/src/web/img/training_icon.png"/>
          </div>
          培训
        </div>
        <router-link to="/app" class="menu_basic">
          <div><img src="/hw_web/static/src/web/img/app_icon.png"/></div>
          应用
        </router-link>
        <div class="menu_custom">
          <div class="menu_foundation mb-0">
            <a :class="{ active: type_id === 0 }" @click="updateFilters('type_id', 0)">全部</a>
            <div v-for="item in type_ids" :key="item.id">
              <a :class="{ active: item.id === type_id }" @click="updateFilters('type_id', item.id)">
                {{ item.name }}
              </a>
            </div>
          </div>
          <div class="menu_append">
            <a :class="{ active: category_id === 0 }" @click="updateFilters('category_id', 0)">全部</a>
            <div v-for="item in category_ids" :key="item.id">
              <a :class="{ active: item.id === category_id }" @click="updateFilters('category_id', item.id)">
                {{ item.name }}
              </a>
            </div>
          </div>
        </div>
      </div>
      <a-input-search placeholder="请输入名称" enter-button allowClear @search="onSearch" style="width: 200px"/>
    </div>
    <div class="course_list">
      <a-list :grid="{ gutter: 16, column: 4, xs: 1, sm: 2 }" :data-source="data_list">
        <template #renderItem="{ item }">
          <a-list-item class="px-0">
            <a-card hoverable style="width: 100%; height: 100%" @click="enterDetail(item.id)">
              <template #cover>
                <div class="info">
                  <!-- <span>系列课程</span> -->
                  <span v-if="item.list_price > 0" class="new">新课特惠</span>
                  <span v-else>限时免费</span>
                  <a class="img_box">
                    <img v-if="item.image" :src="`/web/image/hw.course/${item.id}/image_512?t=${new Date().getTime()}`"/>
                    <img v-else src="/hw_web/static/src/img/course_default.png"/>
                  </a>
                  <div>
                    <a class="line-clamp-1" :title="item.name">{{ item.name }}</a>
                    <div class="summary">
                      <span v-for="category in item.course_category_ids" :key="category.id">{{ category.name }}</span>
                    </div>
                    <a-flex justify="space-between" align="center">
                      {{ item.student_num }} 人已参加
                      <div class="price">
                        <span v-if="item.is_purchase">免费</span>
                        <span v-else-if="item.list_price > 0">￥{{item.list_price}}</span>
                      </div>
                    </a-flex>
                  </div>
                </div>
              </template>
            </a-card>
          </a-list-item>
        </template>
      </a-list>
      <a-flex justify="center">
        <a-pagination
          v-model:current="current"
          :showSizeChanger="showSizeChanger"
          v-model:page-size="pageSize"
          :total="total"
          :locale="zhCn"
          :pageSizeOptions="pageSizeOptions"
          :hideOnSinglePage="true"
        />
      </a-flex>
    </div>
  </div>
</template>

<script setup>
import { onMounted, ref, watch } from "vue";
import { jsonRPC, getResponseData } from "@/utils/http_utils";
import {logDebug, logError} from "@/utils/logger";
import { zhCn } from "@/utils/zhCn";
import { useRouter } from 'vue-router';
const router = useRouter();

const enterDetail = (e)=>{
  router.push(`/course/${e}`);
}

// 更新过滤条件
const updateFilters = (type, value) => {
  if (type === "type_id") {
    type_id.value = value;
  } else if (type === "category_id") {
    category_id.value = value;
  }
};
const type_id = ref(0);
const type_ids = ref([]);
const category_id = ref(0);
const category_ids = ref([]);
const data_list = ref([]);
const total = ref(0);
const showSizeChanger = ref(true);
const pageSize = ref(8);
const current = ref(1);
const search = ref("");
const pageSizeOptions = ref(
  Array.from({ length: 4 }, (_, index) =>
    (pageSize.value * (index + 1)).toString()
  )
);

function fetchData() {
  jsonRPC({
    url: "/api/course",
    params: {
      type_id: type_id.value,
      category_id: category_id.value,
      page_index: current.value,
      page_size: pageSize.value,
      search: search.value,
    },
    success(res) {
      const data = getResponseData(res);
      logDebug(`获取成功`, data);
      data_list.value = data.records;
      total.value = data.record_count;
      type_ids.value = data.type_ids;
      category_ids.value = data.category_ids;
    },
    fail(error) {
      logError(`查询失败, `, error);
    },
  });
}
onMounted(()=>{
  fetchData();
});
watch([type_id, category_id, pageSize, current], fetchData);

const onSearch = (searchValue) => {
  if (search.value != searchValue) {
    current.value = 1;
    search.value = searchValue;
    fetchData();
  }
};

import {trackUV} from "@/utils/trackPage";
// 在页面加载时触发 UV 埋点
onMounted(() => {
  // 调用 trackUV 来统计 UV 数据
  trackUV(router.currentRoute);
});

</script>

<style scoped lang="scss">
.menu_168 {
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
  margin-top: 20px;
  margin-bottom: 20px;
  > div:first-child {
    display: flex;
    align-items: center;
    .menu_basic {
      text-align: center;
      margin-right: 10px;
      color: #757575;
      div {
        width: 50px;
        height: 50px;
        border-radius: 50%;
        background-color: #d6d6d6;
        display: flex;
        justify-content: center;
        align-items: center;
        margin-bottom: 5px;
      }
      img {
        width: 30px;
        height: 30px;
      }
    }
    .menu_custom {
      .menu_foundation,
      .menu_append {
        display: flex;
        flex-wrap: wrap;
        margin-bottom: -10px;
        a {
          display: inline-block;
          border: 1px solid #d3d3d3;
          border-radius: 15px;
          color: #a9a9a9;
          line-height: 28px;
          padding: 0 15px;
          margin-right: 10px;
          margin-bottom: 10px;
          &.active {
            color: #4e95e0;
            background-color: #e6e6e6;
          }
        }
      }
    }
  }
  .ant-input-search {
    flex-shrink: 0;
  }
  .search_bar {
    display: flex;
    align-items: center;
    background-color: #fff;
    border-radius: 3px;
    padding: 0.25rem 0.5rem;
    input {
      border: none;
      background-color: transparent;
      &:focus-visible {
        outline: none;
      }
      &:focus {
        box-shadow: none;
      }
    }
    button {
      font-weight: normal;
      bottom: auto;
      color: #77b7ff;
    }
  }
  & + .home_live {
    .info-special {
      background-color: #fff;
    }
    .info {
      background-color: #fff;
    }
  }
}
.course_list{
  padding: 16px;
  .info {
    display: flex;
    flex-direction: column;
    height: 100%;
    background-color: #fff;
    position: relative;
    border-radius: 8px;
    overflow: hidden;
    >span {
      display: inline-block;
      position: absolute;
      top: 0;
      left: 0;
      padding: 0 15px;
      line-height: 30px;
      border-bottom-right-radius: 8px;
      z-index: 1;
      background-color: #90FAFF;
      &.new{
        background-color: #90FFA5;
      }
    }
    >div{
      flex: 1 0 auto;
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      padding: 1rem;
      .line-clamp-1{
        margin-bottom: 5px;
        font-size: 16px;
        color: #000;
      }
      .summary {
        span {
          display: inline-block;
          color: #52c9ff;
          font-size: 12px;
          border: 1px solid #52c9ff;
          border-radius: 4px;
          padding: 1px 4px;
          margin-right: 5px;
          margin-bottom: 5px;
        }
      }
      .price{
        span{
          font-size: 16px;
          color:#39BFED;
        }
      }
    }
  }
}
</style>