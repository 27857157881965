export function isCreateMode(id) {
    const idStr = `${id}`.toLowerCase()
    const createModeKeys = ['new', 'create']
    return createModeKeys.includes(idStr)
}

// 设置编辑模式，mode只支持三种值，即['readonly', 'create', 'update']，对于其他非法值，就设置为'update'。
// mode的三种值中，'readonly'表示只读模式，'create'表示创建模式，'update'表示更新模式。
// 如果进入的是'readonly'模式的Form页面，通常可以添加一个”编辑“按钮，使其可以在'readonly'和'create'模式之间切换。
export function setEditorMode(editorMode, mode) {
    const modeStr = `${mode}`.toLowerCase().trim()
    const modes = ['readonly', 'create', 'update']
    if (modes.includes(modeStr)) {
        editorMode.value = modeStr
    } else {
        editorMode.value = 'update'
    }
}

// 自动根据路由的路径参数（即class_id、live_id等各种id的值）来判断将要进入的Form页面（参考Odoo的Form视图）是哪种编辑模式。
// 对于id为['new', 'create']这两种情况，就进入create模式的Form页面。
// 对于id不为['new', 'create']的情况，通常id为数字，表示数据库里的id，比如Odoo里的user_id.id等，那么就进入update模式。
// 但是，如果明确指定了编辑模式参数mode，那就严格按照mode指定的值进入对应模式的Form页面。
// mode只支持三种值，即['readonly', 'create', 'update']，对于其他非法值，就设置为'update'。
// mode的三种值中，'readonly'表示只读模式，'create'表示创建模式，'update'表示更新模式。
// 如果进入的是'readonly'模式的Form页面，通常可以添加一个”编辑“按钮，使其可以在'readonly'和'create'模式之间切换。
export function resolveEditorMode(editorMode, id, mode = undefined) {
    if (typeof mode != 'undefined') {
        setEditorMode(editorMode, mode)
        return
    }

    if (isCreateMode(id)) {
        editorMode.value = 'create'
    } else {
        editorMode.value = 'update'
    }
}


