<template>
  <div class="exercise_list">
    <div class="search">
      <a-input-search allowClear enter-button placeholder="请输入名称" style="width: 200px" @search="onSearch"/>
    </div>
    <a-table :columns="columns" :dataSource="data_list">
      <template #bodyCell="{ column, record, text }">
        <template v-if="column.dataIndex == 'duration'">{{text==0?'不限制':text+'分钟'}}</template>
        <template v-if="column.dataIndex == 'pass_criterion'">{{text+'%'}}</template>
        <template v-if="column.dataIndex == 'operation'">
          <a href="#" @click.prevent="create_exercise(record.id)">开始练习</a>
        </template>
      </template>
    </a-table>
  </div>
</template>

<script setup>
import { logDebug, logError } from "@/utils/logger";
import { onMounted, ref } from "vue";
import { jsonOwlRPC } from "@/utils/http_utils";
import { message } from "ant-design-vue";
const emits = defineEmits(['call-parent-method']);
const create_exercise = (e)=>{
  emits('call-parent-method', {
    component: 'detail',
    exercise_id: e,
  });
}
const columns = [
  {
    title: '练习ID',
    dataIndex: 'id',
    align: 'center',
  },
  {
    title: '练习名称',
    dataIndex: 'name',
    align: 'center',
  },
  {
    title: '题目总数',
    dataIndex: 'question_nums',
    align: 'center',
  },
  {
    title: '练习限时',
    dataIndex: 'duration',
    align: 'center',
  },
  {
    title: '练习总分',
    dataIndex: 'total',
    align: 'center',
  },
  {
    title: '通过标准',
    dataIndex: 'pass_criterion',
    align: 'center',
  },
  {
    title: '操作',
    dataIndex: 'operation',
    align: 'center',
  },
];
const data_list = ref([])
const search = ref('');
function fetchData() {
  jsonOwlRPC({
    url: `/job/exercise/paper`,
    params: {
      key_val: search.value,
    },
    success(res) {
      logDebug(`获取练习成功`, res);
      data_list.value = res.records
    },
    fail(error) {
      logError(`获取练习失败`, error);
      message.error(`获取练习失败，请稍后再试`);
    },
  });
}
onMounted(() => {
  fetchData();
});
const onSearch = (searchValue) => {
  if (search.value != searchValue) {
    search.value = searchValue;
    fetchData();
  }
};
</script>

<style scoped lang="scss">
.exercise_list{
  background-color: #fff;
  padding: 20px;
  .search{
    margin-bottom: 20px;
    display: flex;
    justify-content: flex-end;
  }
}
:deep(.ant-table-thead >tr>th){
  background-color: #dcedff;
}
</style>
