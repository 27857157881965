<template>
  <div id="class-form">
    <a-row v-if="editorMode === 'create'" style="margin: 10px">
      <a-col :span="20">
        <a-typography-title :level="4">
          创建班级
        </a-typography-title>
      </a-col>
      <a-col :span="4" style="display: flex; justify-content: center">
        <a-button type="primary" @click="cancelForm">
          取消创建
        </a-button>
      </a-col>
    </a-row>
    <a-row v-else-if="editorMode === 'update'" style="margin: 10px">
      <a-col :span="20">
        <a-typography-title :level="4">
          编辑班级
        </a-typography-title>
      </a-col>
      <a-col :span="4" style="display: flex; justify-content: center">
        <a-button type="primary" @click="cancelForm">
          取消编辑
        </a-button>
      </a-col>
    </a-row>
    <a-row v-else-if="editorMode === 'readonly'" style="margin: 10px">
      <a-col :span="18">
        <a-typography-title :level="4">
          班级详情
        </a-typography-title>
      </a-col>
      <a-col :span="6" style="display: flex; justify-content: space-around">
        <div>
          <a-button type="primary" @click="openDeleteModal=true">删除</a-button>
          <a-modal v-model:open="openDeleteModal" title="删除" @ok="confirmFormDelete">
            <p>确定删除？ 删除ID：{{ classId }}</p>
          </a-modal>
        </div>
        <a-button type="primary" @click="cancelForm">
          返回
        </a-button>
        <a-button type="primary" @click="editorMode = 'update'">
          进入编辑
        </a-button>
      </a-col>
    </a-row>
    <a-row v-else style="margin: 10px">
      <a-col :span="20">
        <a-typography-title :level="4">
          班级详情
        </a-typography-title>
      </a-col>
      <a-col :span="4" style="display: flex; justify-content: center">
        <a-button type="primary" v-if="editorMode === 'readonly'" @click="editorMode = 'update'">
          进入编辑
        </a-button>
      </a-col>
    </a-row>
    <a-form ref="formRef" :model="data_list" :rules="rules"
      :label-col="labelCol"
      :wrapper-col="wrapperCol"
      layout="horizontal"
      :disabled="editorMode === 'readonly'"
    >
      <a-row>
        <a-col :span="12">
          <a-form-item label="班级名称：" name='class_name'>
            <a-input v-model:value="data_list.class_name"/>
          </a-form-item>
          <a-form-item label="培训难度：">
            <a-select v-model:value="data_list.difficulty">
              <a-select-option v-for="item in data_list.difficulty_ids" :key="item.index" :value="item[0]">
                {{ item[1] }}
              </a-select-option>
            </a-select>
          </a-form-item>
          <a-form-item label="课时数：">
            <a-input-number v-model:value="data_list.lesson_hours" min="1"/>
          </a-form-item>
          <a-form-item label="培训定价：">
            <a-input-number v-model:value="data_list.course_price" min="0"/>
          </a-form-item>
          <a-form-item label="培训课程：">
            <a-select v-model:value="data_list.course_ids" mode="multiple" :max-tag-count="3">
              <a-select-option v-for="item in data_list.course_options" :key="item.id" :value="item.id">
                {{ item.name }}
              </a-select-option>
            </a-select>
          </a-form-item>
          <a-form-item label="培训直播：">
            <a-select v-model:value="data_list.live_ids" mode="multiple" :max-tag-count="3">
              <a-select-option v-for="item in data_list.live_options" :key="item.id" :value="item.id">
                {{ item.name }}
              </a-select-option>
            </a-select>
          </a-form-item>
        </a-col>
        <a-col :span="12">
          <a-form-item label="培训封面：" name='file_list'>
            <a-upload
                v-model:file-list="data_list.file_list"
                :customRequest="uploadDummyRequest"
                list-type="picture-card"
                @preview="handlePreview"
                :max-count="1"
                accept=".png,.jpg,.jpeg"
            >
              <div v-if="data_list.file_list.length < 1">
                <plus-outlined/>
                <div class="ant-upload-text">上传图片</div>
              </div>
            </a-upload>
            <a-modal :open="previewVisible" :title="previewTitle" :footer="null" @cancel="cancelPreview">
              <img alt="example" style="width: 100%" :src="previewImage"/>
            </a-modal>
          </a-form-item>
        </a-col>
      </a-row>
      <a-flex justify="center" align="center" class="mt-4">
        <a-button type="primary" v-if="editorMode === 'create'" @click="confirmFormCreate">创建</a-button>
        <a-button type="primary" v-if="editorMode === 'update'" @click="confirmFormUpdate">保存</a-button>
        <a-button v-if="editorMode !== 'readonly'" style="margin-left: 10px" @click="cancelForm">取消</a-button>
      </a-flex>
    </a-form>
  </div>
</template>

<script setup>
import {logComponents, logDebug,logError} from "@/utils/logger";
import {onMounted, reactive, ref} from 'vue'
import {routerBack, getRouterParam, getRouterQuery, routerReplace} from "@/utils/router_utils";
import {PlusOutlined} from "@ant-design/icons-vue";
import {useRouter} from "vue-router";
import {resolveEditorMode, setEditorMode} from "@/utils/form_utils";
import {newCancelPreview, newHandlePreview, newPreviewState, newUploadDummyRequest} from "@/utils/file_utils";
import {jsonRPC,getResponseData} from "@/utils/http_utils";
const router = useRouter()
logDebug('ConsoleClassForm setup.')

const labelCol = {
  style: {
    width: '150px',
  },
};
const wrapperCol = {
  span: 14,
};
const formRef = ref();
const rules = {
  class_name: [
    {
      required: true,
      message: '请输入班级名称',
    },
  ],
  file_list: [
    {
      required: true,
      message: '请选择培训封面',
      trigger: 'change',
    },
  ],
}

const uploadDummyRequest = newUploadDummyRequest()
const data_list = reactive({
  class_name: '',
  difficulty: '',
  lesson_hours: '',
  course_price: '',
  course_ids: [],
  live_ids: [],
  file_list: [],
})

const confirmFormCreate = () => {
  formRef.value.validate()
  .then(() => {
    jsonRPC({
      url: "/vue/console/class/create",
      params: {
        class_name: data_list.class_name,
        difficulty: data_list.difficulty,
        lesson_hours: data_list.lesson_hours,
        course_price: data_list.course_price,
        course_ids: data_list.course_ids,
        live_ids: data_list.live_ids,
        file_list: data_list.file_list,
      },
      success(res) {
        logDebug(`查询成功`, res.data.result.data)
        routerBack(router)
      },
      fail(error) {
        logError(`查询失败, `, error)
      },
    });
    return
  })
  .catch(error => {
    logDebug('error', error);
    return
  });
};

const confirmFormUpdate = () => {
  formRef.value.validate()
  .then(() => {
    jsonRPC({
      url: "/vue/console/class/write",
      params: {
        id: classId.value,
        class_name: data_list.class_name,
        difficulty: data_list.difficulty,
        lesson_hours: data_list.lesson_hours,
        course_price: data_list.course_price,
        course_ids: data_list.course_ids,
        live_ids: data_list.live_ids,
        file_list: data_list.file_list,
      },
      success(res) {
        logDebug(`保存成功`, res.data.result.data)
        setEditorMode(editorMode, 'readonly')
        routerBack(router)
      },
      fail(error) {
        logError(`保存失败, `, error)
      },
    });
    return
  })
  .catch(error => {
    logDebug('error', error);
    return
  });
};

const cancelForm = () => {
  routerBack(router)
};

const {previewVisible, previewImage, previewTitle} = newPreviewState();
const cancelPreview = newCancelPreview(previewVisible, previewTitle)
const handlePreview = newHandlePreview(previewImage, previewVisible, previewTitle)
logComponents([PlusOutlined])

const editorMode = ref('update')
logDebug(`ConsoleClassForm getRouterParam(router)[${JSON.stringify(getRouterParam(router))}]`)
const classId = ref(getRouterParam(router, 'class_id'))
resolveEditorMode(editorMode, classId.value, getRouterQuery(router, 'mode'))

const openDeleteModal = ref(false)
const confirmFormDelete = () => {
  jsonRPC({
    url: "/vue/console/class/delete",
    params: {
      id: classId.value,
    },
    success(res) {
      logDebug(`查询成功`, res.data.result.data)
      openDeleteModal.value = false
      routerReplace(router, '/console/class')
    },
    fail(error) {
      logError(`查询失败, `, error)
    },
  });
}

onMounted(() => {
  jsonRPC({
    url: "/vue/console/class/setting",
    params: {
      id: editorMode.value != 'create' ? classId.value : 0,
    },
    success(res) {
      const data = getResponseData(res)
      logDebug(`获取成功`, data)
      Object.assign(data_list, data);
    },
    fail(error) {
      logError(`查询失败, `, error)
    },
  });
})
</script>

<style scoped lang='scss'>

</style>
