<template>
  <div class="reply_page">
    <div class="blog_list" v-for="item in data_list" :key="item.id">
      <router-link :to="`/blog/homepage?user_id=${item.user.id}`" class="user">
        <a-avatar :size="40" v-if="item.user.has_image" :src="`/web/image/res.users/${item.user.id}/image_512`"/>
        <a-avatar :size="40" v-else src="/hw_web/static/src/img/avatar_live.png"/>
      </router-link>
      <div class="info">
        <div class="blog_list_top">
          <router-link :to="`/blog/homepage?user_id=${item.user.id}`">
            {{item.user.name}}
          </router-link>
          <span>{{ item.create_date }}</span>
        </div>
        <h6>@ {{user_name}}: {{item.comment}}</h6>
        <div v-if="item.post_id.id" class="blog_list_content">
          <div>
            <h5>
              <router-link :to="`/blog/${item.post_id.id}`">{{ item.post_id.name }}</router-link>
              <span v-for="i in item.post_id.tag_ids" :key="i.id">{{i.name}}</span>
            </h5>
            <a-typography-text
              :style="item.post_id.ellipsis ? {maxHeight: '200px',overflow: 'hidden',textOverflow: 'ellipsis',whiteSpace: 'normal',width: '100%'} : {}"
              :ellipsis="item.post_id.ellipsis"
            >
              <div v-html="item.post_id.content"></div>
            </a-typography-text>
            <a-flex justify="center">
              <a-button type="text" @click="item.post_id.ellipsis=!item.post_id.ellipsis" style="color: #429BFF;">
                <template v-if="item.post_id.ellipsis" #icon><DownOutlined style="display: inline-flex;"/></template>
                <template v-else #icon><UpOutlined style="display: inline-flex;"/></template>
                <template v-if="item.post_id.ellipsis">展开全文</template>
                <template v-else>收起全文</template>
              </a-button>
            </a-flex>
          </div>
          <img v-if="item.post_id.is_image" :src="`/web/image/hw.blog.post/${item.post_id.id}/image_512`"/>
        </div>
        <div class="blog_list_bottom">
          <a-tooltip placement="bottom">
            <template #title>浏览量</template>
            <span>
              <img src="/hw_blog/static/src/img/visited.png"/>
              <span>{{ item.post_id.visits }}</span>
            </span>
          </a-tooltip>
          <a-tooltip placement="bottom">
            <template #title>评论人数</template>
            <span>
              <img v-if="item.post_id.is_comments" src="/hw_blog/static/src/img/info.png"/>
              <img v-else src="/hw_blog/static/src/img/comment.png"/>
              <span>{{ item.post_id.comments }}</span>
            </span>
          </a-tooltip>
          <a-tooltip placement="bottom">
            <template #title>点赞</template>
            <span>
              <img v-if="item.post_id.is_likes" @click="onBlogLike(item.post_id.id)" src="/hw_blog/static/src/img/liked.png"/>
              <img v-else @click="onBlogLike(item.post_id.id)" src="/hw_blog/static/src/img/praise.png"/>
              <span>{{ item.post_id.likes }}</span>
            </span>
          </a-tooltip>
        </div>
      </div>
    </div>
    <!-- 分页 -->
    <a-flex justify="center" class="mt-3">
      <a-pagination
        v-model:current="current"
        :showSizeChanger="showSizeChanger"
        v-model:page-size="pageSize"
        :total="total"
        :locale="zhCn"
        :pageSizeOptions="pageSizeOptions"
        :hideOnSinglePage="true"
      />
    </a-flex>
    <div v-if="data_list.length==0" class="empty">
      <a-empty />
    </div>
  </div>
</template>

<script setup>
const emits = defineEmits(['call-parent-Reload']);
function callParentMethod() {
  emits('call-parent-Reload');
}
import { UpOutlined, DownOutlined } from '@ant-design/icons-vue';
import { logDebug, logError } from "@/utils/logger";
import { onMounted, ref, watch } from "vue";
import { getResponseData, jsonRPC } from "@/utils/http_utils";
import { message } from "ant-design-vue";

const user_name = ref('');
const data_list = ref([]);
const total = ref(0);
const showSizeChanger = ref(true);
const pageSize = ref(8);
const current = ref(1);
const search = ref("");
const pageSizeOptions = ref(
    Array.from({length: 4}, (_, index) =>
        (pageSize.value * (index + 1)).toString()
    )
);
function fetchData() {
  jsonRPC({
    url: `/api/blog/message/reply`,
    params: {
      page_index: current.value,
      page_size: pageSize.value,
      search: search.value,
    },
    success(res) {
      const data = getResponseData(res);
      logDebug(`获取粉丝列表成功`, data);
      data_list.value = data.records;
      total.value = data.record_count;
      user_name.value = data.user_name;
      callParentMethod()
    },
    fail(error) {
      logError(`获取粉丝列表失败`, error);
      message.error(`获取粉丝列表失败，请稍后再试`);
    },
  });
}
onMounted(() => {
  fetchData();
});
watch([pageSize, current], fetchData);
// const onSearch = (searchValue) => {
//   if (search.value != searchValue) {
//     current.value = 1;
//     search.value = searchValue;
//     fetchData();
//   }
// };
const onBlogLike = (e) => {
  jsonRPC({
    url: `/api/blog/like/${e}`,
    params: {},
    success(res) {
      logDebug(res);
      fetchData();
    },
    fail(error) {
      logError(`操作失败`, error);
    },
  });
};
</script>

<style scoped lang="scss">
.reply_page{
  height: 100%;
  .blog_list{
    padding: 15px;
    background-color: #fff;
    border-radius: 8px;
    box-shadow: 2px 2px 2px 0px rgba(128, 128, 128, 0.4);
    margin-bottom: 15px;
    display: flex;
    gap: 10px;
    .user{
      flex-shrink: 0;
    }
    .info{
      flex-grow: 1;
      .blog_list_top{
        display: flex;
        flex-direction: column;
        color: #A1A1A1;
        margin-bottom: 5px;
        a{
          font-size: 16px;
          color: #000;
          &:hover{
            color: #ff8c00;
          }
        }
        img{
          height: 25px;
          width: 25px;
          margin-right: 5px;
          border-radius: 50%;
        }
      }
      .blog_list_content{
        display: flex;
        gap: 10px;
        background-color: #EFF6FF;
        padding: 10px;
        border-radius: 8px;
        >div{
          flex-grow: 1;
          h5{
            display: flex;
            flex-flow: wrap;
            gap: 5px;
            a{
              color: #000;
              font-size: 18px;
              font-weight: bold;
              &:hover{
                color: #ff8c00;
              }
            }
            span{
              background-color: #E4EFFF;
              color: #2580fd;
              padding: 3px 7px;
              border-radius: 4px;
              margin-bottom: -3px;
              position: relative;
              top: -2px;
              margin-left: 6px;
              font-size: 14px;
            }
          }
        }
        >img{
          flex-shrink: 0;
          width: 100%;
          max-width: 200px;
          object-fit: cover;
        }
      }
      .blog_list_bottom{
        display: flex;
        justify-content: space-around;
        margin-top: 5px;
        img{
          width: 25px;
          height: 25px;
          margin-right: 5px;
        }
      }
    }
  }
  .empty{
    height: calc(100% - 15px);
    background-color: #fff;
    display: flex;
    justify-content: center;
    align-items: center;
  }
}
</style>
