<template>
  <div>
    <a-button id="function-app-snapshot" :style="props.styleSetting.buttonStyle" type="primary"
              @click="showAppSnapshotModal">
      <a-tooltip :placement="placement">
        <template #title>应用快照</template>
        <SaveOutlined :style="props.styleSetting.iconStyle"/>
      </a-tooltip>
    </a-button>
    <a-modal v-model:open="openAppSnapshotModal"
             cancelText="取消" okText="确认"
             style="width: 70vw" title="应用快照列表"
             @ok="handleOkAppSnapshotModal">
      <a-row>
        <a-col :span="20"></a-col>
        <a-col :span="4">
          <a-button type="primary" @click="showCreateAppSnapshotModal">
            创建快照
          </a-button>
          <a-modal v-model:open="openCreateAppSnapshotModal"
                   :maskClosable="false" :closable="false" cancelText="取消"
                   okText="确认" style="width: 50vw; height: 50vh"
                   title="创建应用快照"
                   @ok="handleOkCreateAppSnapshotModal">
            <a-form ref="formCreateAppSnapshotRef" :label-col="{ span: 6 }"
                    :model="createAppSnapshotForm"
                    :wrapper-col="{ span: 18 }"
                    autocomplete="off"
                    style="margin-top: 30px"
            >
              <a-form-item label="快照描述" name="app_snapshot_description"
                           :rules="[{ required: true, message: '请输入快照描述！' }]">
                <a-input v-model:value="createAppSnapshotForm.app_snapshot_description"
                         placeholder="请简单描述本次快照保存的内容和时间（必填）！"/>
              </a-form-item>
            </a-form>
          </a-modal>
        </a-col>
      </a-row>
      <a-row style="margin-top: 10px">
        <a-col :span="24">
          <a-table :columns="appSnapshotColumns" :data-source="appSnapshotData"
                   :pagination="pagination">
            <template #bodyCell="{ column, record }">
              <template v-if="column.key === 'status'">
                <span>
                  {{ appSnapshotStatus[record['status']] }}
                </span>
              </template>
              <template v-else-if="column.key === 'action'">
                <span>
                  <template v-if="record.status !== 'not_synced'">
                    <a-button v-if="record.in_use" type="link" style="color: #0b0;">使用中</a-button>
                    <a-popconfirm v-if="!record.in_use" cancel-text="否" ok-text="是" title="是否确认删除？"
                                  @confirm="()=>deleteAppSnapshot(record)">
                      <a-button :disabled="isRollingBack" danger type="text" style="color: #666;">删除</a-button>
                    </a-popconfirm>
                  </template>
                  <template
                      v-if="!record.in_use && record.status !== 'not_committed' && record.status !== 'commit_failed' && record.status !== 'not_synced'">
                    <a-divider type="vertical"/>
                    <a-popconfirm cancel-text="否" ok-text="是"
                                  title="应用环境当前修改内容将被丢弃，是否确认回滚？"
                                  @confirm="()=>rollbackAppSnapshot(record)">
                      <a-button :disabled="isRollingBack" type="link">回滚</a-button>
                    </a-popconfirm>
                    <a-modal v-model:open="openRollbackAppSnapshotModal"
                             :closable="false"
                             :footer="null"
                             :maskClosable="false"
                             title="应用回滚中......">
                      <iframe style="display: none;pointer-events: none" class="vnc-frame" :src="url" frameborder="0"/>
                      <a-space style="display: flex;justify-content: center;height: 200px; overflow: hidden">
                        <a-spin size="large"/>
                      </a-space>
                    </a-modal>
                  </template>
                  <template v-if="record.status === 'committed'">
                    <a-divider type="vertical"/>
                    <a-button :disabled="isRollingBack" type="link" @click="()=>showCreateAppTemplateModal(record)">
                      创建应用模板
                    </a-button>
                    <!--  注意在 a-table 里的  a-modal 里，不能访问record，否则拿到的数据总是最后一条记录的数据        -->
                    <a-modal v-model:open="openCreateAppTemplateModal"
                             :maskClosable="false" cancelText="取消"
                             okText="确认"
                             style="width: 50vw; height: 50vh" title="创建用户应用模板"
                             @ok="()=>handleOkCreateAppTemplateModal()">
                      <p class="px-3" style="color: #2481e9">
                        提示：提交创建用户应用模板任务后，根据镜像修改内容大小，用户应用模板制作耗时在数秒钟到数分钟不等。
                        请在“快照”列表查看制作进度，或稍后在“控制台->我的应用->我的模板”标签页查看用户应用模板制作结果。
                      </p>
                      <a-form :label-col="{ span: 6 }"
                              :model="createAppTemplateForm"
                              :wrapper-col="{ span: 18 }"
                              autocomplete="off"
                              name="createAppTemplate"
                              style="margin-top: 30px"
                      >
                        <a-form-item
                            :rules="[{ required: true, message: '请填写应用快照ID！' }]"
                            label="快照序号"
                            name="app_snapshot_id"
                        >
                          <a-input v-model:value="createAppTemplateForm.app_snapshot_id"
                                   :disabled="true"
                                   placeholder="应用快照ID（必填）！"/>
                        </a-form-item>

                        <a-form-item
                            :rules="[{ required: true, message: '请填写应用模板名称！' }]"
                            label="应用模板名称"
                            name="app_snapshot_name"
                        >
                          <a-input v-model:value="createAppTemplateForm.app_snapshot_name"
                                   placeholder="应用模板名称（必填）！"/>
                        </a-form-item>

                        <a-form-item
                            :rules="[{ required: true, message: '请填写应用模板描述！' }]"
                            label="应用模板描述"
                            name="app_snapshot_description"
                        >
                          <a-input v-model:value="createAppTemplateForm.app_snapshot_description"
                                   placeholder="请简单描述本次创建应用模板包含的内容和时间（必填）！"/>
                        </a-form-item>
                      </a-form>
                    </a-modal>
                  </template>
                  <template v-if="record.status === 'synced'">
                    <a-divider type="vertical"/>
                    <a-button type="link" @click="()=>showAppSnapshotDetail(record)">
                      应用模板详情
                    </a-button>
                    <a-modal v-model:open="openShowAppSnapshotDetail"
                             :footer="null" cancelText="取消"
                             okText="确认" style="width: 50vw; height: 50vh" title="应用模板详情"
                             @ok="handleOkShowAppSnapshotDetail">
                      <a-form :disabled="true"
                              :label-col="{ span: 6 }"
                              :model="currentAppSnapshotDetail"
                              :wrapper-col="{ span: 18 }"
                              autocomplete="off"
                              name="appSnapshotDetail"
                              style="margin-top: 30px"
                      >
                        <a-form-item
                            label="应用模板名称"
                            name="app_snapshot_id">
                          <a-input v-model:value="currentAppSnapshotDetail.user_app_name"/>
                        </a-form-item>

                        <a-form-item
                            label="应用模板描述"
                            name="app_snapshot_name">
                          <a-input v-model:value="currentAppSnapshotDetail.user_app_description"/>
                        </a-form-item>
                      </a-form>
                    </a-modal>
                  </template>
                  <template v-if="record.status === 'not_synced'">
                    <span>正在制作用户应用模板... <LoadingOutlined/></span>
                  </template>
                  <template v-if="record.status === 'sync_failed'">
                    <a-divider type="vertical"/>
                    <span>制作用户应用模板失败，请尝试稍后重新制作。</span>
                  </template>
                </span>
              </template>
            </template>
          </a-table>
        </a-col>
      </a-row>
    </a-modal>
  </div>
</template>

<script setup>
import useDebugStore from "@/stores/debugStore";
import {markRaw, onMounted, reactive, ref} from 'vue'
import {LoadingOutlined, SaveOutlined} from '@ant-design/icons-vue';
import {logComponents, logDebug, logError} from "@/utils/logger";
import {getFailedMessage, getResponseData, jsonRPC} from "@/utils/http_utils";
import {message, Modal, notification} from "ant-design-vue";
import {isNotNullOrUndefined, isTrue} from "@/utils/common_utils";
import coreStore from '@/stores/core.js';

const emit = defineEmits(["ok"]); //声明 emits

const $coreStore = coreStore();

logDebug('TerminalFunctionAppSnapshot setup!')
logComponents([LoadingOutlined, SaveOutlined])

const placement = ref(window.location.search.includes('desktop') ? 'right' : 'bottom');
const props = defineProps({
  primal: {type: String},
  data: {type: Object},
  styleSetting: {type: Object},
})

const primal = props.primal
const data = props.data
const vnc_data = props.data['vnc_data']

const appEnvId = data['app_env_id']
const envId = data['env_id']
const url = ref('')

const appSnapshotStatus = {
  'committed': '已提交',
  'not_committed': '未提交',
  'commit_failed': '提交失败',
  'synced': '已同步',
  'not_synced': '未同步',
  'sync_failed': '同步失败',
}

const openAppSnapshotModal = ref(false);
const handleOkAppSnapshotModal = () => {
  openAppSnapshotModal.value = false;
};

const handlePageChange = function (page, pageSize) {
  logDebug(`page[${page}], pageSize[${pageSize}]`)
  pagination.current = page
}

const pagination = reactive({
  // 分页配置
  pageSize: 5, // 每页显示的条数
  showSizeChanger: false, // 是否可以改变每页显示的条数
  pageSizeOptions: ['5', '10', '20'], // 可选的每页显示条数
  showQuickJumper: false, // 是否可以快速跳转到指定页
  showTotal: total => `共 ${total} 条`, // 显示总条数和当前数据范围
  hideOnSinglePage: true, // 只有一页时是否隐藏分页器 true为不显示 false为显示
  current: 1, // 当前页数
  total: 0, // 总条数
  onChange: markRaw(handlePageChange), // 页码改变时的回调函数
})

const appSnapshotColumns = [
  {
    title: '序号',
    // dataIndex是JSON数据的字段名称
    dataIndex: 'id',
    // key是column的索引名称
    key: 'id',
  },
  {
    title: '提交时间',
    dataIndex: 'commit_date',
    key: 'commit_date',
  },
  {
    title: '描述',
    dataIndex: 'description',
    key: 'description',
  },
  {
    title: '状态',
    key: 'status',
    dataIndex: 'status',
  },
  {
    title: '操作',
    key: 'action',
  },
];
const appSnapshotData = ref([]);

onMounted(() => {
  if (isTrue(vnc_data['vm_enable'])) {
    if (primal === 'vnc') {
      url.value = `${vnc_data['vm_rdp_url']}`
    } else {
      url.value = `${vnc_data['vm_vnc_url']}`
    }
  } else {
    url.value = `/hw_experiment/static/src/lib/novnc/vnc.html?
                encrypt=${vnc_data['abc_svc_vnc_ssl']}&
                host=${vnc_data['abc_svc_vnc_host']}&
                port=${vnc_data['abc_svc_vnc_port']}&
                logging=debug&
                env_name=${vnc_data['env_name']}&
                namespace=${vnc_data['namespace']}&
                resize=remote`
  }
})

// 最长查询一个小时
const updateSnapshotStatusIntervalMS = 10000
const updateSnapshotStatusTryTimes = 360
const updateSnapshotStatus = function (tryCount) {
  if (isTrue(isRollingBack.value)) {
    logDebug(`isRollingBack[${isRollingBack.value}]`)
    return;
  }
  logDebug(`updateSnapshotStatus, tryCount[${tryCount}]`)

  if (tryCount <= 0) {
    logError(`updateSnapshotStatus failed after try ${updateSnapshotStatusTryTimes} times.`)
    return
  }

  try {
    let isAnyOneSyncing = false
    const getAppSnapshotResult = jsonRPC({
      url: "/api/experiment/app/snapshot",
      params: {
        app_env_id: appEnvId,
      },
      success(res) {
        const data = getResponseData(res)
        logDebug('updateSnapshotStatus', data)
        const app_snapshot_ids = adjustAppSnapshotData(data['app_snapshot_ids'])
        for (const app_snapshot of app_snapshot_ids) {
          if (app_snapshot.status === 'not_synced') {
            isAnyOneSyncing = true
          }
        }
      },
      fail(error) {
        logError(`获取应用快照列表失败, `, error)
        message.error(`获取应用快照列表失败，[${JSON.stringify(getFailedMessage(error))}]`, 3);
      },
    })
    getAppSnapshotResult.then(function () {
      if (isAnyOneSyncing) {
        setTimeout(function () {
          updateSnapshotStatus(tryCount - 1)
        }, updateSnapshotStatusIntervalMS)
      } else {
        loadAppSnapshot()
      }
    })
  } catch (e) {
    logError(`updateSnapshotStatus failed`, e)
  }
}


const adjustAppSnapshotData = function (appSnapshots) {
  return appSnapshots
}

let loadAppSnapshotTimeout = undefined

const loadAppSnapshot = function () {
  if (isTrue(isRollingBack.value)) {
    logDebug(`isRollingBack[${isRollingBack.value}]`)
    return;
  }
  const getAppSnapshotResult = jsonRPC({
    url: "/api/experiment/app/snapshot",
    params: {
      app_env_id: appEnvId,
    },
    success(res) {
      const data = getResponseData(res)
      logDebug('loadAppSnapshot', data)
      appSnapshotData.value = adjustAppSnapshotData(data['app_snapshot_ids'])
      pagination.total = appSnapshotData.value.length

      if (isNotNullOrUndefined(loadAppSnapshotTimeout)) {
        clearTimeout(loadAppSnapshotTimeout)
      }
      loadAppSnapshotTimeout = setTimeout(function () {
        updateSnapshotStatus(updateSnapshotStatusTryTimes)
      }, updateSnapshotStatusIntervalMS)
    },
    fail(error) {
      logError(`获取应用快照列表失败, `, error)
      message.error(`获取应用快照列表失败，[${JSON.stringify(getFailedMessage(error))}]`, 3);
    },
  })

  return getAppSnapshotResult.then(function (res) {
    logDebug(`getAppSnapshotResult, `, res)
  })
}

const showAppSnapshotModal = function () {
  loadAppSnapshot().then(function () {
    openAppSnapshotModal.value = true
  })
}

const openCreateAppSnapshotModal = ref(false);

const createAppSnapshotForm = reactive({
  'app_snapshot_description': '',
})
const showCreateAppSnapshotModal = function () {
  openCreateAppSnapshotModal.value = true
}

const createAppSnapshot = function () {
  logDebug(`createAppSnapshot, createAppSnapshotForm[${JSON.stringify(createAppSnapshotForm)}]`)

  const params = {
    app_env_id: appEnvId,
    app_snapshot_description: createAppSnapshotForm.app_snapshot_description,
    is_sync: false,
  }

  const createAppSnapshotResult = jsonRPC({
    url: "/api/experiment/app/snapshot/create",
    params: params,
    success(res) {
      const data = getResponseData(res)
      logDebug(`createAppSnapshotResult, data[${JSON.stringify(data)}]`)
      notification.info({
        message: '创建应用快照任务成功',
        description: '创建应用快照任务成功，请稍后在快照列表查看任务执行结果！',
        duration: 10, // 设置为0时，通知不会自动关闭
      });
    },
    fail(error) {
      logError(`创建应用环境快照失败, `, error)
      notification.error({
        message: '创建应用快照任务失败',
        description: `创建应用快照任务失败，失败原因：${JSON.stringify(getFailedMessage(error))}！`,
        duration: 10, // 设置为0时，通知不会自动关闭
      });
    },
  })

  return createAppSnapshotResult.then(function (res) {
    logDebug(`createAppSnapshotResult, `, res)
  })
}
logDebug(`createAppSnapshot[${createAppSnapshot}]`)

const createAppSnapshotV2 = function () {
  logDebug(`createAppSnapshotV2, createAppSnapshotForm[${JSON.stringify(createAppSnapshotForm)}]`)

  const params = {
    app_env_id: appEnvId,
    app_snapshot_description: createAppSnapshotForm.app_snapshot_description,
    is_sync: false,
  }

  return jsonRPC({
    url: "/api/experiment/app/snapshot/create_async",
    params: params,
    success(res) {
      const data = getResponseData(res)
      logDebug(`createAppSnapshotAsyncResult, data[${JSON.stringify(data)}]`)
      notification.info({
        message: '提交创建应用快照任务成功',
        description: '提交创建应用快照任务成功，请稍后在快照列表查看任务执行结果！',
        duration: 10, // 设置为0时，通知不会自动关闭
      });
    },
    fail(error) {
      logError(`提交创建应用快照任务失败, `, error)
      notification.error({
        message: '提交创建应用快照任务失败',
        description: `提交创建应用快照任务失败，失败原因：${JSON.stringify(getFailedMessage(error))}！`,
        duration: 10, // 设置为0时，通知不会自动关闭
      });
    },
  })
}
logDebug(`createAppSnapshotV2[${createAppSnapshotV2}]`)

const formCreateAppSnapshotRef = ref()
const handleOkCreateAppSnapshotModal = () => {
  logDebug(`handleOkCreateAppSnapshotModal, createAppSnapshotForm[${JSON.stringify(createAppSnapshotForm)}]`)
  formCreateAppSnapshotRef.value.validate()
      .then(() => {
        const notificationKey = ref(`open${Date.now()}`);
        notification.open({
          message: '创建应用快照',
          description: '正在创建应用快照！',
          duration: 0, // 设置为0时，通知不会自动关闭
          key: notificationKey.value, // 关联key
        });

        openCreateAppSnapshotModal.value = false

        const createAppSnapshotResult = createAppSnapshot()
        createAppSnapshotResult.then(() => {
          notification.close(notificationKey.value);
          loadAppSnapshot()
        })
      })
      .catch(error => {
        logDebug('error', error);
      });
};

const deleteAppSnapshot = function (record) {
  logDebug(`deleteAppSnapshot[${JSON.stringify(record)}]`)

  const appSnapshotId = record['id']

  const deleteAppSnapshotResult = jsonRPC({
    url: "/api/experiment/app/snapshot/delete",
    params: {
      app_snapshot_id: appSnapshotId,
    },
    success(res) {
      const data = getResponseData(res)
      logDebug(`deleteAppSnapshotResult, data[${JSON.stringify(data)}]`)
    },
    fail(error) {
      logError(`创建应用环境快照失败, `, error)
      message.error(`创建应用环境快照失败，[${JSON.stringify(getFailedMessage(error))}]`, 3);
    },
  })

  deleteAppSnapshotResult.then(function () {
    loadAppSnapshot()
  })

}

const openRollbackAppSnapshotModal = ref(false)

const isRollingBack = ref(false)
const debugStore = useDebugStore();  // 获取 debugStore 实例

const rollbackAppSnapshot = function (record) {
  emit("ok", {}); //触发事件
  openAppSnapshotModal.value = false
  logDebug(`rollbackAppSnapshot[${JSON.stringify(record)}]`)
  isRollingBack.value = true
  let is_debug = true;
  debugStore.setDebugSettings(envId, is_debug);
  const appSnapshotId = record['id']

  const rollbackAppSnapshotResult = jsonRPC({
    url: "/api/experiment/app/snapshot/rollback",
    params: {
      env_id: envId,
      app_snapshot_id: appSnapshotId,
    },
    success(res) {
      const data = getResponseData(res)
      logDebug(`rollbackAppSnapshotResult, data[${JSON.stringify(data)}]`)
      renovate()
    },
    fail(error) {
      logError(`回滚应用环境快照失败, `, error)
      try {
        try {
          let errMsg = JSON.parse(error);
          let user = errMsg.user || '';
          let message = errMsg.message || '资源不足';
          let details = errMsg.details || '';
          Modal.error({
            title: "回滚应用环境快照失败",
            content: (
                <div>
                  <div>{user}</div>
                  <div style={{textIndent: '2em'}}>{message}</div>
                  <div>{details}</div>
                </div>
            ),
            width: '60%', // 设置宽度为 60%
            closable: true, // 右上角关闭按钮
            onCancel: () => {
              Modal.destroyAll(); // 点击关闭按钮时，关闭模态框
            },
            footer: () => (
                <div class="d-flex justify-content-center my-3">
                  <button type="button" class="btn btn-primary" onClick={async () => {
                    await $coreStore.closeApplication('8014');
                    await sessionStorage.setItem('openTopUp', true);
                    await $coreStore.openApplication('8014');
                    Modal.destroyAll(); // 关闭所有弹窗
                  }}>补充资源
                  </button>
                </div>
            ),
          });
        } catch (err) {
          logError("查询失败: 无法解析错误信息", err);
          Modal.error({
            title: "回滚应用环境快照失败",
            content: (
                <div style={{textIndent: '2em'}}>{JSON.stringify(getFailedMessage(error))}</div>
            ),
            width: '60%', // 设置宽度为 60%
            closable: true, // 右上角关闭按钮
            onCancel: () => {
              Modal.destroyAll(); // 点击关闭按钮时，关闭模态框
            },
            footer: () => (
                <div class="d-flex justify-content-center my-3">
                  <button type="button" class="btn btn-primary" onClick={() => {
                    Modal.destroyAll(); // 关闭所有弹窗
                  }}>关闭
                  </button>
                </div>
            ),
          });
        }
      } catch (er) {
        logError("回滚应用环境快照失败发生未捕获的错误：", er);
        message.error('回滚应用环境快照失败，请稍后重试。')
      }
    },
  })
  // message.info(`已触发应用环境回滚，请稍等片刻！`, 8)
  notification.info({
    message: '已触发应用环境回滚，请稍等片刻！',
    duration: 8,
  });

  rollbackAppSnapshotResult.then(() => {
    isRollingBack.value = false
    // openRollbackAppSnapshotModal.value = true
    setTimeout(function () {
      openRollbackAppSnapshotModal.value = false
      window.location.reload();
      message.success(`回滚应用环境快照成功！`)
    }, 60000)
  })
}
const renovate = () => {
  jsonRPC({
    url: "/vue/desktop/environment",
    params: {},
    success(res) {
      const data = getResponseData(res)
      for (const app of data.apps) {
        let loading = false
        if (app.app_env_status != 'Running') {
          loading = true;
          setTimeout(() => {
            renovate();
          }, 5000)
        }
        $coreStore.applications = $coreStore.applications.filter((obj) => {
          return obj.id !== (Number(app.id) + 10000)
        });
        $coreStore.applications.push({
          "id": Number(app.id) + 10000,
          "name": app.name,
          "page": "desktopEnvironment",
          "icon": String(app.image_url),
          "width": 1024,
          "height": 600,
          "link": true,
          "abcPage": true,
          "loading": loading,
        });
      }
    },
    fail(error) {
      logError(`查询失败, `, error);
    },
  });
}

const openShowAppSnapshotDetail = ref(false)
const currentAppSnapshotDetail = reactive({
  user_app_name: "",
  user_app_description: '',
})

const showAppSnapshotDetail = function (record) {
  logDebug(`showAppSnapshotDetail, record[${JSON.stringify(record)}]`)
  // 先清空旧的数据
  Object.assign(currentAppSnapshotDetail, {
    user_app_name: '',
    user_app_description: '',
  })

  const appSnapshotId = record['id']

  const getAppSnapshotDetailResult = jsonRPC({
    url: "/api/experiment/app/snapshot/detail",
    params: {
      app_snapshot_id: appSnapshotId,
    },
    success(res) {
      const data = getResponseData(res)
      logDebug(`getAppSnapshotDetailResult, data[${JSON.stringify(data)}]`)
      Object.assign(currentAppSnapshotDetail, data)
    },
    fail(error) {
      logError(`创建应用环境快照失败, `, error)
      message.error(`创建应用环境快照失败，[${JSON.stringify(getFailedMessage(error))}]`, 3);
    },
  })

  getAppSnapshotDetailResult.then(function () {
    openShowAppSnapshotDetail.value = true
  })
}

const handleOkShowAppSnapshotDetail = function () {
  openShowAppSnapshotDetail.value = false
}


const openCreateAppTemplateModal = ref(false);

const createAppTemplateForm = reactive({
  'app_snapshot_id': '',
  'create_app_snapshot': 'true',
  'app_snapshot_name': '',
  'app_snapshot_description': '',
})
const showCreateAppTemplateModal = function (record) {
  logDebug(`showCreateAppTemplateModal, record[${JSON.stringify(record)}]`)
  Object.assign(createAppTemplateForm, {
    app_snapshot_id: record['id'],
    create_app_snapshot: 'true',
    app_snapshot_name: '',
    app_snapshot_description: '',
  })
  openCreateAppTemplateModal.value = true
}

const handleOkCreateAppTemplateModal = () => {
  logDebug(`handleOkCreateAppTemplateModal, createAppTemplateForm[${JSON.stringify(createAppTemplateForm)}]`)

  const createAppResult = jsonRPC({
    url: "/api/experiment/app/snapshot/create_app",
    params: {
      app_snapshot_id: createAppTemplateForm.app_snapshot_id,
      create_app_snapshot: createAppTemplateForm.create_app_snapshot,
      app_snapshot_name: createAppTemplateForm.app_snapshot_name,
      app_snapshot_description: createAppTemplateForm.app_snapshot_description,
    },
    success(res) {
      const data = getResponseData(res)
      logDebug(`createAppResult, data[${JSON.stringify(data)}]`)
      notification.info({
        message: '提交制作用户应用模板任务成功',
        description: '提交制作用户应用模板任务成功，请稍后在快照列表查看任务执行结果！',
        duration: 10, // 设置为0时，通知不会自动关闭
      });
    },
    fail(error) {
      logError(`提交制作用户应用模板任务失败, `, error)
      notification.error({
        message: '提交制作用户应用模板任务失败',
        description: `提交制作用户应用模板任务失败，失败原因：${JSON.stringify(getFailedMessage(error))}！`,
        duration: 10, // 设置为0时，通知不会自动关闭
      });
    },
  })

  createAppResult.then(() => {
    openCreateAppTemplateModal.value = false
  }).then(function () {
    loadAppSnapshot()
  })
};


defineExpose({
  primal,
  data,
})

</script>

<style scoped>
#function-app-snapshot {
  background: #90EE90;
}
</style>

