<template>
  <div class="class_detail">
    <a-breadcrumb separator=">">
      <a-breadcrumb-item>
        <router-link to="/console/class">培训班级</router-link>
      </a-breadcrumb-item>
      <a-breadcrumb-item>
        <router-link :to="`/console/class/${class_id}`">{{data_list.class_name}}</router-link>
      </a-breadcrumb-item>
      <a-breadcrumb-item>{{ data_list.name }}</a-breadcrumb-item>
    </a-breadcrumb>
    <div class="row mx-0 mt-3">
      <div class="col-md-5">
        <div class="img_box">
          <img v-if="data_list.is_image" :src="`/web/image/hw.course.class/${course_id}/image_1920?t=${new Date().getTime()}`"/>
          <img v-else src="/hw_web/static/src/img/course_default.png"/>
        </div>
      </div>
      <div class="col-md-7">
        <div>
          <h4>{{ data_list.name }}</h4>
          <p>授课老师：
            <span v-for="(item, index) in data_list.teacher_ids" :key="item.id">
              {{ item.name }}<span v-if="index !== data_list.teacher_ids.length - 1">、</span>
            </span>
          </p>
          <p>开课学期：{{data_list.semester}}</p>
        </div>
        <div v-if="data_list.is_create" class="operate">
          <!-- <router-link :to="`/console/class/${class_id}/homework/correct`" class="btn">批改作业</router-link> -->
          <!-- <router-link :to="`/console/class/form/${class_id}?mode=readonly`" class="btn edit">编辑班级</router-link> -->
        </div>
        <div v-else class="operate">
          <!-- <router-link :to="`/console/class/${class_id}/homework/finish`" class="btn">做作业</router-link> -->
          <a v-if="data_list.exam_id" :href="`/certification/exam/${data_list.exam_id}`" class="btn">进入考试</a>
          <a v-if="!data_list.my_attendance && data_list.has_attendance" @click="signIn" class="btn">培训签到</a>
          <!-- <router-link :to="`/console/class/form/${class_id}?mode=readonly`" class="btn edit">
            培训排名
          </router-link> -->
        </div>
      </div>
    </div>
    <a-tabs v-model:selected-tab="selectedTab" class="mx-3" @tabClick="tabClick">
      <a-tab-pane key="tab1" tab="课程概览">
        <div v-html="data_list.description"></div>
      </a-tab-pane>
      <a-tab-pane v-if="data_list.course_id" key="tab_course" tab="课程目录">
        <a-collapse accordion expand-icon-position="end" @change="handleChapterChange">
          <a-collapse-panel v-for="(item, index) in data_list.chapters" :key="index" :header="generateHeader(item)">
            <a-skeleton active :loading="skeletonLessonsActive" :paragraph="{ rows: item.lessons.length }">
              <a-row v-for="(le,y) in item.lessons" :key="le.id" class="accordion">
                <a-col :span="8" class="line-clamp-1" :title="le.name">{{ le.name }}</a-col>
                <a-col :span="8">
                  <a-progress v-if="le.video_id" :stroke-color="{'0%': '#108ee9','100%': '#87d068'}"
                              :percent="le.progress" class="mb-0"/>
                </a-col>
                <a-col :span="8">
                  <a-flex justify="flex-end" align="center">
                    <a v-if="le.video_id || le.description" class="course_video" title="视频课程">
                      <img src="/hw_web/static/src/img/course_video_icon.png" height="13" width="13"
                            @click="go_to_study(index,y,'video')"/>
                    </a>
                    <a v-if="le.lab_manual_contents" class="course_experiment" title="实验手册">
                      <img src="/hw_web/static/src/img/course_experiment_icon.png" height="17" width="17"
                            @click="go_to_study(index,y,'lab_manual')"/>
                    </a>
                    <a v-if="le.exercise_paper_num > 0" class="course_exercise" title="练习刷题">
                      <img src="/hw_web/static/src/img/course_exercise_icon.png" height="17" width="17"
                            @click="go_to_study(index,y,'exercise')"/>
                    </a>
                    <a v-if="le.courseware_num > 0" class="course_courseware" title="本节课件">
                      <img src="/hw_web/static/src/img/course_pdf_icon.png" height="17" width="17"
                            @click="go_to_study(index,y,'courseware')"/>
                    </a>
                  </a-flex>
                </a-col>
              </a-row>
            </a-skeleton>
          </a-collapse-panel>
        </a-collapse>
      </a-tab-pane>
      <template v-if="data_list.is_create">
        <a-tab-pane key="tab2" tab="学生名单">
          <div class="table-responsive">
            <table class="table">
              <thead>
              <tr>
                <th>#</th>
                <th>学号</th>
                <th>姓名</th>
                <th>性别</th>
                <th>院校</th>
                <th>应用环境</th>
                <th>成绩</th>
              </tr>
              </thead>
              <tbody style="max-height: 200px">
              <tr v-for="(row,index) in data_list.records" :key="row.id">
                <td>{{ index + 1 }}</td>
                <td>{{ row.student_barcode }}</td>
                <td>{{ row.name }}</td>
                <td>
                  <template v-if="row.sex == 'male'">男</template>
                  <template v-else-if="row.sex == 'female'">女</template>
                  <template v-else>未设置</template>
                </td>
                <td>{{ row.school }}</td>
                <td>{{ row.experiment }}</td>
                <td>{{ row.score }}</td>
              </tr>
              </tbody>
            </table>
          </div>
          <a-flex justify="center" class="py-3">
            <a-pagination
                v-model:current="current"
                :showSizeChanger="showSizeChanger"
                v-model:page-size="pageSize"
                :total="total"
                :locale="zhCn"
                :pageSizeOptions="pageSizeOptions"
                :hideOnSinglePage="true"
            />
          </a-flex>
        </a-tab-pane>
        <a-tab-pane key="tab3" tab="出勤统计">
          <div class="statistics_list">
            <div class="info" v-for="(row,index) in data_list.statistics" :key="row.id">
              <a-flex justify="space-between" align="center">
                <div>
                  <div style="font-size: 16px;">{{ row.name }}</div>
                  <div style="color: #364DFF;margin-top: 10px;">{{ row.start_time }}</div>
                </div>
                <a-flex align="center">
                  <span>{{ Math.max(row.attendance_ids.length, 0) }}人签到</span>
                  <span>{{ Math.max(row.student_ids.length - row.attendance_ids.length, 0) }}人未签到</span>
                  <a-button type="primary" @click="showModal(index)">签到详情</a-button>
                </a-flex>
              </a-flex>
            </div>
            <a-modal v-model:open="openModal" :footer="null" style="width: 50vw;text-align: center;">
              <p style="font-size: 16px;">{{ title }}</p>
              <div :style="border_top">已签到学员</div>
              <a-row :gutter="[0,0]" style="max-height: 30vh;overflow: auto;">
                <a-col class="ant-col-5" v-for="row in attendance_ids" :key="row.id">
                  <div :style="border_body">
                    <img :src="`/web/image/res.partner/${row.student_id}/image_512`" width="30" height="30"
                         style="border-radius: 50%;">
                    {{ row.student_name }}
                  </div>
                </a-col>
              </a-row>
              <div :style="border_top">未签到学员</div>
              <a-row :gutter="[0,0]" class="mb-3" style="max-height: 30vh;overflow: auto;">
                <a-col class="ant-col-5" :span="6" v-for="row in not_attendance_ids" :key="row.id">
                  <div :style="border_body">
                    <img :src="`/web/image/res.partner/${row.id}/image_512`" width="30" height="30"
                         style="border-radius: 50%;">
                    {{ row.name }}
                  </div>
                </a-col>
              </a-row>
            </a-modal>
          </div>
        </a-tab-pane>
      </template>
      <template v-else>
        <a-tab-pane key="tab4" tab="培训目录">
          <div class="train_list">
            <div class="info" v-for="row in data_list.live_ids" :key="row.id">
              <a-flex justify="space-between">
                <a-flex align="center" class="title">
                  {{ row.name }}
                  <div v-for="x in row.category_ids" :key="x.id">{{ x.name }}</div>
                </a-flex>
                <div style="color: #53A6FF;">{{ row.start_time }}</div>
              </a-flex>
              <a-flex align="center" class="my-2">
                <img :src="`/web/image/hw.teacher/${row.teacher_id.id}/image_128`"/>
                {{ row.teacher_id.name }}
              </a-flex>
              <a-flex justify="space-between" align="flex-end">
                <div v-html="row.description"></div>
                <template v-if="row.is_into">
                  <a-button v-if="!row.is_attendance" type="primary" @click="attendance(row.id)">直播签到</a-button>
                  <a-button v-else type="primary" @click="enter_training(row.id)">进入实训</a-button>
                </template>
                <a-typography-text v-else type="secondary">筹备中</a-typography-text>
              </a-flex>
            </div>
          </div>
        </a-tab-pane>
        <a-tab-pane key="tab5" tab="我的笔记">
          <div class="note_list">
            <div class="info" v-for="(row,index) in data_list.note_ids" :key="row.id"
                 style="cursor: pointer;" @click="showDrawer(index)">
              <a-flex justify="space-between" align="center">
                <div>
                  <a-typography-text style="font-size: 16px;">{{ row.title }}</a-typography-text>
                  <div style="color: #364DFF;margin-top: 10px;">{{ row.live_name }}</div>
                </div>
                <div style="color: #53A6FF;">{{ row.create_date }}</div>
              </a-flex>
            </div>
          </div>
          <!--笔记详情信息-->
          <a-drawer width="640" placement="right" :closable="true" :open="open" @close="onClose">
            <template #extra>
              <a-button style="margin-right: 8px" @click="onClose">关闭</a-button>
            </template>
            <div v-html="drawerData"></div>
          </a-drawer>
          <!--笔记详情信息-->
          <a-drawer width="640" placement="right" :closable="true" :open="open" @close="onClose">
            <template #extra>
              <template v-if="!edit">
                <a-button style="margin-right: 8px" @click="edit = true" type="primary">修改</a-button>
                <a-button style="margin-right: 8px" @click="openDeleteModal = true" danger>删除</a-button>
                <a-button style="margin-right: 8px" @click="onClose">关闭</a-button>
              </template>
              <template v-else>
                <a-button style="margin-right: 8px" @click="onEdit" type="primary">确认修改</a-button>
                <a-button style="margin-right: 8px" @click="onCancel">取消</a-button>
              </template>
            </template>
            <h5>标题：
              <template v-if="!edit">{{ drawerData.title }}</template>
              <a-input v-else v-model:value="drawerData.title"/>
            </h5>
            <h5>创建者：{{ drawerData.creator }}</h5>
            <h5>创建时间：{{ drawerData.create_date }}</h5>
            <h5>直播：{{ drawerData.live_name }}</h5>
            <h5>内容：</h5>
            <div v-if="!edit" v-html="drawerData.description"></div>
            <RichEditor v-else :height="301" :editMode="'create'" v-model="drawerData.description"/>
          </a-drawer>
          <a-modal v-model:open="openDeleteModal" title="删除笔记" @ok="onDelete">
            <p>确定删除？ 删除ID：{{ drawerData.id }}</p>
          </a-modal>
        </a-tab-pane>
        <a-tab-pane key="tab_report" tab="实训报告">
          <div class="homework_list">
            <div class="info" v-for="item in report_homework_data.report_ids" :key="item.id">
              <a-flex justify="space-between" align="center">
                <div>
                  <h6>{{ item.name }}
                    <span v-if="item.exercise_result=='未提交'" class="NotSubmitted">{{ item.exercise_result }}</span>
                    <span v-else>{{ item.exercise_result }}</span>
                  </h6>
                  <p class="mb-0" style="color: #9D9D9D;">提交截止时间：{{ item.end_datetime }}</p>
                </div>
                <a-button v-if="item.exercise_result=='未提交'" type="primary" @click="dowork(item.id,'report')"
                  :disabled="Date.parse(item.end_datetime) <= new Date().getTime()">提交报告</a-button>
                <text v-else style="color: #475AFF;">报告得分：{{ item.score }}</text>
              </a-flex>
            </div>
          </div>
        </a-tab-pane>
        <a-tab-pane key="tab_homework" tab="培训作业">
          <div class="homework_list">
            <div class="info" v-for="item in report_homework_data.homework_ids" :key="item.id">
              <a-flex justify="space-between" align="center">
                <div>
                  <h6>{{ item.name }}
                    <span v-if="item.exercise_result=='未提交'" class="NotSubmitted">{{ item.exercise_result }}</span>
                    <span v-else>{{ item.exercise_result }}</span>
                  </h6>
                  <p v-if="item.exercise_result=='未提交'" class="mb-0" style="color: #53A6FF;">
                    提交截止时间：{{ item.end_datetime }}</p>
                  <p v-else class="mb-0" style="color: #9D9D9D;">提交截止时间：{{ item.end_datetime }}</p>
                </div>
                <a-button v-if="item.exercise_result=='未提交'" type="primary" @click="dowork(item.id,'course')"
                  :disabled="Date.parse(item.end_datetime) <= new Date().getTime()">做作业</a-button>
                <a-flex v-else align="center" gap="15">
                  <text style="color: #475AFF;">作业得分：{{ item.score }}</text>
                  <a-button type="primary" @click="checkwork(item.id)">查看作业</a-button>
                </a-flex>
              </a-flex>
            </div>
          </div>
        </a-tab-pane>
      </template>
    </a-tabs>
  </div>
</template>

<script setup>
import {logDebug, logError} from "@/utils/logger";
import {h,onMounted, ref, reactive, watch} from "vue";
import {getRouterParam} from "@/utils/router_utils";
import {jsonRPC, getResponseData} from "@/utils/http_utils";
import {useRouter} from "vue-router";
import {routerPush} from "@/utils/router_utils";
import {zhCn} from "@/utils/zhCn";
import RichEditor from "@/components/RichEditor.vue";
import {message} from "ant-design-vue";

const router = useRouter();
const class_id = ref(getRouterParam(router, "class_id"));
const course_id = ref(getRouterParam(router, "course_id"));
const selectedTab = ref('tab1');

const dowork = (e,scene_type) => {
  router.push(`/console/class/work/${e}?class_id=${class_id.value}&course_id=${course_id.value}&mode=create&scene_type=${scene_type}`)
}
const checkwork = (e,scene_type) => {
  router.push(`/console/class/work/${e}?class_id=${class_id.value}&course_id=${course_id.value}&mode=readonly&scene_type=${scene_type}`)
}
const signIn = () => {
  jsonRPC({
    url: "/vue/console/class/signIn",
    params: {
      id: Number(data_list.has_attendance),
    },
    success() {
      fetchData()
      message.success('签到成功')
    },
    fail(error) {
      logError(`查询失败, `, error);
    },
  });
}

const data_list = reactive({
  course_id: false,
  name: '',
  class_name: '',
  data_list: [],
  selectedTab: 'tab1',
  ShowState: 'overview',

  exam_id: null,
  has_attendance: false,
  my_attendance: false,
  teacher_ids: [],
  semester: '',
});

const report_homework_data= reactive({
  report_ids: [],
  homework_ids: [],
})
const total = ref(0);
const showSizeChanger = ref(true);
const pageSize = ref(8);
const current = ref(1);
const pageSizeOptions = ref(
    Array.from({length: 4}, (_, index) =>
        (pageSize.value * (index + 1)).toString()
    )
);

function tabClick(tabKey) {
  if (tabKey === 'tab_course') {
    getCourseInfo()
  }
  if (tabKey === 'tab_report') {
    getCourseClassInfo()
  }
  if (tabKey === 'tab_homework') {
    getCourseClassInfo()
  }
}
function generateHeader(item) {
  return h('div', [
    h('span', item.name),
  ]);
}
const go_to_study = ()=>{
  router.push(`/course/${data_list.course_id}`);
}
function getCourseInfo() {
  jsonRPC({
    url: `/api/course/chapters/list`,
    params: {
      course_id: data_list.course_id,
    },
    success(res) {
      const data = getResponseData(res);
      Object.assign(data_list, data);
    },
    fail(error) {
      logError(`查询失败, `, error);
    },
  });
}
function handleChapterChange(e) {
  if (data_list.chapters[e]) {
    fetchDataCourseChaptersLessonsInfo(data_list.chapters[e]['id'])
  }
}
const skeletonLessonsActive = ref(false)
// 获取课程目录中的节
async function fetchDataCourseChaptersLessonsInfo(chapterId) {
  skeletonLessonsActive.value = true
  await jsonRPC({
    url: "/api/course/chapters/lessons/list",
    params: {
      course_id: data_list.course_id,
      chapter_id: chapterId,
    },
    success(res) {
      const data = getResponseData(res);
      const chapter = data_list.chapters.find(chapter => String(chapter.id) === String(chapterId));
      if (chapter) {
        chapter.lessons = data.lessons;
      } else {
        logError(`未找到章节ID为 ${chapterId} 的章节`);
      }
      skeletonLessonsActive.value = false
    },
    fail(error) {
      logError(`获取课程目录中的节失败, `, error);
      message.error(`获取课程目录中的节失败，[${JSON.stringify(error)}]`, 3);
    },
  })
}
function getCourseClassInfo() {
  jsonRPC({
    url: `/api/console/class/course/${course_id.value}`,
    success(res) {
      const data = getResponseData(res);
      Object.assign(report_homework_data, data);
    },
    fail(error) {
      logError(`查询失败, `, error);
    },
  });
}

function fetchData() {
  jsonRPC({
    url: "/vue/console/class/course",
    params: {
      class_id: Number(class_id.value),
      course_id: Number(course_id.value),
      page_index: current.value,
      page_size: pageSize.value,
    },
    success(res) {
      const data = getResponseData(res);
      logDebug(`获取成功`, data);
      Object.assign(data_list, data);
      total.value = data.record_count;
    },
    fail(error) {
      logError(`查询失败, `, error);
    },
  });
}

onMounted(() => {
  fetchData();
  getCourseClassInfo();
});
watch([pageSize, current], fetchData);

const openModal = ref(false)
const title = ref()
const attendance_ids = ref()
const not_attendance_ids = ref()
const showModal = e => {
  openModal.value = true;
  title.value = data_list.statistics[e].name;
  attendance_ids.value = data_list.statistics[e].attendance_ids;
  const studentIds = data_list.statistics[e].student_ids;
  const attendanceIds = data_list.statistics[e].attendance_ids;
  const newStudentIds = studentIds.filter(student => !attendanceIds.some(attendance => attendance.student_id == student.id));
  not_attendance_ids.value = newStudentIds;
};
const attendance = id => {
  jsonRPC({
    url: "/vue/live/attendance",
    params: {
      id: id,
    },
    success(res) {
      const data = getResponseData(res);
      logDebug(`获取成功`, data);
      message.success('签到成功');
      fetchData()
    },
    fail(error) {
      logError(`查询失败, `, error);
    },
  });
}
const enter_training = id => {
  routerPush(router, `/live/${id}`)
}

const edit = ref(false);
const open = ref(false);
const drawerData = ref();
const showDrawer = (e) => {
  drawerData.value = data_list.note_ids[e]
  open.value = true;
};
let openDeleteModal = ref(false);
const onDelete = () => {
  openDeleteModal.value = false;
  jsonRPC({
    url: "/api/experiment/live/note/delete",
    params: {
      id: drawerData.value.id,
    },
    success(res) {
      const data = getResponseData(res)
      logDebug('onDelete', data)
      message.success('删除直播笔记成功')
      fetchData();
    },
    fail(error) {
      logError(`删除直播笔记失败, `, error)
    },
  }).then(() => {
    open.value = false;
  })
};
const onEdit = () => {
  jsonRPC({
    url: "/api/experiment/live/note/edit",
    params: {
      id: drawerData.value.id,
      title: drawerData.value.title,
      description: drawerData.value.description,
    },
    success(res) {
      const data = getResponseData(res)
      logDebug('onEdit', data)
      message.success('修改直播笔记成功')
      fetchData();
    },
    fail(error) {
      logError(`修改直播笔记失败, `, error)
    },
  }).then(() => {
    edit.value = false;
  })
};
const onCancel = () => {
  edit.value = false;
  open.value = false;
  fetchData();
};
const onClose = () => {
  open.value = false;
};
const border_top = {
  height: '48px',
  lineHeight: '48px',
  border: '1px solid #dbdbdb',
  backgroundColor: '#F0FAFF',
  marginBottom: '-1px'
};

const border_body = {
  height: '48px',
  lineHeight: '48px',
  border: '1px solid #dbdbdb',
  marginRight: '-1px',
  marginBottom: '-1px'
};
</script>

<style scoped lang="scss">
.ant-col-5 {
  flex: 0 0 20%;
  max-width: 20%;
}
.class_detail {
  .row {
    .col-md-7 {
      display: flex;
      flex-direction: column;
      justify-content: space-between;
    }
    h4 {
      font-size: 18px;
      font-weight: bold;
      margin-bottom: 10px;
    }
    p {
      margin-bottom: 10px;
    }
    h1 {
      font-size: 28px;
      color: #ff5454;
      margin-top: 20px;
      margin-bottom: 30px;
    }
    .operate {
      .btn {
        height: 40px;
        padding-left: 25px;
        padding-right: 25px;
        border-radius: 20px;
        background-color: #53a6ff;
        color: #fff;
        box-shadow: 1px 1px 4px 0px #54545466;
        &:first-child {
          margin-right: 20px;
        }
        &.edit {
          background-color: #ededed;
          color: #278fff;
        }
      }
    }
  }
  .nav-tabs {
    border: none;
    margin-top: 20px;
    .nav-item {
      .nav-link {
        position: relative;
        font-size: 16px;
        border: none;
        &.active {
          background-color: transparent;
          color: #258eff;
          &:before {
            content: "";
            position: absolute;
            bottom: 0px;
            left: 25%;
            width: 50%;
            height: 3px;
            background-color: #1854f7;
          }
        }
      }
    }
  }
  .train_list {
    .info {
      margin-bottom: 15px;
      box-shadow: 1px 2px 4px 0px #1540e666;
      padding: 15px;
      border-radius: 8px;
      .title {
        font-style: 16px;
        > div {
          border: 1px solid #52c9ff;
          padding: 1px 4px;
          border-radius: 4px;
          font-size: 12px;
          margin-left: 5px;
        }
      }
      img {
        width: 30px;
        height: 30px;
        border-radius: 50%;
        margin-right: 5px;
      }
      button {
        background-color: #59F15E;
      }
    }
  }
  .statistics_list {
    .info {
      margin-bottom: 10px;
      box-shadow: 1px 2px 4px 0px #1540e666;
      padding: 15px;
      border-radius: 8px;
      background-color: #fff;
      span {
        margin-right: 10px;
        color: #278FFF;
      }
      button {
        height: 40px;
        border-radius: 20px;
        background-color: #A0E69B;
      }
    }
  }
  .note_list {
    .info {
      margin-bottom: 10px;
      box-shadow: 1px 2px 4px 0px #1540e666;
      padding: 15px;
      border-radius: 8px;
    }
  }
  .homework_list {
    .info {
      margin-bottom: 10px;
      box-shadow: 1px 2px 4px 0px #1540e666;
      padding: 15px;
      border-radius: 8px;
      background-color: #fff;
      h6 {
        display: flex;
        gap: 8px;
      }
      span {
        display: inline-block;
        color: #52c9ff;
        font-size: 12px;
        border: 1px solid #52c9ff;
        border-radius: 4px;
        padding: 1px 4px;
        &.NotSubmitted {
          color: #DE3D12;
          border-color: #DE3D12;
        }
      }
      button {
        height: 40px;
        border-radius: 20px;
      }
    }
  }
}
.accordion {
  padding: 7px 0;
  &:not(:last-child) {
    border-bottom: 1px solid #e4efff;
  }
  a {
    display: inline-block;
    height: 25px;
    width: 25px;
    border-radius: 50%;
    overflow: hidden;
    text-align: center;
    user-select: none;
    &:not(:last-child) {
      margin-right: 10px;
    }
    background-color: #BFBFBF;
    &.course_video {
      background-color: #94d9fe;
    }
    &.course_experiment {
      background-color: #C1AAFF;
    }
    &.course_case {
      background-color: #99d094;
    }
    &.course_exercise {
      background-color: #ff8857;
    }
    &.course_courseware {
      background-color: #ff8b8b;
    }
  }
}
</style>
