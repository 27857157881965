<template>
  <div class="classwork-detail">
    <a-breadcrumb separator=">">
      <a-breadcrumb-item>
        <router-link to="/console/class">培训班级</router-link>
      </a-breadcrumb-item>
      <a-breadcrumb-item>
        <router-link :to="`/console/class/${class_id}`">{{data_list.class_name}}</router-link>
      </a-breadcrumb-item>
      <a-breadcrumb-item>
        <router-link :to="`/console/class/${class_id}/${course_id}`">{{data_list.course_name}}</router-link>
      </a-breadcrumb-item>
      <a-breadcrumb-item>{{data_list.work_name}}</a-breadcrumb-item>
    </a-breadcrumb>
    <div class="info">
      <a-flex :justify="'space-between'" :align="'center'" style="margin-bottom: 20px;">
        <h1>{{data_list.work_name}}</h1>
        <a-affix :offset-top="10">
          <template v-if="editorMode == 'create'">
            <a-button type="primary" class="submitHomework" @click="submitHomework">提交作业</a-button>
            <a-button type="primary" class="saveHomework" @click="_Back">返回</a-button>
          </template>
          <template v-else-if="editorMode == 'update'">
            <a-button type="primary" class="submitHomework" @click="updateHomework">提交作业</a-button>
            <a-button type="primary" class="saveHomework" @click="_Back">返回</a-button>
          </template>
          <template v-else-if="editorMode == 'correct'">
            <a-button type="primary" class="saveHomework" @click="switchStudents">切换学生</a-button>
            <a-button type="primary" class="submitHomework" @click="completeCorrection">完成批改</a-button>
          </template>
          <a-button v-else type="primary" class="saveHomework" @click="_Back">返回</a-button>
        </a-affix>
      </a-flex>
      <a-spin :spinning="spinning" size="large" tip="Loading...">
        <a-flex :justify="'center'" class="mb-3">
          学生姓名：{{data_list.user_name}} 
          <div class="mx-3"></div> 
          学生编号：{{data_list.student_barcode}}
        </a-flex>
        <a-form ref="formRef" :model="create_list" :label-col="{style: {width: '100px'} }" 
          :disabled="editorMode === 'readonly' || editorMode === 'correct'">
          <div v-for="(item,index) in data_list.question_ids" :key="item.id" class="mb-3">
            <a-flex justify="space-between">
              <a-flex>{{index+1}}、<div style="margin-bottom: -1em;" v-html="item.name"></div></a-flex>
              <a-flex>{{item.type}} <div class="mx-2"/> {{item.score}}分</a-flex>
            </a-flex>
            <a-flex v-if="editorMode === 'readonly' || editorMode === 'correct'" justify="flex-end">
              答案：<template v-if="item.type == 'QA'"><div v-html="item.qa_solution"/></template>
              <template v-else-if="item.type == 'CODE'"><div v-html="item.code_solution"/></template>
              <template v-else>{{item.solution}}</template>
              <div class="mx-3"></div> 
              <template v-if="editorMode == 'readonly'">
                得分：{{create_list[index].answer_score}} 
              </template>
              <a-form-item v-else label="得分:" class="mb-0">
                <a-input-number v-model:value="create_list[index].answer_score"
                  :min="0" :max="data_list.question_ids[index].score" :disabled="false"/>
              </a-form-item>
            </a-flex>
            <a-radio-group v-if="item.type == '单选'" v-model:value="create_list[index].answer" :style=style>
              <div v-for="option in options" :key="option" style="cursor: initial;">
                <a-radio v-if="`option_${option.toLowerCase()}` in data_list.question_ids[index]" :value="option">
                  <a-flex>{{option}}、<span style="margin-bottom: -1em;" v-html="data_list.question_ids[index]['option_' + option.toLowerCase()]"></span></a-flex>
                </a-radio>
              </div>
            </a-radio-group>
            <a-checkbox-group v-else-if="item.type == '多选'" v-model:value="create_list[index].answer" :style=style>
              <div v-for="option in options" :key="option" style="cursor: initial;">
                <a-checkbox v-if="`option_${option.toLowerCase()}` in data_list.question_ids[index]" :value="option">
                  <a-flex>{{option}}、<span style="margin-bottom: -1em;" v-html="data_list.question_ids[index]['option_' + option.toLowerCase()]"></span></a-flex>
                </a-checkbox>
              </div>
            </a-checkbox-group>
            <a-form-item v-else-if="item.type == '填空'">
              <a-textarea v-model:value="create_list[index].answer"/>
            </a-form-item>
            <a-radio-group v-else-if="item.type == '判断'" v-model:value="create_list[index].answer" :style=style>
              <div v-for="option in ['A','B']" :key="option" style="cursor: initial;">
                <a-radio v-if="`option_${option.toLowerCase()}` in data_list.question_ids[index]" :value="option">
                  <a-flex>{{option}}、<span style="margin-bottom: -1em;" v-html="data_list.question_ids[index]['option_' + option.toLowerCase()]"></span></a-flex>
                </a-radio>
              </div>
            </a-radio-group>
            <a-form-item v-else-if="item.type == '问答'">
              <RichEditor v-model="create_list[index].answer" :height="301" 
              :editMode="editorMode == 'readonly' || editorMode == 'correct' ? 'readonly' : 'update'"/>
            </a-form-item>
            <a-form-item v-else-if="item.type == '编程'">
              <RichEditor v-model="create_list[index].answer" :height="301" 
              :editMode="editorMode == 'readonly' || editorMode == 'correct' ? 'readonly' : 'update'"/>
            </a-form-item>
            <template v-else-if="item.type == '报告'">
              <a-form-item label="课题报告">
                <RichEditor v-model="create_list[index].re_solution" :height="301" 
                :editMode="editorMode == 'readonly' || editorMode == 'correct' ? 'readonly' : 'update'"/>
              </a-form-item>
              <a-form-item label="附件">
                <a-upload
                  v-model:file-list="courseware"
                  :before-upload="beforeUpload"
                  @remove="handleRemove"
                  :max-count="1"
                  accept=".pdf"
                >
                  <a-tooltip>
                    <template #title>请上传 PDF 格式的文件</template>
                    <a-button>
                      <UploadOutlined style="display: inline-flex;"/>
                      上传
                    </a-button>
                  </a-tooltip>
                </a-upload>
              </a-form-item>
              <a-form-item label="附件地址">
                <a-input v-model:value="create_list[index].file_path" placeholder="附件地址" />
              </a-form-item>
            </template>
          </div>
        </a-form>
      </a-spin>
    </div>
  </div>
</template>

<script setup>
import {useRouter} from "vue-router";
import {logDebug, logError} from "@/utils/logger";
import {getRouterParam,routerBack} from "@/utils/router_utils";
import {jsonRPC,getResponseData} from "@/utils/http_utils";
import {onMounted, reactive, ref} from 'vue';
import RichEditor from "@/components/RichEditor";
import {getBase64} from "@/utils/file_utils";
import {isTrue} from "@/utils/common_utils";
import { message } from "ant-design-vue";

const router = useRouter()
const workId = ref(getRouterParam(router, 'work_id'))
const query = router.currentRoute.value.query;
const editorMode = query.mode;
const scene_type = query.scene_type;
const record_id = query.record_id;
const class_id = query.class_id;
const course_id = query.course_id;
const formRef = ref();
const options = Array.from({length: 8}, (_, i) => String.fromCharCode(65 + i))
const data_list = reactive({
  'work_name': '',
  'class_name': '',
  'course_name': '',
})
const create_list = reactive({})
const beforeUpload = file => {
  courseware.value = [...(courseware.value || []), file];
  return false;
};
const courseware = ref([])
const handleRemove = file => {
  const index = courseware.value.indexOf(file);
  const newFileList = courseware.value.slice();
  newFileList.splice(index, 1);
  courseware.value = newFileList;
};
function fetchData(mode) {
  let params = {
    class_id: class_id,
    course_id: course_id,
    workId: workId.value,
    ...(editorMode != 'create' && { record_id: record_id }),
    ...(editorMode == 'correct' || editorMode == 'readonly' && { paper_partner_id: isTrue(mode)? mode : 0}),
  };
  jsonRPC({
    url: "/vue/console/class/work/detail",
    params: params,
    success(res) {
      const data = getResponseData(res);
      Object.assign(data_list, data.records);
      Object.assign(create_list, data.answer_ids);
    },
    fail(error) {
      logError(`查询失败, `, error);
    },
  }).then(() => {
    spinning.value = false; // 加载完成后关闭加载状态
  });
}
onMounted(fetchData);

let style = { display: 'flex', flexDirection: 'column' }
let handleHomework = async (mode) => {
  const filePromises = await Promise.all(courseware.value.map(async file => {
    const base64Data = await getBase64(file.originFileObj);
    return {
      ...file,
      raw_data: base64Data,
      file_name: file.name,
    };
  }));
  let params = {
    workId: workId.value,
    class_id: class_id,
    scene_type: scene_type,
    create_list: create_list,
    mode: mode,
    courseware: filePromises,
    ...(mode == 'correct' && { paper_partner_id: data_list.paper_partner_ids[switchIndex.value]}),
  };
  jsonRPC({
    url: "/vue/console/class/work/submit",
    params: params,
    success(res) {
      const data = getResponseData(res)
      logDebug(`获取成功`, data)
      routerBack(router)
    },
    fail(error) {
      logError(`查询失败, `, error)
    },
  });
}
// 提交作业
let submitHomework = () => {
  handleHomework('submit');
}
// 更新作业
let updateHomework = () => {
  handleHomework('update');
}
// 返回
let _Back = () => {
  routerBack(router);
}
const spinning = ref(false);
const switchIndex = ref(0); // 将索引初始值设置为0
// 切换学生
const switchStudents = () => {
  spinning.value = true;
  // 确保索引循环切换
  switchIndex.value = (switchIndex.value + 1) % data_list.paper_partner_ids.length;
  if (switchIndex.value == data_list.paper_partner_ids.length - 1) {
    message.success("已经是最后一个学生");
  } else {
    message.success("切换学生");
  }
  fetchData(data_list.paper_partner_ids[switchIndex.value])
};
// 批改作业
const completeCorrection = () => {
  handleHomework('correct');
}
</script>

<style scoped lang='scss'>
.classwork-detail{
  height: 100%;
  background-color: #fff;
  .info{
    background-color: #fff;
    padding: 20px;
    height: 100%;
  }
  h1{
    font-size: 18px;
    font-weight: bold;
    padding-bottom: 5px;
    padding-right: 20px;
    border-bottom: 1px solid #63B1FF;
  }
  button{
    border-radius: 16px;
    &.submitHomework{
      background-color: #6FA6FF;
    }
    &.saveHomework{
      background-color: #D4D4D4;
    }
    &:not(:last-child) {
      margin-right: 10px;
    }
  }
}
</style>
