<template>
  <div>
    <a-button id="function-vnc-controller" type="primary" @click="clickHandler"
              :style="props.styleSetting.buttonStyle">
      <a-tooltip :placement="placement">
        <template #title>自动调整图形化界面长宽比</template>
        <OneToOneOutlined :style="props.styleSetting.iconStyle"/>
      </a-tooltip>
    </a-button>
  </div>
</template>

<script setup>
import {onMounted, ref} from 'vue';
import {OneToOneOutlined} from '@ant-design/icons-vue';
import {logComponents, logDebug} from "@/utils/logger";

logComponents([OneToOneOutlined])
logDebug('TerminalFunctionVNCRefresh setup!')

const placement = ref(window.location.search.includes('desktop') ? 'right' : 'bottom');
const props = defineProps({
  primal: {type: String},
  data: {type: Object},
  styleSetting: {type: Object},
})

const primal = props.primal
const data = props.data

const emit = defineEmits(["ok", "cancel", 'refresh', 'customize']); //声明 emits


const clickHandler = function () {
  logDebug('打开或关闭控制面板')
  logDebug(JSON.stringify(props.data))


  emit("ok", {}); //触发事件
  // emit("cancel", "我是子组件fullscreen传入的cancel值"); //触发事件
  // emit("refresh", "我是子组件fullscreen传入的refresh值"); //触发事件
  // emit("customize", {
  //   'arg1': '参数1',
  //   'arg2': 2,
  // }) //触发事件
}

onMounted(() => {
  // 在onMounted中获取数据
  logDebug(`TerminalFunctionVNCRefresh onMounted.`)
})

const onSelected = function () {
  logDebug(`TerminalFunctionVNCRefresh onSelected.`)
}

defineExpose({
  primal,
  data,
  onSelected,
})


</script>

<style scoped>
#function-vnc-controller {
  background: #00BFFF;
}
</style>

