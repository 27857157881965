<template>
  <div class="download_page">
    <div class="container">
      <!-- 标题 -->
      <div class="top">
        <h1>下载 Hi168 桌面版</h1>
        <p>体验更多精彩内容，提升学习效率</p>
      </div>

      <!-- 软件信息 -->
      <div class="info">
        <h3>最低支持的操作系统及配置要求</h3>
        <ul>
          <li><strong>支持系统：</strong>Windows 10, Windows 11</li>
          <li><strong>位数：</strong>32位 和 64位</li>
          <li><strong>处理器：</strong>Intel Core i3 或更高</li>
          <li><strong>内存：</strong>4 GB RAM 或更高</li>
          <li><strong>存储空间：</strong>至少 500 MB 可用空间</li>
          <li><strong>图形卡：</strong>集成显卡 或 NVIDIA/AMD 独立显卡</li>
        </ul>
      </div>

      <!-- 下载链接及说明 -->
      <div class="download_links">
        <a v-if="data_list.download_Windows_show" :href="data_list.download_Windows_show" class="download-item active">
          <img src="/hw_web/static/src/web/img/Windows.png" />
          <p>Windows<br /><span>适用于 Windows 10, 11</span></p>
        </a>

        <a v-if="data_list.download_MacOS_show" :href="data_list.download_MacOS_show" class="download-item">
          <img src="/hw_web/static/src/web/img/Mac OS1.png" />
          <p>Mac OS</p>
        </a>

        <a v-if="data_list.download_Linux_show" :href="data_list.download_Linux_show" class="download-item">
          <img src="/hw_web/static/src/web/img/Linux1.png" />
          <p>Linux</p>
        </a>
      </div>

      <!-- 安装说明 -->
      <div class="instructions">
        <h3>安装说明</h3>
        <ul>
          <li><strong>Windows：</strong>下载并运行安装文件 Hi168.exe，按照提示完成安装。</li>
        </ul>
      </div>
    </div>
  </div>
</template>

<script setup>
import { logDebug, logError } from "@/utils/logger";
import { onMounted, reactive} from "vue";
import { getResponseData, jsonRPC } from "@/utils/http_utils";
import { message } from "ant-design-vue";

const data_list = reactive({
  download_Windows_show: null,
  download_MacOS_show: false,
  download_Linux_show: false,
});
function fetchData() {
  jsonRPC({
    url: `/api/download`,
    params: {},
    success(res) {
      const data = getResponseData(res);
      logDebug(`获取成功`, data);
      Object.assign(data_list, data);
    },
    fail(error) {
      logError(`获取失败`, error);
      message.error(`获取失败，请稍后再试`);
    },
  });
}
onMounted(() => {
  fetchData();
});
</script>

<style scoped lang="scss">
.download_page {
  color: #333;
  background-color: #f5f5f5; /* 背景颜色 */
  padding: 40px 20px;
  display: flex;
  flex-direction: column;
  align-items: center;

  .top {
    text-align: center;
    margin-bottom: 40px;
    
    h1 {
      font-size: 32px;
      color: #333;
      margin-bottom: 10px;
    }

    p {
      font-size: 18px;
      color: #666;
    }
  }

  .info {
    width: 100%;
    max-width: 800px;
    text-align: left;
    margin-bottom: 40px;

    h3 {
      font-size: 24px;
      margin-bottom: 20px;
    }

    ul {
      list-style: none;
      padding-left: 0;

      li {
        font-size: 16px;
        margin-bottom: 10px;

        strong {
          color: #333;
        }
      }
    }
  }

  .download_links {
    display: flex;
    justify-content: center;
    gap: 40px;
    margin-bottom: 40px;

    .download-item {
      display: flex;
      flex-direction: column;
      align-items: center;
      text-align: center;
      color: #666;
      transition: all 0.3s ease;

      img {
        height: 50px;
        width: 50px;
        margin-bottom: 15px;
        transition: all 0.3s ease;
      }

      p {
        font-size: 16px;
        margin: 0;
      }

      &:hover {
        img {
          transform: scale(1.1);
        }

        p {
          color: #1296db;
        }
      }

      &.active {
        img {
          height: 70px;
          width: 70px;
        }

        p {
          font-size: 18px;
          color: #1296db;
        }
      }
    }
  }

  .instructions {
    width: 100%;
    max-width: 800px;
    text-align: left;

    h3 {
      font-size: 24px;
      margin-bottom: 20px;
    }

    ul {
      list-style: none;
      padding-left: 0;

      li {
        font-size: 16px;
        margin-bottom: 10px;
      }

      strong {
        color: #333;
      }
    }
  }

  /* 响应式布局 */
  @media (max-width: 768px) {
    .download_links {
      flex-direction: column;
      gap: 20px;
    }

    .top h1 {
      font-size: 28px;
    }

    .top p {
      font-size: 16px;
    }
  }
}
</style>
