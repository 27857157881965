<template>
  <div class="not-found">
    404
  </div>
</template>

<script setup>

import {logDebug} from "@/utils/logger";

logDebug('NotFound setup.')

import {onMounted} from 'vue'

onMounted(() => {
  // 在onMounted中获取数据
  logDebug(`NotFound is now mounted.`)
})

</script>

<style scoped>

</style>
