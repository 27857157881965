
export default {
	fullScreen: function() {//全屏方法必须放在按钮事件里才能启用成功，不可自动全屏。
		var isFullscreen = document.fullScreen || document.mozFullScreen || document.webkitIsFullScreen;
		var el = document.documentElement;
		if (!isFullscreen) {
			(el.requestFullscreen && el.requestFullscreen()) ||
			(el.mozRequestFullScreen && el.mozRequestFullScreen()) ||
			(el.webkitRequestFullscreen && el.webkitRequestFullscreen()) || 
			(el.msRequestFullscreen && el.msRequestFullscreen());
		} else {
			document.exitFullscreen ? document.exitFullscreen() :
			document.mozCancelFullScreen ? document.mozCancelFullScreen() :
			document.webkitExitFullscreen ? document.webkitExitFullscreen() : '';
		}
	},
	isFullscreen: function() {
		return document.fullScreen || document.mozFullScreen || document.webkitIsFullScreen;
	},
	reloadWindow: function() {
		window.location.reload();
	}
}