<template>
  <div class="buyResource">
    <div class="container">
      <a-breadcrumb separator=">">
        <a-breadcrumb-item>我的应用</a-breadcrumb-item>
        <a-breadcrumb-item>购买资源</a-breadcrumb-item>
      </a-breadcrumb>
    </div>
    <div class="info">
      <h4>购买资源</h4>
      <a-form :model="resourceForm" :label-col="{ span: 6 }" :wrapper-col="{ span: 18 }" layout="horizontal">
        <a-form-item label="类型：">
          <a-radio-group button-style="solid">
            <a-radio-button>通用计算型</a-radio-button>
          </a-radio-group>
        </a-form-item>
        <a-form-item label="CPU：">
          <a-radio-group v-model:value="resourceForm.CPU" button-style="solid">
            <a-radio-button v-for="item in CPU_ids" 
              :key="item.id" :value="item.id">{{item.value}}核</a-radio-button>
          </a-radio-group>
        </a-form-item>
        <a-form-item label="内存：">
          <a-radio-group v-model:value="resourceForm.RAM" button-style="solid">
            <a-radio-button v-for="item in RAM_ids" 
              :key="item.id" :value="item.id">{{item.value}}G</a-radio-button>
          </a-radio-group>
        </a-form-item>
        <a-form-item label="磁盘：">
          <a-radio-group v-model:value="resourceForm.disk" button-style="solid">
            <a-radio-button v-for="item in disk_ids" 
              :key="item.id" :value="item.id">{{ item.value.includes('T') ? item.value : item.value + 'G' }}</a-radio-button>
          </a-radio-group>
        </a-form-item>
        <!-- <a-form-item label="网络带宽：">
          <a-radio-group v-model:value="resourceForm.bandwidth" button-style="solid">
            <a-radio-button value="1">1 mb</a-radio-button>
            <a-radio-button value="5">5 mb</a-radio-button>
            <a-radio-button value="10">10 mb</a-radio-button>
          </a-radio-group>
        </a-form-item> -->
        <a-form-item label="使用期限：">
          <a-radio-group v-model:value="resourceForm.day" button-style="solid">
            <a-radio-button value="1">一天</a-radio-button>
            <a-radio-button value="10">十天</a-radio-button>
            <a-radio-button value="30">一个月</a-radio-button>
            <a-radio-button value="90">三个月</a-radio-button>
            <a-radio-button value="180">半年</a-radio-button>
            <a-radio-button value="365">一年</a-radio-button>
          </a-radio-group>
        </a-form-item>
        <div style="border-top: 1px solid #b7d9ff;" class="my-5"></div>
        <div class="container">
          <a-flex justify="flex-end">
            <div>价格：<span style="color: #2481e9;font-size: 16px;font-weight: bold;">¥
              {{total_price.toFixed(2)}}</span></div>
          </a-flex>
          <a-flex justify="flex-end" class="mb-5" style="margin-top: 18px;">
            <div>
              <!-- <a-button style="margin-right: 10px;">加入购物车</a-button> -->
              <a-button type="primary" @click="promptlyOrder">立即下单</a-button>
            </div>
          </a-flex>
        </div>
      </a-form>
    </div>
  </div>
</template>

<script setup>
import {logDebug, logError} from "@/utils/logger";
import { onMounted, reactive, ref, watch } from "vue";
import { jsonRPC, getResponseData } from "@/utils/http_utils";
const resourceForm = reactive({
  'CPU': '',
  'RAM': '',
  'disk': '',
  'bandwidth': '',
  'day': '1',
})
watch([resourceForm], ()=>{
  let CPU_price = CPU_ids.value.find(item => item.id == resourceForm.CPU).lst_price;
  let RAM_price = RAM_ids.value.find(item => item.id == resourceForm.RAM).lst_price;
  let disk_price = disk_ids.value.find(item => item.id == resourceForm.disk).lst_price;
  total_price.value = (CPU_price + RAM_price + disk_price)*resourceForm.day
});
const data_list = ref([])
const CPU_ids = ref([])
const RAM_ids = ref([])
const disk_ids = ref([])
const total_price = ref(0)
onMounted(()=>{
  jsonRPC({
    url: "/api/experiment/app/resource/product_list",
    params: {},
    success(res) {
      const data = getResponseData(res);
      logDebug(`获取成功`, data);
      data_list.value = data;
      if (Array.isArray(data)) {
        CPU_ids.value = (data.find(item => item.product_name == 'CPU') || {}).product_variant;
        if (CPU_ids.value.length > 0) {
          resourceForm.CPU = CPU_ids.value[0].id
        }
        RAM_ids.value = (data.find(item => item.product_name == '内存') || {}).product_variant;
        if (RAM_ids.value.length > 0) {
          resourceForm.RAM = RAM_ids.value[0].id
        }
        disk_ids.value = (data.find(item => item.product_name == '磁盘') || {}).product_variant;
        if (disk_ids.value.length > 0) {
          resourceForm.disk = disk_ids.value[0].id
        }
      }
    },
    fail(error) {
      logError(`查询失败, `, error);
    },
  });
});
let promptlyOrder = ()=>{
  jsonRPC({
    url: "/api/shop_cart/resource",
    params: {
      product_id_list: [resourceForm.CPU,resourceForm.RAM,resourceForm.disk],
      product_uom_qty: resourceForm.day,
    },
    success(res) {
      const data = getResponseData(res);
      logDebug(`获取成功`, data);
      window.location.href = '/shop';
    },
    fail(error) {
      logError(`查询失败, `, error);
    },
  });
}
</script>

<style lang="scss" scoped>
.buyResource{
  .info{
    height: 100%;
    background-color: #fff;
    border-bottom: 1px solid #b7d9ff;
    h4{
      font-size: 20px;
      padding-top: 30px;
      margin-bottom: 20px;
      text-align: center;
    }
  }
}
:where(.ant-radio-button-wrapper){
  height: 30px;
  min-width: 80px;
  line-height: 28px;
  border-radius: 4px;
  margin: 0 5px;
  border-inline-start-width: 1px;
  text-align: center;
  &:not(:first-child)::before{
    content: none
  }
  &:first-child{
    border-start-start-radius: 4px; 
    border-end-start-radius: 4px;
  }
  &:last-child{
    border-start-end-radius: 4px;
    border-end-end-radius: 4px;
  }
}
</style>