<template>
  <div class="console_image_app_detail">
    <a-breadcrumb separator=">">
      <a-breadcrumb-item>
        <router-link v-if="!isVM" :to="`/console/image/container/${image_id}`">容器镜像详情</router-link>
        <router-link v-if="isVM" :to="`/console/image/vm/${vm_image_id}`">虚拟机镜像详情</router-link>
      </a-breadcrumb-item>
      <a-breadcrumb-item>应用模板详情</a-breadcrumb-item>
    </a-breadcrumb>
    <a-row>
      <a-col :span="16">
        <a-form ref="formRef" :disabled="editorMode === 'readonly'" :label-col="labelCol"
                :model="data_list"
                :wrapper-col="wrapperCol"
                layout="horizontal"
        >
          <a-row>
            <a-col :span="24">
              <a-form-item v-if="!isVM" label="容器镜像ID：" name='user_image_id'>
                <a-input :value="image_id" disabled/>
              </a-form-item>
              <a-form-item v-if="isVM" label="虚拟机镜像ID：" name='user_image_id'>
                <a-input :value="vm_image_id" disabled/>
              </a-form-item>
              <a-form-item label="应用ID：" name='user_app_id'>
                <a-input v-model:value="user_app_id" disabled/>
              </a-form-item>
              <a-form-item label="应用名称：" name='app_name'>
                <a-input v-model:value="data_list.app_name" disabled/>
              </a-form-item>
              <a-form-item label="应用描述：" name='app_description'>
                <a-input v-model:value="data_list.app_description"/>
              </a-form-item>
              <a-form-item :rules="[{ required: true, message: '请输入应用图片'}]" label="应用图片："
                           name='app_image'>
                <a-upload
                    v-model:file-list="data_list.app_image"
                    :customRequest="uploadDummyRequest"
                    :max-count="1"
                    accept=".png,.jpg,.jpeg"
                    list-type="picture-card"
                    @preview="handlePreview"
                >
                  <div v-if="data_list.app_image.length < 1">
                    <plus-outlined/>
                    <div class="ant-upload-text">上传图片</div>
                  </div>
                </a-upload>
                <a-modal :footer="null" :open="previewVisible" :title="previewTitle" @cancel="cancelPreview">
                  <img :src="previewImage" alt="example" style="width: 100%"/>
                </a-modal>
              </a-form-item>
              <a-form-item label="CPU：" name='app_cpu'>
                <a-select v-model:value="data_list.app_cpu" class="form-item">
                  <a-select-option value="1">1核</a-select-option>
                  <a-select-option value="2">2核</a-select-option>
                  <a-select-option value="4">4核</a-select-option>
                  <a-select-option value="8">8核</a-select-option>
                  <a-select-option value="16">16核</a-select-option>
                </a-select>
              </a-form-item>
              <a-form-item label="内存：" name='app_memory'>
                <a-select v-model:value="data_list.app_memory" class="form-item">
                  <a-select-option value="1">1GB</a-select-option>
                  <a-select-option value="2">2GB</a-select-option>
                  <a-select-option value="4">4GB</a-select-option>
                  <a-select-option value="8">8GB</a-select-option>
                  <a-select-option value="16">16GB</a-select-option>
                  <a-select-option value="32">32GB</a-select-option>
                </a-select>
              </a-form-item>
              <a-form-item label="磁盘：" name='app_disk'>
                <a-select v-model:value="data_list.app_disk" class="form-item">
                  <template v-if="isVM">
                    <a-select-option value="100">100GB</a-select-option>
                  </template>
                  <template v-else>
                    <a-select-option value="10">10GB</a-select-option>
                    <a-select-option value="20">20GB</a-select-option>
                    <a-select-option value="40">40GB</a-select-option>
                    <a-select-option value="80">80GB</a-select-option>
                    <a-select-option value="100">100GB</a-select-option>
                  </template>
                </a-select>
              </a-form-item>
              <a-form-item label="带宽：" name='app_band_width'>
                <a-select v-model:value="data_list.app_band_width" class="form-item">
                  <a-select-option value="1">1Mbps</a-select-option>
                  <a-select-option value="2">2Mbps</a-select-option>
                  <a-select-option value="5">5Mbps</a-select-option>
                  <a-select-option value="10">10Mbps</a-select-option>
                  <a-select-option value="20">20Mbps</a-select-option>
                </a-select>
              </a-form-item>
              <a-form-item v-if="!isVM" label="GPU：" name='app_gpu'>
                <a-select v-model:value="data_list.app_gpu" :required="true" class="form-item">
                  <a-select-option value="none">无</a-select-option>
                  <a-select-option value="tesla-t4">tesla-t4</a-select-option>
                  <a-select-option value="tesla-v100-sxm3">tesla-v100-sxm3</a-select-option>
                </a-select>
              </a-form-item>
              <a-form-item label="服务端口：" name='service_port_list'>
                <div>
                  <a-row>
                    <a-col :span="24">
                      <a-table :columns="data_list.service_port_list.columns"
                               :data-source="data_list.service_port_list.data"
                               :pagination="false" bordered class="form-table"
                               size="small" tableLayout="fixed">
                        <template #bodyCell="{ column, index, record}">
                          <template v-if="column.dataIndex === 'service_type'">
                            <a-form-item :label="``" :name="`service_type_${index}`"
                                         :rules="[{ required: true, message: '请输入服务端口类型',
                                         validator: () => customCheckServicePort(index, 'service_type') }]"
                                         class="form-item">
                              <a-select v-model:value="record.service_type" class="form-item">
                                <a-select-option value="HTTP">HTTP</a-select-option>
                                <a-select-option value="TCP">TCP</a-select-option>
                                <a-select-option value="UDP">UDP</a-select-option>
                              </a-select>
                            </a-form-item>
                          </template>
                          <template v-if="column.dataIndex === 'service_port'">
                            <a-form-item :label="``" :name="`service_port_${index}`"
                                         :rules="[{ required: true, message: '请输入服务端口号',
                                         validator: () => customCheckServicePort(index, 'service_port') }]"
                                         class="form-item">
                              <a-input-number v-model:value="record.service_port"
                                              class="form-item"
                                              max="65535" min="1"/>
                            </a-form-item>
                          </template>
                          <template v-if="column.dataIndex === 'action'">
                            <a-form-item class="form-item">
                              <a-tooltip>
                                <template #title>删除该条记录</template>
                                <CloseCircleOutlined style="color: red"
                                                     @click="()=>onDeleteServicePort(record.key)"/>
                              </a-tooltip>
                            </a-form-item>
                          </template>
                        </template>
                      </a-table>
                    </a-col>
                  </a-row>
                  <a-row>
                    <a-col :span="24">
                      <a-button @click="()=>onAddServicePort()">新增服务端口</a-button>
                    </a-col>
                  </a-row>
                </div>
              </a-form-item>
              <a-form-item v-if="!isVM" label="环境变量：" name='app_band_width'>
                <div>
                  <a-row>
                    <a-col :span="24">
                      <a-table :columns="data_list.env_var_list.columns"
                               :data-source="data_list.env_var_list.data"
                               :pagination="false" bordered class="form-table"
                               size="small" tableLayout="fixed">
                        <template #bodyCell="{ column, index, record}">
                          <template v-if="column.dataIndex === 'env_name'">
                            <a-form-item :label="``" :name="`env_name_${index}`"
                                         :rules="[{ required: true, message: '请输入环境变量名称',
                                         validator: () => customCheckEnv(index, 'env_name') }]"
                                         class="form-item">
                              <a-input v-model:value="record.env_name" class="form-item"/>
                            </a-form-item>
                          </template>
                          <template v-if="column.dataIndex === 'env_value'">
                            <a-form-item :label="``" :name="`env_value_${index}`"
                                         :rules="[{ required: true, message: '请输入环境变量值',
                                         validator: () => customCheckEnv(index, 'env_value') }]"
                                         class="form-item">
                              <a-input v-model:value="record.env_value" class="form-item"/>
                            </a-form-item>
                          </template>
                          <template v-if="column.dataIndex === 'action'">
                            <a-form-item class="form-item">
                              <a-tooltip>
                                <template #title>删除该条记录</template>
                                <CloseCircleOutlined style="color: red"
                                                     @click="()=>onDeleteEnv(record.key)"/>
                              </a-tooltip>
                            </a-form-item>
                          </template>
                        </template>
                      </a-table>
                    </a-col>
                  </a-row>
                  <a-row>
                    <a-col :span="24">
                      <a-button @click="()=>onAddEnv()">新增环境变量</a-button>
                    </a-col>
                  </a-row>
                </div>
              </a-form-item>
              <a-form-item v-if="!isVM" label="运行命令：" name='app_command'>
                <a-input v-model:value="data_list.app_command"/>
              </a-form-item>
              <a-form-item v-if="!isVM" label="运行命令参数：" name='app_arg_list'>
                <div>
                  <a-row>
                    <a-col :span="24">
                      <a-table :columns="data_list.app_arg_list.columns"
                               :data-source="data_list.app_arg_list.data"
                               :pagination="false" bordered class="form-table"
                               size="small" tableLayout="fixed">
                        <template #bodyCell="{ column, index, record}">
                          <template v-if="column.dataIndex === 'command_arg'">
                            <a-form-item :label="``" :name="`command_arg_${index}`"
                                         :rules="[{ required: false, message: '请输入命令参数',
                                         validator: () => customCheckCommandArg(index, 'command_arg') }]"
                                         class="form-item">
                              <a-input v-model:value="record.command_arg" class="form-item"/>
                            </a-form-item>
                          </template>
                          <template v-if="column.dataIndex === 'action'">
                            <a-form-item class="form-item">
                              <a-tooltip>
                                <template #title>删除该条记录</template>
                                <CloseCircleOutlined style="color: red"
                                                     @click="()=>onDeleteCommandArg(record.key)"/>
                              </a-tooltip>
                            </a-form-item>
                          </template>
                        </template>
                      </a-table>
                    </a-col>
                  </a-row>
                  <a-row>
                    <a-col :span="24">
                      <a-button @click="()=>onAddCommandArg()">新增运行命令参数</a-button>
                    </a-col>
                  </a-row>
                </div>
              </a-form-item>
            </a-col>
          </a-row>
          <a-flex justify="center" align="center" gap="middle" class="mb-5">
            <a-button v-if="editorMode === 'create'" type="primary" @click="confirmFormCreate">创建</a-button>
            <a-button v-if="editorMode === 'update'" type="primary" @click="confirmFormUpdate">保存</a-button>
            <a-button v-if="editorMode !== 'readonly'" @click="cancelForm">取消</a-button>
          </a-flex>
        </a-form>
      </a-col>
      <a-col :span="2"></a-col>
      <a-col :span="6">
        <a-button type="primary" @click="()=>_onCreateDeployment(data_list.template_id)">部署应用</a-button>
        <a-button v-if="!isVM" danger style="margin-left: 20px" @click="()=>onDeleteImageApp(data_list.user_app_id)">
          删除
        </a-button>
      </a-col>
    </a-row>
    <a-modal v-model:open="openCreateAppModal" :confirm-loading="confirmCreateAppLoading"
             title="创建应用" width="60%" @cancel="cancelCreateApp" @ok="confirmCreateApp">
      <a-form ref="formCreateAppRef" :model="createAppForm" :rules="rulesCreateApp" @submit="confirmCreateApp"
              :label-col="labelCol" :wrapper-col="wrapperCol" layout="horizontal">
        <a-form-item label="应用名称" name="app_name">
          <a-input v-model:value="createAppForm.app_name"/>
        </a-form-item>
        <a-form-item label="空间类型" name="ns_type">
          <a-select v-model:value="createAppForm.ns_type" :disabled="createAppForm.disable_namespace_selection">
            <a-select-option v-for="item in createAppForm.ns_types" :key="item[0]" :value="item[0]">
              {{ item[1] }}
            </a-select-option>
          </a-select>
        </a-form-item>
        <a-form-item label="命名空间" name="namespace_id">
          <a-select v-model:value="createAppForm.namespace_id"
                    :disabled="createAppForm.disable_namespace_selection">
            <template v-if="createAppForm.ns_type === 'private'">
              <a-select-option v-for="item in createAppForm.private_namespaces" :key="item.id" :value="item.id">
                {{ item.name }}
              </a-select-option>
            </template>
            <a-select-option v-for="item in createAppForm.public_namespaces" v-else :key="item.id"
                             :value="item.id">
              {{ item.name }}
            </a-select-option>
          </a-select>
        </a-form-item>
        <a-form-item label="部署方式" name="deployment_method">
          <a-select v-model:value="createAppForm.deployment_method">
            <a-select-option value="consumer_compute_power">消耗算力点先用后结</a-select-option>
            <!-- <a-select-option value="use_resource">使用现有资源</a-select-option> -->
          </a-select>
        </a-form-item>
        <template v-if="createAppForm.deployment_method=='consumer_compute_power'">
          <a-form-item label="算力服务" name="compute_power_service">
            <a-radio-group v-model:value="createAppForm.compute_power_service" button-style="solid">
              <a-radio-button v-if="createAppForm.capacity_type_level.base?.length>0" value="base">初级</a-radio-button>
              <a-radio-button v-if="createAppForm.capacity_type_level.middle?.length>0" value="middle">中级
              </a-radio-button>
              <a-radio-button v-if="createAppForm.capacity_type_level.high?.length>0" value="high">高级</a-radio-button>
            </a-radio-group>
          </a-form-item>
          <div>
            服务配置：
            <ul v-if="createAppForm.compute_power_service">
              <li v-for="item in createAppForm.capacity_type_level[createAppForm.compute_power_service]" :key="item">
                <a-flex justify="space-between" align="center">
                  <div>
                    <div>{{ item.template_inner_service_name }}:</div>
                    <a-flex :gap="15">
                      <span v-for="op in item.capacity.capacity_type_op" :key="op">
                        {{ op.hardware_id.hardware_type }}: {{ op.use_number }}
                        <template v-if="op.hardware_id.hardware_unit">{{ op.hardware_id.hardware_unit }}</template><br/>
                        <template v-if="op.hardware_id.hardware_name">({{ op.hardware_id.hardware_name }})</template>
                      </span>
                    </a-flex>
                  </div>
                  <div style="color: #a0c8ff;white-space: nowrap;">
                    {{ item.capacity.capacity_type_op_sum_point }} 算力/{{ displayUnit(item.capacity.unit_op) }}
                  </div>
                </a-flex>
              </li>
            </ul>
          </div>
          <div style="border-top: 3px solid #2580fd;"></div>
          <a-flex justify="space-between" align="center" class="my-3">
            <div style="font-size: 16px;">以上配置当前环境算力点总计：</div>
            <span style="color: #FF5F5F;font-size: 16px;">{{ sum_point }} 算力/{{ sum_point_unit }}</span>
          </a-flex>
        </template>
      </a-form>
    </a-modal>
  </div>
</template>

<script setup>
import {logDebug, logError} from "@/utils/logger";
import {computed, onMounted, reactive, ref, watch} from "vue";
import {getFailedMessage, getResponseData, jsonRPC} from "@/utils/http_utils";
import {useRouter} from 'vue-router';
import {routerBack, routerReplace} from "@/utils/router_utils";
import {CloseCircleOutlined, PlusOutlined} from "@ant-design/icons-vue";
import {newCancelPreview, newHandlePreview, newPreviewState, newUploadDummyRequest} from "@/utils/file_utils";
import {message, Modal} from "ant-design-vue";

const router = useRouter();

logDebug(`router.currentRoute.value`, router.currentRoute.value)
const isVM = `${router.currentRoute.value.path}`.startsWith("/console/image/vm/");

const image_id = router.currentRoute.value.params.image_id
logDebug(`isVM[${isVM}], image_id[${image_id}]`)

const vm_image_id = router.currentRoute.value.params.vm_image_id
logDebug(`isVM[${isVM}], vm_image_id[${vm_image_id}]`)

const user_app_id = router.currentRoute.value.params.user_app_id
logDebug(`isVM[${isVM}], image_id[${image_id}], vm_image_id[${vm_image_id}}], user_app_id[${user_app_id}]`)

const editorMode = ref('update')

const labelCol = {
  style: {
    width: '150px',
  },
};
const wrapperCol = {
  span: 20,
};
const formRef = ref();

const uploadDummyRequest = newUploadDummyRequest()
const {previewVisible, previewImage, previewTitle} = newPreviewState();
const cancelPreview = newCancelPreview(previewVisible, previewTitle)
const handlePreview = newHandlePreview(previewImage, previewVisible, previewTitle)

const data_list = reactive({
  app_type: isVM ? "VirtualMachineApp" : "ContainerApp",
  user_image_id: image_id,
  user_vm_image_id: vm_image_id,
  user_app_id: user_app_id,
  template_id: '',
  app_name: '',
  inner_service_name: '',
  app_description: '',
  app_image: [],
  app_cpu: '1',
  app_memory: '1',
  app_disk: isVM ? '100' : '10',
  app_band_width: '10',
  app_gpu: 'none',
  service_port_list: {
    columns: [
      {
        title: '端口类型',
        dataIndex: 'service_type',
      },
      {
        title: '端口号',
        dataIndex: 'service_port',
      },
      {
        title: '动作',
        dataIndex: 'action',
      },
    ],
    data: [
      // {
      //   key: '1',
      //   service_type: 'HTTP',
      //   service_port: '8080',
      // },
      // {
      //   key: '2',
      //   service_type: 'TCP',
      //   service_port: '6342',
      // },
      // {
      //   key: '3',
      //   service_type: 'UDP',
      //   service_port: '5353',
      // },
    ],
  },
  env_var_list: {
    columns: [
      {
        title: '变量名',
        dataIndex: 'env_name',
      },
      {
        title: '变量值',
        dataIndex: 'env_value',
      },
      {
        title: '动作',
        dataIndex: 'action',
      },
    ],
    data: [
      // {
      //   key: '1',
      //   env_name: 'username',
      //   env_value: 'odoo001',
      // },
      // {
      //   key: '2',
      //   env_name: 'password',
      //   env_value: '123456',
      // },
      // {
      //   key: '3',
      //   env_name: 'database',
      //   env_value: 'db001',
      // },
    ],
  },
  app_command: '',
  app_arg_list: {
    columns: [
      {
        title: '命令参数',
        dataIndex: 'command_arg',
      },
      {
        title: '动作',
        dataIndex: 'action',
      },
    ],
    data: [
      // {
      //   key: '1',
      //   command_arg: 'username',
      // },
      // {
      //   key: '2',
      //   command_arg: 'password',
      // },
      // {
      //   key: '3',
      //   command_arg: 'database',
      // },
    ],
  }
});

function customCheckServicePort(index, key) {
  if (data_list.service_port_list.data[index][key]) {
    return Promise.resolve();
  }
  return Promise.reject();
}

function customCheckEnv(index, key) {
  if (data_list.env_var_list.data[index][key]) {
    return Promise.resolve();
  }
  return Promise.reject();
}

function customCheckCommandArg(index, key) {
  if (data_list.app_arg_list.data[index][key]) {
    return Promise.resolve();
  }
  return Promise.reject();
}

const confirmFormCreate = () => {
  formRef.value.validate()
      .then(function () {
        logDebug('confirmFormCreate')
        jsonRPC({
          url: `/api/make/image/create/app`,
          params: {
            user_image_id: image_id,
            user_vm_image_id: vm_image_id,
            app_type: data_list.app_type,
            app_name: data_list.app_name,
            inner_service_name: data_list.inner_service_name,
            app_description: data_list.app_description,
            app_image: data_list.app_image,
            app_cpu: data_list.app_cpu,
            app_memory: data_list.app_memory,
            app_disk: data_list.app_disk,
            app_band_width: data_list.app_band_width,
            app_gpu: data_list.app_gpu,
            service_port_list: data_list.service_port_list.data,
            env_var_list: data_list.env_var_list.data,
            app_command: data_list.app_command,
            app_arg_list: data_list.app_arg_list.data,
          },
          success(res) {
            const data = getResponseData(res);
            logDebug(`创建应用模板成功`, data);
            message.info(`创建应用模板成功`, 3)
            if (isVM) {
              routerReplace(router, `/console/image/vm/${vm_image_id}`)
            } else {
              routerReplace(router, `/console/image/container/${image_id}`)
            }
          },
          fail(error) {
            logError(`创建应用模板失败`, error);
            const errMsg = getFailedMessage(error)
            message.error(`创建应用模板失败，原因[${errMsg}]`, 3)
            if (isVM) {
              routerReplace(router, `/console/image/vm/${vm_image_id}`)
            } else {
              routerReplace(router, `/console/image/container/${image_id}`)
            }
          },
        })
      })
      .catch(error => {
        logError('error', error);
        return
      });
};

const confirmFormUpdate = () => {
  formRef.value.validate()
      .then(function () {
        logDebug('confirmFormCreate')
        jsonRPC({
          url: `/api/make/image/update/app`,
          params: {
            user_app_id: user_app_id,
            app_name: data_list.app_name,
            app_description: data_list.app_description,
            app_image: data_list.app_image,
            app_cpu: data_list.app_cpu,
            app_memory: data_list.app_memory,
            app_disk: data_list.app_disk,
            app_band_width: data_list.app_band_width,
            app_gpu: data_list.app_gpu,
            service_port_list: data_list.service_port_list.data,
            env_var_list: data_list.env_var_list.data,
            app_command: data_list.app_command,
            app_arg_list: data_list.app_arg_list.data,
          },
          success(res) {
            const data = getResponseData(res);
            logDebug(`更新应用模板成功`, data);
            message.info(`更新应用模板成功`, 3)
          },
          fail(error) {
            logError(`更新应用模板失败`, error);
            const errMsg = getFailedMessage(error)
            message.error(`更新应用模板失败，原因[${errMsg}]`, 3)
          },
        }).then(function () {
          window.location.reload()
        })
      })
      .catch(error => {
        logError('error', error);
        return
      });
};

const onDeleteServicePort = function (key) {
  data_list.service_port_list.data = data_list.service_port_list.data.filter(x => x.key !== key)
}

const onAddServicePort = function () {
  const keys = data_list.service_port_list.data.map(x => parseInt(x.key))
  const maxKey = Math.max(...keys)
  data_list.service_port_list.data.push({
    key: `${maxKey + 1}`,
    service_type: 'HTTP',
    service_port: '80',
  })
}

const onDeleteEnv = function (key) {
  data_list.env_var_list.data = data_list.env_var_list.data.filter(x => x.key !== key)
}

const onAddEnv = function () {
  const keys = data_list.env_var_list.data.map(x => parseInt(x.key))
  const maxKey = Math.max(...keys)
  data_list.env_var_list.data.push({
    key: `${maxKey + 1}`,
    env_name: '',
    env_value: '',
  })
}

const onDeleteCommandArg = function (key) {
  data_list.app_arg_list.data = data_list.app_arg_list.data.filter(x => x.key !== key)
}

const onAddCommandArg = function () {
  const keys = data_list.app_arg_list.data.map(x => parseInt(x.key))
  const maxKey = Math.max(...keys)
  data_list.app_arg_list.data.push({
    key: `${maxKey + 1}`,
    env_name: '',
    env_value: '',
  })
}

const cancelForm = () => {
  routerBack(router)
};
const formCreateAppRef = ref();
const rulesCreateApp = {
  app_name: [
    {
      required: true,
      message: '请输入应用名称',
    },
  ],
  ns_type: [
    {
      required: true,
      message: '请选择空间类型',
      trigger: 'change',
    },
  ],
  namespace_id: [
    {
      required: true,
      message: '请选择命名空间',
      trigger: 'change',
    },
  ],
  deployment_method: [
    {
      required: true,
      message: '部署方式',
      trigger: 'change',
    },
  ],
  compute_power_service: [
    {
      required: true,
      message: '算力服务',
    },
  ],
}
const openCreateAppModal = ref(false);
const confirmCreateAppLoading = ref(false);
const createAppForm = reactive({
  app_name: "",
  ns_type: "",
  namespace_id: "",
  app_id: "",
  template_id: "",
  disable_namespace_selection: false,
  capacity_type_level: {},
  deployment_method: 'consumer_compute_power',
  compute_power_service: 'base',
});

watch(() => createAppForm.ns_type, function (newValue, oldValue) {
  logDebug(`createAppForm.ns_type发生了变化: ${oldValue} -> ${newValue}`);
  if (oldValue !== "") {
    createAppForm.namespace_id = ""
  }
})

function fetchCreateDeploymentData() {
  jsonRPC({
    url: "/vue/console/experiment/deployment/create/setting",
    params: {},
    success(res) {
      logDebug(`查询成功`, res.data.result.data);
      Object.assign(createAppForm, res.data.result.data);
    },
    fail(error) {
      logError(`查询失败, `, error);
    },
  });
}

const _onCreateDeployment = (template_id) => {
  createAppForm.template_id = template_id
  createAppForm.disable_namespace_selection = false
  if (createAppForm['ns_types'] != null && typeof createAppForm['ns_types'] !== "undefined" &&
      createAppForm['ns_types'].length >= 1) {
    createAppForm.ns_type = createAppForm['ns_types'][0][0]
  }
  if (createAppForm.ns_type === 'private') {
    if (createAppForm['private_namespaces'] != null && typeof createAppForm['private_namespaces'] !== "undefined" &&
        createAppForm['private_namespaces'].length >= 1) {
      createAppForm.namespace_id = createAppForm['private_namespaces'][0].id
    }
  } else {
    if (createAppForm['public_namespaces'] != null && typeof createAppForm['public_namespaces'] !== "undefined" &&
        createAppForm['public_namespaces'].length >= 1) {
      createAppForm.namespace_id = createAppForm['public_namespaces'][0].id
    }
  }
  openCreateAppModal.value = true;
};
const sum_point = computed(() => {
  let sum = 0;
  const ser = createAppForm.compute_power_service;
  for (const x of createAppForm.capacity_type_level[ser] || []) {
    sum += x.capacity.capacity_type_op_sum_point;
  }
  return sum;
});
const unitMap = {
  hours: '小时',
  day: '天',
  month: '月'
};
const displayUnit = (e) => {
  return unitMap[e] || '小时';
}
const sum_point_unit = computed(() => {
  let sum = '小时';
  const ser = createAppForm.compute_power_service;
  for (const x of createAppForm.capacity_type_level[ser] || []) {
    if (unitMap[x.capacity.unit_op]) {
      sum = unitMap[x.capacity.unit_op];
      break;
    }
  }
  return sum;
});
const confirmCreateApp = () => {
  formCreateAppRef.value.validate()
      .then(() => {
        confirmCreateAppLoading.value = true
        jsonRPC({
          url: "/vue/console/experiment/deployment/create",
          params: {
            name: createAppForm.app_name,
            template_id: createAppForm.template_id,
            namespace_id: createAppForm.namespace_id,
            is_compute_point: sum_point.value > 0,
            capacity_type_level: createAppForm.compute_power_service,
            need_deduct_compute_point: sum_point.value,
            capacity_unit_option: createAppForm.capacity_type_level[createAppForm.compute_power_service]?.[0]?.capacity?.unit_op,
          },
          success(res) {
            const data = getResponseData(res)
            logDebug(`创建实验环境成功`, data);
            message.success(`创建实验环境成功`)
            setTimeout(()=>{
              jsonRPC({
                url: "/vue/template/used_nums",
                params: {
                  template_id: createAppForm.template_id,
                },
                success() {
                  logDebug(`模板使用次数增加成功`);
                },
                fail(error) {
                  logError(`模板使用次数增加失败, `, error);
                },
              });
            },10*1000)
          },
          fail(error) {
            try {
              try {
                let errMsg = JSON.parse(error);
                let user = errMsg.user || '';
                let message = errMsg.message || '资源不足';
                let details = errMsg.details || '';
                Modal.error({
                  title: "创建实验环境失败",
                  content: (
                      <div>
                        <div>{user}</div>
                        <div style={{textIndent: '2em'}}>{message}</div>
                        <div>{details}</div>
                      </div>
                  ),
                  width: '60%', // 设置宽度为 60%
                  closable: true, // 右上角关闭按钮
                  onCancel: () => {
                    Modal.destroyAll(); // 点击关闭按钮时，关闭模态框
                  },
                  footer: () => (
                      <div class="d-flex justify-content-center my-3">
                        <button type="button" class="btn btn-primary" onClick={async () => {
                          router.push('/cost/topup')
                          Modal.destroyAll(); // 关闭所有弹窗
                        }}>补充资源
                        </button>
                      </div>
                  ),
                });
              } catch (err) {
                logError("查询失败: 无法解析错误信息", err);
                Modal.error({
                  title: "创建实验环境失败",
                  content: (
                      <div style={{textIndent: '2em'}}>{JSON.stringify(getFailedMessage(error))}</div>
                  ),
                  width: '60%', // 设置宽度为 60%
                  closable: true, // 右上角关闭按钮
                  onCancel: () => {
                    Modal.destroyAll(); // 点击关闭按钮时，关闭模态框
                  },
                  footer: () => (
                      <div class="d-flex justify-content-center my-3">
                        <button type="button" class="btn btn-primary" onClick={() => {
                          Modal.destroyAll(); // 关闭所有弹窗
                        }}>关闭
                        </button>
                      </div>
                  ),
                });
              }
            } catch (er) {
              logError("部署创建过程中发生未捕获的错误：", er);
              message.error('部署创建失败，请稍后重试。')
            }
          },
        }).then(function () {
          openCreateAppModal.value = false;
          confirmCreateAppLoading.value = false;
          routerReplace(router, '/console/app')
        });
      })
      .catch(error => {
        logError('error', error);
        return
      });
};

const cancelCreateApp = () => {
  createAppForm.app_name = ""
  createAppForm.ns_type = ""
  createAppForm.namespace_id = ""
  createAppForm.disable_namespace_selection = false
}

const onDeleteImageApp = function (user_app_id) {
  if (confirm("确定要删除当前应用模板吗？")) {
    jsonRPC({
      url: "/vue/console/app/template/delete",
      params: {
        app_id: user_app_id,
      },
      success(res) {
        logDebug(`删除应用模板成功`, res.data.result);
        message.info(`删除应用模板成功`)
        if (isVM) {
          routerReplace(router, `/console/image/vm/${vm_image_id}`)
        } else {
          routerReplace(router, `/console/image/container/${image_id}`)
        }
      },
      fail(error) {
        logError(`删除应用模板失败, `, error);
        message.error(`删除应用模板失败`)
      },
    });
  }
}

onMounted(() => {
  fetchData()
  fetchCreateDeploymentData()
});

function fetchData() {
  jsonRPC({
    url: `/api/make/image/info/app`,
    params: {
      "user_app_id": user_app_id,
    },
    success(res) {
      const data = getResponseData(res);
      logDebug(`获取用户应用模板信息成功`, data);
      Object.assign(data_list, data);
      data_list.service_port_list.data = data.service_port_list_data
      data_list.env_var_list.data = data.env_var_list_data
      data_list.app_arg_list.data = data.app_arg_list_data
      createAppForm.capacity_type_level = JSON.parse(data.capacity_type_level)
    },
    fail(error) {
      logError(`获取用户应用模板信息失败, `, error);
      message.info(`获取用户应用模板信息失败`)
    },
  });
}
</script>

<style lang="scss" scoped>
.ant-table-cell {
  height: 10px;
}

:where(.ant-radio-button-wrapper) {
  height: 30px;
  min-width: 80px;
  line-height: 28px;
  border-radius: 4px;
  margin: 0 5px;
  border-inline-start-width: 1px;
  text-align: center;

  &:not(:first-child)::before {
    content: none
  }

  &:first-child {
    border-start-start-radius: 4px;
    border-end-start-radius: 4px;
  }

  &:last-child {
    border-start-end-radius: 4px;
    border-end-end-radius: 4px;
  }
}
</style>