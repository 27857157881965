<template>
  <div class="batch">
    <a-flex justify="space-between" align="center">
      <a-breadcrumb separator=">">
        <a-breadcrumb-item>
          <router-link to="/console/eduAdmin">教务管理</router-link>
        </a-breadcrumb-item>
        <a-breadcrumb-item>
          <router-link :to="`/console/eduAdmin/class/?id=${class_id}&mode=readonly`">{{
              data_list.class_name
            }}
          </router-link>
        </a-breadcrumb-item>
        <a-breadcrumb-item>
          <router-link :to="`/console/eduAdmin/class/course?id=${course_id}&class_id=${class_id}`">
            {{ data_list.course_name }}
          </router-link>
        </a-breadcrumb-item>
        <a-breadcrumb-item>{{ data_list.work_name }}</a-breadcrumb-item>
      </a-breadcrumb>
      <a-flex>
        <a-button type="primary" @click="confirmForm" style="margin-right: 10px;">提交批改</a-button>
        <a-button type="primary" @click="NextOne">下一份</a-button>
      </a-flex>
    </a-flex>
    <div class="info">
      <a-flex justify="space-between" align="center" class="mb-3">
        <h4><b>{{ data_list.work_name }}</b></h4>
        <a-flex align="center">
          切换学生：
          <a-select v-model:value="data_list.partner_id" :allowClear="true" :style="{ width: '200px' }"
                    @change="fetchData"
                    show-search :options="data_list.student_options" :filter-option="filterOption"
                    placeholder="请选择学生">
          </a-select>
        </a-flex>
      </a-flex>
      <a-flex justify="space-between" align="center">
        <a-flex :justify="'center'" class="mb-3">
          学生姓名：{{ data_list.student_options.find(item => item.value == data_list.partner_id)?.label || '' }}
          <div class="mx-3"></div>
          学生编号：{{ data_list.student_options.find(item => item.value == data_list.partner_id)?.student_barcode || '' }}
        </a-flex>
        <p style="color: #475AFF;">*作业批改只显示主观题，客观题由系统根据标准答案判题</p>
      </a-flex>
      <div class="table-responsive">
        <table class="table table-bordered">
          <tbody>
          <tr>
            <td>学生答卷</td>
            <td>参考答案</td>
            <td>题目总分</td>
            <td>得分</td>
          </tr>
          </tbody>
          <tbody v-for="(row, index) in data_list.records" :key="index">
          <tr>
            <td colspan="20">
              <a-flex>{{ index + 1 }},
                <div v-html="row.name"/>
              </a-flex>
            </td>
          </tr>
          <tr>
            <td>
              <div v-html="row.answer"/>
            </td>
            <td>{{ row.qa_solution }}</td>
            <td>{{ row.score }}</td>
            <td>
              <a-input-number v-model:value="row.qa_score" :min="0" :max="row.score"/>
            </td>
          </tr>
          </tbody>
        </table>
      </div>
    </div>
  </div>
</template>

<script setup>
import {logDebug, logError} from "@/utils/logger";
import {onMounted, ref, reactive} from 'vue'
import {jsonRPC, getResponseData, getFailedMessage} from "@/utils/http_utils";
import {getRouterQuery} from "@/utils/router_utils";
import {useRouter} from "vue-router";
import {message} from "ant-design-vue";
const router = useRouter();
const course_id = ref(getRouterQuery(router, "course_id"));
const class_id = ref(getRouterQuery(router, "class_id"));
const work_id = ref(getRouterQuery(router, "work_id"));

const data_list = reactive({
  class_name: '',
  course_name: '',
  work_name: '',
  partner_id: null,
  student_options: [],
  records: [],
  score: '',
})
const confirmForm = () => {
  jsonRPC({
    url: `/vue/console/eduAdmin/class/course/work/batch/submit`,
    params: {
      records: data_list.records,
      course_id: course_id.value,
      class_id: class_id.value,
      work_id: work_id.value,
      partner_id: data_list.partner_id,
    },
    success() {
      message.success('提交批改成功')
    },
    fail(error) {
      logError(`查询失败, `, error);
      message.error(`${getFailedMessage(error)}`, 2);
      setTimeout(() => {
        window.history.back();  // 返回上一级页面
      }, 2000);  // 2秒后返回
    },
  })
}
const NextOne = () => {
  const {partner_id, student_options} = data_list;
  const lastOption = student_options[student_options.length - 1];
  if (partner_id != lastOption.value) {
    const currentIndex = student_options.findIndex(item => item.value == partner_id);
    if (currentIndex !== -1 && currentIndex < student_options.length - 1) {
      const nextValue = student_options[currentIndex + 1].value;
      data_list.partner_id = nextValue;
      fetchData()
    } else {
      message.warn('当前是最后一个');
    }
  } else {
    message.warn('当前是最后一个');
  }
};

function fetchData() {
  jsonRPC({
    url: `/vue/console/eduAdmin/class/course/work/batch`,
    params: {
      course_id: course_id.value,
      class_id: class_id.value,
      work_id: work_id.value,
      partner_id: data_list.partner_id,
    },
    success(res) {
      const data = getResponseData(res);
      logDebug(`获取成功需求数据成功`, data);
      Object.assign(data_list, data);
    },
    fail(error) {
      logError(`查询失败, `, error);
      message.error(`${getFailedMessage(error)}`, 2);

      // 展示错误消息后自动返回上一级页面
      setTimeout(() => {
        window.history.back();  // 返回上一级页面
      }, 2000);  // 2秒后返回

    },
  })
}

onMounted(fetchData)
</script>

<style scoped lang="scss">
.batch {
  height: 100%;
  width: 100%;
  overflow: auto;
  padding: 0 16px;

  .menu {
    button {
      margin-bottom: 10px;
    }
  }

  .info {
    background-color: #fff;
    padding: 1.5rem;

    h4 {
      display: inline-block;
      font-size: 18px;
      padding-bottom: 5px;
      padding-right: 30px;
      border-bottom: 2px solid #63B1FF;
    }

    table {
      text-align: center;

      td {
        vertical-align: middle;
      }
    }
  }
}

:deep(.ant-flex) {
  white-space: nowrap;
}
</style>
