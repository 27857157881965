<template>
  <div id="terminal-http">
    <div v-if="isHttpReady">
      <iframe :id="name" class="http-iframe" :src="url" frameborder="0" :style="iframeStyle"/>
    </div>
    <div v-else>
      <a-flex class="loading" justify="center" align="center">
        <div>
          <LoadingOutlined style="font-size: 80px"/>
          <p>连接中，请稍后...</p>
        </div>
      </a-flex>

    </div>
  </div>
</template>
<script setup>

import {onMounted, ref} from 'vue';
import {logDebug, logError} from "@/utils/logger";
import {concatUrlPath, isFalse, isNotEmpty, isTrue} from "@/utils/common_utils";
import {LoadingOutlined} from "@ant-design/icons-vue";
import $ from "jquery";

logDebug('TerminalHttp setup!')

const props = defineProps({
  primal: {type: String},
  data: {type: Object},
  styleSetting: {type: Object},
})

const contentHeight = ref(props.styleSetting.contentStyle.height)

const primal = props.primal
const data = props.data


onMounted(() => {
  // 在onMounted中获取数据
  logDebug(`TerminalHttp onMounted.`)
})

const url = ref('')
const name = ref('')
const isHttpReady = ref(false)

function reloadIframe() {
  if (isNotEmpty(name.value)) {
    logDebug('reloadIframe')
    const totalTryCount = 60
    const tryIntervalMS = 3000
    const reloadIframeHandler = function (tryCount) {
      if (tryCount < 1) {
        logError(`reloadIframeHandler failed after try ${tryCount} times`)
        return;
      }

      $.ajax({
        url: url.value,
        type: 'GET',
        // 告诉jQuery不要去处理发送的数据
        processData: false,
        // 告诉jQuery不要去设置Content-Type请求头
        contentType: false,
        success: function () {
          isHttpReady.value = true
        },
        error: function (responseStr) {
          logDebug("error:", responseStr);
          if (responseStr.status === 503 || responseStr.status === 502) {
            setTimeout(reloadIframeHandler, tryIntervalMS, tryCount - 1)
            return
          }
          isHttpReady.value = true
        }
      });
    }
    reloadIframeHandler(totalTryCount)
  }
}

const isFirstSelected = ref(true)
const onSelected = function () {
  logDebug(`TerminalHttp onSelected. data[${JSON.stringify(data)}]`)

  if (isFalse(isFirstSelected.value)) {
    return
  }

  name.value = `http-iframe-${data['id']}`
  url.value = concatUrlPath(data['domain_http_url'], data['domain_http_path'])

  setTimeout(reloadIframe, 10)

  isFirstSelected.value = false
}

const iframeStyle = ref('pointer-events: auto')
const setPointerEvents = function (enablePointerEvents = true) {
  if (isTrue(enablePointerEvents)) {
    iframeStyle.value = 'pointer-events: auto'
  } else {
    iframeStyle.value = 'pointer-events: none'
  }
}

defineExpose({
  primal,
  data,
  onSelected,
  setPointerEvents,
})

</script>
<style scoped>
#terminal-http {
  background: white;
  width: 100%;
  height: v-bind(contentHeight);
}

.http-iframe {
  background: white;
  width: 100%;
  height: v-bind(contentHeight);
}

.loading {
  background: lightgray;
  height: v-bind(contentHeight);
}
</style>

