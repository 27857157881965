<template>
  <div class="blog_message_page">
    <a-breadcrumb separator=">">
      <a-breadcrumb-item>
        <router-link to="/blog">技术论坛</router-link>
      </a-breadcrumb-item>
      <a-breadcrumb-item>{{message_name}}</a-breadcrumb-item>
    </a-breadcrumb>
    <a-row gutter="20">
      <a-col :span="19">
        <component :is="currentComponent" @call-parent-Reload="fetchData"></component>
      </a-col>
      <a-col :span="5">
        <div class="blog_user">
          <div class="user_info">
            <img v-if="data_list.user?.has_image" :src="`/web/image/res.users/${data_list.user?.id}/image_512`"/>
            <img v-else src="/hw_web/static/src/img/avatar_live.png" />
            <h5>{{ data_list.user?.name }}</h5>
          </div>
          <div class="blog_info">
            <router-link :to="`/blog/homepage`">
              <span class="count">{{data_list.user_blog_num}}</span>
              <span class="title">帖子</span>
            </router-link>
            <router-link :to="`/blog/attention`">
              <span class="count">{{data_list.blog_id?.blog_attention_ids.length}}</span>
              <span class="title">粉丝</span>
            </router-link>
            <router-link :to="`/blog/fan`">
              <span class="count">{{data_list.blog_id?.blog_fan_ids.length}}</span>
              <span class="title">粉丝</span>
            </router-link>
          </div>
        </div>
        <div class="blog_message">
          <h5>互动消息</h5>
          <a :class="{ active: selectedKey == 'reply' }" @click="onTag('reply')">
            <img src="/hw_frontend/static/img/blog_@my_icon.png"/>
            @我的
            <a-badge :count="data_list.reply_comment_count" :number-style="{ backgroundColor: '#FF8110' }"/>
          </a>
          <a :class="{ active: selectedKey == 'comment' }" @click="onTag('comment')">
            <img src="/hw_frontend/static/img/blog_comment_icon.png"/>
            评论
            <a-badge :count="data_list.comment_count" :number-style="{ backgroundColor: '#FF8110' }"/>
          </a>
          <a :class="{ active: selectedKey == 'like' }" @click="onTag('like')">
            <img src="/hw_frontend/static/img/blog_like_icon.png"/>
            点赞
            <a-badge :count="data_list.like_count" :number-style="{ backgroundColor: '#FF8110' }"/>
          </a>
        </div>
      </a-col>
    </a-row>
    <a-back-top />
  </div>
</template>

<script setup>
import { logDebug, logError } from "@/utils/logger";
import { onMounted, ref, reactive, watch, computed } from "vue";
import { getResponseData, jsonRPC } from "@/utils/http_utils";
import { getRouterQuery } from "@/utils/router_utils";
import { message } from "ant-design-vue";
import { useRouter } from "vue-router";
const router = useRouter();
const message_name = ref('')
import BlogMyEditorial from './BlogMyEditorial.vue'
import BlogMyLike from './BlogMyLike.vue'
import BlogMyReply from './BlogMyReply.vue'
const selectedKey = ref(getRouterQuery(router, "key"));
const components = {
  comment: BlogMyEditorial,
  like: BlogMyLike,
  reply: BlogMyReply,
}
const currentComponent = computed(() => {
  return components[selectedKey.value]
})
watch(selectedKey, ()=>{
  if (selectedKey.value == 'reply') {
    message_name.value = '@我的'
  } else if (selectedKey.value == 'comment') {
    message_name.value = '评论'
  } else if (selectedKey.value == 'like') {
    message_name.value = '点赞'
  }
},{ immediate: true });
const onTag = (e)=>{
  if (selectedKey.value != e) {
    selectedKey.value = e;
  }
}

const data_list = reactive({
  record_count: 0,
  user_blog_num: 0,
  reply_comment_count: 0,
  comment_count: 0,
  like_count: 0,
});
function fetchData() {
  jsonRPC({
    url: `/api/blog/message/base`,
    params: {},
    success(res) {
      const data = getResponseData(res);
      logDebug(`获取数据成功`, data);
      Object.assign(data_list, data);
    },
    fail(error) {
      logError(`获取数据失败`, error);
      message.error(`获取数据失败，请稍后再试`);
    },
  });
}
onMounted(() => {
  fetchData();
});
</script>

<style scoped lang="scss">
.blog_message_page {
  margin-bottom: 30px;
  margin-left: 10px;
  margin-right: 10px;
  .blog_user {
    border-radius: 8px;
    background-color: #fff;
    padding: 25px;
    margin-bottom: 15px;
    .user_info {
      text-align: center;
      img {
        width: 50px;
        height: 50px;
        border-radius: 50%;
        margin-bottom: 1rem;
        object-fit: cover;
      }
      h5 {
        font-size: 16px;
      }
    }
    .blog_info {
      display: flex;
      justify-content: space-around;
      a {
        display: flex;
        flex-direction: column;
        align-items: center;
        .count {
          font-size: 28px;
          color: #429bff;
        }
        .title {
          color: #000;
        }
      }
    }
  }
  .blog_message {
    border-radius: 8px;
    background-color: #fff;
    padding: 15px;
    margin-bottom: 15px;
    h5 {
      font-weight: bold;
      font-size: 16px;
    }
    img {
      width: 25px;
      height: 25px;
    }
    a {
      display: flex;
      gap: 10px;
      padding: 10px 0;
      color: #101010;
      &.active{
        color: #2580FD;
      }
      &:hover {
        color: #ff8c00;
      }
      span {
        flex-shrink: 0;
        margin-left: auto;
        .ant-badge-count {
          min-width: 24px;
          height: 24px;
          font-size: 14px;
          line-height: 24px;
          border-radius: 12px;
        }
      }
    }
  }
}
</style>
