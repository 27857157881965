<template>
  <div class="signIn">
    <a-breadcrumb separator=">">
      <a-breadcrumb-item>
        <router-link to="/console/eduAdmin">教务管理</router-link>
      </a-breadcrumb-item>
      <a-breadcrumb-item>
        <router-link :to="`/console/eduAdmin/class/?id=${class_id}&mode=readonly`">{{
            data_list.class_name
          }}
        </router-link>
      </a-breadcrumb-item>
      <a-breadcrumb-item>
        <router-link :to="`/console/eduAdmin/class/course?id=${course_id}&class_id=${class_id}`">
          {{ data_list.course_name }}
        </router-link>
      </a-breadcrumb-item>
      <a-breadcrumb-item>签到详情</a-breadcrumb-item>
    </a-breadcrumb>
    <a-spin :spinning="spinning">
      <div class="info">
        <a-flex justify="space-between" align="center">
          <h4><b>签到详情</b></h4>
          <div class="menu">
            开始时间：
            <a-date-picker
              v-model:value="data_list.start_datetime"
              :showTime="showTimeOption"
              :format="showTimedDateFormat"
              placeholder="请筛选签到开始时间"
              @change="fetchData"
            />
            结束时间：
            <a-date-picker
              v-model:value="data_list.end_datetime"
              :showTime="showTimeOption"
              :format="showTimedDateFormat"
              placeholder="请筛选签到结束时间"
              @change="fetchData"
            />
            <a-button type="primary" @click="showPostNewSignModel">发布新签到</a-button>
            <a-button type="primary" @click="exportToExcel">下载签到表</a-button>
          </div>
        </a-flex>
        <div class="table-responsive" v-for="row in data_list.check_list" :key="row.id">
          <table class="table table-bordered">
            <tbody>
              <!-- 显示签到日期 -->
              <tr style="background-color: #F6FCFF;">
                <td colspan="9">签到日期：{{ row.attendance_time }} <a
                    v-if="row.start_datetime">-开始签到-{{ row.start_datetime }}</a>
                </td>
              </tr>
              <!-- 表头：学生、签到状态、签到时间 -->
              <tr>
                <td>学生</td>
                <td>签到状态</td>
                <td>签到时间</td>
                <td>学生</td>
                <td>签到状态</td>
                <td>签到时间</td>
                <td>学生</td>
                <td>签到状态</td>
                <td>签到时间</td>
              </tr>
              <!-- 按每三名学生分组展示 -->
              <!-- 使用计算属性处理学生信息 -->
              <tr v-for="(group, index) in row.attendance_list" :key="index">
                <td v-if="group[0]">{{ group[0].name }}</td>
                <td v-if="group[0]">{{ group[0].types }}</td>
                <td v-if="group[0]">{{ group[0].time_date }}</td>

                <td v-if="group[1]">{{ group[1].name }}</td>
                <td v-if="group[1]">{{ group[1].types }}</td>
                <td v-if="group[1]">{{ group[1].time_date }}</td>

                <td v-if="group[2]">{{ group[2].name }}</td>
                <td v-if="group[2]">{{ group[2].types }}</td>
                <td v-if="group[2]">{{ group[2].time_date }}</td>
              </tr>
              <tr v-if="row.attendance_list.length==0">
                <td colspan="9">暂无签到</td>
              </tr>
            </tbody>
          </table>
        </div>
        <a-modal v-model:open="openPostNewSignModel" title="发布新的签到">
          <template #footer>
            <a-button key="back" @click="handleCancel">取消</a-button>
            <a-button key="submit" type="primary" :loading="loading" @click="handleOk">确认</a-button>
          </template>
          <a-form
              ref="formRef"
              :model="formState"
              :rules="rules"
              :label-col="{ span: 8 }"
              :wrapper-col="{ span: 16 }"
              autocomplete="off"
          >
            <a-form-item label="签到类型" name="attendance_type">
              <a-radio-group v-model:value="formState.attendance_type">
                <a-radio value="no_limit_time">不限时</a-radio>
                <a-radio value="limit_time">限时</a-radio>
              </a-radio-group>
            </a-form-item>

            <a-form-item label="开始时间" name="start_datetime">
              <a-date-picker
                  v-model:value="formState.start_datetime"
                  :showTime="showTimeOption"
                  :format="showTimedDateFormat"
                  placeholder="请选择签到开始时间"
              />
            </a-form-item>
            <a-form-item label="限时(分钟)" name="limit_time_number" v-if="formState.attendance_type == 'limit_time'">
              <a-input-number
                  v-model:value="formState.limit_time_number"
                  type="number"
                  placeholder="请选择签到开始时间"
                  style="width: 100%"
              />
            </a-form-item>
            <a-form-item label="结束时间" name="end_datetime" v-if="formState.attendance_type !== 'limit_time'">
              <a-date-picker
                  v-model:value="formState.end_datetime"
                  :showTime="showTimeOption"
                  :format="showTimedDateFormat"
                  placeholder="请选择签到结束时间"
              />
            </a-form-item>
          </a-form>
        </a-modal>
      </div>
    </a-spin>
  </div>
</template>

<script setup>
import {logDebug, logError} from "@/utils/logger";
import {onMounted, ref, reactive} from 'vue'
import {jsonRPC, getResponseData} from "@/utils/http_utils";
import {getRouterQuery} from "@/utils/router_utils";
import {newShowTimedDateFormat, newShowTimeOption} from "@/utils/time_utils";
const showTimeOption = newShowTimeOption()
const showTimedDateFormat = newShowTimedDateFormat()
import {useRouter} from "vue-router";
import moment from 'moment';
import dayjs from 'dayjs';
import * as XLSX from 'xlsx';
import {saveAs} from 'file-saver';

const formRef = ref();
const spinning = ref(true);
const router = useRouter();
const course_id = ref(getRouterQuery(router, "course_id"));
const class_id = ref(getRouterQuery(router, "class_id"));

const formState = reactive({
  attendance_type: 'no_limit_time',
  start_datetime: '',
  end_datetime: '',
  limit_time_number: 10,
});
const rules = reactive({
  attendance_type: [
    {required: true, message: '请选择签到类型'},
  ],
  start_datetime: [
    {required: true, message: '请选择签到开始时间'},
  ],
  limit_time_number: [
    {required: true, message: '请输入限时时间'},
  ],
  end_datetime: [
    {required: true, message: '请选择签到结束时间'},
    {
      validator: (rule, value) => {
        if (value && formState.start_datetime) {
          return value > formState.start_datetime ? Promise.resolve() : Promise.reject('结束时间必须大于开始时间');
        }
        return Promise.resolve();
      },
    },
  ],
});
const data_list = reactive({
  class_name: '',
  course_name: '',
  type_id: '',
  score: '',
  start_datetime: '',
  end_datetime: '',
  check_list: [{
    attendance_list: [],
    absenteeism_number: 0,
    attendance_time: "",
    attendance_type: "",
    attendees_number: 0,
    be_late_number: 0,
    end_datetime: "",
    id: 47,
    late_number: 0,
    leave_school_number: 0,
    name: "",
    start_datetime: "",
    student_number: '',
    class_course:'',
  }],
})

const loading = ref(false);
const openPostNewSignModel = ref(false);
const showPostNewSignModel = () => {
  openPostNewSignModel.value = true;
};

const handleCancel = () => {
  openPostNewSignModel.value = false;
};

function end_datetime_limit(start_datetime, limit_time_number) {
  // 将 start_datetime 转换为 Date 对象
  const startDate = new Date(start_datetime);

  // 检查 limit_time_number 是以分钟为单位的
  const limitInMilliseconds = limit_time_number * 60 * 1000;

  // 计算结束时间
  const endDate = new Date(startDate.getTime() + limitInMilliseconds);

  // 返回格式化的日期时间字符串
  return endDate.toISOString(); // 返回 ISO 格式的字符串
}


const handleOk = () => {
  formRef.value.validate()
      .then(() => {
        if (formState.attendance_type == 'limit_time') {
          let end_datetime = end_datetime_limit(formState.start_datetime, formState.limit_time_number)
          formState.end_datetime = dayjs(moment(new Date(end_datetime)).toISOString());
        }
        loading.value = true
        PostNewSignURL()
      })
      .catch(error => {
        logDebug('error', error);
      });
  spinning.value = false
};

function PostNewSignURL() {
  jsonRPC({
    url: `/api/class/course/check/post`,
    params: {
      course_id: course_id.value,
      class_id: class_id.value,
      attendance_type: formState.attendance_type,
      start_datetime: formState.start_datetime,
      end_datetime: formState.end_datetime,
    },
    success(res) {
      const data = getResponseData(res);
      logDebug(`获取成功需求数据成功`, data);
      loading.value = false;
      openPostNewSignModel.value = false;
      if (formRef.value) {
        formRef.value.resetFields();
      }
      fetchData()
    },
    fail(error) {
      logError(`查询失败, `, error);
      loading.value = false;
      openPostNewSignModel.value = false;
    },
  })
}

async function fetchData() {
  await jsonRPC({
    url: `/api/class/course/check/list`,
    params: {
      course_id: course_id.value,
      class_id: class_id.value,
      start_datetime: data_list.start_datetime,
      end_datetime: data_list.end_datetime,
    },
    success(res) {
      const data = getResponseData(res);
      logDebug(`获取成功需求数据成功`, data);
      Object.assign(data_list, data);
      spinning.value = false
    },
    fail(error) {
      spinning.value = false
      logError(`查询失败, `, error);
    },
  })
}

onMounted(async () => {
  await fetchData()
});

function formattedTimeStr() {
  const now = new Date();
  const year = now.getFullYear();
  const month = (now.getMonth() + 1).toString().padStart(2, '0'); // 月份需要+1，并确保两位数
  const day = now.getDate().toString().padStart(2, '0');
  const hour = now.getHours().toString().padStart(2, '0');
  const minute = now.getMinutes().toString().padStart(2, '0');

  const formattedTime = `${year}${month}${day}${hour}${minute}`;
  return formattedTime
}

const exportToExcel = () => {
  // 检查数据是否存在
  if (!data_list || !data_list.check_list || !data_list.check_list.length) {
    logError('Data list or check list is undefined');
    return;
  }

  // 准备外层数据的工作表
  const outerData = data_list.check_list.map(item => ({
    id: item.id,
    "课程名称": item.class_course_name,
    "班级线上课程": data_list.course_name,
    "考勤日期": item.attendance_time,
    "考勤类型": item.attendance_type,
    "考勤开始时间": item.start_datetime,
    "考勤截止时间": item.end_datetime,
    "缺勤人数": item.late_number,
    "出勤人数": item.attendees_number,
    "迟到人数": item.be_late_number,
    "旷课人数": item.absenteeism_number,
    "休学人数": item.leave_school_number,
  }));

  const outerSheet = XLSX.utils.json_to_sheet(outerData); // 将外层数据转换为工作表
  const wb = XLSX.utils.book_new(); // 创建工作簿
  XLSX.utils.book_append_sheet(wb, outerSheet, '签到信息汇总'); // 将外层数据工作表添加到工作簿

  // 遍历每个 checkItem，为其 attendance_list 创建单独的工作表
  data_list.check_list.forEach((checkItem) => {
    // 将 checkItem.attendance_list 扁平化
    const attendanceList = checkItem.attendance_list.flat(); // 将嵌套数组扁平化
    // 处理 attendance_list 为 Excel 数据
    const attendanceData = attendanceList.map(attendance => {
      return {
        "ID": attendance.id,
        "学生": attendance.name,
        "签到类型": attendance.types,
        "签到时间": attendance.time_date,
      };
    });
    // 如果考勤列表为空，添加空表头
    if (attendanceData.length === 0) {
      attendanceData.push({
        "ID": '',
        "学生": '',
        "签到类型": '',
        "签到时间": '',
      });
    }
    // 为当前 checkItem 的考勤列表创建工作表
    const attendanceSheet = XLSX.utils.json_to_sheet(attendanceData); // 将考勤数据转换为工作表
    XLSX.utils.book_append_sheet(wb, attendanceSheet, `考勤记录_${checkItem.id}`); // 为每个 checkItem 的考勤数据创建独立的工作表
  });
  // 生成文件并触发下载
  const wbout = XLSX.write(wb, { bookType: 'xlsx', type: 'array' });
  const blob = new Blob([wbout], { type: 'application/octet-stream' });

  const formattedTime = formattedTimeStr();
  saveAs(blob, `考勤记录-${formattedTime}.xlsx`); // 保存为Excel文件
};

</script>

<style scoped lang="scss">
.signIn {
  height: 100%;
  width: 100%;
  overflow: auto;
  padding: 16px;

  .menu {
    button {
      margin-bottom: 10px;
      margin-left: 10px;

      &:last-child {
        background-color: #3FD787;
      }
    }
  }

  .info {
    background-color: #fff;
    padding: 1.5rem;

    h4 {
      display: inline-block;
      font-size: 18px;
      padding-bottom: 5px;
      padding-right: 30px;
      border-bottom: 2px solid #63B1FF;
    }

    table {
      text-align: center;

      td {
        height: 46px;
      }
    }
  }
}
</style>
