<template>
  <div class="shop_check">
    <div class="container">
      <h5>核对订单信息</h5>
      <div class="address">
        <h5>收货信息<span>* 课程、培训等虚拟物品无实际物流。</span></h5>
      </div>
      <a-table :columns="columns" :data-source="data_list.productList" :pagination="false">
        <template #bodyCell="{ column, record, text }">
          <template v-if="column.dataIndex === 'product_name'">
            <div class="product_info">
              <img v-if="record.image" :src="`/web/image/product.template/${record.product_id}/image_512`"/>
              <div>
                <p>{{record.product_name}}</p>
                <p>
                  <span v-for="(i,index) in record.product_tpl_variant_value_ids" :key="index"
                    class="badge badge-pill" style="background:blue;color:white">{{i}}
                  </span>
                </p>
              </div>
            </div>
          </template>
          <template v-if="column.dataIndex === 'product_price_unit'">
            <span style="white-space: nowrap;">¥ {{text.toFixed(2)}}
              <template v-if="record.product_categ_id.includes('实验环境资源')">/ 天</template>
            </span>
          </template>
          <template v-if="column.dataIndex === 'product_price_subtotal'">
            <span style="white-space: nowrap;">¥ {{text.toFixed(2)}}</span>
          </template>
          <template v-if="column.dataIndex === 'product_quantity'">
            <span style="white-space: nowrap;">x {{text}}</span>
            <template v-if="record.product_categ_id.includes('实验环境资源')">天</template>
          </template>
        </template>
      </a-table>
      <div class="payment_method">
        <h5>支付方式</h5>
        <div class="select">
          <label class="d-flex align-items-center mr-5">
            <input type="radio" name="payment_type" value="alipay" v-model="data_list.payment_type" class="mr-2" />
            <img src="/hw_web/static/src/img/Alipay_payment.png" height="40" alt="支付宝支付" class="mr-2" />
            支付宝支付
          </label>
          <label class="d-flex align-items-center">
            <input type="radio" name="payment_type" value="wechatpay" v-model="data_list.payment_type" class="mr-2" />
            <img src="/hw_web/static/src/img/WeChat_payment.png" height="40" alt="微信支付" class="mr-2" />
            微信支付
          </label>
        </div>
      </div>
      <div class="submit_order mb-5">
        <div class="amount">应付总额：
          <span>¥{{ data_list.total_price }}</span>
        </div>
        <!-- <small v-if="data_list.total_price > 0">* 课程及培训将在付款后的7个工作日内陆续开通</small>
        <small v-else class="text-primary">* 发生退费，费用将在3个工作日内退回至余额</small> -->
        <div>
          <button class="btn backtrack" @click="_backtrack">返回购物车</button>
          <button class="btn submit" @click="_submitOrderBtn">提交订单</button>
        </div>
      </div>
    </div>
  </div>
</template>

<script setup>
import { getRouterQuery } from "@/utils/router_utils";
import { useRouter } from "vue-router";
const router = useRouter();
const order_id = ref(getRouterQuery(router, "order_id"));
const sales_order_id = ref(getRouterQuery(router, "sales_order_id"));
const _backtrack = ()=>{
  router.push('/shop')
}
import {logDebug, logError} from "@/utils/logger";
import { ref, reactive, onMounted } from "vue";
import {jsonOwlRPC} from "@/utils/http_utils";
import { message } from "ant-design-vue";

const data_list = reactive({
  productList: [],
  discount: 0,
  total_price: 0,
  payment_type: 'alipay',
});
const columns = [
  {
    title: "订单内容",
    dataIndex: "product_name",
    width: "40%",
  },
  {
    title: "内容类型",
    dataIndex: "product_categ_id",
  },
  {
    title: "单价",
    dataIndex: "product_price_unit",
  },
  {
    title: "数量",
    dataIndex: "product_quantity",
  },
  {
    title: "小计",
    dataIndex: "product_price_subtotal",
  },
];
function fetchData() {
  jsonOwlRPC({
    url: `/payment/order/checkout`,
    params:{
      order_id: order_id.value,
      sales_order_id: sales_order_id.value,
    },
    success(res) {
      data_list.productList = res.product_info_list;
      data_list.discount = res.discount;
      data_list.total_price = res.total_price.toFixed(2);
    },
    fail(error) {
      logError(`查询失败, `, error);
    },
  })
}
onMounted(()=>{
  fetchData()
});
// 检测是否在手机端打开
import bowser from 'bowser';
const isMobile = ()=>{
  if (bowser.mobile || bowser.tablet) {
    return true
  } else {
    return false
  }
}
const _submitOrderBtn = async ()=>{
  if (data_list.total_price>0) {
    // 获取数据
    let payment_type = data_list.payment_type;
    let shopping_cart_id = data_list.productList[0].shopping_cart_id;
    let order_lines = [];
    for (let i = 0; i < data_list.productList.length; i++) {
        order_lines.push(data_list.productList[i].order_line_id);
    }
    let isMobilePay = false;
    if (isMobile()) {
        isMobilePay = true
    }
    // 开始支付
    if (payment_type == 'alipay') {
      let paymentResult = await PaymentUrl('alipay', order_id.value, sales_order_id.value, shopping_cart_id, order_lines, isMobilePay)
      if (paymentResult.status == '200') {
        if (paymentResult.url.length > 0) {
          sessionStorage.setItem('shop_url', paymentResult.url);
          router.push(`/pay?order_id=${order_id.value}&sales_order_id=${sales_order_id.value}&payment_type=${data_list.payment_type}`)
        }
      } else {
        logError(paymentResult.error);
        message.error('支付遇到错误,请稍后重试')
        return
      }
    } else if (payment_type == 'wechatpay') {
      let wechatpayResult = await PaymentUrl('wechatpay', order_id.value, sales_order_id.value, shopping_cart_id, order_lines, isMobilePay)
      if (wechatpayResult.status == '200') {
        if (wechatpayResult.url.length > 0) {
          sessionStorage.setItem('shop_url', wechatpayResult.url);
          router.push(`/pay?order_id=${order_id.value}&sales_order_id=${sales_order_id.value}&payment_type=${data_list.payment_type}`)
        }
      } else {
        logError(wechatpayResult.error);
        message.error('支付遇到错误,请稍后重试')
        return
      }
    } else if (payment_type == 'balance') {
      let paymentServer = await PaymentUrl('balance', order_id.value, sales_order_id.value, shopping_cart_id, order_lines, isMobilePay)
      if (paymentServer.status == '200') {
        ModifyRecord()
      } else {
        logError(paymentServer.error);
        message.error('支付遇到错误,请稍后重试')
        return
      }
    }
  }else{
    message.info("应付总额要大于0")
  }
}
// 开始支付宝支付
const PaymentUrl = async (payment_type, order_id, trade_order_id, shopping_cart_id, order_lines, isMobilePay) => {
  return new Promise((resolve, reject) => {
    jsonOwlRPC({
      url: `/payment/create/${payment_type}/${order_id}/${trade_order_id}`,
      params: {
        shopping_cart_id: shopping_cart_id,
        order_lines: order_lines,
        is_mobile: isMobilePay,
        is_qrcode: true,
      },
      success(res) {
        resolve(res); // 成功时返回res
      },
      fail(error) {
        logError(`查询失败, `, error);
        reject(error); // 失败时返回错误
      },
    });
  });
};
// 修改记录
const ModifyRecord = ()=>{
  jsonOwlRPC({
    url: `/search/order/info`,
    params:{
      order_id: order_id.value,
    },
    async success(res) {
      if (res.ordinary.length > 0) {
        await jsonOwlRPC({
          url: `/console/experiment/deployment/create_v2`,
          params:{
            data: res.ordinary,
          },
          success(res1) {
            logDebug(res1);
            message.success('支付成功！部署已调整')
            router.push('/order')
          },
          fail(error) {
            logError(`查询失败, `, error);
          },
        })
      }
      if (res.adjust.length > 0) {
        await jsonOwlRPC({
          url: `/console/experiment/app/env/adjust_v2`,
          params:{
            data: res.adjust,
          },
          success(res1) {
            logDebug(res1);
            message.success('支付成功！部署已创建')
            router.push('/order')
          },
          fail(error) {
            logError(`查询失败, `, error);
          },
        })
      }
      if (res.renew.length > 0) {
        await jsonOwlRPC({
          url: `/console/experiment/app/env/adjust_time`,
          params:{
            data: res.renew,
          },
          success(res1) {
            logDebug(res1);
            message.success('支付成功！部署已调整')
            router.push('/order')
          },
          fail(error) {
            logError(`查询失败, `, error);
          },
        })
      }
      if (res.payment_success) {
        router.push('/order')
        // 通知
        message.success('购买成功')
      }
    },
    fail(error) {
      logError(`查询失败, `, error);
    },
  })
}
</script>

<style scoped lang="scss">
.shop_check{
  min-height: 80vh;
  padding: 20px;
  background-color: #fff;
  .product_info{
    display: flex;
    align-items: center;
    img{
      height: 90px;
      width: 160px;
    }
    >div{
      display: flex;
      flex-direction: column;
      margin-left: 16px;
      p{
        font-weight: bold;
        margin: 0;
      }
      span{
        margin-top: 10px;
      }
    }
  }
  .address{
    h5{
      background-color: #F2F8FF;
      padding: 10px 20px;
      margin-bottom: 0;
      span{
        margin-left: 13px;
        color: #FB8F00;
      }
    }
    .info{
      padding: 20px;
      h6{
        display: inline-block;
      }
    }
  }
  .payment_method{
    padding: 20px 0;
    h5{
      background-color: #F2F8FF;
      padding: 10px 20px;
      margin-bottom: 0;
      font-size: 16px;
      font-weight: bold;
    }
    .select{
      display: flex;
      padding: 15px 20px;
      label{
        margin-bottom: 0;
        input{
          height: 20px;
          width: 20px;
        }
      }
    }
  }
  .submit_order{
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    padding: 0 20px;
    .amount{
      span{
        font-size: 20px;
        font-weight: bold;
        color: #007fff;
      }
      margin-bottom: 10px;
    }
    small{
      font-size: 14px;
      color: #929292;
      margin-bottom: 10px;
    }
    .backtrack{
      color: #4BA4FF;
      border-color: #96CAFF;
      margin-right: 10px;
    }
    .submit{
      color: #fff;
      background-color: #007FFF;
    }
  }
}
</style>