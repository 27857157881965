<template>
  <div class="loginDesktop">
    <div class="info">
      <component :is="currentComponent" @call-parent-method="parentMethod"></component>
      <a-flex justify="center" align="center" class="bottom">
        登录 或 注册 代表您已阅读并同意 <a href="/protocol/licence" class="btn btn-link btn-sm" target="_blank">用户协议</a>
        和<a href="/protocol/privacy" class="btn btn-link btn-sm" target="_blank">隐私政策</a>
      </a-flex>
    </div>
  </div>
</template>

<script setup>
import {logDebug} from "@/utils/logger";
import { ref, computed } from 'vue'
import DesktopLogin from './DesktopLogin.vue'
import DesktopResetPassword from './DesktopResetPassword.vue'
import DesktopSignup from './DesktopSignup.vue'

const selectedKey = ref('login');
const components = {
  login: DesktopLogin,
  reset: DesktopResetPassword,
  signup: DesktopSignup,
}

const currentComponent = computed(() => {
  return components[selectedKey.value]
})

function parentMethod(e) {
  logDebug('父组件方法被调用了');
  logDebug(e);
  selectedKey.value = e.component;
}
</script>

<style scoped lang="scss">
.loginDesktop {
  width: 100vw;
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  background-image: url(../../../public/wall/wall-20.jpg);
  background-repeat: no-repeat;
  background-size: cover;
  .info{
    background-color: #fff;
    padding: 37px;
    padding-bottom: 0;
    border-radius: 4px;
    outline: 8px solid #ffffff80;
    .bottom{
      font-size: 13px;
      margin-top: 40px;
      margin-bottom: 10px;
    }
  }
}
:deep(.ant-form-item){
  margin-bottom: 14px;
}
</style>