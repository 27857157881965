<template>
  <div>
    <template v-if="formState.retrieve">
      <h2>找回密码</h2>
      <a-form ref="formRetrieveRef" :rules="retrieveRules" :model="retrieveFormState" autocomplete="off"
              @finish="onRetrieveFinish">

        <a-form-item label="" style="text-align: center;">
          <a-radio-group v-model:value="retrieveFormState.restType"
                         style="display: inline-flex; justify-content: center;">
            <a-radio-button value="phone">手机号</a-radio-button>
            <a-radio-button value="email">邮箱</a-radio-button>
          </a-radio-group>
        </a-form-item>

        <!--邮箱验证码-->
        <a-form-item label="邮箱" name="email" v-if="retrieveFormState.restType === 'email'">
          <a-input v-model:value="retrieveFormState.email" :disabled="isEmailDisabled"/>
        </a-form-item>
        <a-form-item label="验证码" name="login_auth_code" v-if="retrieveFormState.restType === 'email'">
          <div style="display: flex;align-items: stretch;">
            <a-input v-model:value="retrieveFormState.login_auth_code" style="margin-right: 10px;"/>
            <a-button type="primary" :disabled="isEmailDisabled" @click="getEmailVerificationCode"
                      style="height: auto;width: auto;">
              {{ isEmailDisabled ? `重新获取(${curCount})` : '获取验证码' }}
            </a-button>
          </div>
        </a-form-item>

        <!--手机获取验证码找回-->
        <a-form-item label="手机号" name="login_phone" v-if="retrieveFormState.restType === 'phone'">
          <a-input v-model:value="retrieveFormState.login_phone" :disabled="isDisabled"/>
        </a-form-item>
        <a-form-item label="验证码" name="login_auth_code" v-if="retrieveFormState.restType === 'phone'">
          <div style="display: flex;align-items: stretch;">
            <a-input v-model:value="retrieveFormState.login_auth_code" style="margin-right: 10px;"/>
            <a-button type="primary" :disabled="isDisabled" @click="getVerificationCode"
                      style="height: auto;width: auto;">
              {{ isDisabled ? `重新获取(${curCount})` : '获取验证码' }}
            </a-button>
          </div>
        </a-form-item>
        <a-flex justify="center" align="center" class="mt-4">
          <a-button type="primary" html-type="submit">确认</a-button>
        </a-flex>
        <a href="#" class="btn btn-link btn-sm float-right" @click.prevent="enterDetail">返回到登录页面</a>
      </a-form>
    </template>
    <template v-else>
      <h2>重置密码</h2>
      <a-form ref="formResetRef" :rules="rules" :model="formState" autocomplete="off" @finish="onResetFinish"
              :label-col="{ style: {width: '100px'} }">

        <a-form-item label="登录手机号" name="login_phone" v-if="retrieveFormState.restType === 'phone'">
          <a-input v-model:value="retrieveFormState.login_phone" disabled/>
        </a-form-item>

        <a-form-item label="登录邮箱" name="login_email" v-if="retrieveFormState.restType === 'email'">
          <a-input v-model:value="retrieveFormState.email" disabled/>
        </a-form-item>

        <a-form-item label="密码" name="password">
          <a-input-password v-model:value="formState.password"/>
        </a-form-item>
        <a-form-item label="确认密码" name="confirm_password">
          <a-input-password v-model:value="formState.confirm_password"/>
        </a-form-item>
        <a-flex justify="flex-end" align="center">
          <a href="#" @click.prevent="enterDetail">返回登录</a>
        </a-flex>
        <div class="text-center">
          <small style="color: red;">*长度为8-16字符;至少包含一个大小写字母、数字</small>
        </div>
        <a-flex justify="center" align="center" class="mt-4">
          <a-button type="primary" html-type="submit">确认</a-button>
        </a-flex>
      </a-form>
    </template>
  </div>
</template>

<script setup>
const emits = defineEmits(['call-parent-method']);

function enterDetail() {
  emits('call-parent-method', {
    component: 'login',
  });
}

import {logDebug, logError} from "@/utils/logger";
import {onUnmounted, reactive, ref} from 'vue';
import {getFailedMessage, jsonRPC, jsonOwlRPC, getResponseData} from "@/utils/http_utils";
import {message} from "ant-design-vue";

const formState = reactive({
  retrieve: true,
  login: '',
  password: '',
  confirm_password: '',
  login_phone: '',
  login_auth_code: '',
});

const rules = {
  password: [
    {required: true, message: '请输入密码！'},
    {min: 8, max: 16, message: '密码长度必须为8到16个字符'},
    {
      validator: (_, value) => {
        const pattern = /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d).{8,16}$/;
        if (!value || pattern.test(value)) {
          return Promise.resolve();
        } else {
          return Promise.reject('密码必须包含大小写字母、数字');
        }
      },
      trigger: 'blur'
    }
  ],
  confirm_password: [
    {required: true, message: '请再次输入密码！'},
    {
      validator: (_, value) => {
        if (value !== formState.password) {
          return Promise.reject('两次输入的密码不一致！');
        }
        return Promise.resolve();
      },
      trigger: 'blur'
    }
  ]
};

const retrieveFormState = reactive({
  retrieve: true,
  email: '',
  login: '',
  login_phone: '',
  login_auth_code: '',
  restType: 'phone',
});
const retrieveRules = {
  login_phone: [
    {required: retrieveFormState.restType === 'phone', message: '请输入手机号！'},
    {pattern: /^1[3-9]\d{9}$/, message: '手机号格式不正确！'}
  ],
  email: [
    {
      required: retrieveFormState.restType === 'email', message: '请输入邮箱！'
    },
    {pattern: /^[\w.-]+@[\w.-]+\.[a-z]{2,6}$/i, message: '邮箱格式不正确！'}
  ],
  login_auth_code: [
    {
      required: retrieveFormState.restType === 'phone', message: '请输入验证码！'
    }
  ],
};

const curCount = ref(120);
const timer = ref(null);
const isDisabled = ref(false);
const isEmailDisabled = ref(false);
const token = ref(false);
const getVerificationCode = () => {
  formRetrieveRef.value.validateFields(['login_phone'])
      .then(() => {
        // 验证通过后执行获取验证码的逻辑
        jsonOwlRPC({
          url: "/reset_password/send_auth_code",
          params: {
            mobile: retrieveFormState.login_phone,
          },
          success(res) {
            const data = getResponseData(res);
            logDebug(`手机验证码发送成功`, data);
            isDisabled.value = true;
            curCount.value = 120;
            timer.value = setInterval(SetRemainTime, 1000);
          },
          fail(error) {
            logError(`验证码获取失败, `, error)
            message.error(`验证码获取失败，[${getFailedMessage(error)}]`);
          }
        })
      })
      .catch((error) => {
        // 验证失败时处理逻辑
        logDebug('手机号验证未通过:', error);
      });
}

const getEmailVerificationCode = () => {
  formRetrieveRef.value.validateFields(['email'])
      .then(() => {
        jsonOwlRPC({
          url: "/email/reset_password/info",
          params: {
            email: retrieveFormState.email,
          },
          success(res) {
            const data = getResponseData(res);
            logDebug(`邮箱验证码发送成功`, data)
            isEmailDisabled.value = true;
            curCount.value = 120;
            timer.value = setInterval(SetRemainTime, 1000);
            message.success(`邮箱验证码已发送，请登录邮箱查看验证码！`)
          },
          fail(error) {
            logError(`验证码获取失败, `, error)
            message.error(`验证码获取失败，[${getFailedMessage(error)}]`);
          }
        })

      })
      .catch((error) => {
        logError(error)
      })
}
const SetRemainTime = () => {
  if (curCount.value === 0) {
    clearInterval(timer.value);
    timer.value = null;
    isDisabled.value = false;
    isEmailDisabled.value = false;
  } else {
    curCount.value--;
  }
};


// onFinish 是前端校验成功的回调，通常用于表单验证。
const formRetrieveRef = ref();
const onRetrieveFinish = values => {
  logDebug('Success:', values);
  if (retrieveFormState.restType == 'phone') {
    formRetrieveRef.value.validate()
        .then(() => {
          jsonRPC({
            url: "/hw_web/login/retrieve",
            params: {
              login: retrieveFormState.login,
              type: retrieveFormState.restType,
              login_phone: retrieveFormState.login_phone,
              login_auth_code: retrieveFormState.login_auth_code,
            },
            success(res) {
              const data = getResponseData(res);
              logDebug(`找回密码成功`, data);
              token.value = data.token;
              formState.retrieve = false
            },
            fail(error) {
              logError(`找回密码失败, `, error)
              message.error(`找回密码失败，[${getFailedMessage(error)}]`);
              formState.retrieve = true
            }
          })
        })
        .catch(error => {
          logDebug('error', error);
        });
  }
  if (retrieveFormState.restType === 'email') {
    formRetrieveRef.value.validate()
        .then(() => {
          jsonRPC({
            url: "/hw_web/login/email/retrieve",
            params: {
              email: retrieveFormState.email,
              login_auth_code: retrieveFormState.login_auth_code,
            },
            success(res) {
              const data = getResponseData(res);
              logDebug(`找回密码成功`, data);
              token.value = data.token;
              formState.retrieve = false
            },
            fail(error) {
              logError(`找回密码失败, `, error)
              message.error(`找回密码失败，[${getFailedMessage(error)}]`);
              formState.retrieve = true
            }
          })
        })
        .catch(error => {
          logDebug('error', error);
        });
  }
}
const formResetRef = ref();
const onResetFinish = values => {
  logDebug('Success:', values);
  formResetRef.value.validate()
      .then(() => {
        jsonRPC({
          url: "/hw_web/login/reset",
          params: {
            type: retrieveFormState.restType,
            login_phone: retrieveFormState.login_phone,
            email: retrieveFormState.email,
            password: formState.password,
            confirm_password: formState.confirm_password,
            token: token.value,
          },
          success(res) {
            const data = getResponseData(res);
            logDebug(`重置密码成功`, data);
            message.success('重置密码成功,登录成功')
            enterDetail()
          },
          fail(error) {
            logError(`重置密码失败, `, error)
            message.error(`重置密码失败，[${getFailedMessage(error)}]`);
          }
        })
      })
      .catch(error => {
        logDebug('error', error);
      });
}
onUnmounted(() => {
  if (timer.value) {
    clearInterval(timer.value);
  }
});
</script>

<style scoped lang="scss">
h2 {
  font-size: 20px;
  text-align: center;
  font-weight: bold;
  margin-bottom: 2rem;
}

button {
  height: 40px;
  width: 150px;
}
</style>