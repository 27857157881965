<template>
  <div class="homework_finish">
    <a-breadcrumb separator=">">
      <a-breadcrumb-item><router-link to="/console/class">培训班级</router-link></a-breadcrumb-item>
      <a-breadcrumb-item><router-link :to="`/console/class/${classId}`">{{class_name}}</router-link></a-breadcrumb-item>
      <a-breadcrumb-item>做作业</a-breadcrumb-item>
    </a-breadcrumb>
    <div class="info">
      <a-flex :justify="'space-between'" :align="'center'" style="margin-bottom: 20px;">
        <h1>{{class_name}}</h1>
        <a-button type="primary" @click="BrushWrongQuestion">刷错题集</a-button>
      </a-flex>
      <a-table :columns="columns" :dataSource="data_list" :pagination="false">
        <template #bodyCell="{ text, column, record }">
          <template v-if="column.dataIndex == 'status'">
            <a-typography-text v-if="record.status == '通过'" type="success">{{text}}</a-typography-text>
            <a-typography-text v-else-if="record.status == '未提交'" type="warning">{{text}}</a-typography-text>
            <a-typography-text v-else-if="record.status == '未通过'" type="danger">{{text}}</a-typography-text>
            <a-typography-text v-else>{{text}}</a-typography-text>
          </template>
          <template v-if="column.dataIndex == 'action'">
            <router-link v-if="record.status == '未完成'" :to="{ path: `/console/class/homework/${record.id}`, 
              query: { mode: 'create', classId: classId, class_name: class_name, name: record.name } }">开始作业</router-link>
            <router-link v-else-if="record.status == '未提交'" :to="{ path: `/console/class/homework/${record.id}`, 
              query: { mode: 'update', record_id: record.record_id, classId: classId, class_name: class_name, name: record.name } }">继续作业</router-link>
            <router-link v-else :to="{ path: `/console/class/homework/${record.id}`, 
              query: { mode: 'readonly', record_id: record.record_id, classId: classId, class_name: class_name, name: record.name } }">查看作业</router-link>
          </template>
        </template>
      </a-table>
    </div>
  </div>
</template>

<script setup>
import { onMounted,ref } from "vue";
import { getResponseData, jsonRPC } from "@/utils/http_utils";
import { getRouterParam, routerPush } from "@/utils/router_utils";
import {logDebug, logError} from "@/utils/logger";
import { useRouter } from "vue-router";
import { message } from "ant-design-vue";
const router = useRouter();
const classId = ref(getRouterParam(router, "class_id"));

const columns = ref([
  {
    title: '作业名称',
    dataIndex: 'name',
  },
  {
    title: '所属章节/直播',
    dataIndex: 'live_name',
  },
  {
    title: '作业提交截止日期',
    dataIndex: 'deadline',
  },
  {
    title: '作业状态',
    dataIndex: 'status',
  },
  {
    title: '操作',
    dataIndex: 'action',
  },
]);
let class_name = ref()
let wrong_question_set = ref()
const data_list = ref([]);
onMounted(() => {
  jsonRPC({
    url: "/vue/console/class/homework/finish",
    params: {
      id: Number(classId.value),
    },
    success(res) {
      const data = getResponseData(res)
      logDebug(`查询成功`,data);
      data_list.value = data.records;
      class_name.value = data.class_name;
      wrong_question_set.value = data.wrong_question_set;
    },
    fail(error) {
      logError(`查询失败, `, error);
    },
  });
});
let BrushWrongQuestion = ()=>{
  message.success("进入错题集")
  routerPush(router, `/console/class/wrong_question_set/${wrong_question_set.value}?classId=${classId.value}&class_name=${class_name.value}`)
}
</script>

<style scoped lang='scss'>
.homework_finish{
  height: 100%;
  .info{
    background-color: #fff;
    padding: 20px;
    height: 100%;
  }
  h1{
    width: 10vw;
    font-size: 18px;
    font-weight: bold;
    padding-bottom: 5px;
    border-bottom: 1px solid #63B1FF;
  }
  button{
    height: 40px;
    border-radius: 20px;
  }
}
</style>