<template>
  <div class="exam_code">
    <div v-html="data_list.description"></div>
    <div class="case_top">
      <label class="lang">
        <h3>切换语言：</h3>
        <a-select v-model:value="data_list.judge_server.language" @change="changeMode" placeholder="请选择语言">
          <a-select-option v-for="language in data_list.judge_server.languages" :key="language.id" :value="language.id">
            {{ language.name }}
          </a-select-option>
        </a-select>
      </label>
      <label class="theme">
        <h3>切换主题：</h3>
        <a-select v-model:value="themeValue" @change="changeTheme" placeholder="请选择主题">
          <a-select-option v-for="theme in themeOptions" :key="theme.value" :value="theme.key">
            {{ theme.label }}
          </a-select-option>
        </a-select>
      </label>
    </div>
    <div class="card">
      <div class="card-body">
        <div class="case_environment">
          <div id="split-0">
            <Codemirror
              v-model="code"
              placeholder="Code goes here..."
              :style="codeOptions.style"
              :mode="codeOptions.mode"
              :theme="codeOptions.theme"
              :spellcheck="codeOptions.spellcheck"
              :autofocus="codeOptions.autofocus"
              :indent-with-tab="codeOptions.indentWithTab"
              :tabSize="codeOptions.tabSize"
              :extensions="codeOptions.extensions"
            />
          </div>
          <div id="split-1" v-show="isTestSetVisible">
            <h4>
              <div>
                考试要求:
                <span v-if="data_list.submit_number !== 0">
                  {{data_list.exercise_judgment_passed? "通过": `${data_list.submit_number}次提交次数`}}
                </span>
                <span v-else>
                  {{data_list.exercise_judgment_passed? "通过": "提交次数已用完或已提交，不通过"}}
                </span>
              </div>
              <a-radio-group v-model:value="menu_sets" button-style="solid">
                <a-radio-button v-for="(testSet,index) in data_list.judge_server.testSets" :key="index" :value="index">测试集{{ index+1 }}</a-radio-button>
              </a-radio-group>
            </h4>
            <div id="testResults">
              <table class="table table-bordered">
                <thead>
                  <tr style="text-align: center;white-space: nowrap;">
                    <th v-show="menu_sets==0">目标输入：</th>
                    <th v-show="menu_sets!=0">测试输入：</th>
                    <th>预计运行结果：</th>
                    <th>实际运行结果：</th>
                  </tr>
                </thead>
                <tbody>
                  <tr v-for="(testSet, index) in data_list.judge_server.testSets" :key="index" v-show="menu_sets==index">
                    <td v-html="testSet.case_input"></td>
                    <td v-html="testSet.case_output"></td>
                    <td v-for="(result,inx) in data_list.judge_server.actual_running_results" :key="inx" v-html="result" v-show="menu_sets==inx"></td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        </div>
        <div class="case_run">
          <div class="case_run_info">
            本案例最大执行时间：{{ data_list.judge_server.time_limit }}秒,
            最大执行内存：{{ data_list.judge_server.memory_limit }}KB
          </div>
          <div>
            <button class="btn" @click="showTestSet">查看测试集</button>
            <input v-if="!data_list.exercise_judgment_passed && data_list.submit_number != 0" 
              type="button" class="btn bit_submit" :disabled="isDisabled" @click="onClickTest" :value="buttonText"/>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script setup>
const props = defineProps({
  exam_id: { type: Number },
  exam_history_id: { type: Number },
  code_id: { type: String },
  disabled: {
    type: Boolean,
    default: false,
  }
});
import { onMounted, ref, reactive, watch } from "vue";
import { jsonOwlRPC } from "@/utils/http_utils";
import { logDebug, logError } from "@/utils/logger";
import { message } from "ant-design-vue";
import { python } from "@codemirror/lang-python";
import { javascript } from "@codemirror/lang-javascript";
import { json } from "@codemirror/lang-json";
import { xml } from "@codemirror/lang-xml";
import { html } from "@codemirror/lang-html";
import { css } from "@codemirror/lang-css";
import { java } from "@codemirror/lang-java";
import { cpp } from "@codemirror/lang-cpp";
import { Codemirror } from "vue-codemirror";
import { duotoneDark, duotoneLight, gruvboxDark, gruvboxLight, vscodeDark } from '@uiw/codemirror-themes-all';

// 编程案例内容
const code = ref();
// 配置代码编辑器
const codeOptions = reactive({
  style: { height: "600px", border: "1px solid black" },
  mode: "python",
  theme: "vscodeDark",
  spellcheck: true,
  autofocus: true,
  indentWithTab: true,
  tabSize: 2,
  extensions: [python(), vscodeDark],
});
const menu_sets = ref(0);
// 改变主题
const themes = [vscodeDark, gruvboxDark, gruvboxLight, duotoneDark, duotoneLight];
const themeValue = ref(0);
const themeOptions = [
  {label: 'vscodeDark',value: 'vscodeDark',key: 0,},
  {label: 'gruvboxDark',value: 'gruvboxDark',key: 1,},
  {label: 'gruvboxLight',value: 'gruvboxLight',key: 2,},
  {label: 'duotoneDark',value: 'duotoneDark',key: 3,},
  {label: 'duotoneLight',value: 'duotoneLight',key: 4,},
];
function changeTheme() {
  codeOptions.extensions = [python(), themes[themeValue.value]];
}
// 改变模式
const Modes = [python(),cpp(),javascript(),json(),xml(),html(),css(),java(),];
const ModeValue = ref(0);
const ModeOptions = [
  {label: 'python',value: 'python',key: 0,},
  {label: 'C++',value: 'C++',key: 1,},
  {label: 'javascript',value: 'javascript',key: 2,},
  {label: 'json',value: 'json',key: 3,},
  {label: 'xml',value: 'xml',key: 4,},
  {label: 'html',value: 'html',key: 5,}, 
  {label: 'css',value: 'css',key: 6,},
  {label: 'java',value: 'java',key: 7,},
];
function changeMode() {
  const languageName = data_list.judge_server.languages.find(i => i.id == data_list.judge_server.language)?.name;
  if (languageName) {
    let ModeKey = ModeOptions.find(i => languageName.toLowerCase().includes(i.label.toLowerCase()))?.key;
    if (ModeKey!='undefined') {
      ModeValue.value = ModeKey;
      codeOptions.extensions = [Modes[ModeValue.value], themes[themeValue.value]];
    }
  }
}

const data_list = reactive({
  description: '',
  judge_server: {
    testSets: [],  // 测试集
    exercise_cases_template: [],  // 预设内容
    actual_running_results: [],  // 结果
    languages: [],  // 当前代码题目允许使用的语言
    language: 0,  // 语言id
  },
  submit_number: 3, // 答题次数
});
// 获取题目数据
let fetchCodeData = () => {
  jsonOwlRPC({
    url: "/exam_code_root",
    params: {
      question_id: props.code_id,
    },
    success(res) {
      logDebug(`获取记录成功`, res);
      Object.assign(data_list, res);
      cases_data(data_list.question_code);
    },
    fail(error) {
      logError(`获取数据错误, `, error);
    },
  });
};
onMounted(() => {
  fetchCodeData();
});
// 整合资料
const cases_data = (e) => {
  jsonOwlRPC({
    url: "/exercise/judge/problems",
    params: {
      question_code: e,
    },
    success(res) {
      logDebug(`获取记录成功`, res);
      const re = res.problems_judge_data[0].cases_data;
      let testSet = [];
      for (let i in re) {
        const test = re[i];
        let tset = {
          id: i,
          name: i,
          case_input: test.case_input,
          case_output: test.case_output,
        };
        testSet.push(tset);
      }

      for (let j = 0; j < testSet.length; j++) {
        testSet[j].case_id = (j + 1).toString();
      }

      data_list.judge_server.testSets = testSet;

      // 清空输入的内容，和结果
      if (code.value !== null && data_list.judge_server.exercise_cases_template.length>0) {
        code.value = data_list.judge_server.exercise_cases_template[0].question_template;
      }
      data_list.judge_server.actual_running_results = [];

      // 设置允许使用的语言
      const languages = [];
      const language_map = res.problems_judge_data[0].language;
      Object.keys(language_map).forEach(function (x) {
        languages.push({
          id: x,
          name: language_map[x],
        });
      });
      if (languages.length>0) {
        data_list.judge_server.languages = languages;
        data_list.judge_server.language = languages[0].id;
        changeMode()
      }
    },
    fail(error) {
      logError(`获取数据错误, `, error);
    },
  });
};

const isTestSetVisible = ref(false); // 用来控制测试集的显示/隐藏
watch(isTestSetVisible, () => {
  if (isTestSetVisible.value) {
    codeOptions.style.height = "400px";
  } else {
    codeOptions.style.height = "600px";
  }
});
const showTestSet = () => {
  isTestSetVisible.value = !isTestSetVisible.value;
};
const isDisabled = ref(false);
watch(() => props.disabled, (newVal) => {
  isDisabled.value = newVal;
  data_list.submit_number = 0;
});
const buttonText = ref("提交答案");
// 运行测试 提交代码
const onClickTest = () => {
  // 禁用按钮
  isDisabled.value = true;
  // 设置倒计时，5秒后恢复按钮
  let countdown = 5;
  buttonText.value = `提交答案 (${countdown})`;  // 更新按钮文本为倒计时状态
  const interval = setInterval(() => {
    countdown--;
    buttonText.value = `提交答案 (${countdown})`;  // 每秒更新一次文本
    if (countdown <= 0) {
      clearInterval(interval);  // 清除倒计时
      buttonText.value = "提交答案";  // 恢复按钮文本
      isDisabled.value = false;  // 恢复按钮可点击
    }
  }, 1000);
  isTestSetVisible.value = true;
  submit_test_running();
};
// 提交代码
const submit_test_running = () => {
  // 从lesson中拿到案例的question_code，返回获取结果的url
  const q_code = data_list.question_code;
  const source_code = code.value;
  if (source_code.length === 0) {
    message.warn("请在代码区输入你的代码！");
    return false;
  }
  if (data_list.submit_number != 0) {
    data_list.submit_number -= 1;
    data_list.judge_server.actual_running_results = ["运行测试中"];
    const source_code_language = data_list.judge_server.language; // 获取语言id
    const url = `/exercise/judge/problems/${q_code}`;
    try {
      jsonOwlRPC({
        url: url,
        params: {
          question_code: q_code,
          language: source_code_language,
          source: source_code,
          judge: "",
        },
        success(res) {
          logDebug(`获取记录成功`, res);
          self_test_running(res);
        },
        fail(error) {
          logError(`获取数据错误, `, error);
        },
      });
    } catch (e) {
      logError("catch", e);
      data_list.judge_server.actual_running_results = ["无法连接到裁判服务器"];
      return false;
    }
  } else {
    message.warn("本题提交次数已用完");
  }
};
// 获取当前提交问题的判定结果
const self_test_running = (judge_submit_url) => {
  isDisabled.value = true;
  const url = "/exercise/judge/problems/results";
  try {
    jsonOwlRPC({
      url: url,
      params: {
        judge_submit_url: judge_submit_url,
      },
      success(res) {
        logDebug(`获取记录成功`, res);
        let cases_data = res.results;
        if (cases_data.status === "P" || cases_data.status === "G") {
          data_list.judge_server.actual_running_results = ["运行中。。。"];
          setTimeout(function () {
            self_test_running(judge_submit_url);
          }, 3000);
          return true;
        } else {
          self_test_running_results(cases_data);
          isDisabled.value = false;
          return true;
        }
      },
      fail(error) {
        logError(`获取数据错误, `, error);
        data_list.judge_server.actual_running_results = [
          "无法连接到裁判服务器",
        ];
        return false;
      },
    });
  } catch (e) {
    isDisabled.value = false;
    logError("catch", e);
    data_list.judge_server.actual_running_results = ["无法连接到裁判服务器"];
    return false;
  }
};
// 对判定结果进行解析
const self_test_running_results = (cases_data) => {
  if (cases_data.status !== 400) {
    const status_data = [];
    if (cases_data.status) {
      if (cases_data.status === "CE") {
        status_data.push(cases_data.status + "编译错误" + cases_data.info);
      } else if (cases_data.status === "QU") {
        status_data.push(cases_data.status + "未知错误，请重试！");
      }
    } else {
      status_data.push("获取判题结果失败");
    }
    if (cases_data.cases) {
      if (cases_data.cases.length > 0) {
        for (let i in cases_data.cases) {
          let data = cases_data.cases[i];
          if (data.status === "WA") {
            status_data.push("Case " + data.case_id + " 答案错误");
          } else if (data.status === "AC") {
            status_data.push(
              "Case " + data.case_id + " 运行结果：成功！" + "用时:" + data.time + "(秒)"
            ); // +"得分" + data.total + "分"
            data_list.exercise_judgment_passed = true;
            // 提交答案到考试记录
            try {
              jsonOwlRPC({
                url: "/certification/exam/code/submit",
                params: {
                  exam_id: props.exam_id,
                  exam_history_id: props.exam_history_id,
                  code: code.value,
                  pass_id: data_list.exercise_judgment_passed,
                },
                success() {
                  message.success("编程题提交成功");
                },
                fail(error) {
                  logError(`获取数据错误, `, error);
                },
              });
            } catch (error) {
              logError("错误：" + error);
            }
          } else if (data.status === "CE") {
            status_data.push("Case " + data.case_id + " 编译错误");
          } else if (data.status === "IR") {
            status_data.push("Case " + data.case_id + " 运行错误,退出代码");
          } else if (data.status === "TLE") {
            status_data.push("Case " + data.case_id + " 超出时间限制");
          } else if (data.status === "MLE") {
            status_data.push("Case " + data.case_id + " 超出内存限制");
          } else if (data.status === "IE") {
            status_data.push("Case " + data.case_id + " Internal Error");
          } else {
            status_data.push("未知错误，请重试！");
          }
        }
      }
    }
    data_list.judge_server.actual_running_results = status_data;
    return true;
  }
  if (cases_data.status === 400) {
    data_list.judge_server.actual_running_results = ["获取判题结果失败"];
    return false;
  }
};
</script>

<style scoped lang="scss">
.exam_code {
  .case_top {
    height: 40px;
    display: flex;
    justify-content: space-between;
    .lang,.theme {
      display: flex;
      h3 {
        flex-shrink: 0;
        font-size: 24px;
        margin-bottom: 0;
      }
    }
  }
  .card {
    height: 640px;
    width: 100%;
    > .card-body {
      height: 100%;
      padding: 0;
      background-color: #2b2b2b !important;
      color: #fff;
      .case_run {
        height: 40px;
        display: flex;
        justify-content: space-between;
        align-items: center;
        margin: 0 10px;
        .btn {
          flex-shrink: 0;
          background-color: #008eff;
          color: white;
          line-height: normal;
          font-size: 14px;
          &.bit_submit {
            margin-left: 1rem;
            background-color: #ffa257;
          }
        }
      }
      .case_environment {
        height: calc(100% - 40px);
        #split-1 {
          height: 200px;
          h4 {
            font-size: 20px;
            background-color: #176bcb;
            display: flex;
            justify-content: space-between;
            align-items: center;
            padding: 0 10px;
            margin-bottom: 0;
          }
          #testResults {
            height: calc(100% - 32px);
            overflow: auto;
            .table{
              color: #fff;
              margin-bottom: 0;
              th{
                border-bottom-width: 1px;
              }
            }
          }
        }
      }
    }
  }
}
:deep(.ant-radio-button-wrapper){
  background-color: #176bcb;
  border: 1px solid #176bcb;
  &:first-child{
    border-inline-start: 1px solid #176bcb;
  }
  &:not(:first-child)::before{
    background-color: #176bcb;
  }
}
</style>
