<template>
  <div id="live_rtc">
    <a-spin tip="连麦环境环境开启中。。。" :spinning="false">
      <div class="login">
        <div class="main">
          <div class="main-title">
            <h3>Hi168 直播连麦</h3>
          </div>
          <div class="star-container" style="text-align: center;color: #333;letter-spacing: 3px;">
            <div class="star">
              <h5 v-show="!showUserCallButton">通话中。。。。</h5>
            </div>
          </div>
          <div class="main-button">
            <a-button @click="handleVoiceCall(current_online_id)" v-show="showUserCallButton">
              发起语音
            </a-button>
            <a-button @click="handleVoiceCallLeaveBtn(current_online_id,login_user_id)" v-show="!showUserCallButton">
              挂断语音
            </a-button>
          </div>
          <div>
            <a-button type="dashed" @click="showDrawer">设置</a-button>
          </div>
          <a-drawer
              title="连麦设置"
              placement="bottom"
              :closable="false"
              :open="open"
              @close="onClose"
          >
            <a-card>
              <div>
                <a-typography-text>
                  当前麦克风：{{ micId }}
                </a-typography-text>
                <p>
                  麦克风列表：
                </p>
                <a-select
                    v-model:value="micValue"
                    placeholder="请选择"
                    style="width: 100%"
                    @change="handleChange"
                    :field-names="{ label: 'label', value: 'deviceId'}"
                    :options="micList"
                    :disabled="selectDisabled"
                >
                </a-select>
              </div>
              <template #actions>
                <a-button type="primary" @click="testMicrophoneAudioTrack()">确认并测试设备</a-button>
              </template>
            </a-card>
            <a-card>
              <p>房间ID：{{ liveId }}</p>
              <p>UserID：{{ login_user_id }}</p>
              <p>用户名：{{ current_user_name }}</p>
              <p>OnlineId：{{ current_online_id }}</p>
            </a-card>
          </a-drawer>
        </div>
      </div>

      <div class="main-web" style="display: none;">
        <div class="container-box">
          <div class="local-audio">
            <audio loop autoplay id="Rtc_prismAudio_local"></audio>
          </div>
        </div>
      </div>
    </a-spin>
  </div>
</template>

<script setup>
import {
  markRaw, onBeforeUnmount,
  onMounted,
  reactive,
  ref,
} from "vue";
import {logDebug, logError} from "@/utils/logger";
import DingRTC from "dingrtc";
import {message, notification} from "ant-design-vue";
import {
  getResponseData,
  isFailedResponse,
  isSessionExpired,
  newSessionExpiredHandler,
  normalizeURL
} from "@/utils/http_utils";
import {useRoute} from "vue-router";
import axios from "axios"; // 引入 useRoute 钩子

logDebug('Live RTC setup.')

DingRTC.setLogLevel("debug");

// 创建通话DingRTC引擎
let newClient = markRaw(DingRTC.createClient())

const route = useRoute(); // 获取 route 对象
const liveId = route.params.live_id; // 通过 useRoute 获取 live_id

const online_user_ids = ref([]);
let teacher_uid = ref();
let total_user_ids = ref(0);
let current_online_user_ids = ref(0);
let login_user_id = ref();
const current_online_id = ref();
const current_user_name = ref();
const selectDisabled = ref(false);

let joinInfo = reactive({'appId': null, 'token': null, 'uid': null, 'channel': null, 'userName': null,});

let micList = []
let speakerList = []
let micId = null
let speakerId = null

let showUserCallButton = ref(true);
let loadResult = ref(true);
let localMicTrack;

const micValue = ref();
const sessionId = route.query.session_id;

const open = ref(false);
const showDrawer = () => {
  open.value = true;
};
const onClose = () => {
  open.value = false;
};

function jsonRtcRPC(args) {
  const {url, params, success, fail, sessionExpiredHandler} = args;
  const normUrl = normalizeURL(`${url}?session_id=${sessionId}`);
  const method = 'POST';
  const headers = {
    'content-type': 'application/json',
  };
  const data = {
    jsonrpc: '2.0',
    method: 'call',
    params: params,
    id: null,
  };
  const options = {
    method: method,
    url: normUrl,
    data: JSON.stringify(data),
    headers: headers,
  };

  return axios(options)
      .then(function (response) {
        if (isFailedResponse(response)) {
          logError(`isFailedResponse response, `, response);
          if (isSessionExpired(response)) {
            if (typeof sessionExpiredHandler === 'function') {
              sessionExpiredHandler(response)
            } else {
              newSessionExpiredHandler()()
            }
          } else {
            if (typeof fail === 'function') {
              fail(response.data.result.message)
            }
          }
          return false
        } else {
          if (typeof success === 'function') {
            success(response);
          }
          return true
        }
      })
      .catch(function (error) {
        logError(`jsonRPC exception, error[${JSON.stringify(error)}]`);
        if (typeof fail === 'function') {
          fail(error);
        }
        return false
      });
}

// 选择并自动测试麦克风
const handleChange = async value => {
  logDebug(`selected ${value}`);
  micId = value // 设置为所选麦克风

  const microphoneTrack = await DingRTC.createMicrophoneAudioTrack({deviceId: micId});
  logDebug("microphoneTrack", microphoneTrack)
  microphoneTrack.play('#Rtc_prismAudio_local')

  testMicrophoneButton.value = true
  message.loading('麦克风测试中：请打开声音，并持续讲话....', 6);
  setTimeout(() => {
    microphoneTrack.stop();
    microphoneTrack.close();
    testMicrophoneButton.value = false
    logDebug('Microphone track stopped');
  }, 6000);
};

/**
 * isSupport rtc能力检测
 */
async function AliRtcSupportCheck() {
  try {
    const supported = DingRTC.checkSystemRequirements();
    if (supported) {
      loadResult.value = false
    } else {
      notification.warning({message: '当前浏览器不支持连麦',})
      return false
    }
  } catch (error) {
    logError('Error during initialization:', error);

    logErrorRpc({
      content: 'rtc能力检测',
      error,
      mark_id: 'AliRtcSupportCheck>181>up2o98'
    });
  }
}

// 初始化最后警告时间
let lastWarningTime = 0;

// Notification，带有节流机制
const throttledNotification = (message, message_type) => {
  const currentTime = Date.now();
  if (currentTime - lastWarningTime >= 5000) { // 检查是否已经过去了5秒
    if (message_type == "warning") {
      notification.warning({
        message: message,
        duration: 1,
      });
    } else if (message_type == "info") {
      notification.info({
        message: message,
        duration: 1,
      });
    }
    lastWarningTime = currentTime; // 更新最后警告时间
  }
};

/**
 * LocalDeviceInit 初始化远端设备，并做事件回调
 */
async function RemoteInit() {
  if (newClient?.userId){
    // 检查网络质量并发出警告
    newClient.on('network-quality', (uplinkNetworkQuality, downlinkNetworkQuality) => {
      // 网络质量定义
      const networkQualityDescriptions = {
        '0': '网络状态未知',
        '1': '网络极佳',
        '2': '网络较好',
        '3': '网络一般',
        '4': '网络较差',
        '5': '网络极差',
        '6': '网络已断开'
      };
      // 检查网络质量并发出警告
      const checkNetworkQuality = (quality, direction) => {
        if (Number(quality) >= 5) {
          const message = `当前${direction}网络状态： ${networkQualityDescriptions[quality] || quality}`;
          throttledNotification(`当前网络不佳`, 'warning');
          logDebug(message);
          logErrorRpc({
            content: '检查网络质量并发出警告',
            message,
            mark_id: 'checkNetworkQuality>235>u12oul'
          });
        }
      };
      checkNetworkQuality(uplinkNetworkQuality, '上行');
      checkNetworkQuality(downlinkNetworkQuality, '下行');
    });
  }

}

// 获取在线用户列表
const queryOnlineUserList = () => {
  jsonRtcRPC({
    url: `/api/live/${liveId}/online/user_list`,
    success(res) {
      const responseData = getResponseData(res);
      logDebug('获取在线用户列表', responseData);
      const {online_user_ids: onlineUserIds, login_user_id: loginUserId, teacher_id: teacherId} = responseData;
      online_user_ids.value = onlineUserIds;

      const currentUser = onlineUserIds.find(user => user.user_id === loginUserId);
      current_online_id.value = currentUser ? currentUser.id : false;
      current_user_name.value = currentUser ? currentUser.user_name : false;
      login_user_id.value = loginUserId;
      teacher_uid.value = teacherId;
      total_user_ids.value = responseData.total_user_ids
      current_online_user_ids.value = responseData.current_online_user_ids

      teacherHandleUserLeave();
    },
    fail(error) {
      logError('获取在线用户列表失败', error);
    },
  });
};

// 老师挂断电话，更新学生用户信息
const teacherHandleUserLeave = async () => {
  const currentUser = online_user_ids.value.find(user => user.user_id === login_user_id.value);
  if (currentUser?.id && currentUser.apply_call_state === 'teacher_down') {
    showUserCallButton.value = true;
    if (localMicTrack) {
      await localMicTrack.close();
      await newClient.unpublish();
      newClient.leave()
      window.location.reload()
    }
  }
};

/**
 * 发送请求以更新语音状态
 */
function applyCallRequest(online_id, state) {
  jsonRtcRPC({
    url: `/api/live/online/call/state`, // 请求的 API 端点
    params: {
      online_id: online_id, // 在线用户 ID
      apply_call_state: state, // 语音状态
    },
    // 请求成功后的回调函数
    success(res) {
      // 处理响应数据
      const data = getResponseData(res);
      logDebug("更新语音状态请求", data);
      // 更新在线用户列表
      queryOnlineUserList();
    },
    // 请求失败后的回调函数
    fail(error) {
      // 打印错误信息
      logError(`语音请求失败,`, `${error}`);
    },
  });
}

const isLoggingEnabled = true; // 当需要关闭日志记录时，将此值设置为 false
// 封装错误日志记录函数
function logErrorRpc({content = '', error = null, mark_id = null} = {}) {
  if (!isLoggingEnabled) {
    return;
  }
  // 文件名
  const fileName = 'LiveRtc.vue';

  // 获取当前时间
  const currentTime = new Date().toLocaleString();

  // 构建日志名称
  const name = `${fileName}_${currentTime}`;

  // 如果有错误对象，将其内容追加到日志中
  const errorDetails = error ? ` | Error: ${error?.reason || error?.message || JSON.stringify(error)}` : '';

  jsonRtcRPC({
    url: `/api/frontend/logs`,
    params: {
      name,
      content: `微信WebView端-${content}:${errorDetails}`,
      mark_id,
    },
    success(res) {
      logDebug("日志请求成功", getResponseData(res));
    },
    fail(error) {
      logError(`日志请求失败:`, error);
    },
  });
}

const deadline = ref(0) // 通话倒计时

// 查询在线用户列表Token
let queryUserListToken = () => {
  return new Promise((resolve, reject) => {
    jsonRtcRPC({
      url: `/api/live/${liveId}/channel/token`,
      success(res) {
        const data = getResponseData(res);
        Object.assign(joinInfo, data);
        // logDebug('查询在线用户列表Token成功', data);
        resolve(data); // 成功后调用 resolve
      },
      fail(error) {
        logError('查询在线用户列表Token失败', error);
        reject(error); // 失败后调用 reject
        logErrorRpc({
          content: '查询在线用户列表Token失败',
          error: error,
          mark_id: `queryUserListToken>404>36oc344`
        })
      },
    });
  });
};

const toggleButtonState = (isEnabled) => {
  showUserCallButton.value = isEnabled;
  selectDisabled.value = !isEnabled;
};

// 学生：发起语音
const handleVoiceCall = async (online_id) => {
  const supported = DingRTC.checkSystemRequirements();

  if (!supported) {
    notification.warning({message: '当前浏览器不支持通话，请更换浏览器'});
    return;
  }

  if (!micId) {
    await checkAndAuthMicPhone()
    await checkLocalTrack();
  }

  deadline.value = Date.now() + 1000 * 60 * 20

  try {
    toggleButtonState(false)// 修改按钮状态
    applyCallRequest(online_id, 'ready'); // 更新语音状态请求

    await getNewClient() // 获取RTC客户端
    LocalMCUPublish() // 推送发布本地麦克风

  } catch (error) {
    logError("Error during voice call:", error);
    notification.error({message: `呼叫失败，推送本地麦克风失败: ${error?.reason || error?.message || JSON.stringify(error)}，请稍后再试`});

    toggleButtonState(true)

    logErrorRpc({
      content: '学生：发起语音呼叫失败',
      error,
      mark_id: 'handleVoiceCall>428>2ur3os'
    });
  }
};

// 学生：发起语音-创建本地麦克风轨道
const LocalMCUPublish = () => {
  if (!micId) {
    logError('没有找到本地麦克风ID，无法推送，请检查麦克风权限是否已开启，或是否有可用的麦克风');
    return;
  }

  DingRTC.createMicrophoneAudioTrack({deviceId: micId}) // 创建本地麦克风轨道
      .then(track => {
        if (!newClient) {
          throw new Error('RTC 客户端未初始化');
        }
        localMicTrack = track;
        return newClient.publish(track); // 推送发布本地麦克风轨道
      })
      .then(() => {
        logDebug('推送发布本地麦克风成功');
      })
      .catch(error => {
        logError('Failed to publish local microphone track:', error);
        logErrorRpc({
          content: '学生：推送发布本地麦克风',
          error,
          mark_id: 'LocalMCUPublish>491>79fedo'
        });
      });
};

const handleVoiceCallLeaveBtn = async (online_id, user_id) => {
  // 发起挂断请求
  applyCallRequest(online_id, 'down');

  // 更新 UI 状态
  showUserCallButton.value = true;
  logDebug("学生", user_id);
  logDebug("侧挂断语音 localMicTrack", localMicTrack);

  try {
    // 确保 localMicTrack 存在
    if (localMicTrack) {
      // 取消发布本地流
      await newClient.unpublish(localMicTrack);
      logDebug("已取消发布本地麦克风轨道");

      // 关闭本地麦克风轨道
      await localMicTrack.close();
      logDebug("本地麦克风轨道已关闭");

      // 退出频道
      newClient.leave();
      logDebug("已离开频道");

      // 重置 UI 状态
      selectDisabled.value = false;

      // 避免页面重载，考虑使用其他方式来重置状态
      window.location.reload();
    } else {
      logDebug("没有找到本地麦克风轨道");
    }
  } catch (error) {
    logError("处理挂断操作时出错:", error);
    logErrorRpc({
      content: '学生：处理挂断语音操作失败',
      error,
      mark_id: 'handleVoiceCallLeaveBtn>492>9w7d4s'
    });
  }
};

// 获取麦克风权限,并默认取第一个麦克风
const checkAndAuthMicPhone = async function () {
  try {
    // 获取麦克风权限
    const stream = await navigator.mediaDevices.getUserMedia({audio: true});
    // 获取音频设备列表
    const devices = await navigator.mediaDevices.enumerateDevices();
    const audioDevices = devices.filter(device => device.kind === 'audioinput');

    // 关闭音频流
    stream.getTracks().forEach(track => track.stop());

    // 如果找到音频设备，则进行处理
    if (audioDevices.length > 0) {
      // 将音频设备添加到列表中
      micList = audioDevices

      micId = micList[0].deviceId
    } else {
      notification.warning({message: '未检测到音频设备，请检查设备或权限是否开启！',});
    }
  } catch (e) {
    logError("麦克风开启失败，", e);
    notification.warning({message: '麦克风开启失败，请授权开启！',});
    logErrorRpc({
      content: '麦克风开启失败',
      e,
      mark_id: 'handleVoiceCall>428>2ur3os'
    });
  }
}

let testMicrophoneButton = ref(false)
// 测试麦克风6秒后结束
const testMicrophoneAudioTrack = async () => {
  if (micId) {
    const microphoneTrack = await DingRTC.createMicrophoneAudioTrack({deviceId: micId});
    logDebug("microphoneTrack", microphoneTrack)
    microphoneTrack.play('#Rtc_prismAudio_local')
    testMicrophoneButton.value = true
    message.loading('麦克风测试中：请打开声音，并持续讲话....', 6);
    setTimeout(() => {
      microphoneTrack.stop();
      microphoneTrack.close();
      testMicrophoneButton.value = false
      logDebug('Microphone track stopped');
    }, 6000);
  } else {
    await checkAndAuthMicPhone()
  }
}

// 检查本地localMicTrack
const checkLocalTrack = async () => {
  try {
    await DingRTC.getMicrophones()
        .then((list) => {
          micList = list
        })
        .catch((e) => {
          logErrorRpc({
            content: 'DingRTC.getMicrophones 检查本地麦克风-localMicTrack',
            error: e,
            mark_id: 'checkLocalTrack>538>re753',
          });
        });
  } catch (error) {
    logError('获取麦克风列表时出错', error);
    logErrorRpc({
      content: '获取麦克风列表时出错 - checkLocalTrack',
      error,
      mark_id: 'checkLocalTrack>538>re753',
    });
  }
};

// 获取RTC SDK 客户端
const getNewClient = async () => {
  // 检查是否已经成功初始化客户端
  if (newClient?.userId) {
    logDebug('DingRTC 客户端已经初始化，准备离开频道');

    try {
      newClient.leave();
      logDebug('DingRTC 客户端已成功离开频道');
    } catch (leaveError) {
      logErrorRpc({
        content: '离开 DingRTC 频道失败',
        error: leaveError,
        mark_id: 'getNewClient>594>qw8628',
      });
      logError('离开 DingRTC 频道失败', leaveError);
    }
  }

  try {
    // 创建新的 DingRTC 客户端并将其标记为非响应式
    newClient = null;
    newClient = markRaw(DingRTC.createClient());

    // 初始化并加入客户端
    await newClient.join({
      appId: joinInfo.appId,
      token: joinInfo.token,
      uid: joinInfo.uid,
      channel: joinInfo.channel,
      userName: joinInfo.userName,
    });
    await RemoteInit() //初始化远端设备，并做事件回调
    logDebug('DingRTC 客户端已成功加入频道');
  } catch (error) {
    // 处理初始化或加入 DingRTC 客户端的错误
    logErrorRpc({
      content: '初始化或加入 DingRTC 客户端失败',
      error,
      mark_id: 'getNewClient>589>cv9d28',
    });
    logError('初始化或加入 DingRTC 客户端失败', error);
  }
};

// RTC SDK 获取设备列表
const getDeviceList = async () => {
  try {
    const [DingMicList, DingSpeakerList] = await Promise.all([
      DingRTC.getMicrophones(),
      DingRTC.getPlaybackDevices(),
    ]);

    micList = DingMicList;
    speakerList = DingSpeakerList;
    micId = DingMicList?.[0]?.deviceId || null;
    speakerId = DingSpeakerList?.[0]?.deviceId || null;

    logDebug("设备 micList", micList);
    logDebug("设备 speakerList", speakerList);
    logDebug("设备 micId", micId);
    logDebug("设备 speakerId", speakerId);
  } catch (error) {
    logError("获取设备列表失败", error);
    logErrorRpc({
      content: '获取设备列表失败',
      error,
      mark_id: 'getDeviceList>562>c12u28',
    });
  }
};

let getOnlineUserListTimer = ref();

onMounted(() => {
  queryOnlineUserList()
  getOnlineUserListTimer.value = setInterval(queryOnlineUserList, 5000);
});

onBeforeUnmount(() => {
  clearInterval(getOnlineUserListTimer.value);
});

onMounted(async () => {
  try {
    await queryUserListToken(); // 第一次加载，需要后端生成token
    await AliRtcSupportCheck(); // token获取成功后进行rtc能力检测
    await getDeviceList()
  } catch (error) {
    logError('Error during initialization:', error);
  }
});

onBeforeUnmount(async () => {
  await getNewClient()
  if (!newClient || !newClient.subscribe) {
    return Promise.reject(new Error("client.subscribe is not defined"));
  }
  newClient.leave();

  applyCallRequest(current_online_id.value, 'down');
  showUserCallButton.value = true;
  if (localMicTrack) {
    await localMicTrack.close();
    await newClient.unpublish();
  }
})

</script>

<style scoped>
body {
  height: 100%;
  width: 100%;
  margin: 0;
  padding: 0;
}

.login {
  min-height: 100vh;
  position: relative;
  background-color: #006eff;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 20px; /* 为小屏幕添加一些内边距 */
}

.login .main {
  width: 100%;
  max-width: 520px;
  background-color: #fff;
  padding: 30px 26px 24px 26px;
  box-sizing: border-box;
  font-size: 14px;
  border-radius: 8px; /* 增加圆角效果 */
}

.main .main-title {
  font-size: 30px;
  text-align: center;
  color: #333;
  letter-spacing: 1px;
}

.main .main-input {
  margin: 33px 0 13px 0;
}

.main-input input {
  padding: 0 14px;
  width: 100%;
  box-sizing: border-box;
  line-height: 38.5px;
  height: 38.5px;
  color: #888;
  border: solid 1px #ddd;
  margin-bottom: 10px;
  touch-action: none;
}

.main .main-button button {
  padding: 0 14px;
  width: 100%;
  box-sizing: border-box;
  line-height: 38.5px;
  height: 38.5px;
  background-color: #006eff;
  border: solid 1px #006eff;
  color: white;
  letter-spacing: 1px;
}

/* 自适应布局 */
@media (max-width: 768px) {
  .login .main {
    padding: 20px 15px;
  }

  .main .main-title {
    font-size: 24px;
  }

  .main-input input,
  .main .main-button button {
    line-height: 32px;
    height: 52px;
  }
}

.container-box {
  margin-left: auto;
  margin-right: auto;
  position: relative;
  padding: 0 20px; /* 保证小屏幕上的间距 */
}

.local-video video {
  width: 100%;
  height: auto;
  display: block;
  background-color: black;
}

.streamType label {
  font-family: Arial, sans-serif;
  font-size: 16px;
}
</style>