<template>
	<div class="icon-item" :class="{selected:props.item.selected}" @contextmenu="onContextmenu($event)"
		draggable="true" @dragstart="onDragstart($event)" @dragend="onDragend($event)">
		<a-badge :count="alertCount" style="transform: none">
			<a-spin :spinning="props.item.loading" tip="创建中...">
			<div class="icon-body" @click="onClick($event)" @dblclick="onDblclick()" :title="props.item.name">
				<div class="icon" :class="props.item.icon" :id="props.item.id"></div>
				<div class="text line-clamp-1">{{ props.item.name }}</div>
			</div>
			</a-spin>
		</a-badge>
	</div>
</template>

<script setup>
import {ref, onMounted} from 'vue';
import {AppGlobalConfig} from "@/utils/config_utils";
import coreStore from '@/stores/core.js';

const $coreStore = coreStore();
const alertCount = ref(0);
const props = defineProps({
	item: Object,
	order: Number
});
onMounted(()=>{
	var divElement = document.getElementById(props.item.id);
	var backgroundImage = window.getComputedStyle(divElement).backgroundImage;
	if (backgroundImage == 'none') {
		divElement.style.backgroundImage = `url('${AppGlobalConfig.baseUrl + props.item.icon}')`;
	}
});
const onContextmenu = function(event) {
	$coreStore.showContextMenu(event.clientX, event.clientY, 'icon', props.item);
};
const onClick = function() {
	if (!props.item.loading) {
		$coreStore.selectIcon(props.item.id);
	}
};
const onDblclick = function() {
	if (!props.item.loading) {
		$coreStore.openApplication(props.item.id);
	}
};

const draggedItem = ref(null);
// 开始拖拽
function onDragstart(event) {
	draggedItem.value = event.target;
}
// 拖拽结束
function onDragend(event) {
	const gridContainer = document.querySelector('.icon-grid-container');
	const maxColumn = Math.ceil((gridContainer.clientWidth + 10) / 110) - 1;
	const maxRow = Math.ceil((gridContainer.clientHeight + 10) / 110) - 1;

	let newColumn = Math.ceil((event.clientX + 10) / 110);
	let newRow = Math.ceil((event.clientY + 10) / 110);
	newColumn = (newColumn > maxColumn) ? maxColumn : newColumn;
	newRow = (newRow > maxRow) ? maxRow : newRow;

	const itemsWithInlineStyle = document.querySelectorAll('.icon-item[style]');
	for (let i = 0; i < itemsWithInlineStyle.length; i++) {
		const item = itemsWithInlineStyle[i];
		if (item.style.gridColumn == newColumn && item.style.gridRow == newRow) {
			return; // 跳出整个 onDragend 函数
		}
	}

	draggedItem.value.style.gridColumn = newColumn;
	draggedItem.value.style.gridRow = newRow;
}
</script>

<style lang="less" scoped>
.icon-item {
	display: flex;
	&.selected {
		.icon-body {
			background: rgba(0, 134, 255, 0.27);
		}
	}
	.icon-body {
		border-radius: 4px;
		width: 90px;
		.icon {
			height: 64px;
			width: 64px;
			background-color: #fff;
			border-radius: 25%;
			background-size: 85px;
			margin: 0 auto;
		}
		// 动画
		// .icon:active {
		// 	animation: heartBeat 2s;
		// }
		.text {
			min-height: 20px;
			line-height: 20px;
			text-align: center;
			overflow: hidden;
			word-wrap: break-word;
			word-break: break-all;
			text-overflow: ellipsis;
			color: #FFF;
			text-shadow: 2px 2px 2px #000;
		}
	}
	
	.icon-body:hover {
		background:rgba(43, 115, 199, 0.2);
	}
	&[draggable="true"] {
		&:active {
			background: transparent;
			opacity: 0.5;
			.icon-body:hover {
				background: transparent;
			}
		}
	}
}
</style>
