<template>
  <div class="work">
    <a-breadcrumb separator=">">
      <a-breadcrumb-item>
        <router-link to="/console/eduAdmin">教务管理</router-link>
      </a-breadcrumb-item>
      <a-breadcrumb-item>
        <router-link :to="`/console/eduAdmin/class/?id=${class_id}&mode=readonly`">{{
            data_list.class_name
          }}
        </router-link>
      </a-breadcrumb-item>
      <a-breadcrumb-item>
        <router-link :to="`/console/eduAdmin/class/course?id=${course_id}&class_id=${class_id}`">
          {{ data_list.course_name }}
        </router-link>
      </a-breadcrumb-item>
      <a-breadcrumb-item>课程作业</a-breadcrumb-item>
    </a-breadcrumb>
    <div class="info">
      <a-flex justify="space-between" align="center">
        <h4><b>课程作业</b></h4>
        <div class="menu">
          <a-button type="primary" @click="publish">发布新作业</a-button>
        </div>
      </a-flex>
      <a-table :columns="columns" :dataSource="data_list.records" :pagination="pagination">
        <template #bodyCell="{ column, record, text }">
          <template v-if="column.dataIndex == 'submit'">{{ text }}/{{ data_list.people_number }}</template>
          <template v-if="column.dataIndex == 'correct'">{{ text }}/{{ record.submit }}</template>
          <template v-if="column.dataIndex == 'operation'">
            <a v-if="record.submit>0" href="#" @click.prevent="batch(record.id)">开始批改</a>
            <a v-else>暂无提交</a>
          </template>
        </template>
      </a-table>
    </div>
  </div>
</template>

<script setup>
import {logDebug, logError} from "@/utils/logger";
import {onMounted, ref, reactive, watch, markRaw} from 'vue'
import {jsonRPC, getResponseData, getFailedMessage} from "@/utils/http_utils";
import {getRouterQuery} from "@/utils/router_utils";
import {useRouter} from "vue-router";
import {message} from "ant-design-vue";

const router = useRouter();
const course_id = ref(getRouterQuery(router, "course_id"));
const class_id = ref(getRouterQuery(router, "class_id"));

const publish = () => {
  router.push(`/console/eduAdmin/class/course/publish?course_id=${course_id.value}&class_id=${class_id.value}&mode=create`);
}
const batch = (e) => {
  router.push(`/console/eduAdmin/class/course/batch?course_id=${course_id.value}&class_id=${class_id.value}&work_id=${e}`);
}
const data_list = reactive({
  class_name: '',
  course_name: '',
  people_number: 0,
  type_id: '',
  score: '',
  records: [],
  record_count: 0,
})

function fetchData() {
  jsonRPC({
    url: `/vue/console/eduAdmin/class/course/work`,
    params: {
      course_id: course_id.value,
      class_id: class_id.value,
      page_index: pagination.current,
      page_size: pagination.pageSize,
    },
    success(res) {
      const data = getResponseData(res);
      logDebug(`获取成功需求数据成功`, data);
      Object.assign(data_list, data);
      pagination.total = data.record_count
    },
    fail(error) {
      logError(`查询失败, `, error);
      message.error(`${getFailedMessage(error)}`);
    },
  })
}

onMounted(fetchData)
const columns = [
  {
    title: '作业名称',
    dataIndex: 'name',
    align: 'center',
  },
  {
    title: '发布时间',
    dataIndex: 'create_date',
    align: 'center',
  },
  {
    title: '提交截止时间',
    dataIndex: 'end_datetime',
    align: 'center',
  },
  {
    title: '提交情况',
    dataIndex: 'submit',
    align: 'center',
  },
  {
    title: '批改情况',
    dataIndex: 'correct',
    align: 'center',
  },
  {
    title: '操作',
    dataIndex: 'operation',
    align: 'center',
  },
];
const handlePageChange = (page, pageSize) => {
  logDebug(`page[${page}], pageSize[${pageSize}]`)
  pagination.current = page;
  fetchData();
}
const pagination = reactive({
  // 分页配置
  pageSize: 8, // 每页显示的条数
  showSizeChanger: false, // 是否可以改变每页显示的条数
  pageSizeOptions: ['5', '10', '20'], // 可选的每页显示条数
  showQuickJumper: false, // 是否可以快速跳转到指定页
  showTotal: total => `共 ${total} 条`, // 显示总条数和当前数据范围
  hideOnSinglePage: true, // 只有一页时是否隐藏分页器 true为不显示 false为显示
  current: 1, // 当前页数
  total: 0, // 总条数
  onChange: markRaw(handlePageChange), // 页码改变时的回调函数
})
watch([pagination.current], fetchData);
</script>

<style scoped lang="scss">
.work {
  height: 100%;
  width: 100%;
  overflow: auto;
  padding: 16px;

  .menu {
    button {
      margin-bottom: 10px;
    }
  }

  .info {
    background-color: #fff;
    padding: 1.5rem;

    h4 {
      display: inline-block;
      font-size: 18px;
      padding-bottom: 5px;
      padding-right: 30px;
      border-bottom: 2px solid #63B1FF;
    }

    table {
      text-align: center;

      td {
        height: 46px;
      }
    }
  }
}
</style>
