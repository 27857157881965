import { defineStore } from 'pinia'
import {reactive,toRefs } from 'vue'; 
import helper from '@/utils/helper.js'

const messageStore = defineStore('message', () => {
	const state = reactive({
		count: 0,
		current: {},
		list: []
	});
	
	const pushMessage =  function(message) {
		message.id = helper.uuid()
		message.timestamp = new Date()
		state.current = message
		state.list.push(message)
		state.count = state.list.length
	}
	
	const cleanMessage = function() {
		state.list = []
		state.count = 0
	}
	
	const deleteMessage = function(id) {
		state.list.forEach(function(item, i) {
			if (item.id == id) {
				state.list.splice(i, 1)
			}
		})
	}
	
	return {
		pushMessage,deleteMessage,cleanMessage,
		...toRefs(state)
	}
});
export default messageStore
