<template>
  <div id="live-form" style="color: #ffffff">
<!--    <a-typography>-->
<!--      <a-typography-text type="danger">-->
<!--        “创建”或“修改”使用相同的组件，参考Odoo的Form视图做法。根据editorMode显示“创建”或者“修改”。-->
<!--        另外，如果没有特别需求，“详情”页面也可以使用该相同组件，只不过其editorMode设置为“readonly”，也就是只读。-->
<!--        然后在“readonly”模式下，添加一个“编辑”按钮，从而从“readonly”模式进入到“update”模式，从而允许修改。-->
<!--      </a-typography-text>-->
<!--    </a-typography>-->

    <a-row v-if="editorMode === 'create'" style="margin: 10px">
      <a-col :span="20">
        <a-typography-title :level="4">
          创建岗位
        </a-typography-title>
      </a-col>
      <a-col :span="4" style="display: flex; justify-content: center">
        <a-button type="primary" @click="cancelForm">
          取消创建
        </a-button>
      </a-col>
    </a-row>

    <a-row v-else-if="editorMode === 'update'" style="margin: 10px">
      <a-col :span="20">
        <a-typography-title :level="4">
          编辑岗位
        </a-typography-title>
      </a-col>
      <a-col :span="4" style="display: flex; justify-content: center">
        <a-button type="primary" @click="editorMode = 'readonly'">
          取消编辑
        </a-button>
      </a-col>
    </a-row>

    <a-row v-else-if="editorMode === 'readonly'" style="margin: 10px">
      <a-col :span="18">
        <a-typography-title :level="4">
          岗位详情
        </a-typography-title>
      </a-col>
      <a-col :span="6" style="display: flex; justify-content: space-around">
        <div>
          <a-button type="primary" @click="openDeleteModal=true">删除</a-button>
          <a-modal v-model:open="openDeleteModal" title="删除" @ok="confirmFormDelete">
            <p>确定删除？ 删除ID：{{ jobId }}</p>
          </a-modal>
        </div>
        <a-button type="primary" @click="cancelForm">
          返回
        </a-button>
        <a-button type="primary" @click="editorMode = 'update'">
          进入编辑
        </a-button>
      </a-col>
    </a-row>

    <a-row v-else style="margin: 10px">
      <a-col :span="20">
        <a-typography-title :level="4">
          岗位详情
        </a-typography-title>
      </a-col>
      <a-col :span="4" style="display: flex; justify-content: center">
        <a-button type="primary" v-if="editorMode === 'readonly'" @click="editorMode = 'update'">
          进入编辑
        </a-button>
      </a-col>
    </a-row>


    <a-breadcrumb separator=">">
      <a-breadcrumb-item>
        <router-link :to="`/console/job`">岗位列表</router-link>
      </a-breadcrumb-item>
      <a-breadcrumb-item>{{createJobForm.name}}</a-breadcrumb-item>
    </a-breadcrumb>

    <a-form id="jobDetail"
        :label-col="labelCol"
        :wrapper-col="wrapperCol"
        layout="horizontal"
        :disabled="editorMode === 'readonly'"
    >

      <a-row>
        <a-col :span="12">
          <a-form-item label="岗位名称">
            <a-input v-model:value="createJobForm.name" />
          </a-form-item>


<!--          <a-form-item label="工作地区：">-->
<!--            <a-input v-model:value="createJobForm.province_id"/>-->
<!--          </a-form-item>-->

          <a-form-item label="职位类别：">
            <a-select
                ref="select"
                v-model:value="createJobForm.category_id"
                placeholder="请选择职位类别"
                style="width: 100%"
                :options="category_id_options"
                @change="handleCategoryIdChange"
            >
            </a-select>
          </a-form-item>
          <a-form-item label="工作类型：">
            <a-select
                v-model:value="createJobForm.type_id"
                ref="select"
                placeholder="请选择工作类型"
                style="width: 100%"
                :options="type_id_options"
                @change="handleSkillTypeIdsChange"
            ></a-select>
          </a-form-item>

          <a-form-item label="工作经验：">
            <a-select
                ref="select"
                v-model:value="createJobForm.experience_id"
                placeholder="请选择工作经验"
                style="width: 100%"
                :options="experience_id_options"
                @change="handleExperienceIdChange"
            >
            </a-select>

          </a-form-item>

          <a-form-item label="福利待遇：">
            <a-select
                v-model:value="createJobForm.welfare_ids"
                ref="select"
                mode="multiple" :max-tag-count="3"
                placeholder="请选择福利待遇"
                style="width: 100%"
                :options="welfare_ids_options"
                @change="handleWelfareIdsChange"
            ></a-select>

          </a-form-item>
          <a-form-item label="招聘公司：">
            <a-select
                ref="select"
                v-model:value="createJobForm.company_id"
                placeholder="请选择招聘公司"
                style="width: 100%"
                :options="recruit_company_ids_option"
                @change="handleCompanyIdChange"
            >
            </a-select>

          </a-form-item>
          <a-form-item label="工作地址：">
            <a-input v-model:value="createJobForm.work_address" aria-placeholder="请填写工作地址"/>
          </a-form-item>
<!--          <a-form-item label="招聘时间" name="date_range" v-bind="validateInfos.date_range">-->
<!--            <a-range-picker v-model:value="formState['range-picker']" value-format="YYYY-MM-DD" />-->
<!--          </a-form-item>-->
        </a-col>
        <a-col :span="12">
          <a-form-item label="招聘人数:">
            <a-form-item name="input-number" no-style>
              <a-input-number v-model:value="createJobForm.recruit_count" :min="1" :max="100" :keyboard="true"/>
            </a-form-item>
          </a-form-item>
          <a-form-item label="薪资:" >
            <a-input-number prefix="￥" v-model:value="createJobForm.salary_minimum" :min="0.01" style="width: 45%"/>
            ~
            <a-input-number prefix="￥" v-model:value="createJobForm.salary_maximum" :min="0.01" style="width: 45%"/>
          </a-form-item>


          <a-form-item label="年龄要求：">
            <a-input v-model:value="createJobForm.age_limit"/>
          </a-form-item>
          <a-form-item label="学历要求：">
            <a-select
                v-model:value="createJobForm.education_id"
                ref="select"
                placeholder="请选择学历要求"
                style="width: 100%"
                :options="education_id_options"
                @change="handleEducationIdChange"
            ></a-select>

          </a-form-item>
          <a-form-item label="技能要求：">
            <a-select
                v-model:value="createJobForm.skill_ids"
                ref="select"
                mode="multiple" :max-tag-count="3"
                placeholder="请选择技能要求"
                style="width: 100%"
                :options="skill_ids_options"
                @change="handleSkillIdsChange"
            ></a-select>


          </a-form-item>
          <a-form-item label="招聘负责人:">
            <a-input v-model:value="createJobForm.principal2"/>
          </a-form-item>
          <a-form-item label="岗位录入人：">
            <a-input v-model:value="createJobForm.principal2"/>
          </a-form-item>
        </a-col>
      </a-row>
      <a-row>
        <a-col :span="24">
          <a-form-item label="工作职责：">
            <RichEditor :height="301" :editMode="editorMode" v-model="createJobForm.responsibility"/>
          </a-form-item>
        </a-col>
      </a-row>
      <a-row>
        <a-col :span="24">
          <a-form-item label="任职资格：">
            <RichEditor :height="301" :editMode="editorMode" v-model="createJobForm.qualification"/>
          </a-form-item>
        </a-col>
      </a-row>
      <a-flex justify="center" align="center" class="mt-4">
        <a-button type="primary" v-if="editorMode === 'create'" @click="confirmFormCreate">创建</a-button>
        <a-button type="primary" v-if="editorMode === 'update'" @click="confirmFormUpdate">保存</a-button>
        <a-button v-if="editorMode !== 'readonly'" style="margin-left: 10px" @click="cancelForm">取消</a-button>
      </a-flex>
    </a-form>
  </div>
</template>

<script setup>
import {logDebug, logError} from "@/utils/logger";
import {logComponents} from "@/utils/logger";
import {onMounted, reactive, ref} from 'vue'
import {routerBack, getRouterParam, getRouterQuery, routerReplace} from "@/utils/router_utils";
import {PlusOutlined} from "@ant-design/icons-vue";
import {useRouter} from "vue-router";
import {resolveEditorMode, setEditorMode} from "@/utils/form_utils";
import {jsonRPC} from "@/utils/http_utils";
import RichEditor from "@/components/RichEditor.vue";

const router = useRouter()

logDebug('ConsoleLiveForm setup.')

const labelCol = {
  style: {
    width: '150px',
  },
};
const wrapperCol = {
  span: 14,
};



const createJobForm = reactive({
  id: '',
  name: '',// 岗位名称
  skill_ids: [],
  education_id:'',
  province_name: '', // 省份
  city_name: '', // 城市
  county_name: '',
  recruit_count: '1', // 招聘人数
  work_address: '',
  age_limit: '',
  type_id: '',
  province_id: '',
  company_id: '',
  experience_name: '',
  education_name: '',
  salary_minimum: '', //最高薪水
  salary_maximum: '', // 最低薪水
  state: '',
  category_id: '', // 职位类别
  file_list:[],
  experience_id:[],
  welfare_ids:[],
  responsibility:'', // 工作职责
  principal2:'',// 招聘负责人
  qualification:'', // 任职资格
})


const category_id_options = ref([
  {
    value: '',
    label: '',
  },
]);

const skill_ids_options = ref([
  {
    value:'',
    label:'',
  }
])
const type_id_options = ref([
  {
    value:'',
    label:'',
  }
])
const welfare_ids_options = ref([
  {
    value:'',
    label:'',
  }
])
const education_id_options = ref([
  {
    value:'',
    label:'',
  }
])
const experience_id_options = ref([
  {
    value:'',
    label:'',
  }
])
const recruit_company_ids_option = ref([
  {
    value:'',
    label:'',
  }
])

const handleCategoryIdChange = value => {
  logDebug(`handleCategoryIdChange selected ${value}`);
};
const handleExperienceIdChange = value => {
  logDebug(`handleExperienceIdChange selected ${value}`);
};
const handleEducationIdChange = value => {
  logDebug(`handleEducationIdChange selected ${value}`);
};

const handleSkillIdsChange = value => {
  logDebug(`handleSkillIdsChange selected ${value}`);
};
const handleSkillTypeIdsChange = value => {
  logDebug(`handleSkillTypeIdsChange selected ${value}`);
};
const handleWelfareIdsChange = value => {
  logDebug(`handleSkillIdsChange selected ${value}`);
};
const handleCompanyIdChange = value => {
  logDebug(`handleCompanyIdChange selected ${value}`);
};


// 创建
const confirmFormCreate = e => {
  logDebug(`confirmFormCreate, e[${JSON.stringify(e)}]`);
  logDebug(`confirmFormCreate[${JSON.stringify(createJobForm)}]`)
  jsonRPC({
    url: "/api/console/job/create",
    params: {data: JSON.stringify(createJobForm)},
    success(res) {
      logDebug(`创建成功`, res.data.result.data)
      routerBack(router)
    },
    fail(error) {
      logError(`创建失败, `, error)
    },
  });
};
// 保存
const confirmFormUpdate = e => {
  logDebug(`confirmFormUpdate, e[${JSON.stringify(e)}]`);
  logDebug(`confirmFormUpdate[${JSON.stringify(createJobForm)}]`)
  jsonRPC({
    url: `/api/console/job/${jobId.value}/write`,
    params: {
      id: jobId.value,
      data: JSON.stringify(createJobForm),
    },
    success(res) {
      logDebug(`保存成功`, res.data.result.data)
      setEditorMode(editorMode, 'readonly')
      routerBack(router)
    },
    fail(error) {
      logError(`保存失败, `, error)
    },
  });
};
// 取消
const cancelForm = e => {
  logDebug(`cancelForm, e[${JSON.stringify(e)}]`);
  routerBack(router)
};
logComponents([PlusOutlined])


const editorMode = ref('update')
logDebug(`ConsoleLiveForm getRouterParam(router)[${JSON.stringify(getRouterParam(router))}]`)
const jobId = ref(getRouterParam(router, 'job_id'))
resolveEditorMode(editorMode, jobId.value, getRouterQuery(router, 'mode'))

const openDeleteModal = ref(false)
const confirmFormDelete = function () {
  logDebug("confirmFormDelete")
  openDeleteModal.value = false
  delJobData()
  // routerBack(router)
  routerReplace(router, '/console/job')
}

async function getJobBaseInfo(){
  jsonRPC({
    url: "/api/console/job/base_info",
    success(res) {
      logDebug(`获取Job基础信息成功data`, res.data.result.data)
      logDebug(`获取Job基础信息成功`, res.data.result.data.category_id_option)
      category_id_options.value= res.data.result.data.category_id_option
      skill_ids_options.value= res.data.result.data.skill_id_option
      welfare_ids_options.value= res.data.result.data.welfare_ids_option
      type_id_options.value= res.data.result.data.type_id_option
      education_id_options.value= res.data.result.data.education_id_option
      experience_id_options.value= res.data.result.data.experience_id_option
      recruit_company_ids_option.value= res.data.result.data.recruit_company_ids_option
    },
    fail(error) {
      logError(`查询失败, `, error)
    },
  });
  return true
}

function getJobData(){
//   获取当前传递来的 Job Id 详情信息
  jsonRPC({
    url: `/api/console/job/${jobId.value}/detail`,
    success(res) {
      logDebug(`获取Job详情信息成功data`, res.data.result.data)
      let data = res.data.result.data
      Object.assign(createJobForm, data)
    },
    fail(error) {
      logError(`查询失败, `, error)
    },
  });
  return true
}


function delJobData(){
  jsonRPC({
    url: `/api/console/job/${jobId.value}/delete`,
    success(res) {
      logDebug(`获取Job基础信息成功data`, res.data.result.data)
    },
    fail(error) {
      logError(`查询失败, `, error)
    },
  });
  return true
}
onMounted(async () => {
  getJobData()
  await getJobBaseInfo()
})

</script>

<style scoped>

</style>
