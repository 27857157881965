<template>
  <div>
    <a-button id="function-full-screen" type="primary" @click="clickHandler"
              :style="props.styleSetting.buttonStyle">
      <a-tooltip v-if="!fullscreen">
        <template #title>全屏</template>
        <FullscreenOutlined :style="props.styleSetting.iconStyle"/>
      </a-tooltip>
      <a-tooltip v-else>
        <template #title>退出全屏</template>
        <FullscreenExitOutlined :style="props.styleSetting.iconStyle"/>
      </a-tooltip>
    </a-button>
  </div>
</template>

<script setup>
import {logDebug} from "@/utils/logger";
import {onMounted, onBeforeUnmount, ref} from 'vue';
import {FullscreenExitOutlined, FullscreenOutlined} from '@ant-design/icons-vue';

const props = defineProps({
  primal: {type: String},
  data: {type: Object},
  styleSetting: {type: Object},
})

const primal = props.primal
const data = props.data

// 组件逻辑
const fullscreen = ref(false);

const handleFullScreen = function () {
  let element = document.getElementById('live_page');
  
  // 检查当前文档是否处于全屏状态
  if (fullscreen.value) {
    if (document.fullscreenElement) {
      document.exitFullscreen();
    } else if (document.webkitFullscreenElement) {
      document.webkitCancelFullScreen();
    } else if (document.mozFullScreenElement) {
      document.mozCancelFullScreen();
    } else if (document.msFullscreenElement) {
      document.msExitFullscreen();
    }
  } else {
    if (element.requestFullscreen) {
      element.requestFullscreen();
    } else if (element.webkitRequestFullScreen) {
      element.webkitRequestFullScreen();
    } else if (element.mozRequestFullScreen) {
      element.mozRequestFullScreen();
    } else if (element.msRequestFullscreen) {
      element.msRequestFullscreen();
    }
  }

  fullscreen.value = !fullscreen.value;
};
const handleKeyDown = function (event) {
  if (event.key === 'Escape' && fullscreen.value) {
    handleFullScreen();
  }
};
onMounted(() => {
  document.addEventListener('keydown', handleKeyDown);
});
onBeforeUnmount(() => {
  document.removeEventListener('keydown', handleKeyDown);
});

const clickHandler = function () {
  logDebug('全屏')
  const element = document.querySelector('.live-play');
  if (element) {
    if (!document.fullscreenElement) {
      if (element.requestFullscreen) {
        element.requestFullscreen();
      } else if (element.webkitRequestFullscreen) {
        element.webkitRequestFullscreen();
      } else if (element.msRequestFullscreen) {
        element.msRequestFullscreen();
      }
    } else {
      if (document.exitFullscreen) {
        document.exitFullscreen();
      } else if (document.webkitExitFullscreen) {
        document.webkitExitFullscreen();
      } else if (document.msExitFullscreen) {
        document.msExitFullscreen();
      }
    }
  } else {
    handleFullScreen()
  }
  logDebug(JSON.stringify(props.data))

  // 下面是触发父组件回调方法的示例：
  // emit("ok", "我是子组件fullscreen传入的ok值"); //触发事件
  // emit("cancel", "我是子组件fullscreen传入的cancel值"); //触发事件
  // emit("refresh", "我是子组件fullscreen传入的refresh值"); //触发事件
  // emit("customize", {
  //   'arg1': '参数1',
  //   'arg2': 2,
  // }) //触发事件
}

const onSelected = function () {
  logDebug(`TerminalFunctionFullScreen onSelected.`)
}

defineExpose({
  primal,
  data,
  onSelected,
})
</script>

<style scoped>
#function-full-screen {
  background: #90EE90;
}
</style>

