<template>
  <div id="job_list">
    <a-card style="width: 100%;">
      <a-row id="search_item" style="align-items: center;justify-content: space-around">
        <a-col :span="16">
          <a-space :size="[0, 8]" wrap>
            <a-radio-group
                v-model:value="default_select"
                :options="tagsDataOptions"
                optionType="button"
                buttonStyle="solid"
                @change="checked => handleTagChange(checked)"/>
          </a-space>
        </a-col>
        <a-space>
          <a-input-search placeholder="请输入岗位名称" style="width: 200px" enter-button allowClear @search="onSearch"/>
          <div>
            <a-button type="primary">
              <router-link to="/console/job/form/create">发布职位</router-link>
            </a-button>
          </div>
        </a-space>
      </a-row>
    </a-card>
    <a-row class="gutter-row" :gutter="{ xs: 8, sm: 16, md: 24, lg: 32 }" style="margin: 0;">
      <a-col class="gutter-col" :span="24" style="padding: 0;">
        <a-list item-layout="horizontal" :pagination="false" size="large" :data-source="data_list" rowKey="item.id">
          <template #renderItem="{ item }">
            <a-badge-ribbon :text="item.badge_data.text" :color="item.badge_data.color" placement="start"
                            style="margin-left: 8px;margin-top: -8px;">
              <a-list-item key="item.title" style="margin: 3px 0px;background-color: #ffffff;height: 130px">
                <template #actions>
                  <div>
                    <div>
                      <router-link key="list-loadmore-edit" :to="`/console/job/form/${item.id}?mode=readonly`">编辑
                      </router-link>
                    </div>
                  </div>
                  <a-dropdown v-if="item.state !== 'done'">
                    <a class="ant-dropdown-link" @click.prevent>
                      操作
                      <DownOutlined/>
                    </a>
                    <template #overlay>
                      <a-menu>
                        <a-menu-item v-if="item.state === 'doing'">
                          <a type="primary" @click="showConfirm(item.id)">停止招聘</a>
                        </a-menu-item>
                        <a-menu-item v-if="item.state === 'draft'">
                          <a type="primary" @click="showOpenJobConfirm(item.id)">开放岗位</a>
                        </a-menu-item>
                      </a-menu>
                    </template>
                  </a-dropdown>
                </template>
                <a-list-item-meta>
                  <template #title>
                    <router-link v-if="item.state !== 'done'" key="list-loadmore-edit"
                                 :to="`/console/job/form/${item.id}?mode=readonly`">
                      <a-typography-title
                          style="margin-top: 23px;max-width: 80%"
                          :level="4"
                          :ellipsis="item.name.length > 5 ? { tooltip: `${item.name}` } : false"
                          :content="item.name"
                      >
                      </a-typography-title>
                    </router-link>
                    <a-typography-title v-else
                                        style="margin-top: 23px;max-width: 80%"
                                        :level="4"
                                        :ellipsis="item.name.length > 5 ? { tooltip: `${item.name}` } : false"
                                        :content="item.name"
                    >
                    </a-typography-title>


                    <a-space :size="[0, 'small']" wrap v-if="item.skills.length > 0">
                      <a-tag v-for="(skill, index) in item.skills.slice(0, 6)"
                             :key="skill.id"
                             :id="index"
                             :bordered="false"
                             :color="generateRandomColor(skill.id)">
                        {{ skill.name }}
                      </a-tag>
                    </a-space>
                  </template>
                  <template #description>
                    <a-space>
                      <div v-if="item.province_name || item.city_name || item.county_name">
                        <a v-if="item.province_name">{{ item.province_name }}-</a>
                        <a v-if="item.city_name">{{ item.city_name }}-</a>
                        <a v-if="item.county_name">{{ item.county_name }}</a>
                      </div>
                      <div v-if="item.experience_name">
                        <a-divider type="vertical"/>
                        {{ item.experience_name }}
                      </div>
                      <div v-if="item.education_name">
                        <a-divider type="vertical"/>
                        {{ item.education_name }}
                      </div>
                    </a-space>
                  </template>
                </a-list-item-meta>
                <a-list-item-meta>
                  <template #description>
                    <div>
                      <h5 style="color: #FF5200;margin-bottom: 0;display: inline-block">
                        <a v-if="item.salary_minimum">{{ item.salary_minimum }}-</a>
                        <a v-if="item.salary_maximum">{{ item.salary_maximum }}</a>
                      </h5>
                      <a style="display: inline;"> 元/月</a>
                    </div>
                  </template>
                </a-list-item-meta>
                <a-list-item-meta>
                  <template #description>
                    <div>
                      <div style="display: flex;">
                        <div style="flex: 1; text-align: center;">
                          <p style="display: inline;">{{ item.delivery_ids.length }}</p>
                          <br>
                          <a style="display: inline;color: #000000">已投递</a>
                        </div>

                        <div style="flex: 1; text-align: center;">
                          <p style="display: inline;">{{ item.invite_ids.length }}</p>
                          <br>
                          <a style="display: inline;color: #000000">面试邀请</a>
                        </div>

                        <div style="flex: 1; text-align: center;">
                          <p style="display: inline;">{{ item.success_ids.length }}</p>
                          <br>
                          <a style="display: inline;color: #000000">面试成功</a>
                        </div>

                        <div style="flex: 1; text-align: center;">
                          <p style="display: inline;">{{ item.fail_ids.length }}</p>
                          <br>
                          <a style="display: inline;color: #000000">面试失败</a>
                        </div>
                      </div>
                    </div>
                  </template>
                </a-list-item-meta>
              </a-list-item>
            </a-badge-ribbon>
          </template>
        </a-list>
      </a-col>
    </a-row>
    <a-flex justify="center" class="py-3">
      <a-pagination
          v-model:current="current"
          :showSizeChanger="showSizeChanger"
          v-model:page-size="pageSize"
          :total="total"
          :locale="zhCn"
          :pageSizeOptions="pageSizeOptions"
          :hideOnSinglePage="true"
      />
    </a-flex>
  </div>
</template>

<script setup>
import {logDebug, logError} from "@/utils/logger";
import {onMounted, reactive, ref, watch} from 'vue'
import {logComponents} from "@/utils/logger";
import {PlusOutlined} from "@ant-design/icons-vue";
import {jsonRPC} from "@/utils/http_utils";
import {zhCn} from "@/utils/zhCn";
import {ExclamationCircleOutlined} from '@ant-design/icons-vue';
import {createVNode} from 'vue';
import {Modal, notification} from 'ant-design-vue';

logDebug('ConsoleClass setup.')
logComponents([PlusOutlined])

const data_list = ref([]);
const total = ref(0);
const showSizeChanger = ref(true);
const pageSize = ref(8);
const current = ref(1);
const search = ref('');
const search_state = ref(null);
const pageSizeOptions = ref(Array.from({length: 4}, (_, index) => (pageSize.value * (index + 1)).toString()));

const loading = ref(true);

const tagsDataOptions = reactive([
  {
    label: '全部',
    value: 'all',
  },
  {
    label: '招聘中',
    value: 'doing',
  },
  {
    label: '待开放',
    value: 'draft',
  },
  {
    label: '已关闭',
    value: 'done',
  },
]);

const default_select = ref(tagsDataOptions[0].value);
const colors = ['green', 'red', 'yellow', 'orange', 'pink', 'cyan', 'blue', 'purple', 'geekblue', 'magenta', 'volcano', 'gold', 'lime'];


function generateRandomColor(id) {
  const index = id % colors.length;
  return colors[index];
}

function showConfirm(id) {
  Modal.confirm({
    okText: "确认",
    cancelText: "取消",
    title: '停止招聘',
    icon: createVNode(ExclamationCircleOutlined),
    content: '您确认停止当前招聘？',
    onOk() {
      return jsonRPC({
        url: '/api/console/job/state/change',
        params: {
          job_id: id,
          job_status: 'done',
        },
        success(res) {
          notification.success({
            message: '修改成功',
            description:
                '招聘状态修改成功！',
          })
          fetchData()
          logDebug(`招聘状态修改成功！`, res)
        },
        fail(error) {
          notification.error({
            message: '修改失败',
            description:
                '招聘状态修改失败，请稍后再试！',
          })
          logError(`招聘状态修改失败, `, error)
        },
      })
    },
    onCancel() {
      logDebug("点击了取消！")
    },
  });
}

function showOpenJobConfirm(id) {
  Modal.confirm({
    okText: "确认",
    cancelText: "取消",
    title: '开放岗位',
    icon: createVNode(ExclamationCircleOutlined),
    content: '您确认开放当前岗位？',
    onOk() {
      return jsonRPC({
        url: '/api/console/job/state/change',
        params: {
          job_id: id,
          job_status: 'doing',
        },
        success(res) {
          notification.success({
            message: '修改成功',
            description:
                '招聘状态修改成功！',
          })
          fetchData()
          logDebug(`招聘状态修改成功！`, res)
        },
        fail(error) {
          notification.error({
            message: '修改失败',
            description:
                '招聘状态修改失败，请稍后再试！',
          })
          logError(`招聘状态修改失败, `, error)
        },
      })
    },
    onCancel() {
      logDebug("点击了取消！")
    },
  });
}

function handleTagChange() {
  let search_value = default_select.value
  if (search_value === 'all') {
    current.value = 1;
    search_state.value = null;
    fetchData();
  } else {
    current.value = 1;
    search_state.value = search_value;
    fetchData();
  }

}


function fetchData() {
  jsonRPC({
    url: "/api/console/job",
    params: {
      state: search_state.value,
      page_index: current.value,
      page_size: pageSize.value,
      search: search.value,
    },
    success(res) {
      logDebug(`查询成功`, res.data.result.data)
      data_list.value = res.data.result.data.records
      total.value = res.data.result.data.record_count
      loading.value = !loading.value;
    },
    fail(error) {
      logError(`查询失败, `, error)
    },
  });
}

onMounted(() => {
  fetchData()
});
watch([pageSize, current], fetchData);

const onSearch = (searchValue) => {
  if (search.value != searchValue) {
    current.value = 1;
    search.value = searchValue;
    fetchData();
  }
};
</script>

<style scoped>


</style>